import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';
import numberMask from 'text-mask-addons/dist/createNumberMask';
import { totalIncomeMaxNLS, FinancialValidation } from '../financial-validation';
import { validateNumberFactory } from '../validation/number-validator.directive';


import { CropInventory } from './crop-inventory';
import { CropItemFactory } from '../reusables/crop-item-factory';
import { YieldUnitsFactory } from '../reusables/yield-units-factory';


import { CropInventoryItem } from '../../models/crop-inventory-item';
import { IItemFactory } from '../reusables/autocomplete/autocomplete.component';

@Component({
    selector: 'rol-financial-information',
    templateUrl: 'financial-information.component.html',
    styleUrls: ['../styles_form.css', 'financial-table.css']
})
export class FinancialInformationComponent extends AbstractFormPage implements OnInit {

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService) {
        super(currentAppSvc, router, utilities, history);
        this.pageAlias = 'balancesheet';
        this.initPage();
    }

    public get totalAssetsInvalid(): boolean {
        return this.application.balanceSheet.totalAssets > this.totalIncomeMax;
    }

    public get totalLiabilitiesInvalid(): boolean {
        return this.application.balanceSheet.totalLiabilities > this.totalIncomeMax;
    }

    public get totalFarmIncomeInvalid(): boolean {
        return this.application.income.totalFarmIncome > this.totalIncomeMax;
    }
    form: TopForm = new TopForm();
    currentAssets: FormControl;
    termAssets: FormControl;
    currentLiabilities: FormControl;
    termLiabilities: FormControl;
    intermediateAssets: FormControl;
    intermediateLiabilities: FormControl;
    creationDate: FormControl;
    minBalanceSheetDate: moment.Moment;
    minDateError = false;
    maxDateError = false;
    minDate: Date = new Date(this.getMinimumBalanceSheetDate()['_d']);
    financialValidation: FinancialValidation = new FinancialValidation();
    totalIncomeMax: number = totalIncomeMaxNLS;
    cropInventory = new CropInventory(this.utilities);
    cropFactory: IItemFactory = new CropItemFactory();
    yieldUnitsFactory: IItemFactory = new YieldUnitsFactory();

    mask = numberMask({
        prefix: '$',
        includeThousandsSeperator: true,
        allowDecimal: true,
    });

    numbersOnlyMask = function (valueInput: string) {
        const mask = [];
        for (let i = 0; i < valueInput.length; ++i) {
            mask.push(/\d/);
        }
        return mask;
    };

    ngOnInit() {
        this.currentAssets = new FormControl(
            '',
            [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.termAssets = new FormControl('', [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.currentLiabilities = new FormControl(
            '',
            [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.termLiabilities = new FormControl(
            '',
            [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.intermediateAssets = new FormControl(
            '',
            [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.intermediateLiabilities = new FormControl(
            '',
            [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.creationDate = new FormControl('', []);
        this.form.addControl('currentAssets', this.currentAssets);
        this.form.addControl('termAssets', this.termAssets);
        this.form.addControl('currentLiabilities', this.currentLiabilities);
        this.form.addControl('termLiabilities', this.termLiabilities);
        this.form.addControl('intermediateLiabilities', this.intermediateLiabilities);
        this.form.addControl('intermediateAssets', this.intermediateAssets);
        this.form.addControl('creationDate', this.creationDate);
        this.minBalanceSheetDate = this.getMinimumBalanceSheetDate();
        this.checkDateAge();
    }

    getMinimumBalanceSheetDate(): moment.Moment {
        return moment().subtract(12, 'month');
    }

    checkDateAge() {
        if (this.application && this.application.balanceSheet) {
            this.minDateError = this.application.balanceSheet.creationDate < new Date(this.minDate.toDateString());
            this.maxDateError = this.application.balanceSheet.creationDate > new Date();    
        }
    }

    submit() {
        this.form.updateValueAndValidity();
        this.form.submitted = true;

        const noDateError = (!this.application.balanceSheet.creationDate && this.application.balanceSheet.totalEquity > 0);

        const totalsOutsideOfRange = this.totalAssetsInvalid || this.totalLiabilitiesInvalid || this.totalFarmIncomeInvalid;

        if (this.minDateError || this.maxDateError || noDateError) {
            window.scrollTo(0, 0);
        } else {
            if (this.form.valid && !totalsOutsideOfRange) {
                this.next();
            } else {
                this.scrollToError();
            }
        }
    }

    addCropInventoryItem() {
        this.cropInventory.form.submitted = true;
        if (this.cropInventory.form.valid) {
            if (this.cropInventory.editingCropInventory) {
                const match = this.application.income.cropInventory.findIndex((crop) => crop === this.cropInventory.editingCropInventory);
                if (match !== -1) {
                    const crop = this.cropInventory.value;
                    this.application.income.cropInventory[match] = crop;
                }
                this.cropInventory.editingCropInventory = null;
            } else {
                const crop = this.cropInventory.value;
                this.application.income.cropInventory.push(crop);
            }

            this.cropInventory.form.submitted = false;
            this.cropInventory.form.reset();            
        }
    }

    cropInventoryUpdate(crop: CropInventoryItem) {
        if (!this.cropInventory.editingCropInventory 
            || (this.cropInventory.editingCropInventory && this.cropInventory.editingCropInventory !== crop)) {

            this.cropInventory.editingCropInventory = crop;
            this.cropInventory.update(crop);
        } else if (this.cropInventory.editingCropInventory) {
            this.cropInventory.form.submitted = false;
            this.cropInventory.editingCropInventory = null;
            this.cropInventory.form.reset();
        }
    }

    cropInventoryRemove(cropToRemove: CropInventoryItem) {
        const match = this.application.income.cropInventory.findIndex((crop) => crop === cropToRemove);
        if (match !== -1) {
            this.application.income.cropInventory.splice(match, 1);
            this.cropInventory.editingCropInventory = null;
            this.cropInventory.form.reset();
            return true;
        }
        return false;
    }


}
