import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SurveyQuestionService } from '../../services/survey-questions-http.service';
import { SurveyQuestion } from '../../models/survey-question';

@Component({
    selector: 'rol-survey-component',
    template: `
        <form (ngSubmit)="submit()" class="survey-container" *ngIf="questions && !showSubmitted">
            <h3 class="survey-header">{{Title}}</h3>
            <div class="survey-question form-group" *ngFor="let question of questions; let i = index;">
                <label>{{i+1}}. {{question.question}}</label>
                <input name="{{'single'+i}}" *ngIf="question.type.SingleLine" type="text" class="form-control" [(ngModel)]="question.answer" />
                <textarea name="{{'multi'+i}}" *ngIf="question.type.FreeText" class="form-control" [(ngModel)]="question.answer" ></textarea>
                <select name="{{'select'+i}}" *ngIf="question.type.MultipleChoice" class="form-control" [(ngModel)]="question.answer" >
                    <option value='' selected></option>
                    <option *ngFor="let option of question.type.MultipleChoice;" [value]="option">{{option}}</option>
                </select>
            </div>
            <div class="group">
                <button class="btn btn-primary" type="submit" >Submit</button>
            </div>
        </form>
        <div class="submitted-container" *ngIf="showSubmitted">
            <h3>{{ThankYou}}</h3>
        </div>
    `,
    styleUrls: ['survey.component.css'],
    providers: [SurveyQuestionService]
})
export class SurveyComponent implements OnInit {
    questions: SurveyQuestion[];
    showSubmitted = false;
    @Input() Title = 'Please take a moment and let us know how we can make your experience better.';
    @Input() ThankYou = 'Thank you.  Your survey questions have been submitted.';
    @Output() surveySubmit: EventEmitter<void> = new EventEmitter<void>();

    ngOnInit() {
        this.surveyService.getQuestions().then((questions) => {
            this.questions = questions;
        });
    }

    constructor(private surveyService: SurveyQuestionService) { }

    submit() {
        this.surveyService.submitQuestions(this.questions);
        this.showSubmitted = true;
        this.surveySubmit.emit(null);

    }
}
