<div class="menu-wrapper stage container">
    <div class="menu-stage">
        <rol-section-header>Additional Loan Documents</rol-section-header>
        <div class="documents raised">
            <h2>
                Below forms to be completed for <span class="underline">Partnership and Corporation/Other applications as applicable</span>
            </h2>
            <ul class="documents-list">
                <li>
                    <a href="assets/Additional_Information_on_Intermediary_Companies.pdf" target="_blank">Intermediary Company(ies) Information Form</a>
                    - Please complete if any Intermediary Companies are in the Borrower's organizational structure
                </li>
                <li>
                    <a href="assets/Trust_Information_Form.pdf" target="_blank">Trust Information Form</a>
                    - Please complete if there is a Trust in the Borrower's ownership structure
                </li>
                <li>
                    <a href="assets/Additional_Signatory_Identity_Verification_Form.pdf" target="_blank">Signatory Identity Verification Form</a>
                    - Both the Signatory and the Richardson Representative need to complete this form if there is a Signatory who is neither
                    a Primary Applicant Co-Borrower nor a Joint Applicant Co-Borrower
                </li>
            </ul>
        </div>
    </div>
</div>