<div class="message-container raised-card stage container" *ngIf="showMessages()">
    <p-accordion>
        <p-accordionTab header="Message Center" [selected]="true">
            <div class="message-header" *ngFor="let header of messages | keys">
                <h5>{{header}}</h5>
                <ul>
                    <li class="message-content" *ngFor="let message of messages[header]">
                        <div class="message-text" [innerHtml]="message.Text | safeHtml"></div>
                    </li>
                </ul>
            </div>
        </p-accordionTab>
    </p-accordion>

</div>