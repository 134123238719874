<div [ngClass.gt-xs]="'programPanel'">
    <p><strong>Please contact a Richardson representative for the Ag Business Centre Name, Number & Customer Number information.</strong></p>

    <div class="card group">
        <div class="control-group businessCentre">
            <label>Ag Business Centre Information</label>
            <div class="form-group">
                <div class="form-group">
                    Ag Business Centre Name: <input type="text" class="form-control" [formControl]="businessCentreName"
                        [(ngModel)]="vendor.businessCentreName" maxlength="50">
                    <div class="error" *ngIf="(form.submitted || businessCentreName.dirty) && businessCentreName.invalid">
                        <p>Invalid name</p>
                    </div>
                </div>
                <div class="form-group">
                    Ag Business Centre Number: <input type="text" class="form-control" [formControl]="businessCentreNumber"
                        [(ngModel)]="vendor.businessCentreNumber" [textMask]="{mask: businessCentreMask, guide:false}" maxlength="7">
                    <div class="error" *ngIf="(form.submitted || businessCentreNumber.dirty) && businessCentreNumber.invalid">
                        <p>Invalid number</p>
                    </div>
                </div>
                <div class="form-group">
                    Customer Number: <input type="text" class="form-control" [formControl]="customerNumber"
                        [(ngModel)]="vendor.businessCentreCustomerNumber" [textMask]="{mask: customerNumberMask, guide:false}" maxlength="6">
                    <div class="error" *ngIf="(form.submitted || customerNumber.dirty) && customerNumber.invalid">
                        <p>Invalid customer number</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="control-group creditLimit">
            <label>How much credit limit would you like for this program?</label>

            <input type="text" class="form-control" [formControl]="creditLimit" [(ngModel)]="vendor.creditLimit"
                (ngModelChange)="vendor.creditLimit = _utilities.toNumber($event)" name="creditLimit"
                [textMask]="{mask: creditMask, guide:false}" maxlength="14">
            <div class="error" *ngIf="(form.submitted || creditLimit.dirty) && creditLimit.invalid">
                <p *ngIf="creditLimit.errors['required']">Required</p>
                <p *ngIf="creditLimit.hasError('validateNumber') && creditLimit.errors['validateNumber'].NAN">Invalid
                    number</p>
                <p *ngIf="creditLimit.hasError('validateNumber') && creditLimit.errors['validateNumber'].min">Must be
                    greater than 0</p>
                <p *ngIf="creditLimit.hasError('validateNumber') && creditLimit.errors['validateNumber'].max">Cannot be
                    greater than $999,999,999</p>
            </div>
        </div>
    </div>
</div>