import { FormControl } from '@angular/forms';

export function validateIsNotPOBox() {
    return (c: FormControl) => {
        const valStr = c.value;
        if (valStr) {
            const filteredStr = valStr.toString().replace(/\./g, '').toLowerCase();
            if (filteredStr.match(/po\d+|po\s+\d+|po\s+box/)) {
                return { isPOBox: true };
            } else {
                return null;
            }
        } else {
            return null;
        }
    };
}

export function validateIsNotBox() {
    return (c: FormControl) => {
        const valStr = c.value;
        if (valStr) {
            const filteredStr = valStr.toString().replace(/\./g, '').toLowerCase();
            if (filteredStr.match(/^\s*box\s*\d+/)) {
                return { isBox: true };
            } else {
                return null;
            }
        } else {
            return null;
        }
    };
}
