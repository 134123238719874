<div ngFormGroupName="borrower">
    <rol-fullname-component name="fullname" [fullName]="borrower.fullName" Title="Legal Name" [form]="form"
        [group]="group">
    </rol-fullname-component>
    <div class="form-group">
        <label>Email</label>
        <input name="email" class="form-control" [(ngModel)]="borrower.email" placeholder="Email" required type="email"
            #email="ngModel" rolValidateEmail />
        <div class="error" *ngIf="(form.submitted ||email.dirty) && email.invalid">
            <p *ngIf="email.errors['required']">Required</p>
            <p *ngIf="email.errors['validateEmail']">Email is invalid</p>
        </div>
    </div>
    <div class="form-group">
        <rol-date-box [(ngModel)]="borrower.dateOfBirth" label="Date of Birth (mm/dd/yyyy)" rolDateRangeValidator [min]="minDob"
            [max]="maxDob" required name="dob" #dob="ngModel"></rol-date-box>
        <div *ngIf="(form.submitted || dob.dirty) && dob.invalid" class="error">
            <p *ngIf="dob.errors['required']">A date is required</p>
            <p *ngIf="dob.errors['invalidDate']">That is not a valid date. Format is mm/dd/yyyy.</p>
            <p *ngIf="dob.errors['precedesMin']">Please select a date after {{dob.errors['precedesMin'].toDateString()}}
            </p>
            <p *ngIf="dob.errors['exceedsMax']">Please select a date before {{dob.errors['exceedsMax'].toDateString()}}
            </p>
        </div>
    </div>
    <rol-multiple-phone-component [borrower]="borrower" [form]="form"></rol-multiple-phone-component>

    <rol-combined-address-component name="combAddress" [(physicalAddress)]="borrower.physicalAddress"
        [(mailingAddress)]="borrower.mailingAddress" [form]="form" [group]="group">
    </rol-combined-address-component>
    
    <div *ngIf="borrower.isControllingParty === true" class="form-group">
        <label for="signerTitle">Company Role/Title</label>
        <input #signerTitle="ngModel" name="signerTitle" class="form-control" [(ngModel)]="borrower.signerTitle"
            required placeholder="Title">
        <div *ngIf="(form.submitted || signerTitle.dirty) && signerTitle.invalid" class="error">
            <p *ngIf="signerTitle.errors">Required</p>
        </div>
    </div>
    <div class="form-group">
        <label>Is Primary Occupation other than farming?</label>
        <select [ngModel]="borrower.primaryOccupationNotFarmer" (change)="changeOccupationNotFarmer($event.target.value)" name="primaryOccupationNotFarmer" required
            class="form-control">
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    </div>
    <div *ngIf="borrower.primaryOccupationNotFarmer" class="form-group">
        <label for="primaryOccupation">Primary Occupation</label>
        <input #primaryOccupation="ngModel" name="primaryOccupation" class="form-control" [(ngModel)]="borrower.primaryOccupation"
            required placeholder="Occupation">
        <div *ngIf="(form.submitted || primaryOccupation.dirty) && primaryOccupation.invalid" class="error">
            <p *ngIf="primaryOccupation.errors">Required</p>
        </div>
    </div>
    <div class="form-group">
        <label>Canadian Citizen</label>
        <select [ngModel]="borrower.isCanadianCitizen" (change)="changeCitizen($event.target.value)" name="canadiancitizen" required
            class="form-control">
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    </div>
    <div class="form-group" *ngIf="!borrower.isCanadianCitizen">
        <label>Country of Citizenship</label>
        <select [(ngModel)]="borrower.countryOfCitizenship" name="citizenshipCountry" class="form-control"
            #citizenshipCountry="ngModel" required>
            <option *ngFor="let x of citizenshipCountries" value="{{x}}">{{x}}</option>
        </select>
        <div *ngIf="(form.submitted || citizenshipCountry.dirty) && !citizenshipCountry.valid" class="error">
            Required
        </div>
    </div>
    <div class="form-group">
        <label>Bankruptcy Filing</label>
        <select [ngModel]="borrower.hasBankruptcy" (change)="changeBankruptcy($event.target.value)" name="bankruptcy"
            required class="form-control">
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    </div>
    <div *ngIf="borrower.hasBankruptcy" class="form-group">
        <rol-date-box [(ngModel)]="borrower.bankruptcyFilingDate" label="Bankruptcy Filing Date (mm/dd/yyyy)" rolDateRangeValidator
            [max]="today" required name="filingDate" #filingDate="ngModel"></rol-date-box>
        <div *ngIf="form.submitted && filingDate.invalid" class="error">
            <p *ngIf="filingDate.errors['required']">A date is required</p>
            <p *ngIf="filingDate.errors['invalidDate']">That is not a valid date. Format is mm/dd/yyyy.</p>
            <p *ngIf="filingDate.errors['precedesMin']">Please select a date after
                {{filingDate.errors['precedesMin'].toDateString()}}</p>
            <p *ngIf="filingDate.errors['exceedsMax']">Please select a date before
                {{filingDate.errors['exceedsMax'].toDateString()}}</p>
        </div>
    </div>
    <div class="well">
        <legend>
            <span class="spanTitle">Additional Financial Information</span>
        </legend>
        <div class="form-group">
            <label>Do you have any Crop Input loans/advances outstanding with other creditors?</label>
            <div>
                <p-inputSwitch [(ngModel)]="borrower.richardsonFinancialInformation.hasOutstandingLoans" name="outstandingLoans" required
                    (onChange)="changeOutstandingLoans($event)"></p-inputSwitch>
            </div>
        </div>
        <div *ngIf="borrower.richardsonFinancialInformation.hasOutstandingLoans && formGroups.length > 0" class="form-group">
            <p-accordion (onClose)="tabClose($event)" (onOpen)="tabOpen($event)">
                <p-accordionTab *ngFor="let loan of borrower.richardsonFinancialInformation.outstandingLoans; let i = index" [selected]="isOpen[i]">
                    <p-header [ngClass]="{'error': form.submitted && !formGroups[i].valid && !isOpen[i], 'ng-invalid': form.submitted && !formGroups[i].valid && !isOpen[i]}">
                        <span *ngIf="!showDisplayName(loan)" class="new">(new)</span>
                        <span *ngIf="showDisplayName(loan)">{{loan.loanType}}</span>
                        <span class="actionicon" (click)="delete(i, $event)">
                            <i class="glyphicon fa fa-trash-o"></i> Delete
                        </span>
                    </p-header>
                    <rol-loan-information [loan]="loan" [form]="form" [group]="formGroups[i]"></rol-loan-information>
                </p-accordionTab>
            </p-accordion>
        </div>
        <div *ngIf="borrower.richardsonFinancialInformation.hasOutstandingLoans" class="buttonContainer">
            <button type="button" class="btn btn-primary" (click)="add()">Add Loan</button>
        </div>
        <div class="form-group">
            <label>Have you ever filed for mediation with the Farm Debt Mediation Board?</label>
            <div>
                <p-inputSwitch [(ngModel)]="borrower.richardsonFinancialInformation.hasMediation" name="mediation" required
                    (onChange)="changeMediation($event)"></p-inputSwitch>
            </div>
        </div>
        <div *ngIf="borrower.richardsonFinancialInformation.hasMediation" class="form-group">
            <rol-month-year name="mediationDate" [yearsBack]="7" title="Mediation" required
                [form]="form" [monthControl]="mediationMonth" [yearControl]="mediationYear"></rol-month-year>
        </div>
        <div class="form-group">
            <label>Do you have crop insurance?</label>
            <div>
                <p-inputSwitch [(ngModel)]="borrower.richardsonFinancialInformation.hasCropInsurance" name="cropInsurance" required
                    (onChange)="changeCropInsurance($event)"></p-inputSwitch>
            </div>
        </div>
        <div *ngIf="borrower.richardsonFinancialInformation.hasCropInsurance" class="form-group">
            <label>How many acres?</label>
            <input type="text" name="cropAcres" #cropAcres="ngModel" class="form-control"
                [ngModel]="borrower.richardsonFinancialInformation.cropInsuredAcres" required
                (ngModelChange)="borrower.richardsonFinancialInformation.cropInsuredAcres = toNumber($event)" validate-number
                [textMask]="{mask: numbersOnlyMask, guide:false}">
            <div *ngIf="form.submitted && cropAcres.invalid" class="error">
                <p>Required</p>
            </div>
        </div>
        <div class="form-group">
            <label>Do you have hail insurance?</label>
            <div>
                <p-inputSwitch [(ngModel)]="borrower.richardsonFinancialInformation.hasHailInsurance" name="hailInsurance" required
                    (onChange)="changeHailInsurance($event)"></p-inputSwitch>
            </div>
        </div>
        <div *ngIf="borrower.richardsonFinancialInformation.hasHailInsurance" class="form-group">
            <label>How many acres?</label>
            <input type="text" name="hailAcres" #hailAcres="ngModel" class="form-control"
                [ngModel]="borrower.richardsonFinancialInformation.hailInsuredAcres" required
                (ngModelChange)="borrower.richardsonFinancialInformation.hailInsuredAcres = toNumber($event)" validate-number
                [textMask]="{mask: numbersOnlyMask, guide:false}">
            <div *ngIf="form.submitted && hailAcres.invalid" class="error">
                <p>Required</p>
            </div>
        </div>
        <div class="form-group">
            <label>Off-Farm Income?</label>
            <div>
                <p-inputSwitch [(ngModel)]="borrower.richardsonFinancialInformation.hasOtherIncome" name="income" required
                    (onChange)="changeOtherIncome($event)"></p-inputSwitch>
            </div>
        </div>
        <div *ngIf="borrower.richardsonFinancialInformation.hasOtherIncome" class="form-group">
            <label>Amount</label>
            <input type="text" name="otherIncomeAmount" #otherIncomeAmount="ngModel" class="form-control"
                [ngModel]="borrower.richardsonFinancialInformation.otherIncomeAmount" validate-number required
                (ngModelChange)="borrower.richardsonFinancialInformation.otherIncomeAmount = toNumber($event)"
                [textMask]="{mask: priceMask, guide:false}">
            <div *ngIf="form.submitted && otherIncomeAmount.invalid" class="error">
                <p>Required</p>
            </div>
        </div>
        <div *ngIf="borrower.richardsonFinancialInformation.hasOtherIncome" class="form-group">
            <label>Source</label>
            <input #otherIncomeSource="ngModel" name="otherIncomeSource" class="form-control"
                [(ngModel)]="borrower.richardsonFinancialInformation.otherIncomeSource" required placeholder="Income Source">
            <div *ngIf="form.submitted && otherIncomeSource.invalid" class="error">
                <p>Required</p>
            </div>
        </div>
    </div>
</div>