<div class="form-group">
    <label for="question">Question</label>
    <input name="question" class="form-control" readonly="true" value={{securityQuestion}} />
</div>

<div class="form-group">
    <label for="answer">Answer</label>
    <input [autofocus] id="answer" name="answer" type="answer" [formControl]="answerForm" class="form-control"
        placeholder="Answer" required [(ngModel)]="securityAnswer" (keyup)="updateAnswer($event.target.value)" />
    <div class="error" *ngIf="(form.submitted || answerForm.dirty) && answerForm.invalid">
        <p *ngIf="answerForm.errors['required']">Required</p>
    </div>
</div>
<div class="optional" *ngIf="loginMessage">
    <p>{{loginMessage}}</p>
</div>
<div class="error" *ngIf="serverError">
    <p>{{serverError}}</p>
</div>