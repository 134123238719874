export class ApplicationHeader {
    id: any;
    status = 'Draft';
    creationDate: Date;
    completionDate: Date;

    constructor(appData?: any) {
        if (appData) {
            this.id = appData.ID;
            this.status = appData.Status;
            this.creationDate = appData.CreatedDate;
            this.completionDate = appData.ApplicationCompletedDate;
        }
    }
}
