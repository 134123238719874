export class FullName {
    constructor(appData?: any) {
        if (appData) {
            this.firstName = appData.FirstName;
            this.middleName = appData.MiddleName;
            this.lastName = appData.LastName;
            this.suffix = appData.Suffix;
        }
    }

    firstName = '';
    middleName = '';
    lastName = '';
    suffix = '';

    toString(): string {
        return (this.firstName + ' ' + this.middleName + ' ' + this.lastName + ' ' + this.suffix).trim();
    }
}
