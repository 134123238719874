import { Component } from '@angular/core';

import { CONFIG } from '../../../environments/environment';

@Component({
    selector: 'rol-environment-banner',
    template: `<div *ngIf="environment != 'prod'" class="dev-banner">{{environment ? environment : 'unknown'}}</div>`,
    styles: [`
        .dev-banner {
            position: fixed;
            font-size: 1.9rem;
            top: -28px;
            left: -82px;
            transform: rotate(-45deg);
            z-index: 9999;
            padding: 60px 60px 5px;
            width: 240px;
            background-color: #f26821;
            color: white;
            text-align: center;
            font-style: italic;
            box-shadow: 0 6px 14px rgba(0,0,0,0.25)
        }
    `]
})
export class EnvironmentBannerComponent {
    environment: string = CONFIG.env;
}
