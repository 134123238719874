import { Component, AfterViewInit, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { TopForm } from '../top-form';
import { OutstandingLoan } from '../../models/outstanding-loan';

@Component({
    selector: 'rol-loan-information',
    templateUrl: 'loan-information.component.html',
    styleUrls: ['../styles_form.css']
})
export class LoanInformationComponent implements OnInit, AfterViewInit {
    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() loan: OutstandingLoan;

    loanType: FormControl;
    creditor: FormControl;
    amount: FormControl;
    issueMonth: FormControl;
    issueYear: FormControl;

    ngOnInit(): void {
        this.loanType = new FormControl('', [Validators.required]);
        this.loanType.updateValueAndValidity();

        this.creditor = new FormControl('', [Validators.required]);
        this.creditor.updateValueAndValidity();

        this.amount = new FormControl('', [Validators.required]);
        this.amount.updateValueAndValidity();

        this.issueMonth = new FormControl(this.loan.issueMonth,
            [ Validators.required ]);
        this.issueMonth.updateValueAndValidity();

        this.issueYear = new FormControl(this.loan.issueYear,
            [ Validators.required ]);
        this.issueYear.updateValueAndValidity();
    }

    ngAfterViewInit(): void {
        this.group.addControl('loanType', this.loanType);
        this.group.addControl('creditor', this.creditor);
        this.group.addControl('amount', this.amount);
        this.group.addControl('issueMonth', this.issueMonth);
        this.group.addControl('issueYear', this.issueYear);

        this.issueMonth.valueChanges.subscribe({
            next: (month) => { this.loan.issueMonth = month; }
        });
        this.issueYear.valueChanges.subscribe({
            next: (year) => { this.loan.issueYear = year; }
        });
    }
}
