<div class="container stage main_container">
    <rol-side-nav selectedAlias="{{pageAlias}}"></rol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Entity Information</h1>

        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-6">
            <p class="text-small">All fields are required unless otherwise noted.</p>
        </div>
    </div>
    <div class="spacer">
        <div fxFlex.gt-md="60">
            <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application && application.entity">
                <div class="form-group">
                    <label for="legalName">Entity Legal Name</label>
                    <input name="legalName" class="form-control" [(ngModel)]="application.entity.legalName" required
                        placeholder="Entity Legal Name" #legalName="ngModel" />
                    <div class="error" *ngIf="(form.submitted || legalName.dirty) && legalName.invalid">
                        <p *ngIf="legalName.errors['required']">Required</p>
                    </div>
                </div>

                <rol-doing-business-as [application]="application" [form]="form"></rol-doing-business-as>

                <div class="form-group"*ngIf="isPartnership()">
                    <label>Entity Type</label>
                    <input #entityType="ngModel" 
                        name="entityType"
                        class="form-control" 
                        [ngModel]="application.entity.entityType"
                        disabled>
                </div>
                <div class="form-group"*ngIf="!isPartnership()">
                    <label for="items">Entity Type</label>&nbsp;<rol-help-tip>Select an option from the list.</rol-help-tip>
                    <select [(ngModel)]="application.entity.entityType"
                        name="entityType" required 
                        class="form-control" #entityType="ngModel">
                        <option value=""></option>
                        <option *ngFor="let x of entityTypes" value="{{x}}">{{x}}</option>
                    </select>
                    <div class="error" *ngIf="(form.submitted || entityType.dirty) && entityType.invalid">Required
                    </div>
                </div>

                <rol-multiple-phone-component [borrower]="application.entity" [form]="form" ></rol-multiple-phone-component>

                <rol-physical-address-state-select name="stateOfOrganization" [(ngModel)]="application.entity.stateOfOrganization"
                    Title="Province of Organization/Incorporation" [form]="form" [group]="group"></rol-physical-address-state-select>

                <div class="well">
                    <legend>
                        <span class="spanTitle">Borrower Information</span>
                    </legend>

                    <div class="form-group">
                        <label for="hasFormedInLast12Months">
                            Was the Borrower legally formed less than twelve (12) months ago?
                        </label>
                        <select [(ngModel)]="application.entity.richardsonEntityInformation.hasFormedInLast12Months"
                            name="hasFormedInLast12Months" required 
                            class="form-control" #hasFormedInLast12Months="ngModel"
                            (change)=onChangeHasFormedInLast12Months($event.target.value)>
                            <option [ngValue]="true">Yes</option>
                            <option [ngValue]="false">No</option>
                        </select>
                        <div class="error" *ngIf="(form.submitted || hasFormedInLast12Months.dirty) && hasFormedInLast12Months.invalid">Required
                        </div>
                    </div>
        
                    <div *ngIf="wasFormedInLast12Months()" class="form-group">
                        <label for="isActivityConsistentWithVision">
                            Are the Borrower's activities (i.e. farming) consistent with what was envisioned/contemplated when they were incorporated?
                        </label>
                        <select [(ngModel)]="application.entity.richardsonEntityInformation.isActivityConsistentWithVision"
                            name="isActivityConsistentWithVision" required 
                            class="form-control" #isActivityConsistentWithVision="ngModel"
                            (change)=onChangeIsActivityConsistentWithVision($event.target.value)>
                            <option [ngValue]="true">Yes</option>
                            <option [ngValue]="false">No</option>
                        </select>
                        <div class="error" *ngIf="(form.submitted || isActivityConsistentWithVision.dirty) && isActivityConsistentWithVision.invalid">Required
                        </div>
                    </div>
    
                    <div *ngIf="activitiesNotConsistentWithVision()" class="form-group">
                        <label for="rationaleForActivitiesChanges">
                            Provide a rationale for this change
                        </label>
                        <input #rationaleForActivitiesChanges="ngModel" 
                            name="rationaleForActivitiesChanges" 
                            class="form-control" 
                            [(ngModel)]="application.entity.richardsonEntityInformation.rationaleForActivitiesChanges"
                            required>
                        <div *ngIf="(form.submitted || rationaleForActivitiesChanges.dirty) && rationaleForActivitiesChanges.invalid" class="error">
                            <p *ngIf="rationaleForActivitiesChanges.errors">Required</p>
                        </div>
                    </div>
    
                    <div *ngIf="wasFormedInLast12Months()" class="form-group">
                        <label for="hasRecentLeadershipChanges">
                            Have any of the Shareholders, Directors, Authorized Signatories or Organizational Structure changed since incorporation?
                        </label>
                        <select [(ngModel)]="application.entity.richardsonEntityInformation.hasRecentLeadershipChanges"
                            name="hasRecentLeadershipChanges" required 
                            class="form-control" #hasRecentLeadershipChanges="ngModel"
                            (change)=onChangeHasRecentLeadershipChanges($event.target.value)>
                            <option [ngValue]="true">Yes</option>
                            <option [ngValue]="false">No</option>
                        </select>
                        <div class="error" *ngIf="(form.submitted || hasRecentLeadershipChanges.dirty) && hasRecentLeadershipChanges.invalid">Required
                        </div>
                    </div>
    
                    <div *ngIf="hasRecentLeadershipChanges()" class="form-group">
                        <label for="rationaleForLeadershipChanges">
                            Please highlight the change(s) and provide a rationale for this change
                        </label>
                        <input #rationaleForLeadershipChanges="ngModel" 
                            name="rationaleForLeadershipChanges" 
                            class="form-control" 
                            [(ngModel)]="application.entity.richardsonEntityInformation.rationaleForLeadershipChanges"
                            required>
                        <div *ngIf="(form.submitted || rationaleForLeadershipChanges.dirty) && rationaleForLeadershipChanges.invalid" class="error">
                            <p *ngIf="rationaleForLeadershipChanges.errors">Required</p>
                        </div>
                    </div>
                </div>

                <rol-combined-address-component name="combAddress"
                    [(physicalAddress)]="application.entity.physicalAddress"
                    [(mailingAddress)]="application.entity.mailingAddress" [form]="form" [group]="group">
                </rol-combined-address-component>

                <div class="well">
                    <legend>
                        <span class="spanTitle">Additional Borrower Information</span>
                    </legend>            

                    <div class="form-group">
                        <label for="isBorrowerNotForProfitOrganization">
                            Is the Borrower a Not for Profit organization?
                        </label>
                        <select [(ngModel)]="application.entity.richardsonEntityInformation.isBorrowerNotForProfitOrganization"
                            name="isBorrowerNotForProfitOrganization" required 
                            class="form-control" #isBorrowerNotForProfitOrganization="ngModel"
                            (change)=onChangeIsBorrowerNotForProfitOrganization($event.target.value)>
                            <option [ngValue]="true">Yes</option>
                            <option [ngValue]="false">No</option>
                        </select>
                        <div class="error" *ngIf="(form.submitted || isBorrowerNotForProfitOrganization.dirty) && isBorrowerNotForProfitOrganization.invalid">Required
                        </div>
                    </div>
    
                    <div *ngIf="isBorrowerNotForProfit()" class="form-group">
                        <label for="isBorrowerRegisteredCharity">
                            Is the Borrower a registered charity with Revenue Canada Agency under the <em>Income Tax Act</em>?
                        </label>
                        <select [(ngModel)]="application.entity.richardsonEntityInformation.isBorrowerRegisteredCharity"
                            name="isBorrowerRegisteredCharity" required 
                            class="form-control" #isBorrowerRegisteredCharity="ngModel"
                            (change)=onChangeIsBorrowerCharitable($event.target.value)>
                            <option [ngValue]="true">Yes</option>
                            <option [ngValue]="false">No</option>
                        </select>
                        <div class="error" *ngIf="(form.submitted || isBorrowerRegisteredCharity.dirty) && isBorrowerRegisteredCharity.invalid">Required
                        </div>
                    </div>
    
                    <div *ngIf="isBorrowerNotForProfit()" class="form-group">
                        <label for="doesBorrowerSolicitCharitableDonations">
                            If the Borrower is not registered with Revenue Canada Agency, does it solicit charitable donations?
                        </label>
                        <select [(ngModel)]="application.entity.richardsonEntityInformation.doesBorrowerSolicitCharitableDonations"
                            name="doesBorrowerSolicitCharitableDonations" required 
                            class="form-control" #doesBorrowerSolicitCharitableDonations="ngModel"
                            (change)=onChangeIsBorrowerCharitable($event.target.value)>
                            <option [ngValue]="true">Yes</option>
                            <option [ngValue]="false">No</option>
                        </select>
                        <div class="error" *ngIf="(form.submitted || doesBorrowerSolicitCharitableDonations.dirty) && doesBorrowerSolicitCharitableDonations.invalid">Required
                        </div>
                    </div>
    
                    <div *ngIf="isBorrowerCharitable()" class="form-group">
                        <label for="charitableDonationsSource">
                            Please indicate the source of charitable donations
                        </label>
                        <input #charitableDonationsSource="ngModel" 
                            name="charitableDonationsSource" 
                            class="form-control" 
                            [(ngModel)]="application.entity.richardsonEntityInformation.charitableDonationsSource"
                            required>
                        <div *ngIf="(form.submitted || charitableDonationsSource.dirty) && charitableDonationsSource.invalid" class="error">
                            <p *ngIf="charitableDonationsSource.errors">Required</p>
                        </div>
                    </div>
    
                    <div *ngIf="isBorrowerCharitable()" class="form-group">
                        <label for="charitableDonationsPurpose">
                            Please describe the purpose(s) of the charitable donations
                        </label>
                        <input #charitableDonationsPurpose="ngModel" 
                            name="charitableDonationsPurpose" 
                            class="form-control" 
                            [(ngModel)]="application.entity.richardsonEntityInformation.charitableDonationsPurpose"
                            required>
                        <div *ngIf="(form.submitted || charitableDonationsPurpose.dirty) && charitableDonationsPurpose.invalid" class="error">
                            <p *ngIf="charitableDonationsPurpose.errors">Required</p>
                        </div>
                    </div>
                </div>

                <div class="buttonContainer">
                    <button class="btn btn-primary" type="submit">Continue</button>
                </div>
            </form>
        </div>
    </div>
    <div style="clear:both"></div>
</div>