import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';

@Component({
    selector: 'rol-password-confirmation',
    templateUrl: './password-confirmation.component.html',
    styleUrls: [ './password-confirmation.component.css' ]
})
export class PasswordConfirmationComponent implements OnInit {
    constructor(private router: Router) { }

    ngOnInit() {
        if (window.top.location != window.location) {
            window.top.location = window.location
        }
    }

    successAndReturn() {
        this.router.navigate(['/home']);
    }
}