import { Question } from './question';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export class SimpleQuestion extends Question {
    questionText: string;
    example: string;
    values: number[] = [];
    customValidators: ((c: AbstractControl) => ValidationErrors)[] = [];

    constructor(appData?: any) {
        super(appData);
        if (appData) {
            this.questionText = appData.questionText;
            this.example = appData.example;
            this.values = appData.values;
        }
    }
}
