import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ApplicationType } from '../../models/application-type';
import { Person } from '../../models/person';
import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';
import { validateCheckboxFactory } from '../validation/checkbox-required.directive';

@Component({
    selector: 'rol-borrower-information',
    templateUrl: 'borrower-information.component.html',
    styleUrls: ['../styles_form.css', './borrower-information.component.css']
})

export class BorrowerInformationComponent extends AbstractFormPage {
    isOpen: boolean[];

    accordionMap: number[];
    formGroups: FormGroup[];
    form: TopForm;
    showModal: boolean;
    preApproved = false;
    hasPrimaryApplicantConfirmation: FormControl;

    get borrowerText(): string {
        if (!this.application) {
            return 'Borrower';
        }
        return this.application.appType === ApplicationType.Single ? 'Borrower' : 'Co-Borrower';
    }

    get isSingleOwnerApplication(): boolean {
        return this.application.appType === ApplicationType.Single;
    }

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService) {
        super(currentAppSvc, router, utilities, history);
        this.pageAlias = 'borrower';
        this.isOpen = [];
        this.formGroups = [];
        this.accordionMap = [];
        this.initPage();
    }

    afterInitPage() {
        this.form = new TopForm();

        if (!this.isSingleOwnerApplication) {
            this.hasPrimaryApplicantConfirmation = new FormControl(this.application.hasPrimaryApplicantConfirmation, validateCheckboxFactory());
            this.form.addControl('hasPrimaryApplicantConfirmation', this.hasPrimaryApplicantConfirmation);
        }

        if (this.application.borrowers.length === 0) {
            this.add();
        } else {
            let opened = false;
            for (let i = 0; i < this.application.borrowers.length; ++i) {
                if (!opened &&
                    (!this.application.borrowers[i].fullName.firstName ||
                        !this.application.borrowers[i].fullName.lastName)) {
                    opened = true;
                    this.isOpen.push(true);
                } else {
                    this.isOpen.push(false);
                }
                this.accordionMap.push(i);
                const group = new FormGroup({});
                this.formGroups.push(group);
                this.form.addControl(this.accordionMap.length.toString(), group);
            }
        }

        if (this.application.preApprovalStagingId) {
            this.SetPreApprovalStatus(true);
            this.isOpen[0] = true;
        }
    }

    add() {
        this.form.submitted = false;
        this.application.borrowers.push(new Person());
        this.closeAllTabs();
        this.accordionMap.push(this.isOpen.length);
        this.isOpen.push(true);
        const group = new FormGroup({});
        this.formGroups.push(group);
        this.form.addControl(this.accordionMap.length.toString(), group);
        window.scrollTo(0, 0);
    }

    delete(i: number, e: MouseEvent) {
        this.application.borrowers.splice(i, 1);
        this.isOpen.splice(i, 1);
        this.formGroups.splice(i, 1);
        let found = null;
        this.accordionMap = this.accordionMap.map((mapping, idx) => {
            if (mapping === i) {
                found = true;
                this.form.removeControl((idx + 1).toString());
                return -1;
            } else {
                return found ? mapping - 1 : mapping;
            }
        });
        e.preventDefault();

        if (this.application.borrowers.length === 0) {
            this.add();
        }
    }

    tabOpen(e) {
        this.closeAllTabs();
        this.isOpen[e.index] = true;
    }

    tabClose(e) {
        this.isOpen[e.index] = false;
    }

    private closeAllTabs() {
        for (let i = 0; i < this.isOpen.length; ++i) {
            this.isOpen[i] = false;
        }
    }

    hasNonWhitespace(s) {
        return /[^\s]/g.test(s);
    }

    showDisplayName(borrower: Person): boolean {
        return this.hasNonWhitespace(borrower.fullName);
    }

    submit() {
        this.form.updateValueAndValidity();
        this.form.submitted = true;

        // tslint:disable-next-line: forin
        for (const index in this.form.controls) {
            const discharge = this.form.controls[index].get('dischargeDate');
            if (discharge) {
                discharge.updateValueAndValidity();
            }
        }

        if (this.application.preApprovalStagingId && !this.comparePreApprovalData()) {
            this.showModal = true;
            return;
        }

        if (this.form.valid) {
            this.next(1);
        } else {
            this.scrollToError();
        }
    }

    viewModal() {
        this.showModal = true;
    }

    comparePreApprovalData(): boolean {
        const borrowerFullName = this.application.borrowers[0].fullName;
        const borrowerMailingAddress = this.application.borrowers[0].mailingAddress;
        const defaultName = this.application.preApprovalBorrower.fullName;
        const defaultAddress = this.application.preApprovalBorrower.mailingAddress;

        if (borrowerFullName.lastName === defaultName.lastName
            && borrowerFullName.middleName === defaultName.middleName
            && borrowerFullName.suffix === defaultName.suffix
            && borrowerMailingAddress.line1 === defaultAddress.line1
            && borrowerMailingAddress.line2 === defaultAddress.line2
            && borrowerMailingAddress.city === defaultAddress.city
            && borrowerMailingAddress.state === defaultAddress.state
            && borrowerMailingAddress.zipcode === defaultAddress.zipcode) {
            return true;
        } else {
            return false;
        }
    }

    restorePreApprovalDefaults() {
        if (this.application.borrowers.length === 0) {
            this.add();
        }
        const borrower = this.application.borrowers[0];
        const defaults = this.application.preApprovalBorrower;

        borrower.fullName.firstName = defaults.fullName.firstName;
        borrower.fullName.lastName = defaults.fullName.lastName;
        borrower.fullName.middleName = defaults.fullName.middleName;
        borrower.fullName.suffix = defaults.fullName.suffix;

        borrower.mailingAddress.city = defaults.mailingAddress.city;
        borrower.mailingAddress.line1 = defaults.mailingAddress.line1;
        borrower.mailingAddress.line2 = defaults.mailingAddress.line2;
        borrower.mailingAddress.state = defaults.mailingAddress.state;
        borrower.mailingAddress.zipcode = defaults.mailingAddress.zipcode;
        borrower.physicalAddress = this.application.preApprovalBorrower.mailingAddress;
        borrower.physicalAddress.isPhysicalAddress();

        this.SetPreApprovalStatus(true);
        this.showModal = false;
    }

    ProceedWithoutPreApproval() {
        this.application.preApprovalStagingId = null;
        this.SetPreApprovalStatus(false);
        this.submit();
    }

    SetPreApprovalStatus(status: boolean) {
        this.preApproved = status;
        this.application.borrowers[0].isPreApproved = this.preApproved;
    }

    changeHasPrimaryApplicantConfirmation(event): void {
        const checked = event.target.checked;
        this.application.hasPrimaryApplicantConfirmation = checked;
    }
}
