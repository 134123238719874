export const LineOfBusinesses = [
    'Beef Cattle Ranching',
    'Flax Production',
    'Pea Production',
    'Mixed Grain',
    'Barley Production',
    'Hay Production',
    'Oat Production',
    'Poultry Farming',
    'Canola Production',
    'Hog Farming',
    'Wheat Production'
];
