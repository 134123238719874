import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, EmailValidator } from '@angular/forms';

export function validateEmailFactory() {
  return (c: FormControl) => {
    const EMAIL_REGEXP = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/i;

    return EMAIL_REGEXP.test(c.value) ? null : {
      validateEmail: {
        valid: false
      }
    };
  };
}

@Directive({
  selector: '[rolValidateEmail][ngModel],[validate-email][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidatorDirective), multi: true }
  ]
})
export class EmailValidatorDirective {

  validator: Function;

  constructor() {
    this.validator = validateEmailFactory();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }
}
