export class Customer {
    CifNumber = '';
    CifNo = '';
    PhoneNumber = '';
    Address = '';
    City = '';
    State = '';
    Zip = '';
    DOB = '';
    SSN = '';
    DisplayName = '';
    EmailAddress = '';
    Relationship = '';

    constructor(data?: any) {
        if (data) {
            this.CifNumber = data.CifNumber;
            this.CifNo = data.CifNo;
            this.PhoneNumber = data.PhoneNumber;
            this.Address = data.Address;
            this.City = data.City;
            this.State = data.State;
            this.Zip = data.Zip;
            this.DOB = data.DOB;
            this.SSN = data.SSN;
            this.DisplayName = data.LetterTitle;
            this.EmailAddress = data.EmailAddress;
            this.Relationship = data.Relationship;
        }
    }
}
