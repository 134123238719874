import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import * as moment from 'moment';
import { InterruptSource, InterruptArgs } from '@ng-idle/core';

import { User } from '../../app/models/user';
import { PasswordChange } from '../../app/models/password-change';
import { UserService } from '../../app/services/user.service';
import { CONFIG } from '../../environments/environment';
import { UserToken } from '../../app/models/user-token';
import { MsalService } from '@azure/msal-angular';
import { IUserLoginData, IfAuthService } from 'if-angular-security';
import { LoggingService } from '../../app/services/logging.service';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ContactDetail } from '../../app/models/accounts/contact-detail';
import { Commitment } from '../../app/models/accounts/commitment';
import { AppVersionHelper } from '../../version-helper';
import { UserExtendedSecurityProfile } from '../models/user-extended-security-profile';
import { EndSessionRequest } from '@azure/msal-browser';

export interface IAuthService {
    Register(user: User,
        recaptchaToken: string,
        agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date,
        accountNumber: any,
        ssn: any,
        zip: any);
    GetSecurityQuestion(username: string, password: string, passwordResetToken: string);
    Login(username: string, password: string, securityQuestion: string, securityAnswer: string);
    CreateAnonymousUser(): Promise<boolean>;
    UpdateUser(user: User): Promise<boolean>;
    ChangePassword(passwordChange: PasswordChange): Promise<boolean>;
    InitiatePasswordReset(email: string): Promise<boolean>;
    ResetPassword(email: string, token: string, newPassword: string, question: string, answer: string): Promise<boolean>;
    CheckAvailability(email: string, username: string): Promise<string | Object>;
    VerifyAccountDetails(accountNumber: string, last4ssn: string, zipCode: string): Promise<number | Object>;
    UpdateUserAcceptanceDates(agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date): Promise<boolean | Object>;
    GetLastLoginMessage(username: string): Promise<any> ;
}

export enum LoginHandlingType {
    LoginDefault,
    Registration
};

@Injectable()
export class AuthService extends InterruptSource implements IAuthService {
    private headers = new HttpHeaders({ 'content-type': 'application/json' });
    private resourceUrl = CONFIG.apiBaseUri + 'Account';
    private resourceUrlV2 = CONFIG.apiBaseUri + 'v2/' + 'Account';
    private accountResourceUrl = CONFIG.apiBaseUri;

    public loginHandlingType: LoginHandlingType = LoginHandlingType.LoginDefault;

    constructor(private http: HttpClient, private userService: UserService, private logService: LoggingService,
        private msalService: MsalService,
        private ifAuthService: IfAuthService) {
        super(null, null);

        //TODO:MFA
        this.onUserLogin = this.onUserLogin.bind(this); // bind to so that this works as callback function 
        this.loggedIn = this.loggedIn.bind(this); 
    }

    loggedIn(): boolean {
        const user = this.userService.GetUser();
        return user && user.refreshToken && !this.isUserAuthExpired();
    }

    private handleError(error: any) {
        let errorErrorMessage: string = error && error.error ? error.error.Message : null;
        let errorMessage: string = error && error.message ? error.message : null;
        let parsed: any = {};
        try {
            parsed = JSON.parse(error._body);
        } catch (err) {
            if (error.status === 401) {
                errorMessage = 'Authorization failed';
            }
        }
        return Promise.reject(errorErrorMessage || errorMessage || parsed.Message || parsed.message || 'Server error.  Please try again later.');
    }

    //TODO:MFA
    public Register(user: User,
        recaptchaToken: string,
        agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date,
        accountNumber: any,
        ssn: any,
        zip: any): Promise<string> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.Register start, firstname: ${user?.firstName}, recaptcha not empty: ${recaptchaToken && recaptchaToken.length > 0}`, null, null, currentUser);

        const data: any = user;
        data.recaptchaToken = recaptchaToken;
        data.onlineAgreementAcceptanceDate = agreementAcceptedDate;
        data.eStatementAcceptanceDate = eStatementAcceptanceDate;
        data.achUSBankStatementAcceptanceDate = achUSBankStatementAcceptanceDate;
        data.accountNumber = accountNumber;
        data.ssn = ssn;
        data.zip = zip;
        data.isRichardson = true;

        return this.http //TODO:MFA
            .post(this.resourceUrlV2 + '/Register', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then((userToken: string) => {
                this.logService.recordInfo(`AuthService.Register completed`, null, null, currentUser);
                return userToken;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.Register error, firstname: ${user?.firstName}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    public CreateAnonymousUser(): Promise<boolean> {
        return this.http
            .post(this.resourceUrl + '/CreateAnonymousUser', '', { headers: this.headers })
            .toPromise()
            .then(res => {
                this.updateUserAndTokens(res, true);
                return true;
            })
            .catch(this.handleError);
    }

    public GetSecurityQuestion(username: string, password: string, passwordResetToken: string): Promise<any> {
        const data = { Email: username, Password: password, ResetPasswordToken: passwordResetToken };

        return this.http
            .post(this.resourceUrl + '/GetSecurityQuestion', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(question => {
                return question;
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    let msg = '';
                    if (error.error) {
                        msg = error.error;
                    } else {
                        msg = password && password !=='' ? 'Username or password is incorrect' : 'Invalid user or reset token has expired';
                    }
                    return Promise.reject(msg);
                }
                return this.handleError(error);
            });
    }

    public GetLastLoginMessage(username: string): Promise<any> {
        const data = { Email: username,};
        let browserDate = new Date();
        let offsetMinutes = -1 * browserDate.getTimezoneOffset(); //javascript treats the offset direction the opposite way that dotnet does.
        return this.http
            .get(this.resourceUrl + `/GetLastLoginMessage/?email=${username}&utcOffsetMinutes=${offsetMinutes}`, { headers: this.headers })
            .toPromise()
            .then(result => {
                return result;
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    let msg = '';
                    if (error._body) {
                        msg = error._body;
                    } else {
                        msg = 'Unable to retrieve login message for user:' + username;
                    }
                    return Promise.reject(msg);
                }
                return this.handleError(error);
            });
    }    

    public Login(username: string, password: string, securityQuestion: string, securityAnswer: string): Promise<boolean> {
        const data = { Email: username, Password: password, Question: securityQuestion, Answer: securityAnswer };

        return this.http
            .post(this.resourceUrl + '/Login', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                this.updateUserAndTokens(res, false);
                return true;
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    return Promise.reject('Incorrect answer to security question');
                }
                return this.handleError(error);
            });
    }

    //TODO:MFA - Might need to add more this.logService logging here.
    public RefreshTokens(): Promise<boolean | Object> {
        const user = this.userService.GetUser();

        if (!user || !user.refreshToken) {
            throwError(new Error('Missing user or refresh token'));
        }

        return this.ifAuthService.getAccessToken().pipe(map((userData) => { //TODO:MFA 
            this.onTokenRefresh(userData);
            if (this.isAttached) {
                const args = new InterruptArgs(this, new Date());
                this.onInterrupt.emit(args);
            }
            return true;
        })).toPromise();
    }

    // <MFA:TODO>
    // NOTE : On login, we are likely inside a frame; os, use window.top to navigate to destination screen
    onUserLogin(userData: IUserLoginData): void {      
        const isNotLoggedIn = !this.userService.loggedIn(); // NOTE: Need to check this before calling processUserLoginData()

        this.processUserLoginData(userData).subscribe(user => {
            const userFromService = this.userService.GetUser();

            if (!userFromService) {
                window.top.location.replace(AppVersionHelper.getVersionRoute('/home'));
            } else {
                switch(this.loginHandlingType) {
                    case LoginHandlingType.LoginDefault:
                        // Only need to check status, redirect, etc if not logged in yet (aka. initial login)
                        // Don't need to perform these actions if already logged in and performing profile updates etc.
                        if(isNotLoggedIn) {
                            this.onUserLoginLoginDefault(userFromService);
                        }        
                        break;
                    case LoginHandlingType.Registration:
                        this.loginHandlingType = LoginHandlingType.LoginDefault; // Always set back to default after special usage
                        this.onUserLoginRegistration();
                        break;
                }
            }
        });
    }

    private onUserLoginLoginDefault(userFromService : User) {
        // NOTE: hasGoodCustomContactStatus and hasGoodAccountStatuses logic are copied from login component and AccountHttpService
        // Due to login sequencing, dependency, and logic flow, we cannot directly use AccountHttpService here 
        Promise.all([this.hasGoodCustomContactStatus(userFromService), this.hasGoodAccountStatuses(userFromService)]).then((values) => {
            const hasGoodCustomContactStatus = values[0];
            const hasGoodAccountStatuses = values[1];
            if (hasGoodCustomContactStatus && hasGoodAccountStatuses) {
                window.top.location.replace(AppVersionHelper.getVersionRoute('/dashboard'));
            } else { // user can't access our system
                this.userService.Logout();
                window.top.location.replace(AppVersionHelper.getVersionRoute('/home?m=account-access'));                    
            }
        });
    }

    private onUserLoginRegistration() {
        window.top.location.replace(AppVersionHelper.getVersionRoute('/registration/registration-summary'));
    }

    onTokenRefresh(userData: IUserLoginData): void {
        this.processUserLoginData(userData).subscribe();
    }

    private processUserLoginData(tokenData: IUserLoginData) : Observable<User> {
        return this.getUserSecurityProfile(tokenData)
            .pipe(map(profile => {
                const user = this.buildUserObject(tokenData, profile);
                this.userService.SetUser(user);
                this.userService.startIdleClock();
                return user;
            }));
    }

    private getUserSecurityProfile(tokenData: IUserLoginData): Observable<UserExtendedSecurityProfile> {
        const apiUrl = CONFIG.apiBaseUri + 'UserExtendedSecurityProfile';
        return this.customGetWithToken<UserExtendedSecurityProfile>(apiUrl, tokenData.accessToken, { headers: this.headers, observe: 'response' })
        .pipe(
            map((res) => {
                return res ? new UserExtendedSecurityProfile(res.body) : null;
            })
        );
    }

    private buildUserObject(tokenData: IUserLoginData, profile: UserExtendedSecurityProfile): User {
        const user = new User(null);

        user.authToken = tokenData.accessToken;
        user.authExpires = tokenData.expires;
        user.authCreated = new Date();
        
        // We are not really doing much with the refreshToken, but it still needs to be populated to avoid crashes
        user.refreshToken = tokenData.accessToken;

        if (profile) {
            user.userId = profile.id;
            user.firstName = profile.firstName;
            user.lastName = profile.lastName;
            user.isAnonymous = profile.isAnonymous;
            user.email = profile.email;
            user.cifno = profile.cifNo;
        }

        return user;
    }

    private hasGoodCustomContactStatus(user: User) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let hasGoodCustomContactStatus = true;
            if (user.cifno) {
                this.getCustomerContactDetails(user.cifno)
                    .then((results) => {
                        if (results.AccountsDisabled) {
                            hasGoodCustomContactStatus = false;
                        } 
                        resolve(hasGoodCustomContactStatus);
                    });
            } else {
                return resolve(hasGoodCustomContactStatus);
            }
        });
    }

    private hasGoodAccountStatuses(user: User) : Promise<boolean>{
        return new Promise<boolean>((resolve, reject) => {
            let hasGoodAccountStatuses = true;
            if(!user.cifno) {
                resolve(hasGoodAccountStatuses);
            } else {
                this.getAllCommitments()
                    .then((results) => {                        
                        const allCommitments = results;                        
                        if (allCommitments && allCommitments.length > 0) {
                            user.hasBadCreditAccounts = allCommitments.some(x => this.isBadCreditAccount(x));
                            user.hasGoodCreditAccounts = allCommitments.some(x => !this.isBadCreditAccount(x));
                            user.canApply = allCommitments.every(x => !this.stopApplying(x));

                            if (user.hasBadCreditAccounts && !user.hasGoodCreditAccounts) {
                                hasGoodAccountStatuses = false;
                            }
                        }

                        return resolve(hasGoodAccountStatuses);
                    });
                }
        });
    }

    private isBadCreditAccount(commitment: Commitment): boolean {
        return commitment.HasBankruptcy
            || commitment.HasCollections;
    }

    private stopApplying(commitment: Commitment): boolean {
        return commitment.IsDelinquent
            || commitment.HasExtension
            || commitment.HasCreditHold
            || this.isBadCreditAccount(commitment);
    }

    private getAllCommitments(): Promise<Commitment[]> {
        return this.customGetWithToken(this.accountResourceUrl + 'Grower/AllCommitments', this.userService.GetUser().authToken)
            .toPromise()
            .then((response) => {
                const allCommitments = Array.from(response as any, (item) => {
                    return new Commitment(item);
                });
                return allCommitments;
            })
            .catch(this.handleError);
    }

    private getCustomerContactDetails(cifno: number): Promise<ContactDetail> {
        const url = this.accountResourceUrl + 'Grower/GetCustomerContactDetails?cifno=' + cifno;
        return this.customGetWithToken(url, this.userService.GetUser().authToken, { headers: this.headers })
            .toPromise()
            .then((res) => new ContactDetail(res))
            .catch(this.handleError);
    }

    private customGetWithToken<T>(url: string, token: string, options?: any): Observable<any> {
        options = options || {};
        var headers = options.headers || new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${token}`);
        
        options.headers = headers;
        return this.http.get<T>(url, options);
    }

    // </MFA:TODO>

    //TODO:MFA:FUTURE When working on Remove or share some updateUserAndTokens and updateTokens code below.

    private updateUserAndTokens(token, isAnonymous): User {
        const user = this.updateTokens(token);
        user.isAnonymous = isAnonymous;
        user.email = token.userName;
        user.firstName = token.firstName;
        user.lastName = token.lastName;
        user.userId = token.userID;
        user.cifno = token.cifno;
        this.userService.SetUser(user);

        return user;
    }

    private updateTokens(token): User {
        let user = this.userService.GetUser();
        if (!user) {
            user = new User();
        }
        user.authToken = token.access_token;
        user.refreshToken = token.refresh_token;
        user.authExpires = moment().add(token.expires_in, 's').toDate();
        user.authCreated = new Date();
        this.userService.SetUser(user);
        this.userService.startIdleClock();

        return user;
    }

    public UpdateUser(user: User): Promise<boolean> {
        const headers = this.createAuthorizationHeader(this.headers);

        return this.http
            .post(this.resourceUrlV2 + '/UpdateUser', JSON.stringify(user), { headers: headers })
            .toPromise()
            .then(res => {
                this.userService.SetUser(user);
                return true;
            })
            .catch(this.handleError);
    }

    public ChangePassword(passwordChange: PasswordChange): Promise<boolean> {
        const headers = this.createAuthorizationHeader(this.headers);

        return this.http
            .post(this.resourceUrl + '/ChangePassword', JSON.stringify(passwordChange), { headers: headers })
            .toPromise()
            .then(res => {
                return true;
            })
            .catch((err) => {
                const modelMessage = this.GetErrorFromModelState(err);
                if (modelMessage) {
                    return Promise.reject(modelMessage);
                }
                return this.handleError(err);
            });
    }

    private GetErrorFromModelState(err): string {
        let parsed: any = {};
        try {
            parsed = JSON.parse(err._body);
        } catch (e) {
        }
        if (parsed.ModelState && parsed.ModelState[''] && parsed.ModelState[''].length) {
            return parsed.ModelState[''][0];
        }
        return null;
    }

    public InitiatePasswordReset(email: string): Promise<boolean> {
        return this.http
            .post(this.resourceUrl + '/InitiatePasswordReset',
                JSON.stringify({ 'email': email, websiteName: 'RichardsonOnline' }),
                { headers: this.headers })
            .toPromise()
            .then(res => {
                return true;
            })
            .catch(this.handleError);
    }

    public ResetPassword(email: string, token: string, newPassword: string, question: string, answer: string): Promise<boolean> {
        const data = { 'Email': email, 'Token': token, 'NewPassword': newPassword, 'Question': question, 'Answer': answer };
        return this.http
            .post(this.resourceUrl + '/ResetPassword', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                return true;
            })
            .catch((err: any) => {
                if (err.status === 401) {
                    return Promise.reject('Incorrect answer to security question');
                }
                return this.handleError(err);
            });
    }

    public ChangeExpiredPassword(email: string, oldPassword: string, newPassword: string): Promise<boolean> {
        const data = { 'Email': email, 'OldPassword': oldPassword, 'NewPassword': newPassword };
        return this.http
            .post(this.resourceUrl + '/ChangeExpiredPassword', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                return true;
            })
            .catch((err: any) => {
                return this.handleError(err);
            });
    }

    public CheckAvailability(email: string, username: string): Promise<string | object> {
        const data = { 'Email': email, 'UserName': username };
        return this.http
            .post(this.resourceUrl + '/CheckAvailability', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public VerifyAccountDetails(accountNumber: string, last4ssn: string, zipCode: string): Promise<number | Object> {
        const data = { 'AccountNumber': accountNumber, 'Last4ssn': last4ssn, 'ZipCode': zipCode };
        return this.http
            .post(this.resourceUrl + '/VerifyAccountDetails', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public createAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
        headers = headers || new HttpHeaders();

        if (this.userService.GetUser()) {
            if (this.isUserAuthExpired()) {
                // authorization expired... redirect somewhere?
            } else {
                this.RefreshTokens().catch((reason) => {
                    console.log('Could not refresh token:', reason);
                });

                headers = headers.set('Authorization', `Bearer ${this.userService.GetUser().authToken}`);
            }
        }
        return headers;
    }

    private isUserAuthExpired(): boolean {
        return this.userService.GetUser().authExpires <= new Date();
    }

    public UpdateUserAcceptanceDates(
        agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date): Promise<boolean | Object> {

        const headers = this.createAuthorizationHeader(this.headers);

        const data = {
            'OnlineAgreementAcceptanceDate': agreementAcceptedDate,
            'EStatementAcceptanceDate': eStatementAcceptanceDate,
            'AchUSBankStatementAcceptanceDate': achUSBankStatementAcceptanceDate
        };
        return this.http
            .post(this.resourceUrl + '/UpdateUserAcceptanceDates', JSON.stringify(data), { headers: headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public GetUserAcceptanceDates(): Promise<any> {
        const headers = this.createAuthorizationHeader(this.headers);

        return this.http
            .post(this.resourceUrl + '/GetUserAcceptanceDates', null, { headers: headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public ValidatePreApproval(code: string, name: string): Promise<any> {
        const data = { Code: code, LastName: name };

        return this.http
            .post(this.resourceUrl + '/ValidatePreApproval', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch((error: any) => {
                return this.handleError(error);
            });
    }

    // TODO:MFA Full system logout including clearing user info, b2c logout and redirect.
    public Logout(postLogoutRedirectUri: string = null) {
        this.userService.Logout();
        window.sessionStorage.clear(); // Need to clear b2c session data to prevent MsalService error for some scenarios

        // We need to specify the client id in order to have an application scope for B2C policies
        const logoutRequest: EndSessionRequest = {
            extraQueryParameters: { 'client_id': CONFIG.b2cConfig.clientId }
        };
        if (postLogoutRedirectUri) {
            logoutRequest.postLogoutRedirectUri = postLogoutRedirectUri;
        }
        this.msalService.logoutRedirect(logoutRequest); // b2c logout and redirect
    }
}
