import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { AuthenticatedHttp } from './authenticated-http.service';
import { StorageService } from './storage.service';
import { CONFIG } from '../../environments/environment';
import { Canada } from '../models/constants';

@Injectable()
export class LookupService {
    private baseUri = `${CONFIG.apiBaseUri}/lookups`;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private inProcessLookups: Map<string, Promise<any>> = new Map<string, Promise<any>>();

    constructor(private http: AuthenticatedHttp, private storageService: StorageService) { }

    get(lookupType: string, checkForCached: boolean = true): Promise<any> {
        if (checkForCached) {
            const cachedValue = this.storageService.getObjectFromSession(lookupType);
            if (cachedValue) {
                return Promise.resolve(cachedValue);
            } else if (this.inProcessLookups.has(lookupType)) {
                return this.inProcessLookups.get(lookupType);
            }
        }

        const promise = this.createHttpGetPromise(lookupType);
        this.inProcessLookups.set(lookupType, promise);
        return promise;
    }

    createHttpGetPromise(lookupType: string): Promise<any> {
        const url = `${this.baseUri}/${lookupType}`;
        return this.http.get(url, { headers: this.headers })
            .toPromise()
            .then(response => {
                const payload = response;
                this.storageService.storeObjectInSession(lookupType, payload);
                return payload;
            })
            .catch(this.handleError);
    }

    getCitizenshipCountries(): Promise<any> {
        return this.get('citizenship-countries');
    }

    getCitizenshipCountriesExceptCanada(): Promise<any> {
        return this.get('citizenship-countries')
            .then(response => {
                return response.filter(value => value !== Canada);
            });
    }

    getAddressCountries(): Promise<any> {
        return this.get('address-countries');
    }

    private handleError(error: any) {
        const json = error;
        if (json && json.Message) {
            return Promise.reject(json.Message);
        } else {
            let errorVal = error.message || error;
            if (errorVal.status === 0) {
                errorVal = 'Failed to connect to VF Services API';
            }

            console.error('An error occurred', errorVal);
            return Promise.reject(errorVal);
        }
    }
}
