import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'rol-account-preferences',
    templateUrl: 'account-preferences.component.html',
    styleUrls: ['account-preferences.component.css']
})
export class AccountPreferencesComponent implements OnInit {

    constructor(private userService: UserService, private authService: AuthService) { }

    ngOnInit() { }
}
