import { Component, Input } from '@angular/core';

@Component({
    selector: 'rol-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.css']
})
export class SectionHeaderComponent {
    @Input() nav: boolean;
    @Input() navFunction: () => void;

    goBack() {
        if (this.navFunction) {
            this.navFunction();
        } else {
            window.history.back();
        }
    }
}
