import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { Crop } from '../../models/crop';
import { Livestock } from '../../models/livestock';
import { HistoryService } from '../../services/history.service';
import { IItemFactory } from '../reusables/autocomplete/autocomplete.component';
import { validateNumberFactory } from '../validation/number-validator.directive';
import { totalIncomeMaxNLS, FinancialValidation } from '../financial-validation';
import { Crops } from './crops';
import { Livestocks } from './livestocks';
import { CropItemFactory } from '../reusables/crop-item-factory';
import { YieldUnitsFactory } from '../reusables/yield-units-factory';
import { LivestockItemFactory } from './livestock-item-factory';

@Component({
    selector: 'rol-financial-information-income',
    templateUrl: 'financial-information-income.component.html',
    styleUrls: ['../styles_form.css', 'income-table.css']
})
export class FinancialInformationIncomeComponent extends AbstractFormPage {

    constructor(currentAppSvc: CurrentApplicationService,
        router: Router,
        utilities: UtilitiesService,
        history: HistoryService,
        private route: ActivatedRoute) {
        super(currentAppSvc, router, utilities, history);
        this.pageAlias = 'income';
        this.initPage();
    }

    public get totalCropIncomeInvalid(): boolean {
        return this.application.income.grossCropIncome > this.totalIncomeMax;
    }

    public get totalLivestockIncomeInvalid(): boolean {
        return this.application.income.grossLivestockIncome > this.totalIncomeMax;
    }

    public get totalOtherIncomeInvalid(): boolean {
        return this.application.income.otherFarmIncome > this.totalIncomeMax;
    }

    public get totalIncomeIsInvalid(): boolean {
        return this.application.income.totalFarmIncome && this.application.income.totalFarmIncome >= this.totalIncomeMax;
    }

    otherFarmIncomeForm: TopForm = new TopForm();
    otherFarmIncomeControl: FormControl;
    financialValidation: FinancialValidation = new FinancialValidation();
    cropFactory: IItemFactory = new CropItemFactory();
    yieldUnitsFactory: IItemFactory = new YieldUnitsFactory();
    livestockFactory: IItemFactory = new LivestockItemFactory();
    totalIncomeMax: number = totalIncomeMaxNLS;

    crops = new Crops(this.utilities);
    livestock = new Livestocks(this.utilities);

    productiveAcresForm: TopForm = new TopForm();
    acresOwnedControl: FormControl;
    acresRentedControl: FormControl;


    numbersOnlyMask = function (valueInput: string) {
        const mask = [];
        for (let i = 0; i < valueInput.length; ++i) {
            mask.push(/\d/);
        }
        return mask;
    };

    addCrop() {
        this.crops.form.submitted = true;
        if (this.crops.form.valid) {
            if (this.crops.editingCrop) {
                const match = this.application.income.cropList.findIndex((crop) => crop === this.crops.editingCrop);
                if (match !== -1) {
                    const crop = this.crops.value;
                    this.application.income.cropList[match] = crop;
                }
                this.crops.editingCrop = null;
            } else {
                const crop = this.crops.value;
                this.application.income.cropList.push(crop);
            }

            this.crops.form.submitted = false;
            this.application.income.calculateValues();
            this.crops.clear();
        }
    }

    cropListUpdate(crop: Crop) {
        if (!this.crops.editingCrop || (this.crops.editingCrop && this.crops.editingCrop !== crop)) {
            this.crops.editingCrop = crop;
            this.crops.update(crop);
        } else if (this.crops.editingCrop) {
            this.crops.form.submitted = false;
            this.crops.editingCrop = null;
            this.crops.clear();
        }
    }

    cropListRemove(cropToRemove: Crop) {
        const match = this.application.income.cropList.findIndex((crop) => crop === cropToRemove);
        if (match !== -1) {
            this.application.income.cropList.splice(match, 1);
            this.application.income.calculateValues();
            this.crops.editingCrop = null;
            this.crops.clear();
            return true;
        }
        return false;
    }

    addLivestock() {
        this.livestock.form.submitted = true;
        if (this.livestock.form.valid) {
            if (this.livestock.editingLivestock) {
                const match = this.application.income.livestockList.findIndex((livestock) => livestock === this.livestock.editingLivestock);
                if (match !== -1) {
                    this.application.income.livestockList[match] = this.livestock.value;
                }
                this.livestock.editingLivestock = null;
            } else {
                this.application.income.livestockList.push(this.livestock.value);
            }

            this.livestock.form.submitted = false;
            this.application.income.calculateValues();
            this.livestock.clear();
        }
    }

    livestockListUpdate(livestock: Livestock) {
        if (!this.livestock.editingLivestock || (this.livestock.editingLivestock && this.livestock.editingLivestock !== livestock)) {
            this.livestock.editingLivestock = livestock;
            this.livestock.update(livestock);
        } else if (this.livestock.editingLivestock) {
            this.livestock.form.submitted = false;
            this.livestock.editingLivestock = null;
            this.livestock.clear();
        }
    }

    livestockListRemove(livestockToRemove: Livestock) {
        const match = this.application.income.livestockList.findIndex((livestock) => livestock === livestockToRemove);
        if (match !== -1) {
            this.application.income.livestockList.splice(match, 1);
            this.application.income.calculateValues();
            this.livestock.editingLivestock = null;
            this.livestock.clear();
            return true;
        }
        return false;
    }

    afterInitPage() {
        this.otherFarmIncomeControl = new FormControl(this.application.income.otherFarmIncome || 0,
            [Validators.required, validateNumberFactory(false, 2, 0, this.totalIncomeMax, this.utilities)]);
        this.otherFarmIncomeForm.addControl('otherFarmIncomeControl', this.otherFarmIncomeControl);

        this.acresOwnedControl = new FormControl(this.application.productiveAcres.acresOwned || '',
            [Validators.required, validateNumberFactory(false, 2, 0, this.totalIncomeMax, this.utilities)]);
        this.productiveAcresForm.addControl('acresOwnedControl', this.acresOwnedControl);

        this.acresRentedControl = new FormControl(this.application.productiveAcres.acresRented || '',
            [Validators.required, validateNumberFactory(false, 2, 0, this.totalIncomeMax, this.utilities)]);
        this.productiveAcresForm.addControl('acresRentedControl', this.acresRentedControl);
    }

    next() {
        this.productiveAcresForm.updateValueAndValidity();
        this.productiveAcresForm.submitted = true;

        if (!this.totalCropIncomeInvalid
            && !this.totalLivestockIncomeInvalid
            && !this.totalOtherIncomeInvalid
            && !this.totalIncomeIsInvalid
            && !this.productiveAcresForm.invalid) {
            super.next();
        }
    }
}
