import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Bowser from 'bowser';

import { TextRetrieverService } from '../app/services/text-retriever.service';
import { UtilitiesService } from '../app/services/utilities.service';
import { UserService } from '../app/services/user.service';
import { CurrentApplicationService } from '../app/services/current-application.service';
import { ReCaptchaService } from '../app/services/recaptcha.service';
import { HistoryService } from '../app/services/history.service';
import { Subject } from 'rxjs';
import { IVfAuthServiceInitOptions, IfAuthService } from 'if-angular-security';
import { AuthService } from './services/auth.service';

// AppComponent advises if the browser is supported and provides header-free components for B2C content iframes
@Component({
    selector: 'rol-app',
    templateUrl: './app.component.html',
    providers: [TextRetrieverService, UtilitiesService, ReCaptchaService, HistoryService],
})
export class AppComponent implements OnInit, OnDestroy {
    showBowserAlert: boolean;
    private readonly _destroying$ = new Subject<void>();

    constructor(userService: UserService, currentApp: CurrentApplicationService,
        private ifAuthService: IfAuthService,
        private authService : AuthService) {
        userService.CurrentAppService = currentApp;

        const vfServiceInitOptions: IVfAuthServiceInitOptions = {
            appDestroyedSubject : this._destroying$,
            onLoginCallback : this.authService.onUserLogin,
            isLoggedInInApp : this.authService.loggedIn,
        };      
        this.ifAuthService.initService(vfServiceInitOptions);
    }

    ngOnInit() {
        this.browserCheck();
    }

    ngOnDestroy(): void { // TODO:MFA
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    // MFA - Hide headers/footers for components displayed in iframes
    browserCheck() {
        const browser = Bowser.getParser(window.navigator.userAgent);

        this.showBowserAlert = !browser.satisfies({
            chrome: '>=87',
            firefox: '>=80',
            edge: '>=88',
            ios_saf: '>=12',
            and_chr: '>=87',
            and_ff: '>=80'
        });
    }
}
