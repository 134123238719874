import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl } from '@angular/forms';
import { comparisonFactory } from './comparisonFactory';


@Directive({
  selector: '[rolValidateComparison][ngModel],[validate-comparison][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ComparisonValidatorDirective), multi: true }
  ]
})
export class ComparisonValidatorDirective {

  validator: Function;

  constructor() {
    this.validator = comparisonFactory();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }
}
