<rol-payment-navigation [selectedAlias]=alias></rol-payment-navigation>
<rol-loading-feedback *ngIf="loading"></rol-loading-feedback>
<div *ngIf="alias == 'Details' && error">
    <h2 class="error">There was an issue pulling the payment details for this account.</h2>
    <button type="button" class="btn btn-primary bottom" (click)="Cancel()">Cancel</button>
</div>
<div *ngIf="!loading" class="raised-card">
    <div *ngIf="account?.HasPendingPayment">
        <h2>Existing Pending Payment Found</h2>
        <p class="bigger">There is an existing pending payment for this account. You cannot enter a new payment until
            the pending payment is processed or cancelled.
            Go to <a [routerLink]="['/dashboard/account/',loanNumber, 'payment-history']"
                routerLinkActive="active">Payment History</a> for more details.</p>
    </div>
    <div *ngIf="!account?.HasPendingPayment && (!account?.HasBalance || payments.length == 0)">
        <h2>No Balance On Account</h2>
        <p class="bigger">None of the loans on this account have a balance to make a payment towards.
            Go to <a [routerLink]="['/dashboard/account/',loanNumber]" routerLinkActive="active">Account Details</a> for
            more information.</p>
    </div>
    <div *ngIf="payments.length > 0">
        <h1>Payment {{alias}} <rol-loading-feedback *ngIf="submitting" [inline]="true"></rol-loading-feedback>
        </h1>
        <div *ngIf="alias == 'Details' && payments?.length != 1">
            <h4>To make a payment towards your account, you can either:</h4>
            <ul>
                <li>Make a full payoff payment by selecting Full Payment below, or</li>
                <li>Make a partial payment by selecting Partial Payment below.</li>
            </ul>
        </div>
        <h4 *ngIf="alias == 'Details' && payments?.length == 1">Make a payment toward your account below</h4>
        <p-tabMenu [model]="paymentTypes" [activeItem]="defaultType"
            [hidden]="alias != 'Details' || payments?.length == 1"></p-tabMenu>
        <div class="raised-card payment-wrapper">
            <div *ngIf="alias == 'Details'">
                <div *ngIf="!payments[0]?.AccountPayment">
                    <label>Enter or modify the payment amount to be paid towards each program.</label>
                </div>
                <div *ngIf="payments[0]?.AccountPayment">
                    <div class="row bigger">
                        <div class="col-sm-2">
                        </div>
                        <div class="col-sm-4">
                            <div class="financialsDiv"><label>Total Payoff Amount:</label></div>
                        </div>
                        <div class="col-sm-3">
                            <div class="financialsDiv">{{TotalPayoffAmount() | currency:'USD'}}</div>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>
                    <div class="row bigger">
                        <div class="col-sm-2">
                        </div>
                        <div class="col-sm-4">
                            <div class="financialsDiv"><label>Total Payment Amount:</label></div>
                        </div>
                        <div class="col-sm-3">
                            <div class="financialsDiv">{{TotalPaymentAmount() | currency:'USD'}}</div>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="alias == 'Confirmation'" class="confirmation-header">
                <h3>Your payment has been successfully submitted!</h3>
                <p>{{ConfirmationText()}}</p>
                <p *ngFor="let confirmationNumber of confirmationNumbers; let i=index"
                    class="confirmation-message raised-card">{{confirmationNumber}}</p>
            </div>
            <div *ngIf="alias == 'Details' && !payments[0]?.AccountPayment">
                <p-table [value]="payments" [responsive]="true">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th>Dealer</th>
                            <th>Program</th>
                            <th>Due Date</th>
                            <th>Payoff Amount</th>
                            <th>Payment Amount</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-row>
                        <tr>
                            <td> {{row.DealerName }} </td>
                            <td> {{row.ProgramName}} </td>
                            <td> {{row.MaturityDate | date:'shortDate' }} </td>
                            <td> {{row.PayoffAmount | currency:'USD' }} </td>
                            <td>
                                <rol-number-input [(ngModel)]="row.PaymentAmount"></rol-number-input>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="5">
                                <ul class="report-table-footer">
                                    <li>
                                        <label>Total Payoff Amount: </label>
                                        <span> {{TotalPayoffAmount() | currency:'USD'}}</span>
                                    </li>
                                    <li>
                                        <label>Total Payment Amount: </label>
                                        <span> {{TotalPaymentAmount() | currency:'USD'}}</span>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div *ngIf="alias != 'Details'">
                <p-table [value]="pendingPayments" [responsive]="true">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th>Dealer</th>
                            <th>Program</th>
                            <th>Payment Date</th>
                            <th>Payoff Amount</th>
                            <th>Payment Amount</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-row>
                        <tr>
                            <td> {{row.DealerName }} </td>
                            <td> {{row.ProgramName}} </td>
                            <td> {{row.PaymentDate | date:'shortDate' }} </td>
                            <td> {{row.PayoffAmount | currency:'USD' }} </td>
                            <td> {{row.PaymentAmount | currency:'USD' }} </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="5">
                                <ul class="report-table-footer">
                                    <li>
                                        <label>Total Payoff Amount: </label>
                                        <span> {{TotalPayoffAmount() | currency:'USD'}}</span>
                                    </li>
                                    <li>
                                        <label>Total Payment Amount: </label>
                                        <span> {{TotalPaymentAmount() | currency:'USD'}}</span>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div *ngIf="alias == 'Details'">
            <form name="form" [formGroup]="form" (ngSubmit)="VerifyPaymentDetails()" novalidate>
                <h2>Payment Source</h2>
                <div class="row payment-source raised-card">
                    <div class="col-sm-4 col-md-4">
                        <div class="form-group">
                            <label>Account Type:</label><br />
                            <mat-radio-group class="mat-radio-group" [(ngModel)]="achAccountType"
                                formControlName="ACHAccountType">
                                <mat-radio-button class="mat-radio-button" value="Checking">Checking</mat-radio-button>
                                <mat-radio-button class="mat-radio-button" value="Savings">Savings</mat-radio-button>
                            </mat-radio-group>
                            <div class="error"
                                *ngIf="(form.submitted || ACHAccountType.dirty) && ACHAccountType.invalid">
                                <p *ngIf="ACHAccountType.errors['required']">Account Type is required</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Bank Account Number</label>
                            <input name="BankAccountNumber" [formControl]="BankAccountNumber" class="form-control"
                                [(ngModel)]="bankAccountNumber" placeholder="Bank Account Number" />
                            <div class="error"
                                *ngIf="(form.submitted || BankAccountNumber.dirty) && BankAccountNumber.invalid">
                                <p *ngIf="BankAccountNumber.errors['required']">Bank Account Number is required</p>
                                <p
                                    *ngIf="BankAccountNumber.errors['minlength'] || BankAccountNumber.errors['maxlength']">
                                    Bank Account Number must be between 4 and 17 characters long</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Bank Routing Number</label>
                            <input name="BankRoutingNumber" [formControl]="BankRoutingNumber" class="form-control"
                                [(ngModel)]="bankRoutingNumber" placeholder="Bank Routing Number" />
                            <div class="error"
                                *ngIf="(form.submitted || BankRoutingNumber.dirty) && BankRoutingNumber.invalid">
                                <p *ngIf="BankRoutingNumber.errors['required']">Bank Routing Number is required</p>
                                <p
                                    *ngIf="BankRoutingNumber.errors['minlength'] || BankRoutingNumber.errors['maxlength']">
                                    Bank Routing Number must be 9 characters long.</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Name As It Appears On Check</label>
                            <input name="BankAccountHolder" [formControl]="BankAccountHolder" class="form-control"
                                [(ngModel)]="bankAccountHolder" placeholder="Bank Account Holder Name" />
                            <div class="error"
                                *ngIf="(form.submitted || BankAccountHolder.dirty) && BankAccountHolder.invalid">
                                <p>Bank Account Holder Name is required</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8 col-md-8">
                        <img class="image" src="assets/images/check_sample.gif">
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="alias == 'Review'">
            <ul class="payment-instructions">
                <li>Please allow one business day for posting to your account.</li>
                <li>Payments made after {{ACHProcessingCutoff}} CT will require an additional business day to process.
                </li>
                <li>If your payment is past due, then your payment will be applied first to any interest and principal
                    that is due. Late charges or default interest may be applicable
                    per the terms of your note, and will be billed separately.</li>
            </ul>
        </div>
        <div class="error bigger" *ngIf="errorMessage.length > 0">
            {{errorMessage}}
        </div>
        <button *ngIf="alias == 'Details'" type="submit" class="btn btn-primary bottom raised"
            (click)="VerifyPaymentDetails()" [disabled]="submitting">Continue</button>
        <button *ngIf="alias == 'Review' && !submitError" type="submit" class="btn btn-primary bottom raised"
            (click)="Submit()" [disabled]="submitting">Submit</button>
        <button *ngIf="alias == 'Review' && !submitError" type="button" class="btn btn-secondary bottom"
            (click)="Edit()" [disabled]="submitting">Edit</button>
        <button *ngIf="alias != 'Confirmation'" type="button" class="btn btn-secondary bottom" (click)="Cancel()"
            [disabled]="submitting">Cancel</button>
        <button *ngIf="alias == 'Confirmation'" type="button" class="btn btn-primary bottom raised"
            (click)="PrintConfirmation()" [disabled]="submitting">Print Confirmation</button>
        <button *ngIf="alias == 'Confirmation'" type="button" class="btn btn-secondary bottom" (click)="Cancel()"
            [disabled]="submitting">Done</button>
        <div class="clear"></div>
    </div>
</div>