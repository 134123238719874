import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationOptions } from '../../../models/navigation-options';
import { ApplicationHttpService } from '../../../services/application-http.service';
import { CurrentApplicationService } from '../../../services/current-application.service';
import { Application } from '../../../models/application';
import { ApplicationType } from '../../../models/application-type';
import { ApplicationHeader } from '../../../models/application-header';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user';
import { Message } from 'primeng/api';
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'rol-application-status-summary',
    templateUrl: 'application-status-summary.component.html',
    styleUrls: ['application-status-summary.component.css'],
    providers: [ApplicationHttpService]
})
export class ApplicationStatusSummaryComponent implements OnInit {

    @Output() hasOpenApplication: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() loadingApplications: EventEmitter<boolean> = new EventEmitter<boolean>();

    applicationHeaders: ApplicationHeader[];
    loading: boolean;
    inProcessStatuses: string[] = ['Draft', 'Signing', 'Submitted', 'Incomplete', 'InProcess', 'Printed'];
    documentType = 'Origination';
    selectedApplicationId: string;
    user: User;
    messages: Message[] = [];
    applicationLoadError = 'Failed to get the application details';
    applicationLoadDetails = 'If problem continues, please create a new application.';

    constructor(private applicationService: ApplicationHttpService,
        private currentApplicationService: CurrentApplicationService,
        private router: Router, private userService: UserService, private notificationService: NotificationService) { }

    ngOnInit(): void {
        this.getApplicationStatuses();
    }

    getApplicationStatuses(): void {
        this.loading = true;
        this.loadingApplications.emit(true);
        this.applicationService.getApplicationHeaders()
            .then((applications) => {
                this.applicationHeaders = applications;
                this.applicationHeaders.forEach(applicationHeader => {
                    if (this.inProcessStatuses.includes(applicationHeader.status)) {
                        this.hasOpenApplication.emit(true);
                    }
                });
            })
            .then(() => {
                this.loading = false;
                this.loadingApplications.emit(false);
            });
        this.user = this.userService.GetUser();
    }

    getApplicationDisplayName(applicationHeader: ApplicationHeader): string {
        return this.getApplicationStatus(applicationHeader);
    }

    public getApplicationStatus(applicationHeader: ApplicationHeader): string {
        if (applicationHeader.status === 'InProcess') {
            return 'In Process';
        }
        return applicationHeader.status;
    }

    refresh(): void {
        this.getApplicationStatuses();
    }

    resume(applicationHeader: ApplicationHeader) {
        if (applicationHeader.status === 'Draft') {
            this.loading = true;
            this.applicationService.getApplication(applicationHeader.id)
                .then((app) => {
                    this.currentApplicationService.setCurrentApplication(app);
                    this.router.navigate([NavigationOptions.GetStartPage(app)]);
                })
                .catch(error => {
                    this.loading = false;
                    this.setApplicationLoadErrorMessage();
                });
        }
    }

    newApp() {
        this.currentApplicationService.setCurrentApplication(null);
        this.router.navigate(['/apply']);
    }

    sign(applicationHeader: ApplicationHeader) {
        if (applicationHeader.status === 'Signing') {
            this.applicationService.getApplication(applicationHeader.id)
                .then((app) => {
                    this.applicationService.getDocuSignUrl(app).then((docuSignURL) => {
                        if (docuSignURL !== null) {
                            window.location.href = docuSignURL;
                        }
                    });
                })
                .catch(error => {
                    this.setApplicationLoadErrorMessage();
                });
        }
    }

    edit(applicationHeader: ApplicationHeader) {
        if (applicationHeader.status === 'Signing' || applicationHeader.status === 'Cancelled') {
            this.loading = true;
            this.applicationService.getApplication(applicationHeader.id)
                .then((application) => {
                    this.applicationService.editSigningApplication(application).then((app) => {
                        this.currentApplicationService.setCurrentApplication(app);
                        this.router.navigate([NavigationOptions.GetStartPage(app)]);
                    });
                })
                .catch(error => {
                    this.loading = false;
                    this.setApplicationLoadErrorMessage();
                });
        }
    }

    cancel(applicationHeader: ApplicationHeader) {
        if (applicationHeader.status === 'Draft' || applicationHeader.status === 'Signing') {
            this.loading = true;
            this.applicationService.getApplication(applicationHeader.id)
                .then((app) => {
                    this.applicationService.cancel(app).then(() => this.refresh());
                })
                .catch(error => {
                    this.loading = false;
                    this.setApplicationLoadErrorMessage();
                });
        }
    }

    delete(applicationHeader: ApplicationHeader) {
        if (applicationHeader.status === 'Cancelled') {
            this.loading = true;
            this.applicationService.getApplication(applicationHeader.id)
                .then((application) => {
                    this.applicationService.delete(application).then(() => this.refresh());
                    this.currentApplicationService.getCurrentApplication().then((app) => {
                        if (applicationHeader.id === app.id) {
                            this.currentApplicationService.setCurrentApplication(null);
                        }
                    });
                })
                .catch(error => {
                    this.loading = false;
                    this.setApplicationLoadErrorMessage();
                });
        }
    }

    print(applicationHeader: ApplicationHeader) {
        if (applicationHeader.status === 'Signing' || applicationHeader.status === 'Submitted' || applicationHeader.status === 'Printed') {
            this.loading = true;
            this.applicationService.getApplication(applicationHeader.id)
                .then((application) => {
                    application.print().then((_) => {
                        this.loading = false;
                    });
                })
                .catch(error => {
                    this.loading = false;
                    this.setApplicationLoadErrorMessage();
                });
        }
    }

    setApplicationLoadErrorMessage() {
        this.notificationService.push({ severity: 'error', summary: this.applicationLoadError, detail: this.applicationLoadDetails });
    }
}
