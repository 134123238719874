<div class="increased-risk-countries">  
    <div class="form-group">
        <label>Do you sell your product or have business activities outside of Canada and/or the US?</label>
        <select class="form-control"
                [(ngModel)]="showIncreasedRiskCountries" 
                (change)="showIncreasedRiskCountriesChanged($event.target.value)">
            <option [ngValue]="null"></option>
            <option [ngValue]="true">Yes</option>
            <option [ngValue]="false">No</option>
        </select>    
    </div> 
    <div *ngIf="!isAnswered && submitted" class="error rc-error">You must select "Yes" or "No"</div>
    <div *ngIf="showIncreasedRiskCountries"
            class="form-group p-fluid">
        <label>In which countries?</label>
        <p-autoComplete [(ngModel)]="selectedIncreasedRiskCountries"
                        class="increased-risk-countries-auto-complete"
                        [suggestions]="autoCompleteFilteredCountries" 
                        (completeMethod)="filterCountry($event.query)" 
                        field="label" 
                        [multiple]="true" 
                        (ngModelChange)="updateIncreasedRiskCountries()">
        </p-autoComplete>
    </div> 
    <div *ngIf="isMissingIncreasedRiskCountries && submitted" class="error rc-error">You must list countries outside of Canada and the US</div>
</div>