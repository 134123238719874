import { FormControl, FormGroup, Validators } from '@angular/forms';

import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { Livestock } from '../../models/livestock';
import { validateNumberFactory } from '../validation/number-validator.directive';
import { totalIncomeMaxNLS, FinancialValidation } from '../financial-validation';
import { IItemFactory } from '../reusables/autocomplete/autocomplete.component';
import { LivestockItemFactory } from './livestock-item-factory';

export class Livestocks {
    form: TopForm;
    editingLivestock: Livestock;
    livestockName: string;
    current: Livestock;
    financialValidation: FinancialValidation = new FinancialValidation();
    totalIncomeMax: number = totalIncomeMaxNLS;
    livestockFactory: IItemFactory = new LivestockItemFactory();

    constructor(private utilities: UtilitiesService) {
        this.current = new Livestock();
        this.form = new TopForm();
        this.form.addControl('count', new FormControl('', Validators.required));
        this.form.addControl('price', new FormControl('',
            [Validators.required, validateNumberFactory(false, 2, 0, this.totalIncomeMax, this.utilities)]));
    }

    update(livestock?: Livestock) {
        this.livestockName = livestock ? livestock.name : '';
        this.current.count = livestock ? livestock.count : 0;
        this.current.price = livestock ? livestock.price : 0;
    }

    clear() {
        this.update();
    }

    setLivestockName() {
        const livestocks = this;
        return function (livestockName) {
            livestocks.livestockName = livestockName;
        };
    }

    get value(): Livestock {
        return new Livestock({
            Name: this.livestockName,
            Count: this.current.count,
            Price: this.current.price
        });
    }
}
