export class Passport { 
    public number: string;
    public countryOfIssuance: string;

    constructor(appData?: any) {
        if(appData) {
            this.number = appData.Number;
            this.countryOfIssuance = appData.CountryOfIssuance;
        }
    }
}
