import { Component } from '@angular/core';

@Component({
    selector: 'rol-terms-and-conditions',
    templateUrl: 'terms-and-conditions.html',
    styles: [`
        .displayBox {
            background:#e7e7e7;
            margin-left: auto;
            margin-right: auto;
            margin-top: 4rem;
            padding:15px;
            overflow: auto;
            height:40rem;
            width: 90%;

        }
        p {
            padding-bottom: .5rem;
        }
    `]
})
export class TermsAndConditionsComponent {

}
