import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AccountHttpService } from '../../../services/account-http.service';
import { Payment } from '../../../models/accounts/payment';

@Component({
    selector: 'rol-payment-history',
    templateUrl: 'payment-history.component.html',
    styleUrls: ['payments.component.css']
})
export class PaymentHistoryComponent implements OnInit {

    loading: boolean;
    loanNumber: string;
    pendingPayments: Payment[] = [];
    completedPayments: Payment[] = [];
    reversalIds: string[] = [];
    cancelingPayment: boolean;
    submitting: boolean;
    cancelConfirmation: boolean;
    cancelError: boolean;
    error: boolean;

    constructor(private route: ActivatedRoute, private accountService: AccountHttpService) { }

    ngOnInit(): void {
        this.loanNumber = this.route.snapshot.parent.params['accountId'];
        this.GetPaymentHistory();
        window.scrollTo(0, 0);
    }

    GetPaymentHistory() {
        this.pendingPayments = [];
        this.completedPayments = [];
        this.loading = true;
        this.accountService.getPaymentHistory(this.loanNumber)
            .then((results) => {
                results.forEach(result => {
                    if (result.Status === 'Paid') {
                        this.completedPayments.push(result);
                    } else {
                        this.pendingPayments.push(result);
                    }
                });
            })
            .catch(() => {
                this.error = true;
            })
            .then(() => {
                this.loading = false;
            });
    }

    cancelClick() {
        this.cancelingPayment = true;
    }

    cancelCancel() {
        this.cancelingPayment = false;
    }

    closeCancelError() {
        this.cancelError = false;
    }

    closeCancelConfirmation() {
        this.cancelConfirmation = false;
        this.GetPaymentHistory();
    }

    cancelPending() {
        this.submitting = true;
        this.accountService.cancelPendingPayments(this.pendingPayments)
            .then((results) => {
                this.reversalIds = results;
                if (this.reversalIds.length === 0) {
                    this.cancelError = true;
                } else {
                    this.cancelConfirmation = true;
                }
            })
            .catch(() => {
                this.cancelError = true;
            })
            .then(() => {
                this.submitting = false;
                this.cancelCancel();
                this.accountService.updateAccountObservable();
            });
    }

    ConfirmationText(): string {
        let text = '';
        if (this.reversalIds.length > 1) {
            text += 'Your confirmation numbers for this transaction are:';
        } else {
            text += 'Your confirmation number for this transaction is:';
        }
        return text;
    }

}
