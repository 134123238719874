export const SecurityQuestions = [
    'What is your favorite website or ag news source?',
    'What is the make or model of your favorite piece of equipment?',
    'What is your favorite food?',
    'What country would you like to visit?',
    'What was the name of your childhood pet?',
    'What was the make/model/year of your first car?',
    'What is your oldest sibling\'s middle name?',
    'What is your mother\'s maiden name?',
    'What city or town were you born in?',
    'What is the name of the first school you attended?',
    'What were the last 4 digits of your childhood telephone number?',
    'What is your favorite vacation destination?'
];
