export class DealerHeader {
    RowId: number;
    DealerNumber: string;
    Name: string;

    constructor(appData?: any) {
        if (appData) {
            this.RowId = appData.RowId;
            this.DealerNumber = appData.DealerNumber;
            this.Name = appData.Name;
        }
    }
}
