import { FullName } from "./fullname";

export class Director {
    constructor(appData?: any) {
        if (appData) {
            this.fullName = new FullName(appData.FullName);
        }
    }

    fullName: FullName = new FullName();

    toString(): string {
        return this.fullName.toString();
    }
}
