import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Person } from '../../../models/person';
import { TopForm } from '../../top-form';
import { AtLeastOneValidator } from '../../validation/atleast-one-validator';
import { PhoneMask } from '../../../models/constants';
import { Entity } from '../../../models/entity';

@Component({
    selector: 'rol-multiple-phone-component',
    templateUrl: 'multiple-phone.component.html',
})
export class MultiplePhoneComponent implements OnInit {
    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() borrower: Person | Entity;

    length = 14;
    mask = PhoneMask;

    ngOnInit() {
        var businessPhone = new FormControl(this.borrower.businessPhoneNumber, [Validators.minLength(this.length), Validators.maxLength(this.length)]);
        var homephone = new FormControl(this.borrower.homePhoneNumber, [Validators.minLength(this.length), Validators.maxLength(this.length)]);
        var mobilephone = new FormControl(this.borrower.mobilePhoneNumber, [Validators.minLength(this.length), Validators.maxLength(this.length)]);

        this.group = new FormGroup({
            'businessphone': businessPhone,
            'homephone': homephone,
            'mobilephone': mobilephone
        }, AtLeastOneValidator);
    }

    get businessPhoneCtrl(): FormControl { 
        return this.group.get("businessphone") as FormControl;
    }

    get homePhoneCtrl(): FormControl { 
        return this.group.get("homephone") as FormControl;
    }

    get mobilePhoneCtrl(): FormControl { 
        return this.group.get("mobilephone") as FormControl;
    }
}
