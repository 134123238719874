<div class="container" fxLayout="column">
    <div class="row">
        <div class="col-sm-4" id="login" fxFlex.lt-md="100%">
            <div *ngIf="showLogin">
                <rol-login [hideCancel]=true (closeEvent)="redirectIfLoggedIn()"></rol-login>
                <div id="loginOptions">
                    <p class="passwordLink"><a class="clickable" tabindex="0" (click)="switchToForgot()">Forgot your
                            password?</a></p>
                    <p class="registerLink">If you are a new user, please <a class="clickable" routerLink="/registration">click here</a> to register.</p>
                    <p class="right">Only authorized use of this site is permitted. Unauthorized access may result in legal or disciplinary action.</p>
                    <p class="right">
                        <a class="clickable customer-security-link" (click)="openModal()">Click here</a> for customer security information.
                    </p>
                    <p class="right">This site uses cookies to provide for technical functionality as well as to make our website more enjoyable and relevant
                        to you. By continuing your visit on the website, you consent to the use of these cookies.
                    </p>
                </div>
            </div>
            <div *ngIf="!showLogin">
                <rol-reset-password (closeEvent)="switchToLogin()"></rol-reset-password>
            </div>
        </div>
        <div class="col-sm-8" fxHide.lt-md="true" >
            <div id="carousel">
                <div id="transparent">
                    <div>
                        <div class="slider-container">
                            <div class="richardson-pioneer">
                                <a class="richardson-pioneer-logo"><img src="assets/images/Richardson_Pioneer_WHT.png" alt="Richardson Pioneer logo" /></a>
                            </div>
                            <h3>WELCOME TO THE NEW<br>AG PARTNER FINANCING ONLINE SITE</h3>
                            <button class="btn btn-primary" [routerLink]="['/registration']">Register
                                Here!</button>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container" fxLayout="row" fxHide.gt-xs="true" fxLayoutAlign="space-around">
    <button type="button" class="btn btn-primary" routerLink="/apply">Apply Here</button>
    <button type="button" class="btn btn-primary" routerLink="/contact">Contact Us</button>
</div>

<div *ngIf="showModal" class="modal-wrapper">
    <div class="modal-content">
        <div>
            <h1>How we protect You</h1>
            <p class="lead">Ag-Partner Financing, powered by Rabobank, uses many advanced security measures to protect you while you are using our website.</p>
            <p>Below are just a few of the measures we use:</p>
            <ul class="bullets">
                <li>Unique login IDs and passwords - You select your own login ID and password.</li>
                <li>Firewalls - Blocks unauthorized access to the Ag-Partner Financing, powered by Rabobank computer system, 
                    where your information is kept.</li>
                <li>Monitoring - We monitor for unusual activity on your account. However, this is not fool-proof and does not 
                    take the place of you regularly monitoring your account.</li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>Technology</strong></p>
            <p>We protect our computer system with backup files, virus detection software, firewalls, and other computer software and hardware. 
                By taking these measures to protect the integrity of our computer system, we are also helping ensure the safety of your personal 
                information.</p>
            <p><strong>Limited Employee Access</strong></p>
            <p>Access to account information is restricted to only those employees who are required to have access to it in order to assist you. 
                Authorized employees are required to keep customer information confidential, and are subject to disciplinary action or termination 
                if they fail to do so.</p>
            </div>
        <div class="buttonContainer">
            <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
        </div>
    </div>
</div>