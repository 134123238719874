export class SurveyQuestion {
    constructor(appData?: any) {
        if (appData) {
            this.question = appData.Question;
            this.answer = appData.Answer ? appData.Answer : '';
            this.questionId = appData.QuestionId;
            this.type = JSON.parse(appData.Type);
        }
    }
    questionId: number;
    question = '';
    answer = '';
    type: { ['MultipleChoice']?: string[], ['FreeText']?: boolean, ['SingleLine']?: boolean };
}
