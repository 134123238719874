import { ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Application } from '../models/application';
import { CurrentApplicationService } from '../services/current-application.service';
import { NavigationOptions } from '../models/navigation-options';
import { UtilitiesService } from '../services/utilities.service';
import { HistoryService } from '../services/history.service';

export abstract class AbstractFormPage {
    application: Application;
    pageAlias: string;
    constructor(
        private currentAppSvc: CurrentApplicationService,
        public router: Router,
        protected utilities: UtilitiesService,
        private history: HistoryService) {
    }

    initPage() {
        this.currentAppSvc.getCurrentApplication().then((app) => {
            // tslint:disable-next-line: max-line-length
            // if app is not defined there is a security mismatch where the user is not allowed to see the requested app, redirect to landing page.
            if (!app || app.currentPage === '') {
                return this.router.navigate(['/apply']);
            }
            // tslint:disable-next-line: max-line-length
            // trying to get to an invalid page for this application, or trying to get to a page past where the user has validated the app up to.
            this.application = app;
            if (this.pageNumber === -1) {
                this.application = null;
                return this.router.navigate(['/apply']);
            }
            if (app.currentPage) {
                if (this.pageNumber > NavigationOptions.GetPageIndexByAlias(app.currentPage, app, true)) {
                    this.application = null;
                    return this.router.navigate(['/apply']);
                }
            }

            if (this.pageNumber === 0 || this.history.isEmpty()) {
                this.history.init(this.router.url);
            }
            this.afterInitPage();
        });
    }

    next(numberToMove?: number, save: boolean = true) {
        numberToMove = numberToMove || 1;
        const newPage = NavigationOptions.AppPages(this.application, true)[this.pageNumber + numberToMove];
        if (save) {
            this.application.currentPage = newPage.alias;
            this.application.save()
                .then(id => this.currentAppSvc.setCurrentApplication(this.application))
                .catch((error) => { });
        }
        this.history.forward(newPage.route);
        this.router.navigateByUrl(newPage.route);
        window.scrollTo(0, 0);
    }

    get pageNumber() {
        return NavigationOptions.GetPageIndexByAlias(this.pageAlias, this.application, true);
    }

    toNumber(value) {
        return this.utilities.toNumber(value);
    }

    back() {
        this.router.navigateByUrl(this.history.back());
    }

    goToPage(path: string) {
        this.history.forward(path);
        this.router.navigateByUrl(path);
    }

    get showBack() {
        return !this.history.isEmpty();
    }

    afterInitPage() {
        // virtual method to override
    }

    scrollToError() {
        const element: any = document.querySelector('.ng-invalid');
        if (element) {
            element.scrollIntoView();
            window.scrollBy(0, -100);
        }
    }
}
