import { Component, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { TopForm } from '../../top-form';
import { Application } from '../../../models/application';
import { DoingBusinessAsItem } from './doing-business-as-item';

@Component({
    selector: 'rol-doing-business-as',
    templateUrl: './doing-business-as.component.html',
    styleUrls: ['./doing-business-as.styles.css']
})
export class DoingBusinessAsComponent implements AfterViewInit {

    @Input() application: Application;
    @Input() form: TopForm;
    dbaFormGroup: FormGroup;
    dbas: DoingBusinessAsItem[] = [];
    uniqueIndex = 0;

    constructor(private changeDetectorRef: ChangeDetectorRef) { }

    ngAfterViewInit() {
        this.dbaFormGroup = new FormGroup({});
        this.form.addControl('DoingBusinessAs', this.dbaFormGroup);
        if (this.application.entity.doingBusinessAs) {
            this.application.entity.doingBusinessAs.forEach(() => this.addNewFormControlForDBA());
        }

        this.changeDetectorRef.detectChanges();
    }

    addDBA() {
        this.addNewFormControlForDBA();
        this.application.entity.doingBusinessAs.push('');
    }

    addNewFormControlForDBA() {
        const dbaItem = new DoingBusinessAsItem();
        dbaItem.control = new FormControl('', Validators.required);
        dbaItem.name = `dba-${this.uniqueIndex++}`;

        this.dbaFormGroup.addControl(dbaItem.name, dbaItem.control);
        this.dbas.push(dbaItem);
    }

    removeDBA(dbaItem: DoingBusinessAsItem) {
        const index = this.dbas.indexOf(dbaItem);
        this.dbaFormGroup.removeControl(dbaItem.name);
        this.application.entity.doingBusinessAs.splice(index, 1);
        this.dbas.splice(index, 1);
    }
}
