import { Question } from './question';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ComplexQuestion extends Question {
    constructor(appData?: any) {
        super(appData);
        if (appData) {
            this.assetQuestionText = appData.assetQuestionText;
            this.assetExample = appData.assetExample;
            this.totalLiabilityQuestionText = appData.totalLiabilityQuestionText;
            this.totalLiabilityExample = appData.totalLiabilityExample;
            this.shortTermLiabilityQuestionText = appData.shortTermLiabilityQuestionText;
            this.shortTermLiabilityExample = appData.shortTermLiabilityExample;
            this.assets = appData.assets;
            this.totalLiability = appData.totalLiability;
            this.shortTermLiability = appData.shortTermLiability;
        }
    }

    assetQuestionText: string;
    assetExample: string;
    assetCustomValidators: ((c: AbstractControl) => ValidationErrors)[] = [];

    totalLiabilityQuestionText: string;
    totalLiabilityExample: string;
    totalLiabilityCustomValidators: ((c: AbstractControl) => ValidationErrors)[] = [];

    shortTermLiabilityQuestionText: string;
    shortTermLiabilityExample: string;
    shortTermLiabilityCustomValidators: ((c: AbstractControl) => ValidationErrors)[] = [];

    liabilityDescription: string;

    assets: number[] = [];
    totalLiability: number[] = [];
    shortTermLiability: number[] = [];
    get termLiability(): number[] {
        return this.totalLiability.map((value, index) => {
            return value - this.shortTermLiability[index];
        });
    }
}
