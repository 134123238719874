export class Transaction {
    TransactionDate: Date;
    Amount: number;
    Type: string;
    Program: string;
    Vendor: string;

    constructor(data?: any) {
        if (data) {
            this.Amount = data.Amount;
            this.TransactionDate = data.TransactionDate ? new Date(data.TransactionDate) : null;
            this.Type = data.Type;
            this.Program = data.Program;
            this.Vendor = data.Vendor;
        }
    }
}
