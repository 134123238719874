import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookupService } from '../../services/lookup.service';
import { Option } from '../../models/option.model';

@Component({
    selector: 'rol-increased-risk-countries',
    templateUrl: './increased-risk-countries.component.html',
    styleUrls: ['./increased-risk-countries.component.css']
})
export class IncreasedRiskCountriesComponent implements OnInit {
    @Input() increasedRiskCountries: string[];
    @Input() submitted: boolean;
    @Output() increasedRiskCountriesChange: EventEmitter<string[]> = new EventEmitter();

    countries: Option[];
    autoCompleteFilteredCountries: Option[];
    selectedIncreasedRiskCountries: Option[];
    showIncreasedRiskCountries = true;

    get isValid(): boolean {
      return this.isAnswered && !this.isMissingIncreasedRiskCountries;
    }

    get isAnswered(): boolean {
      return this.showIncreasedRiskCountries != null;
    }

    get isMissingIncreasedRiskCountries(): boolean {
      return this.showIncreasedRiskCountries === true && (!this.increasedRiskCountries || this.increasedRiskCountries.length === 0);
    }

    constructor(
        private lookupService: LookupService
    ) { }

    ngOnInit() {
        this.getCountries();
        this.showIncreasedRiskCountries = this.increasedRiskCountries && this.increasedRiskCountries.length > 0;
    }

    private getCountries(): void {
        this.lookupService.getAddressCountries()
            .then(resolve => {
                this.countries = resolve.map(x => new Option(x.DisplayName, x.Name));
                this.countries = this.countries.filter(c => c.value !== 'CA' && c.value !== 'US');

                // Populated selected countries with an Option rather than just country code
                if (this.countries && this.increasedRiskCountries) {
                  this.selectedIncreasedRiskCountries = this.countries.filter(
                      c => this.increasedRiskCountries.some(irc => irc === c.value));
                }
        });
    }

    public showIncreasedRiskCountriesChanged(value: string) {
        if (value.includes('false')) {
            this.selectedIncreasedRiskCountries = [];
            this.increasedRiskCountries = [];
            this.updateIncreasedRiskCountries();
        }
    }

    public filterCountry(query: any): void {
        const filtered: any[] = [];

        for (let i = 0; i < this.countries.length; i++) {
            const country = this.countries[i];
            if (country.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        }

        this.autoCompleteFilteredCountries = filtered;
    }

    public updateIncreasedRiskCountries(): void {
        this.increasedRiskCountries = this.selectedIncreasedRiskCountries.map(sirc => sirc.value);
        this.increasedRiskCountriesChange.emit(this.increasedRiskCountries);
    }
}
