import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CONFIG } from '../../../environments/environment';

@Component({
    selector: 'b2c-content-host',
    templateUrl: './b2c-content-host.component.html',
    styleUrls: ['./b2c-content-host.component.css']
})
export class B2cContentHostComponent implements OnInit {
    @Input() b2cFrameSource: string;
    @Input() b2cFrameHeight: string;

    public shouldHideFrame = true;
    public b2cframeSrc: SafeResourceUrl;

    private debug = false;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit() {
        // We need to store the value into a variable otherwise the iframe will reset every change detection cycle
        // This will avoid the page refreshing multiple times despite the URL remaining constant
        this.b2cframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.b2cFrameSource);

        const wait = new Promise(resolve => setTimeout(resolve, CONFIG.b2cFrameDelay));
        wait.then(_ => {
            this.shouldHideFrame = false;
        });

        this.setupFrameListener();
    }

    get b2cFrameSrc(): SafeResourceUrl {
        return this.b2cframeSrc;
    }

    private setupFrameListener() {
        window.addEventListener(
            'message', (e) => {
                this.log('framesize event received', e);
                const b2cUri = `https://${CONFIG.b2cConfig.tenantId}.b2clogin.com`;
                if (!e.origin.match(b2cUri)) { // event
                  return;
                }
                let message = e.data;
                if (message.height) {
                  this.b2cFrameHeight = message.height + 'px';
                }
              },
            false
          );
    }

    private log(message: string, e: any) {
      if (this.debug) {
        console.log( message, e);
      }
    }
}
