<div class="directors-section">
    <h4>Directors of the Borrower (Required for Loan Applicants that are Corporations):</h4>
    <div class="directors-instructions">For Corporations, please list the names of all your Directors (i.e. members of the Board).</div>
    <p-accordion (onClose)="tabClose($event)" (onOpen)="tabOpen($event)">
        
            <p-accordionTab [selected]="isOpen[i]" *ngFor="let d of directors; let i = index">
                <p-header [ngClass]="{'error': form.submitted, 'ng-invalid': form.submitted && !isOpen[i]}">
                    <span *ngIf="!showDisplayName(d)" class="new">(new)</span>
                    <span *ngIf="showDisplayName(d)">{{d.fullName.firstName}} {{d.fullName.lastName}}</span>
                    <span class="actionicon" (click)="delete(i, $event)">
                        Delete
                        <i class="glyphicon fa fa-trash-o"></i>
                    </span>
                </p-header>
                <rol-fullname-component name="fullname" [fullName]="d.fullName" Title="Legal Name" [form]="form">
                </rol-fullname-component>
            </p-accordionTab>
        
    </p-accordion>
    <div *ngIf="form.submitted && directorRequiredError" class="error">
        <p>At least 1 director is required for Corporations</p>
    </div>

    <div class="group">
        <button type="button" class="btn btn-primary" (click)="add()">Add Director</button>
    </div>
</div>
