<div class="container stage main_container">
    <rol-side-nav selectedAlias="{{pageAlias}}"></rol-side-nav>
    <h1 *ngIf="preApproved" class="row preapprovalBanner">
        Pre-Screened Offer Acceptance
    </h1>
    <div class="row">
        <div class="col-sm-7">
            <h1>{{borrowerText}} Information</h1>

        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-6">
            <p class="text-small">All fields are required unless otherwise noted.</p>
        </div>
    </div>
    <div class="spacer">
        <div fxFlex.gt-md="60">
            <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application">
                <p-accordion (onClose)="tabClose($event)" (onOpen)="tabOpen($event)">
                    <div *ngFor="let b of application.borrowers; let i = index">

                        <div *ngIf="!isSingleOwnerApplication && i === 0">
                            <div class="row applicant-ribbon">Primary Applicant Co-Borrower</div>
                            <p></p>
                            <div class="col-sm-12">
                                <label>
                                    Primary Applicant Co-Borrower is confirming, by checking the box below, that
                                    he/she has met the following condition:
                                </label>
                            </div>
                            <div class="col-sm-12 primary-applicant-signoff">
                                <div class="error" *ngIf="form.submitted && hasPrimaryApplicantConfirmation.invalid">
                                    <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                                </div>
                                <input type="checkbox" name="hasPrimaryApplicantConfirmation" (change)="changeHasPrimaryApplicantConfirmation($event)"
                                       [formControl]="hasPrimaryApplicantConfirmation" [ngClass]="{error: form.submitted && hasPrimaryApplicantConfirmation.invalid}">

                                The Primary Applicant Co-Borrower is the ultimate owner or the ultimate majority
                                shareholder of the Borrower named in <b>(corporations)</b> or, is the most influential person in the day to day operations of the Borrower
                                <b>(co-ops, community assoc., unincorporated organizations)</b> or, is a partner <b>(partnership)</b>
                                <br><br>
                                <b>For Partnerships all partners must be a Co-Borrower.</b>
                                <br><br>
                            </div>
                        </div>
                        <div *ngIf="!isSingleOwnerApplication && i === 1">
                            <p></p>
                            <div class="row applicant-ribbon">Joint Applicant Co-Borrowers</div>
                        </div>

                        <p-accordionTab [selected]="isOpen[i]">
                            <p-header [ngClass]="{'error': form.submitted && !formGroups[i].valid, 'ng-invalid': form.submitted && !formGroups[i].valid && !isOpen[i]}">
                                <span *ngIf="!showDisplayName(b)" class="new">(new)</span>
                                <span *ngIf="showDisplayName(b)">{{b.fullName.firstName}} {{b.fullName.lastName}}</span>
                                <span class="actionicon" (click)="delete(i, $event)">
                                    <i class="glyphicon fa fa-trash-o"></i> Delete
                                </span>
                            </p-header>
                            <rol-borrower-detail [borrower]="b" [form]="form" [group]="formGroups[i]"></rol-borrower-detail>
                        </p-accordionTab>
                    </div>
                </p-accordion>

                <div class="buttonContainer">
                    <button class="btn btn-secondary back-btn" type="button"
                            (click)="back()" fxFlex.lt-md="40">
                        Back
                    </button>
                    <button type="submit" class="btn btn-primary" fxFlex.lt-md="40">Continue</button>
                    <button type="button" class="btn btn-primary" (click)="add()">Add {{borrowerText}}</button>
                </div>
            </form>
        </div>
    </div>
    <div style="clear:both"></div>
</div>

<div *ngIf="showModal" class="modal-wrapper">
    <div class="modal-content">
        <div>
            You have changed the values associated with your pre-screen offer. Please click “Restore Defaults” if you
            wish to continue with the pre-screen offer. If you would simply like to start a new application, which would
            not be processed under the pre-screen offer, please click “Proceed” and you can begin the new application
            process.
        </div>
        <div class="buttonContainer">
            <button type="button" class="btn btn-primary" (click)="ProceedWithoutPreApproval()">Proceed</button>
            <button type="button" class="btn btn-primary" (click)="restorePreApprovalDefaults()">Restore
                Defaults</button>
        </div>
    </div>
</div>