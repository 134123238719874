export class DocumentMetaData {
    DocumentType: string;
    DocumentCategory: string;
    DocumentDescription: string;
    Refno: string;
    PrimaryCifNumber: string;

    constructor(data?: any) {
        if (data) {
            this.DocumentType = data.DocumentType;
            this.DocumentCategory = data.DocumentCategory;
            this.DocumentDescription = data.DocumentDescription;
            this.Refno = data.Refno;
            this.PrimaryCifNumber = data.PrimaryCifNumber;
        }
    }
}