export class UserPreferences {

    UserId: string = '';
    EmailOptIn: boolean = false;
    EmailAddress: string;
    TextOptIn: boolean = false;
    PhoneNumber: string;

    constructor(userData?: any) {
        if (userData) {
            this.UserId = userData.UserId;
            this.EmailOptIn = userData.EmailOptIn;
            this.EmailAddress = userData.EmailAddress;
            this.TextOptIn = userData.TextOptIn;
            this.PhoneNumber = userData.PhoneNumber;
        }
    }

}
