import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Application } from '../models/application';
import { ApplicationType } from '../models/application-type';
import { Entity } from '../models/entity';
import { AuthService } from '../services/auth.service';
import { TopVendors } from '../models/vendor';
import { CurrentApplicationService } from '../services/current-application.service';
import { UserService } from '../services/user.service';
import { VendorService } from '../services/vendor.service';
import { NavigationOptions } from '../models/navigation-options';

@Component({
    selector: 'rol-new-landing',
    templateUrl: 'new-landing.component.html',
    styleUrls: ['new-landing.component.css']
})
export class NewLandingComponent {
    application: Application;

    constructor(
        private router: Router,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private currentAppService: CurrentApplicationService,
        private vendorService: VendorService) {
        window.scrollTo(0, 0);
        this.currentAppService.makeNewApp();
        this.currentAppService.getCurrentApplication().then(app => {
            this.application = app;
            let forceVendor: string = activatedRoute.snapshot.params['vendor'];
            if (forceVendor) {
                forceVendor = decodeURI(forceVendor);
                const top = TopVendors.find(vendor => {
                    return vendor.name.toLowerCase() === forceVendor.toLowerCase();
                });
                if (top) {
                    forceVendor = top.name;
                }
                this.application.vendors[0].vendorName = forceVendor;
                this.application.vendors[0].forceVendor = true;
            }
        });
    }

    proceedWithSingle() {
        this.application.entity = null;
        this.application.appType = ApplicationType.Single;
        this.proceed();
    }

    proceedWithCoborrower() {
        if (!this.application.entity) {
            this.application.entity = new Entity();
        }
        this.application.appType = ApplicationType.CoBorrower;
        this.application.entity.entityType = 'Partnership';
        this.proceed();
    }

    proceedWithEntity() {
        if (!this.application.entity) {
            this.application.entity = new Entity();
        }
        this.application.appType = ApplicationType.Entity;
        this.proceed();
    }

    proceed() {
        const startpage = NavigationOptions.AppPages(this.application, true)[0];
        this.application.currentPage = startpage.alias;
        this.currentAppService.setCurrentApplication(this.application);
        this.vendorService.GetVendors();
        this.router.navigate([startpage.route]);
    }



}
