import { Injectable } from '@angular/core';

@Injectable()
export class HistoryService {
    private history: string[] = [];

    public forward(path: string) {
        this.history.push(path);
    }

    public back(): string {
        this.history.pop();

        return this.getCurrent();
    }

    public isEmpty() {
        return this.history.length <= 1;
    }

    getCurrent() {
        return this.history[this.history.length - 1];
    }

    public init(path: string) {
        this.history = [path];
    }
}
