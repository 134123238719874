import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import * as moment from 'moment';
import { InterruptSource, InterruptArgs } from '@ng-idle/core';

import { User } from '../models/user';
import { PasswordChange } from '../models/password-change';
import { UserService } from './user.service';
import { CONFIG } from '../../environments/environment';
import { UserToken } from '../models/user-token';

export interface IAuthService {
    Register(user: User,
        recaptchaToken: string,
        agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date,
        accountNumber: any,
        ssn: any,
        zip: any);
    GetSecurityQuestion(username: string, password: string, passwordResetToken: string);
    Login(username: string, password: string, securityQuestion: string, securityAnswer: string);
    CreateAnonymousUser(): Promise<boolean>;
    UpdateUser(user: User): Promise<boolean>;
    ChangePassword(passwordChange: PasswordChange): Promise<boolean>;
    InitiatePasswordReset(email: string): Promise<boolean>;
    ResetPassword(email: string, token: string, newPassword: string, question: string, answer: string): Promise<boolean>;
    CheckAvailability(email: string, username: string): Promise<string | Object>;
    VerifyAccountDetails(accountNumber: string, last4ssn: string, zipCode: string): Promise<number | Object>;
    UpdateUserAcceptanceDates(agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date): Promise<boolean | Object>;
    GetLastLoginMessage(username: string): Promise<any> ;
}

@Injectable()
export class AuthService extends InterruptSource implements IAuthService {
    private headers = new HttpHeaders({ 'content-type': 'application/json' });
    private resourceUrl = CONFIG.apiBaseUri + 'Account';

    constructor(private http: HttpClient, private userService: UserService) {
        super(null, null);
    }

    private handleError(error: any) {
        let errorErrorMessage: string = error && error.error ? error.error.Message : null;
        let errorMessage: string = error && error.message ? error.message : null;
        let parsed: any = {};
        try {
            parsed = JSON.parse(error._body);
        } catch (err) {
            if (error.status === 401) {
                errorMessage = 'Authorization failed';
            }
        }
        return Promise.reject(errorErrorMessage || errorMessage || parsed.Message || parsed.message || 'Server error.  Please try again later.');
    }

    public Register(user: User,
        recaptchaToken: string,
        agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date,
        accountNumber: any,
        ssn: any,
        zip: any): Promise<boolean | Object> {

        const data: any = user;
        data.recaptchaToken = recaptchaToken;
        data.onlineAgreementAcceptanceDate = agreementAcceptedDate;
        data.eStatementAcceptanceDate = eStatementAcceptanceDate;
        data.achUSBankStatementAcceptanceDate = achUSBankStatementAcceptanceDate;
        data.accountNumber = accountNumber;
        data.ssn = ssn;
        data.zip = zip;
        data.isRichardson = true;

        return this.http
            .post(this.resourceUrl + '/Register', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then((res: UserToken) => {

                const respUser = new User();
                respUser.isAnonymous = false;
                respUser.email = res.userName;
                respUser.firstName = res.firstName;
                respUser.lastName = res.lastName;
                respUser.userId = res.userID;
                respUser.authToken = res.access_token;
                respUser.authExpires = moment().add(res.expires_in, 's').toDate();
                respUser.authCreated = new Date();
                respUser.cifno = res.cifno;
                this.userService.SetUser(respUser);
                return true;
            })
            .catch(this.handleError);
    }

    public CreateAnonymousUser(): Promise<boolean> {
        return this.http
            .post(this.resourceUrl + '/CreateAnonymousUser', '', { headers: this.headers })
            .toPromise()
            .then(res => {
                this.updateUserAndTokens(res, true);
                return true;
            })
            .catch(this.handleError);
    }

    public GetSecurityQuestion(username: string, password: string, passwordResetToken: string): Promise<any> {
        const data = { Email: username, Password: password, ResetPasswordToken: passwordResetToken };

        return this.http
            .post(this.resourceUrl + '/GetSecurityQuestion', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(question => {
                return question;
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    let msg = '';
                    if (error.error) {
                        msg = error.error;
                    } else {
                        msg = password && password !=='' ? 'Username or password is incorrect' : 'Invalid user or reset token has expired';
                    }
                    return Promise.reject(msg);
                }
                return this.handleError(error);
            });
    }

    public GetLastLoginMessage(username: string): Promise<any> {
        const data = { Email: username,};
        let browserDate = new Date();
        let offsetMinutes = -1 * browserDate.getTimezoneOffset(); //javascript treats the offset direction the opposite way that dotnet does.
        return this.http
            .get(this.resourceUrl + `/GetLastLoginMessage/?email=${username}&utcOffsetMinutes=${offsetMinutes}`, { headers: this.headers })
            .toPromise()
            .then(result => {
                return result;
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    let msg = '';
                    if (error._body) {
                        msg = error._body;
                    } else {
                        msg = 'Unable to retrieve login message for user:' + username;
                    }
                    return Promise.reject(msg);
                }
                return this.handleError(error);
            });
    }    

    public Login(username: string, password: string, securityQuestion: string, securityAnswer: string): Promise<boolean> {
        const data = { Email: username, Password: password, Question: securityQuestion, Answer: securityAnswer };

        return this.http
            .post(this.resourceUrl + '/Login', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                this.updateUserAndTokens(res, false);
                return true;
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    return Promise.reject('Incorrect answer to security question');
                }
                return this.handleError(error);
            });
    }

    public RefreshTokens(): Promise<boolean | Object> {
        const response = new Promise((resolve, reject) => {
            const user = this.userService.GetUser();

            if (user && user.refreshToken) {
                const data = { RefreshToken: user.refreshToken };
                this.http
                    .post(this.resourceUrl + '/RefreshLogin', JSON.stringify(data), { headers: this.headers })
                    .toPromise()
                    .then(res => {
                        this.updateTokens(res);
                        if (this.isAttached) {
                            const args = new InterruptArgs(this, new Date());
                            this.onInterrupt.emit(args);
                        }
                        resolve(true);
                    })
                    .catch(reason => reject(reason));
            } else {
                reject(new Error('Missing user or refresh token'));
            }
        });

        return response;
    }

    private updateUserAndTokens(token, isAnonymous): User {
        const user = this.updateTokens(token);
        user.isAnonymous = isAnonymous;
        user.email = token.userName;
        user.firstName = token.firstName;
        user.lastName = token.lastName;
        user.userId = token.userID;
        user.cifno = token.cifno;
        this.userService.SetUser(user);

        return user;
    }

    private updateTokens(token): User {
        let user = this.userService.GetUser();
        if (!user) {
            user = new User();
        }
        user.authToken = token.access_token;
        user.refreshToken = token.refresh_token;
        user.authExpires = moment().add(token.expires_in, 's').toDate();
        user.authCreated = new Date();
        this.userService.SetUser(user);
        this.userService.startIdleClock();

        return user;
    }

    public UpdateUser(user: User): Promise<boolean> {
        const headers = this.createAuthorizationHeader(this.headers);

        return this.http
            .post(this.resourceUrl + '/UpdateUser', JSON.stringify(user), { headers: headers })
            .toPromise()
            .then(res => {
                this.userService.SetUser(user);
                return true;
            })
            .catch(this.handleError);
    }

    public ChangePassword(passwordChange: PasswordChange): Promise<boolean> {
        const headers = this.createAuthorizationHeader(this.headers);

        return this.http
            .post(this.resourceUrl + '/ChangePassword', JSON.stringify(passwordChange), { headers: headers })
            .toPromise()
            .then(res => {
                return true;
            })
            .catch((err) => {
                const modelMessage = this.GetErrorFromModelState(err);
                if (modelMessage) {
                    return Promise.reject(modelMessage);
                }
                return this.handleError(err);
            });
    }

    private GetErrorFromModelState(err): string {
        let parsed: any = {};
        try {
            parsed = JSON.parse(err._body);
        } catch (e) {
        }
        if (parsed.ModelState && parsed.ModelState[''] && parsed.ModelState[''].length) {
            return parsed.ModelState[''][0];
        }
        return null;
    }

    public InitiatePasswordReset(email: string): Promise<boolean> {
        return this.http
            .post(this.resourceUrl + '/InitiatePasswordReset',
                JSON.stringify({ 'email': email, websiteName: 'RichardsonOnline' }),
                { headers: this.headers })
            .toPromise()
            .then(res => {
                return true;
            })
            .catch(this.handleError);
    }

    public ResetPassword(email: string, token: string, newPassword: string, question: string, answer: string): Promise<boolean> {
        const data = { 'Email': email, 'Token': token, 'NewPassword': newPassword, 'Question': question, 'Answer': answer };
        return this.http
            .post(this.resourceUrl + '/ResetPassword', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                return true;
            })
            .catch((err: any) => {
                if (err.status === 401) {
                    return Promise.reject('Incorrect answer to security question');
                }
                return this.handleError(err);
            });
    }

    public ChangeExpiredPassword(email: string, oldPassword: string, newPassword: string): Promise<boolean> {
        const data = { 'Email': email, 'OldPassword': oldPassword, 'NewPassword': newPassword };
        return this.http
            .post(this.resourceUrl + '/ChangeExpiredPassword', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                return true;
            })
            .catch((err: any) => {
                return this.handleError(err);
            });
    }

    public CheckAvailability(email: string, username: string): Promise<string | object> {
        const data = { 'Email': email, 'UserName': username };
        return this.http
            .post(this.resourceUrl + '/CheckAvailability', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public VerifyAccountDetails(accountNumber: string, last4ssn: string, zipCode: string): Promise<number | Object> {
        const data = { 'AccountNumber': accountNumber, 'Last4ssn': last4ssn, 'ZipCode': zipCode };
        return this.http
            .post(this.resourceUrl + '/VerifyAccountDetails', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public createAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
        headers = headers || new HttpHeaders();

        if (this.userService.GetUser()) {
            if (this.isUserAuthExpired()) {
                // authorization expired... redirect somewhere?
            } else {
                this.RefreshTokens().catch((reason) => {
                    console.log('Could not refresh token:', reason);
                });

                headers = headers.set('Authorization', `Bearer ${this.userService.GetUser().authToken}`);
            }
        }
        return headers;
    }

    private isUserAuthExpired(): boolean {
        return this.userService.GetUser().authExpires <= new Date();
    }

    public UpdateUserAcceptanceDates(
        agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date): Promise<boolean | Object> {

        const headers = this.createAuthorizationHeader(this.headers);

        const data = {
            'OnlineAgreementAcceptanceDate': agreementAcceptedDate,
            'EStatementAcceptanceDate': eStatementAcceptanceDate,
            'AchUSBankStatementAcceptanceDate': achUSBankStatementAcceptanceDate
        };
        return this.http
            .post(this.resourceUrl + '/UpdateUserAcceptanceDates', JSON.stringify(data), { headers: headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public GetUserAcceptanceDates(): Promise<any> {
        const headers = this.createAuthorizationHeader(this.headers);

        return this.http
            .post(this.resourceUrl + '/GetUserAcceptanceDates', null, { headers: headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public ValidatePreApproval(code: string, name: string): Promise<any> {
        const data = { Code: code, LastName: name };

        return this.http
            .post(this.resourceUrl + '/ValidatePreApproval', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch((error: any) => {
                return this.handleError(error);
            });
    }
}
