import { Application } from './application';
import { ApplicationType } from './application-type';
import * as moment from 'moment';
const MAX_APP_AGE_TO_CONTINUE = 90;
interface Page { alias: string; name: string; hideFromNav: boolean; route: string; }

export class NavigationOptions {
  public static Pages: Page[] = [
    { alias: 'program', name: 'Program', hideFromNav: false, route: '/program' },
    { alias: 'entity', name: 'Entity', hideFromNav: false, route: '/entity-information' },
    { alias: 'borrower', name: 'Borrower', hideFromNav: false, route: '/borrower-information' },
    { alias: 'ownership', name: 'Ownership', hideFromNav: false, route: '/ownership' },
    { alias: 'line-of-business-information', name: 'Line of Business', hideFromNav: false, route: '/line-of-business-information' },
    { alias: 'income', name: 'Financial', hideFromNav: false, route: '/financial-information-income' },
    { alias: 'balancesheet', name: 'Balance Sheet', hideFromNav: true, route: '/financial-information' },
    { alias: 'sign', name: 'Summary', hideFromNav: false, route: '/summary-and-sign' },
    { alias: 'confirmation', name: 'Confirmation', hideFromNav: true, route: '/confirmation' }
  ];

  public static GetStartPage(application: Application): string {
    const appPages = this.AppPages(application, true);
    let page = appPages.find(el => el.alias === application.currentPage);
    if (moment(application.createdDate) < moment().subtract(MAX_APP_AGE_TO_CONTINUE, 'days') || !page || page.alias === 'confirmation') {
      page = appPages[0];
    }
    return page.route;
  }

  public static GetPageIndexByAlias(name: string, application: Application, includeHidden?: boolean) {
    return this.AppPages(application, !!includeHidden).findIndex((el) => {
      return el.alias === name;
    });
  }

  public static VisiblePages(appType: ApplicationType = ApplicationType.Single): Page[] {
    return this.GetPages(appType).filter((page) => !page.hideFromNav);
  }

  private static GetPages(appType: ApplicationType): Page[] {
    if (appType === ApplicationType.Single) {
      return this.Pages.filter(page => page.alias !== 'entity' && page.alias !== 'ownership');
    }
    const result = this.Pages.map((el) => {
      if (el.alias === 'borrower') {
        return {
          alias: el.alias,
          name: 'Co-Borrower',
          hideFromNav: el.hideFromNav,
          route: el.route
        };
      } else {
        return el;
      }
    });
    return result;
  }

  public static AppPages(application: Application, includeHidden: boolean): Page[] {
    const appType = application.appType;
    return includeHidden ? this.GetPages(appType) : this.VisiblePages(appType);
  }
}
