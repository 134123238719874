<nav class="navbar navbar-light bg-light navbar-expand-md fixed-top">
  <div class="container" fxLayout>
    <div class="navbar-header">
      <button type="button" class="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar"
        aria-expanded="false" aria-controls="navbar">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" [routerLink]="getHomePage()"><img src="assets/images/AgPartnerFinancing_WEB.png"
          alt="Rabo AgriFinance logo" /></a>
    </div>
    <div id="navbar" class="collapse navbar-collapse justify-content-end" fxHide.lt-md="true">
      <ul class="nav navbar-nav">
        <li class="nav-item" data-toggle="collapse" data-target="#navbar.in" *ngIf="userLoggedIn()"
          routerLinkActive="current" routerLink="/dashboard/overview"><a routerLink="/dashboard/overview">Home</a></li>
        <li class="nav-item" data-toggle="collapse" data-target="#navbar.in" *ngIf="!userLoggedIn()"
          routerLinkActive="current" routerLink="/registration"><a routerLink="/registration">Sign up</a></li>
        <li class="nav-item" data-toggle="collapse" *ngIf="!userLoggedIn()" (click)="modalView = 'login'" class="active"
          data-toggle="modal" data-target="#loginModal"><a data-toggle="modal" data-target="#loginModal">Sign in</a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target="#navbar.in" *ngIf="userLoggedIn()"
          routerLinkActive="current" routerLink="/additional-documents"><a routerLink="/additional-documents">Additional Loan Docs</a></li>
        <li *ngIf="userLoggedIn()" class="active dropdown nav-item">
          <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false">{{currentUser.displayName}} <span class="caret"></span></a>
          <ul class="dropdown-menu">
            <li data-toggle="collapse" data-target="#navbar.in" routerLink="/dashboard"><a routerLink="/dashboard">My
                Dashboard</a></li>
            <li data-toggle="collapse" data-target="#navbar.in" routerLink="/dashboard/preferences"><a
                routerLink="/dashboard/preferences">My Profile</a></li>
            <li data-toggle="collapse" data-target="#navbar.in" (click)="logout()"><a (click)="logout()">Logout</a></li>
          </ul>
      </ul>
    </div>
  </div>
</nav>

<div *ngIf="modalView" class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel"
  #loginModal>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <div *ngIf="modalView === 'login'">
          <rol-login (closeEvent)="close($event)"></rol-login>
          <p *ngIf="notice">{{notice}}</p>
          <p>Or <a class="clickable" routerLink="/registration" data-dismiss="modal" aria-label="Close">register new
              user account</a></p>
          <p><a class="clickable" tabindex="0" (click)="notice='';modalView = 'forgot'">I forgot my password.</a></p>
        </div>
        <rol-reset-password *ngIf="modalView === 'forgot'" (closeEvent)="closeResetPassword($event)">
        </rol-reset-password>
      </div>
    </div>
  </div>
</div>