export abstract class Question {
    public order: number;
    public fieldType: 'Assets' | 'Liabilities';
    public length: 'shortTerm' | 'term' | 'intermediate';
    public description: string;

    constructor(appData?: any) {
        if (appData) {
            this.order = appData.order;
            this.fieldType = appData.fieldType;
            this.length = appData.length;
            this.description = appData.description;
        }
    }
}