import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../../../services/user.service';

@Component({
    selector: 'rol-account-navigation-dashboard',
    templateUrl: 'navigation.component.html',
    styleUrls: ['navigation.component.css']
})
export class NavigationDashboardComponent {
    currentTab = '';
    @Input() loan_number: string;
    @Input() account;
    constructor(private router: Router, private userService: UserService, activatedRoute: ActivatedRoute) {
        activatedRoute.url.subscribe(() => {
            activatedRoute.firstChild.data.subscribe((data) => {
                this.currentTab = data['tab'] || this.currentTab;
            });
        });
    }

    canViewAccountDetails(): boolean {
        return !this.account.IsDelinquent && !this.account.HasCreditHold && !this.account.HasExtension;
    }
}
