import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import { SurveyQuestion } from '../models/survey-question';
import { AuthenticatedHttp } from './authenticated-http.service';
import { CONFIG } from '../../environments/environment';

@Injectable()
export class SurveyQuestionService implements ISurveyHttpService {
    private headers = new HttpHeaders({ 'content-type': 'application/json' });
    private resourceUrl = CONFIG.apiBaseUri + 'Survey';

    constructor(private http: AuthenticatedHttp) { }
    getQuestions() {
        const url = this.resourceUrl + '/Questions';
        return this.http.get(url)
            .toPromise()
            .then(response => Array.from(response as any[], (item) => {
                return new SurveyQuestion(item);
            }))
            .catch(this.handleError);
    }

    submitQuestions(questions: SurveyQuestion[]) {
        const url = this.resourceUrl + '/Answers';
        const dto = questions.map((question: SurveyQuestion) => {
            return {
                questionId: question.questionId,
                answer: question.answer
            };
        });
        return this.http.post(url, JSON.stringify(dto), { headers: this.headers })
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }
    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}

export interface ISurveyHttpService {
    getQuestions(): Promise<SurveyQuestion[]>;
    submitQuestions(questions: SurveyQuestion[]);
}

