<if-notification default-life="12000"></if-notification>
<div class="app-status raised">
    <h1 class="title">My Applications</h1>
    <rol-loading-feedback *ngIf="loading"></rol-loading-feedback>
    <ul *ngIf="!loading" class="app-status-list">
        <li *ngFor="let applicationHeader of applicationHeaders"
            class="raised-card {{applicationHeader.status | lowercase}}">
            <div class="row">
                <div class="col-sm-9 col-md-9">
                    <h2><a (click)="resume(applicationHeader)">{{getApplicationDisplayName(applicationHeader)}}</a></h2>
                    <p><label>Status:</label> {{getApplicationStatus(applicationHeader)}}</p>
                    {{'' // Stored as UTC so it needs to be converted}}
                    <p><label>Created:</label> {{applicationHeader.creationDate + 'z' | date:'longDate'}}</p>
                    {{'' // Stored as CST or CDT}}
                    <p *ngIf="applicationHeader.completionDate"><label>Completed:</label> {{applicationHeader.completionDate | date:'longDate'}}</p>
                </div>
                <div class="col-sm-3 col-md-3 controls">
                    <div class="resume">
                        <a tabindex="0" class="btn btn-success raised" (click)="resume(applicationHeader)"><i
                                class="glyphicon fa fa-play"></i>Resume</a>
                    </div>
                    <div class="edit">
                        <a tabindex="0" class="btn btn-success raised blue" (click)="edit(applicationHeader)"><i
                                class="glyphicon fa fa-edit"></i>Edit</a>
                    </div>
                    <div class="sign">
                        <a tabindex="0" class="btn btn-success raised" (click)="sign(applicationHeader)"><i
                                class="glyphicon fa fa-check"></i>Sign</a>
                    </div>
                    <div class="print">
                        <a tabindex="0" class="btn btn-success raised blue" (click)="print(applicationHeader)"><i
                                class="glyphicon fa fa-print"></i>Print</a>
                    </div>
                    <div class="cancel">
                        <a tabindex="0" class="btn btn-danger raised" (click)="cancel(applicationHeader)"><i
                                class="glyphicon fa fa-stop"></i>Cancel</a>
                    </div>
                    <div class="restore">
                        <a tabindex="0" class="btn btn-success raised" (click)="edit(applicationHeader)"><i
                                class="glyphicon fa fa-refresh"></i>Restore</a>
                    </div>
                    <div class="delete">
                        <a tabindex="0" class="btn btn-danger raised" (click)="delete(applicationHeader)"><i
                                class="glyphicon fa fa-trash"></i>Delete</a>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <div class="application-controls">
        <a tabindex="0" class="btn btn-primary raised" (click)="newApp()"><i class="glyphicon fa fa-plus"></i>New
            Application</a>
    </div>
</div>