import { Component, Input, AfterViewInit, ViewChildren, QueryList, OnInit } from '@angular/core';
import { NgModel, FormGroup } from '@angular/forms';
import { Person } from '../../models/person';
import { TopForm } from '../top-form';
import * as moment from 'moment';
import { LookupService } from '../../services/lookup.service';
import { Canada } from '../../models/constants';

@Component({
    selector: 'rol-owner-detail',
    templateUrl: 'owner-detail.component.html',
    styles: [`
    .postfix {
        float: right;
        margin-top:-30px;
        padding-right:5px;
    }`]
})
export class OwnerDetailComponent implements OnInit, AfterViewInit {
    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;

    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() isBorrower: boolean;
    @Input() mode = '';
    @Input() index: number;
    @Input() alreadyAddedOwners: Person[] = null;
    @Input() owner: Person = new Person();

    maxDob: Date = moment().subtract(18, 'years').toDate();
    minDob: Date = new Date(1880, 0, 1);
    citizenshipCountries: string[] = null;
    permanentResidenceCountries: string[] = null;

    get showIsCountryOfPermanentAddressCanada() {
        return this.owner.isBeneficialOwner !== null && this.owner.isBeneficialOwner === true;
    }

    get showCountryOfPermanentResidence() {
        return this.showIsCountryOfPermanentAddressCanada 
            && this.owner.isCanadaCountryOfPermanentResidence !== null
            && this.owner.isCanadaCountryOfPermanentResidence === false;
    }

    constructor(
        private lookupService: LookupService
    ) {}
    
    ngOnInit() {
        this.lookupService.getCitizenshipCountriesExceptCanada()
            .then(resolve => {
                this.citizenshipCountries = [''].concat(resolve);
            });
    }

    ngAfterViewInit() {
        if (this.group) {
            this.inputChildren.forEach((item) => {
                if (!this.group.contains(item.name)) {
                    this.group.addControl(item.name, item.control);
                }
            });
        }
    }

    getPhysicalAddress() {
        if (this.owner.isBorrower
            && (this.owner.physicalAddress == null
                || this.owner.physicalAddress.line1 === ''
                || this.owner.physicalAddress.line1 == null)) {
            return this.owner.mailingAddress;
        } else {
            return this.owner.physicalAddress;
        }
    }

    reAddConditionalControls() {
        setTimeout(() => {
            this.inputChildren.forEach((item) => {
                if (!this.group.contains(item.name)) {
                    this.group.addControl(item.name, item.control);
                }
            });
        });
    }

    onChangeCanadaPermanentResidence(value) {
        this.owner.isCanadaCountryOfPermanentResidence = value === "true";
        this.owner.countryOfPermanentResidence = this.owner.isCanadaCountryOfPermanentResidence 
            ? Canada 
            : null;

        if (this.owner.isCanadaCountryOfPermanentResidence) {
            this.group.removeControl('countryOfPermanentResidence');
        } else {
            this.reAddConditionalControls();
        }
    }

    onChangeIsBeneficialOwner(value) {
        this.owner.isBeneficialOwner = value === "true";

        if (this.owner.isBeneficialOwner) {
            this.group.removeControl('isCanadaCountryOfPermanentResidence');
            this.group.removeControl('countryOfPermanentResidence');
        } else {
            this.reAddConditionalControls();
        }
    }
}
