import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { CurrentApplicationService } from '../services/current-application.service';


@Component({
    selector: 'rol-user-dashboard',
    template: `
        <div class="dashboard-wrapper stage container">
            <div class="dash-stage">
                <router-outlet></router-outlet>
            </div>
        </div>
    `,
    styles: [`
        .dashboard-wrapper {
            background: #ebebeb;
            box-shadow: 0 0 5px #777;
            padding: 0px;
        }

        .dashboard-wrapper .dash-stage {
            background: #ebebeb;
            min-height: 570px;
        }
    `]
})
export class UserDashboardComponent implements OnInit {
    constructor(private router: Router, private userService: UserService) { }

    ngOnInit() {
        window.scrollTo(0, 0);
    }

    logout(): void {
        this.userService.Logout();
        this.router.navigate(['/home']);
    }
}
