import { Component } from '@angular/core';

@Component({
    selector: 'rol-authorized-party',
    templateUrl: 'authorized-party.component.html',
    styleUrls: ['authorized-party.style.css']
})
export class AuthorizedPartyComponent {

    constructor() { }
}
