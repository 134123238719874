import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { NavigationOptions } from '../../../models/navigation-options';
import { Application } from '../../../models/application';
import { ApplicationType } from '../../../models/application-type';
import { CurrentApplicationService } from '../../../services/current-application.service';


@Component({
    selector: 'rol-side-nav',
    templateUrl: 'side-nav.component.html',
    styleUrls: ['progress-navigation.css']
})
export class SideNavComponent {
    constructor(private router: Router, private currentAppService: CurrentApplicationService, private activeRoute: ActivatedRoute) {
        currentAppService.getCurrentApplication().then((app) => {
            this.currentApplication = app;
            if (app) {
                this.options = NavigationOptions.AppPages(this.currentApplication, false);
            }
        });

    }

    @Input() selectedAlias: string;
    currentApplication: Application;
    options: any[];
    showingMenu = false;
    closeProgressNav = true;

    isDisabled(alias: string) {
        return this.getIndexByAlias(alias) > this.getIndexByAlias(this.selectedAlias);
    }

    isSelected(alias: string) {
        return this.getIndexByAlias(alias) === this.getIndexByAlias(this.selectedAlias);
    }

    getIndexByAlias(alias: string): number {
        return this.options.findIndex((page) => page.alias === alias);
    }

    showMenu(show: boolean) {
        this.showingMenu = show;
    }

    navigate(route: string, alias: string) {
        if (!this.isDisabled(alias)) {
            this.router.navigate([route], { relativeTo: this.activeRoute });
        } else {
            return false;
        }
    }
}
