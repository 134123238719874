import { Component, OnInit } from '@angular/core';
import { IfAuthService } from 'if-angular-security';
import { IRedirectRequestParams } from 'if-angular-security/lib/models/redirect-request-params.interface';
import { CONFIG } from '../../../../environments/environment';

@Component({
    selector: 'rol-preferences-password-frame',
    templateUrl: './preferences-password-frame.component.html',
    styleUrls: ['./preferences-password-frame.component.css']
})
export class PreferencesPasswordFrameComponent implements OnInit {
    constructor(private ifAuthSecurityService: IfAuthService) { }

    ngOnInit() {
        const redirectRequest: IRedirectRequestParams = {
            policy : CONFIG.b2cPasswordChangeFrame.policy,
            redirectUri: CONFIG.b2cPasswordChangeFrame.redirectUri
        };

        this.ifAuthSecurityService.loginRedirect(redirectRequest);
    }
}
