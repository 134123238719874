import { Injectable } from '@angular/core';
import { Idle } from '@ng-idle/core';
import * as moment from 'moment';

import { User } from '../models/user';
import { CurrentApplicationService } from './current-application.service';
import { StorageService } from './storage.service';

@Injectable()
export class UserService implements IUserService {
    private currentUser: User;
    private userStorageKey = 'appUser';
    private _currentAppSrv: CurrentApplicationService;

    constructor(private storageService: StorageService, private idle: Idle) { }

    public GetUser(): User {
        if (!this.currentUser) {
            const storageUser = this.storageService.getObjectFromLocal(this.userStorageKey);
            if (storageUser) {
                const retrievedUser = new User(storageUser);
                if (retrievedUser.authExpires > new Date()) {
                    this.currentUser = retrievedUser;
                    this.startIdleClock();
                }
            }
        }

        return this.currentUser;
    }

    public SetUser(user: User) {
        if (!user) {
            this.storageService.deleteFromLocal(this.userStorageKey);
        } else {
            this.storageService.storeObjectInLocal(this.userStorageKey, user);
        }

        this.currentUser = user;
    }

    // TODO:MFA
    loggedIn() : boolean {
        return this.GetUser() && !this.GetUser().isAnonymous;
    }

    public set CurrentAppService(appsrc: CurrentApplicationService) {
        this._currentAppSrv = appsrc;
    }

    public startIdleClock() {
        if (this.currentUser) {
            const difference = moment(this.currentUser.authExpires).diff(moment(), 's');
            const idleTimeout = difference - this.idle.getTimeout();
            this.idle.setIdle(idleTimeout);
            this.idle.watch();
        }
    }

    public Logout() {
        this.SetUser(null);
        this._currentAppSrv.setCurrentApplication(null);
        this.idle.stop();
    }
}

export interface IUserService {
    GetUser(): User;
    SetUser(user: User);
    Logout();
}
