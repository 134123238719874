<div class="container stage main_container">
    <rol-side-nav selectedAlias="{{pageAlias}}"></rol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Line of Business Information</h1>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-6">
            <p class="text-small">All fields are required unless otherwise noted.</p>
        </div>
    </div>
    <div class="spacer">
        <div fxFlex.gt-md="60">
            <div class="lineOfBusiness panel group">
                <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application">
                    <div class="control-group rc-wrapper">
                        <h3>What is your <em>primary</em> line of business?</h3>
                        <rol-radio-complete [options]="businesses" [value]="application.lineOfBusiness"
                            (changedValue)="ngOnChanges($event)"></rol-radio-complete>
                        <div *ngIf="!application.lineOfBusiness && form.submitted" class="error rc-error">You must pick
                            a line of business</div>
                    </div>
                    <rol-increased-risk-countries [(increasedRiskCountries)]="application.increasedRiskCountries" [submitted]="form.submitted"></rol-increased-risk-countries>
                    
                    <p-accordion (onClose)="tabClose($event)" (onOpen)="tabOpen($event)">
                        <p-accordionTab *ngFor="let r of application.tradeReferences; let i = index" [selected]="isOpen[i]">
                            <p-header [ngClass]="{'error': form.submitted && !formGroups[i].valid, 'ng-invalid': form.submitted && !formGroups[i].valid && !isOpen[i]}">
                                <span *ngIf="!showDisplayName(r)" class="new">(new)</span>
                                <span *ngIf="showDisplayName(r)">{{r.companyName}}</span>
                                <span class="actionicon" (click)="delete(i, $event)">
                                    <i class="glyphicon fa fa-trash-o"></i> Delete
                                </span>
                            </p-header>
                            <rol-trade-reference [reference]="r" [form]="form" [group]="formGroups[i]"></rol-trade-reference>
                        </p-accordionTab>
                    </p-accordion>
    
                    <div class="buttonContainer group">
                        <button *ngIf="showBack" class="btn btn-secondary back-btn" type="button"
                            (click)="back()" fxFlex.lt-md="40">Back</button>
                        <button type="submit" class="btn btn-primary" fxFlex.lt-md="40">Continue</button>
                        <button type="button" class="btn btn-primary" (click)="add()">Add Trade Reference</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div style="clear:both"></div>
</div>