import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[rolAutoSelect]',
  host: { '(focus)': 'selectAll()' }
})
export class AutoSelectDirective {
  constructor(private ele: ElementRef) {

  }

  selectAll() {
    this.ele.nativeElement.select();
  }
}
