import { Question } from './wizard/question';
import { SimpleQuestion } from './wizard/simple-question';
import { ComplexQuestion } from './wizard/complex-question';

export class BalanceSheet {
    constructor(appData?: any) {
        if (appData) {
            if (appData.Questions && appData.Questions.length > 0) {
                this.questions = Array.from(appData.Questions, (data) => {
                    return !!data['questionText'] ? new SimpleQuestion(data) : new ComplexQuestion(data);
                });
            }
            this.creationDate = appData.CreationDate ? new Date(appData.CreationDate) : null;
            this.shortTermAssets = appData.ShortTermAssets;
            this.shortTermLiabilities = appData.ShortTermLiabilities;
            this.termAssets = appData.TermAssets;
            this.termLiabilities = appData.TermLiabilities;
            this.intermediateAssets = appData.IntermediateAssets;
            this.intermediateLiabilities = appData.IntermediateLiabilities;
        }
    }
    questions: Question[];
    shortTermAssets = 0;
    termAssets = 0;
    intermediateAssets = 0;
    shortTermLiabilities = 0;
    termLiabilities = 0;
    intermediateLiabilities = 0;
    creationDate: Date;

    get totalAssets(): number {
        return this.shortTermAssets + this.termAssets + this.intermediateAssets;
    }

    get totalLiabilities(): number {
        return this.shortTermLiabilities + this.termLiabilities + this.intermediateLiabilities;
    }

    get totalEquity(): number {
        return this.totalAssets - this.totalLiabilities;
    }

    // returns null if no questions, -1 if wizard is completed, otherwise the index of the first incomplete step
    getCurrentWizardIndex() {
        if (!this.questions) {
            return null;
        }
        return this.questions.findIndex(question => {
            if (question instanceof SimpleQuestion) {
                return question.values.length === 0;
            } else if (question instanceof ComplexQuestion) {
                return question.assets.length === 0 &&
                    question.shortTermLiability.length === 0 &&
                    question.totalLiability.length === 0;
            }
        });
    }

    convertWizardAnswersToAppData() {
        const calculatedValues = {
            shortTermLiabilities: 0,
            termLiabilities: 0,
            intermediateLiabilities: 0,
            shortTermAssets: 0,
            termAssets: 0,
            intermediateAssets: 0
        };
        this.questions.forEach((question) => {
            if (question instanceof SimpleQuestion) {
                question['values'].forEach((v, i) => {
                    calculatedValues[question.length + question.fieldType] += v;
                });
            } else { // complex type
                question['assets'].forEach((v) => {
                    calculatedValues[question.length + 'Assets'] += v;
                });
                question['shortTermLiability'].forEach((v, i) => {
                    calculatedValues.shortTermLiabilities += v;
                    calculatedValues
                    [(question.length === 'intermediate' ? 'intermediate' : 'term') + 'Liabilities'] += (question['totalLiability'][i] - v);
                });
            }
        });

        this.intermediateAssets = calculatedValues.intermediateAssets;
        this.intermediateLiabilities = calculatedValues.intermediateLiabilities;
        this.shortTermLiabilities = calculatedValues.shortTermLiabilities;
        this.shortTermAssets = calculatedValues.shortTermAssets;
        this.termLiabilities = calculatedValues.termLiabilities;
        this.termAssets = calculatedValues.termAssets;
        this.creationDate = new Date();
    }
}
