import { DocumentMetaData } from './document-meta-data';

export class Document {
    DocumentId: number;
    Name: string;
    File: File;
    MetaData: DocumentMetaData;
    DocumentDate: string;

    constructor(data?: any) {
        if (data) {
            this.DocumentId = data.DocumentId;
            this.Name = data.Name;
            this.File = data.File;
            this.MetaData = data.metaData || data.MetaData;
            this.DocumentDate = data.DocumentDate;
        }
    }
}