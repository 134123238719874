import { Component, OnInit, AfterViewInit, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { OrganizationType } from '../../models/organization-type';
import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';
import { ApplicationType } from '../../../app/models/application-type';
import { CorporationItemFactory } from '../reusables/autocomplete/CorporationItemFactory';
import { IItemFactory } from '../reusables/autocomplete/autocomplete.component'

@Component({
    selector: 'rol-entity-information',
    templateUrl: 'entity-information.component.html',
    styleUrls: ['../styles_form.css']
})
export class EntityInformationComponent extends AbstractFormPage implements OnInit, AfterViewInit {
    form: TopForm;
    group: FormGroup;
    organizationTypes: OrganizationType[] = OrganizationType.getValidTypes();
    today: Date = new Date();
    itemFactory: IItemFactory = new CorporationItemFactory;
    entityTypes: string[];
    entityType: FormControl = new FormControl(null, Validators.required);

    @ViewChildren(NgModel) inputChildren;

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService) {
        super(currentAppSvc, router, utilities, history);
        this.pageAlias = 'entity';
        this.entityTypes = this.itemFactory.getItems("");
        this.initPage();
    }

    ngOnInit() {
        this.form = new TopForm();
        this.group = new FormGroup({});
        this.form.addControl("group", this.group);
    }

    ngAfterViewInit() {
        this.inputChildren.forEach((item) => {
            this.group.addControl(item.name, item.control);
        });

        const addables = ['legalName', 'hasFormedInLast12Months', 
                          'isActivityConsistentWithVision', 'rationaleForActivitiesChanges', 
                          'hasRecentLeadershipChanges', 'rationaleForLeadershipChanges',
                          'isBorrowerNotForProfitOrganization',
                          'isBorrowerRegisteredCharity', 'doesBorrowerSolicitCharitableDonations',
                          'charitableDonationsSource', 'charitableDonationsPurpose' ];
              
        this.inputChildren.changes.subscribe((items) => {
            items.map((item) => {
                if (addables.indexOf(item.name) > -1) {
                    this.form.addControl(item.name, item.control);
                }
            });
        });
    }

    submit() {
        this.group.updateValueAndValidity();
        this.form.updateValueAndValidity();
        this.form.submitted = true;

        if (this.form.valid) {
            this.next();
        } else {
            this.scrollToError();
        }
    }

    isPartnership() {
        return this.application.appType === ApplicationType.CoBorrower;
    }

    wasFormedInLast12Months(): boolean {
        return this.application.entity.richardsonEntityInformation.hasFormedInLast12Months;
    }

    activitiesNotConsistentWithVision(): boolean {
        return this.wasFormedInLast12Months()
            && this.application.entity.richardsonEntityInformation.isActivityConsistentWithVision === false;
    }

    hasRecentLeadershipChanges(): boolean {
        return this.wasFormedInLast12Months()
            && this.application.entity.richardsonEntityInformation.hasRecentLeadershipChanges;
    }

    isBorrowerNotForProfit(): boolean {
        return this.application.entity.richardsonEntityInformation.isBorrowerNotForProfitOrganization;
    }

    isBorrowerCharitable(): boolean {
        return this.isBorrowerNotForProfit()
            && (this.application.entity.richardsonEntityInformation.isBorrowerRegisteredCharity
                || this.application.entity.richardsonEntityInformation.doesBorrowerSolicitCharitableDonations);
    }

    onChangeHasFormedInLast12Months(value) {
        if (!this.wasFormedInLast12Months()) {
          this.form.removeControl('isActivityConsistentWithVision');
          this.form.removeControl('rationaleForActivitiesChanges');
          this.form.removeControl('hasRecentLeadershipChanges');
          this.form.removeControl('rationaleForLeadershipChanges');
        }
    }

    onChangeIsActivityConsistentWithVision(value) {
        if (!this.activitiesNotConsistentWithVision()) {
            this.form.removeControl('rationaleForActivitiesChanges');
        }
    }

    onChangeHasRecentLeadershipChanges(value) {
        if (!this.hasRecentLeadershipChanges()) {
            this.form.removeControl('rationaleForLeadershipChanges');
        }
    }

    onChangeIsBorrowerNotForProfitOrganization(value) {
        if (!this.isBorrowerNotForProfit()) {
            this.form.removeControl('isBorrowerRegisteredCharity');
            this.form.removeControl('doesBorrowerSolicitCharitableDonations');
            this.form.removeControl('charitableDonationsSource');
            this.form.removeControl('charitableDonationsPurpose');
        }
    }

    onChangeIsBorrowerCharitable(value) {
        if (!this.isBorrowerCharitable()) {
            this.form.removeControl('charitableDonationsSource');
            this.form.removeControl('charitableDonationsPurpose');
        }
    }
}
