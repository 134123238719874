<div class="form-group">
    <div class="row">
        <label for="month" class="col-lg-6">{{title}} Month</label>
        <label for="year" class="col-lg-6">{{title}} Year</label>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <select name="month" class="form-control" [formControl]="monthControl">
                <option *ngFor="let m of months" [ngValue]="m.value">{{m.name}}</option>
            </select>
            <div *ngIf="form.submitted && monthControl.invalid" class="error">
                <p *ngIf="monthControl.errors['required']">Required</p>
            </div>
        </div>
        <div class="col-lg-6">
            <select name="year" class="form-control" [formControl]="yearControl">
                <option *ngFor="let y of years" [ngValue]="y">{{y}}</option>
            </select>
            <div *ngIf="form.submitted && yearControl.invalid" class="error">
                <p *ngIf="yearControl.errors['required']">Required</p>
            </div>
        </div>
    </div>
</div>