import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
    selector: 'rol-radio-complete',
    templateUrl: 'radio-complete.component.html',
    styleUrls: ['radio-complete.component.css']
})
export class RadioCompleteComponent implements OnInit {
    @Input()
    options: string[];
    @Input()
    value: string;
    @Output()
    changedValue = new EventEmitter<string>();
    initialValue = '';

    ngOnInit() {
        if (this.shouldCheck('custom')) {
            this.initialValue = this.value;
        }
    }

    updateValue(newValue) {
        if (newValue !== this.value) {
            this.value = newValue;
            this.changedValue.emit(this.value);
        }
    }

    shouldCheck(type: string, option?: string): boolean {
        if (type === 'radio') {
            return this.value && this.value.toLowerCase() === option.toLowerCase();
        } else if (type === 'custom') {
            return this.value && this.options.findIndex((opt) => opt.toLowerCase() === this.value.toLowerCase()) === -1;
        }
    }
}
