import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationHttpService } from '../../services/application-http.service';
import { CurrentApplicationService } from '../../services/current-application.service';
import { NavigationOptions } from '../../models/navigation-options';
import { AbstractFormPage } from '../abstractpage';
import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';

class Status {
    title: string;
    message: string;
}

@Component({
    selector: 'rol-confirmation',
    templateUrl: 'confirmation.component.html',
    styleUrls: ['../styles_form.css', 'confirmation.component.css']
})
export class ConfirmationComponent extends AbstractFormPage implements OnInit {
    status: Status;
    showContinue = false;
    showPrintDialog = false;
    currentAppSrvc: CurrentApplicationService;

    constructor(currentAppSvc: CurrentApplicationService,
        router: Router,
        utilities: UtilitiesService,
        history: HistoryService,
        private applicationService: ApplicationHttpService) {
        super(currentAppSvc, router, utilities, history);
        this.pageAlias = 'confirmation';
        this.initPage();
        this.currentAppSrvc = currentAppSvc;
    }

    ngOnInit() {
        const eventQP = this.router.parseUrl(this.router.url).queryParams['event'];
        this.status = new Status();

        if (eventQP === 'signing_complete') {
            this.status.title = 'Complete';
            this.status.message = 'Thank you for completing your application!  We will get back to you as soon as possible.';
        } else if (eventQP === 'id_check_failed') {
            this.showContinue = true;
            this.status.title = 'ID Check';
            this.status.message = 'Sorry, your application was cancelled due to an ID check failure.';
        } else {
            this.showContinue = true;
            this.status.title = 'Cancelled';
            this.status.message = 'Your application was cancelled. You can:';
        }
    }

    continueCurrentApp() {
        this.applicationService.editSigningApplication(this.application).then((app) => {
            this.currentAppSrvc.setCurrentApplication(app);
            this.router.navigate([NavigationOptions.GetStartPage(app)]);
        });
    }

    createNew() {
        this.currentAppSrvc.setCurrentApplication(null);
        this.router.navigate(['/apply']);
    }

    printApp() {
        this.application.print();
        this.closePrintDialog();
    }

    openPrintDialog() {
        this.showPrintDialog = true;
    }

    closePrintDialog() {
        this.showPrintDialog = false;
    }
}
