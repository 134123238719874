<form ngFormModel="form" (ngSubmit)="submit()" novalidate>
    <h2>Reset Password</h2>
    <div class="form-group">
        <label for="email">Email</label>
        <input name="email" [formControl]="email" class="form-control" placeholder="Email" />
        <div class="error" *ngIf="(form.submitted || email.dirty) && email.invalid">
            <p *ngIf="email.errors['required']">Required</p>
        </div>
    </div>

    <div class="error" *ngIf="serverError">
        <p>{{serverError}}</p>
    </div>
    <div class="buttonContainer">
        <button class="btn btn-primary" type="submit">Reset Password</button>
        <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
    </div>
</form>

<rol-modal-dialog [show]="showConfirmation" (close)="showConfirmation = false">
    <div class="modal-body">
        <h1>We sent you an e-mail.</h1>
        <p>Please check your inbox for further instructions.</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit" (click)="successAndReturn();">Ok</button>
    </div>
</rol-modal-dialog>