import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user';
import { CurrentApplicationService } from '../../../services/current-application.service';
import { AccountHttpService } from '../../../services/account-http.service';
import { Commitment } from '../../../models/accounts/commitment';
import { CONFIG } from '../../../../environments/environment';

const $ = window['$'];

@Component({
    selector: 'rol-header-component',
    templateUrl: 'header.component.html',
    styleUrls: ['../../styles_form.css']
})
export class HeaderComponent implements OnInit {
    modalView: string;
    notice: string;
    environment: string;
    currentUser: User;

    private allCommitments: Commitment[] = null;
    private loadingCommitments = false;
    private commitmentsLoaded = false;

    constructor(private router: Router, private userService: UserService, private currentAppService: CurrentApplicationService,
        private accountService: AccountHttpService) { }

    ngOnInit() {
        this.environment = CONFIG.env;
    }

    userLoggedIn() {
        this.currentUser = this.userService.GetUser();

        return this.currentUser && !this.userService.GetUser().isAnonymous;
    }

    getHomePage(): string {
        if (this.userLoggedIn) {
            return '/dashboard';
        } else {
            return '/home';
        }
    }

    close(event: boolean) {
        this.modalView = '';
        $('#loginModal, .modal-backdrop').hide(); // dont like this... need to refactor to not use jquery
        this.notice = '';
        if (event) {
            if (this.currentAppService.hasCurrentApplication()) {
                this.currentAppService.getCurrentApplication().then((app) => {
                    if (app && app.id) {
                        // associates any current application with the new logged in user
                        app.save();
                    }
                });
            }
            if (this.userLoggedIn()) {
                this.router.navigate(['/dashboard']);
            }
        }
    }

    closeResetPassword(event: boolean) {
        this.modalView = 'login';
        if (event) {
            this.notice = 'Please check your email to reset your password.';
        }
    }

    logout() {
        this.userService.Logout();
        this.router.navigate(['/home']);
    }

    showApplyHere(): boolean {
        const user = this.userService.GetUser();
        if (user) {
            return user.canApply;
        } else {
            return true;
        }
    }
}
