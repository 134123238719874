<div class="TitleBar">Amend Your Account</div>
<rol-loading-feedback *ngIf="loading"></rol-loading-feedback>
<div *ngIf="!loading" class="raised-card">
    <div *ngIf="error || preexisting">
        <h2 *ngIf="error" class="error">{{errorMessage}}</h2>
        <h2 *ngIf="preexisting" class="error">An amendment for this account is already in process. It must be completed
            before a new one can be submitted.</h2>
        <div>For questions please contact a customer service rep @888-395-8505</div>
        <button type="button" class="btn btn-primary bottom" [routerLink]="['/dashboard']"
            routerLinkActive="active">Back</button>
        <div class="clear"></div>
    </div>
    <div class="instructions" *ngIf="account != null && !preexisting">
        <div>
            Now you can make changes to your account online. Request an increase in your credit limit, re-allocate your
            existing credit across your current programs or add a new program.
        </div>
        <h3 *ngIf="errorMessage" class="error">Error: {{errorMessage}}</h3>
    </div>
    <div *ngIf="amendment" class="raised-card card-grey">
        <div>
            <h2>Current Programs</h2>
            Click on a row in the table to reallocate your current credit or increase your limit.  Or add a new dealer
            buy clicking the 'New' button below.
        </div>
        <div>
            <p-table [value]="amendment.SubCommitments" metaKeySelection=false selectionMode="single"
                (onRowSelect)="onRowSelect($event)" [(selection)]="selectedSubCommitment" [responsive]="true">
                <ng-template pTemplate="caption">
                    Programs
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>Number</th>
                        <th>Program</th>
                        <th>Credit Limit</th>
                        <th></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-row>
                    <tr [pSelectableRow]="row">
                        <td> {{row.SubCommitmentNumber }} </td>
                        <td> {{row.Dealer.Name }} </td>
                        <td> {{row.CreditLimit  | currency:'USD' }} </td>
                        <td> <button *ngIf="!row.PrincipalBalance || row.PrincipalBalance == 0" type="button" pButton
                                (click)="deleteSubCommitment(row)" icon="fa fa-trash-o"></button> </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="4">
                            <ul class="report-table-footer">
                                <li>
                                    <label>Current Loan Amount: </label>
                                    <span> {{account.getTotalLoanAmount() | currency:'USD'}}</span>
                                </li>
                                <li>
                                    <label>Future Loan Amount: </label>
                                    <span> {{amendment.getTotalLoanAmount() | currency:'USD'}}</span>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div>
            <button type="button" class="btn btn-primary" (click)="submitAmendment()">Submit</button>
            <button type="button" (click)="toggleForm()" class="btn btn-primary">{{showForm ? 'Close' : 'New'}}</button>
            <div class="clear"></div>
        </div>
        <div class="contact-details">
            For additional account amendment options or questions about your account please call customer service at
            (888) 395-8505
        </div>
    </div>
</div>

<p-dialog [header]="getFormHeader()" [(visible)]="showForm" class="minimumWidth500" [closable]="false">
    <form class="form-wrapper dialog-form" novalidate [formGroup]="form" (ngSubmit)="addSub()">
        <label>Program</label><br />
        <p-dropdown [disabled]="formSubCommitment?.SubCommitmentNumber" [scrollHeight]="140"
            [options]="vendors" [(ngModel)]="selectedVendor" formControlName="Dealer"></p-dropdown><br />
        <span *ngIf="formSubmitted && !form.controls['Dealer']?.valid" class="error">Program is Required<br /></span>
        <label>Credit Limit</label><br />
        <input type="text" class="form-control" name="creditLimit" [(ngModel)]="formSubCommitment.CreditLimit"
            (ngModelChange)="formSubCommitment.CreditLimit = toNumber($event)" formControlName="CreditLimit"
            rolAutoSelect [textMask]="{mask: currencyMask, guide:false}"><br />
        <span *ngIf="formSubmitted && !form.controls['CreditLimit']?.valid" class="error">Credit Limit is
            Required<br /></span>

        <div class="dialogFooter">
            <button type="submit" id="addSub"
                class="btn btn-primary">{{updatingSubCommitment ? 'Update' : 'Add'}}</button>
            <button type="button" (click)="toggleForm()" id="cancelSub" class="btn btn-secondary">Cancel</button>
        </div>
    </form>
</p-dialog>

<p-dialog header="Confirm Delete" [(visible)]="deletingSubCommitment" [closable]="false">
    Are you sure you want to delete this program from the amendment?<br />
    <div class="dialogFooter">
        <button class="btn btn-primary" (click)="confirmDeleteSubCommitment()">Yes</button>
        <button class="btn btn-secondary" (click)="cancelDeleteSubCommitment()">No</button>
    </div>
</p-dialog>

<p-dialog header="Submit Amendment" [(visible)]="submittingAmendment" [modal]="true" [closable]="false">
    <div *ngIf="!successfulSubmission">
        Please confirm that you want to submit this amendment.<br /><br />
        <ul class="change-summary" *ngFor="let change of changeSummary">
            <li>{{change}}</li>
        </ul>
        <br />
        <label>Note:</label> Once submitted, you will be unable to make additional amendments to this account until the
        submitted amendment is fully processed.<br />
        <div class="dialogFooter">
            <button class="btn btn-primary" (click)="confirmSubmitAmendment()"
                [disabled]="waitingOnRequest">Submit</button>
            <button class="btn btn-secondary" (click)="cancelSubmitAmendment()"
                [disabled]="waitingOnRequest">Cancel</button>
            <rol-loading-feedback *ngIf="waitingOnRequest" inline=true class="loading-dots"></rol-loading-feedback>
        </div>
    </div>
    <div *ngIf="successfulSubmission">
        <h3>Thank You!</h3>
        Your amendment has been submitted and will be processed shortly. If additional information is required you will
        be contacted in the next 1-4 business days.
        <br>
        <button type="button" class="btn btn-primary bottom" [routerLink]="['/dashboard/account/', account.LoanNumber]"
            routerLinkActive="active">Account Details</button>
    </div>
</p-dialog>