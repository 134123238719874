import { Transaction } from './transaction';

export class TransactionReport {
    Transactions: Transaction[];
    TransactionTypes: string[];
    Programs: string[];

    constructor(data?: any) {
        if (data) {
            this.Transactions = data.Transactions ? Array.from(data.Transactions, (d) => new Transaction(d)) : [];
            this.TransactionTypes = data.TransactionTypes ? data.TransactionTypes : [];
            this.Programs = data.Programs ? data.Programs : [];
        }
    }
}
