<div class="preference-group group raised-card">
    <h2>Update Contact Information</h2>
    <rol-loading-feedback *ngIf="loading"></rol-loading-feedback>
    <div *ngIf="!loading && !contactInfoChangeComplete">
        <form [formGroup]="contactInfoForm" class="preference-group group" (ngSubmit)="contactInfoSubmit()" novalidate>
            <div class="well">
                <legend>
                    <span class="spanTitle">
                        Email/User Name
                    </span>
                </legend>
                <div class="form-group">
                    <div>This will change your user name for this site and where you receive email notifications.</div>
                    <input type="email" id="email" class="form-control" formControlName="email"
                        [(ngModel)]="contactDetails?.UserPreferences.EmailAddress" placeholder="Email/UserName">
                    <div class="alert alert-danger"
                        *ngIf="(contactInfoForm.submitted || !!contactInfoForm.controls.email.dirty) && !contactInfoForm.controls.email.valid">
                        <div *ngIf="!!contactInfoForm.controls['email'].hasError('required')">An e-mail address is
                            required</div>
                        <div *ngIf="!!contactInfoForm.controls['email'].hasError('validateEmail')">Please enter a valid
                            e-mail
                            address</div>
                    </div>
                </div>
            </div>
            <div *ngIf="user.cifno" class="well">
                <legend>
                    <span class="spanTitle">
                        Mailing Address
                    </span>
                </legend>
                <p>{{contactDetails.MailingAddress.line1}}</p>
                <p *ngIf="contactDetails.MailingAddress.line2?.length > 0">{{contactDetails.MailingAddress.line2}}</p>
                <p>{{contactDetails.MailingAddress.city}}, {{contactDetails.MailingAddress.state}}
                    {{contactDetails.MailingAddress.zipcode}}</p>
                <!--<div class="form-group">
                    <input name="street" class="form-control" formControlName="streetCtrl" [(ngModel)]="contactDetails.MailingAddress.line1" placeholder="Address" />
                    <div class="alert alert-danger" *ngIf="(contactInfoForm.submitted || contactInfoForm.controls.streetCtrl.dirty) && contactInfoForm.controls.streetCtrl.invalid">
                        <p *ngIf="contactInfoForm.controls.streetCtrl.errors['required']">Required</p>
                    </div>
                    <input name="street2" class="form-control line2" formControlName="street2Ctrl" [(ngModel)]="contactDetails.MailingAddress.line2" placeholder="Address Line 2" />
                </div>
                <div class="form-group">
                    <label for="city">City</label>
                    <input name="city" class="form-control" formControlName="cityCtrl" [(ngModel)]="contactDetails.MailingAddress.city" placeholder="City" />
                    <div class="alert alert-danger" *ngIf="(contactInfoForm.submitted || contactInfoForm.controls.cityCtrl.dirty) && contactInfoForm.controls.cityCtrl.invalid">
                        <p *ngIf="contactInfoForm.controls.cityCtrl.errors['required']">Required</p>
                    </div>
                </div>
                <div class="form-group">
                    <label for="state">State</label>
                    <select name="state" class="form-control" formControlName="stateCtrl" [(ngModel)]="contactDetails.MailingAddress.state" required>
                        <option *ngFor="let s of states" value="{{s.abbreviation}}">{{s.abbreviation}} - {{s.name}}</option>
                    </select>
                    <div class="alert alert-danger" *ngIf="(contactInfoForm.submitted || contactInfoForm.controls.stateCtrl.dirty) && contactInfoForm.controls.stateCtrl.invalid">
                        <p *ngIf="contactInfoForm.controls.stateCtrl.errors['required']">Required</p>
                    </div>
                </div>
                <div class="form-group">
                    <label for="zip">ZIP Code</label>
                    <input name="zip" class="form-control" formControlName="zipCtrl" [(ngModel)]="contactDetails.MailingAddress.zipcode" placeholder="ZIP Code" [textMask]="{mask: zipMask, guide:false}" required pattern="^[0-9]{5}(?:-[0-9]{4})?$" maxlength="10" />
                    <div class="alert alert-danger" *ngIf="(contactInfoForm.submitted || contactInfoForm.controls.zipCtrl.dirty) && contactInfoForm.controls.zipCtrl.invalid">
                        <p *ngIf="contactInfoForm.controls.zipCtrl.errors['required']">Required</p>
                        <p *ngIf="contactInfoForm.controls.zipCtrl.errors['pattern']">ZIP Code is invalid</p>
                    </div>
                </div>-->
                <!--<p-checkbox [(ngModel)]="sameAsPhysical" [ngModelOptions]="{standalone: true}" label="Same as physical address" binary="true" (click)="setPhysicalAddressValidators()"></p-checkbox>-->
                <p-checkbox *ngIf="sameAsPhysical" [disabled]="true" [(ngModel)]="sameAsPhysical"
                    [ngModelOptions]="{standalone: true}" label="Same as physical address" binary="true"
                    (click)="setPhysicalAddressValidators()"></p-checkbox>
            </div>

            <div *ngIf="user.cifno && !sameAsPhysical" class="well">
                <legend>
                    <span class="spanTitle">
                        Physical Address
                    </span>
                </legend>
                <p>{{contactDetails.PhysicalAddress.line1}}</p>
                <p *ngIf="contactDetails.PhysicalAddress.line2?.length > 0">{{contactDetails.PhysicalAddress.line2}}</p>
                <p>{{contactDetails.PhysicalAddress.city}}, {{contactDetails.PhysicalAddress.state}}
                    {{contactDetails.PhysicalAddress.zipcode}}</p>
                <!--<div class="form-group">
                    <input name="street" class="form-control" formControlName="physicalStreetCtrl" [(ngModel)]="contactDetails.PhysicalAddress.line1" placeholder="Address" />
                    <div class="alert alert-danger" *ngIf="(contactInfoForm.submitted || contactInfoForm.controls.physicalStreetCtrl.dirty) && contactInfoForm.controls.physicalStreetCtrl.invalid">
                        <p *ngIf="contactInfoForm.controls.physicalStreetCtrl.errors['required']">Required</p>
                    </div>
                    <input name="street2" class="form-control line2" formControlName="physicalStreet2Ctrl" [(ngModel)]="contactDetails.PhysicalAddress.line2" placeholder="Address Line 2" />
                </div>
                <div class="form-group">
                    <label for="city">City</label>
                    <input name="city" class="form-control" formControlName="physicalCityCtrl" [(ngModel)]="contactDetails.PhysicalAddress.city" placeholder="City" />
                    <div class="alert alert-danger" *ngIf="(contactInfoForm.submitted || contactInfoForm.controls.physicalCityCtrl.dirty) && contactInfoForm.controls.physicalCityCtrl.invalid">
                        <p *ngIf="contactInfoForm.controls.physicalCityCtrl.errors['required']">Required</p>
                    </div>
                </div>
                <div class="form-group">
                    <label for="state">State</label>
                    <select name="state" class="form-control" formControlName="physicalStateCtrl" [(ngModel)]="contactDetails.PhysicalAddress.state" required>
                        <option *ngFor="let s of states" value="{{s.abbreviation}}">{{s.abbreviation}} - {{s.name}}</option>
                    </select>
                    <div class="alert alert-danger" *ngIf="(contactInfoForm.submitted || contactInfoForm.controls.physicalStateCtrl.dirty) && contactInfoForm.controls.physicalStateCtrl.invalid">
                        <p *ngIf="contactInfoForm.controls.physicalStateCtrl.errors['required']">Required</p>
                    </div>
                </div>
                <div class="form-group">
                    <label for="zip">ZIP Code</label>
                    <input name="zip" class="form-control" formControlName="physicalZipCtrl" [(ngModel)]="contactDetails.PhysicalAddress.zipcode" placeholder="ZIP Code" [textMask]="{mask: zipMask, guide:false}" required pattern="^[0-9]{5}(?:-[0-9]{4})?$" maxlength="10" />
                    <div class="alert alert-danger" *ngIf="(contactInfoForm.submitted || contactInfoForm.controls.physicalZipCtrl.dirty) && contactInfoForm.controls.physicalZipCtrl.invalid">
                        <p *ngIf="contactInfoForm.controls.physicalZipCtrl.errors['required']">Required</p>
                        <p *ngIf="contactInfoForm.controls.physicalZipCtrl.errors['pattern']">ZIP Code is invalid</p>
                    </div>
                </div>-->
            </div>
            <div *ngIf="user.cifno" class="well">
                <legend>
                    <span class="spanTitle">
                        Phone Numbers
                    </span>
                </legend>
                <div class="row phonesHeader hidden-xs">
                    <div class="col-sm-6">
                        Phone Number
                    </div>
                    <div class="col-sm-3">
                        Primary
                    </div>
                    <div class="col-sm-3">
                        Mobile
                    </div>
                </div>
                <div class="row phones" *ngFor="let phoneNumber of contactDetails?.PhoneNumbers; let i=index">
                    <div class="col-sm-6 phone">
                        {{formatPhoneNumber(phoneNumber.PhoneNumber)}}
                    </div>
                    <div class="col-sm-3">
                        <mat-radio-group class="mat-radio-group" [(ngModel)]="contactDetails.PrimaryPhoneNumber"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button class="mat-radio-button" value="{{phoneNumber.PhoneNumber}}">
                            </mat-radio-button>
                        </mat-radio-group>
                        <span class="visible-xs">(primary)</span>
                    </div>
                    <div class="col-sm-3">
                        <p-checkbox [(ngModel)]="phoneNumber.IsMobile" [ngModelOptions]="{standalone: true}"
                            binary="true"></p-checkbox>
                        <span class="visible-xs">(mobile)</span>
                    </div>
                </div>
                <div class="row phones newPhoneSection">

                    <div class="col-sm-6 phone" *ngIf="addingNewPhone">
                        <div class="form-group">
                            <label>New Phone Number</label>
                            <input name="Phone" class="form-control" formControlName="newPhoneCtrl"
                                [(ngModel)]="newPhone.PhoneNumber" placeholder="Phone Number"
                                [textMask]="{mask: phoneMask, guide:false}" maxlength="14" minlength="14" required />
                            <div class="alert alert-danger" *ngIf="(contactInfoForm.submitted || contactInfoForm.controls['newPhoneCtrl'].dirty) &&
                                contactInfoForm.controls['newPhoneCtrl'].invalid">
                                <div *ngIf="contactInfoForm.controls['newPhoneCtrl'].hasError('required')">Required
                                </div>
                                <div *ngIf="contactInfoForm.controls['newPhoneCtrl'].hasError('minlength') ||
                                    contactInfoForm.controls['newPhoneCtrl'].hasError('maxlength')">
                                    Phone Number is invalid</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3" *ngIf="addingNewPhone">
                        <div class="phonesHeader hidden-xs">Primary</div>
                        <mat-radio-group class="mat-radio-group" [(ngModel)]="contactDetails.PrimaryPhoneNumber"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button class="mat-radio-button" value="{{newPhone.PhoneNumber}}">
                            </mat-radio-button>
                        </mat-radio-group>
                        <span class="visible-xs">(primary)</span>
                    </div>
                    <div class="col-sm-3" *ngIf="addingNewPhone">
                        <div class="phonesHeader hidden-xs">Mobile</div>
                        <p-checkbox [(ngModel)]="newPhone.IsMobile" [ngModelOptions]="{standalone: true}" binary="true">
                        </p-checkbox>
                        <span class="visible-xs">(mobile)</span>
                    </div>
                    <button type="button" class="btn btn-secondary" (click)="addPhone(false)"
                        *ngIf="addingNewPhone">Cancel</button>
                    <button *ngIf="!addingNewPhone" type="button" class="btn btn-secondary"
                        (click)="addPhone(true)">Add</button>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="well">
                <legend>
                    <span class="spanTitle">
                        Password
                    </span>
                </legend>
                <div class="form-group">
                    <div>Your password is required to save changes.</div>
                    <input type="password" id="Password" class="form-control" formControlName="password"
                        [(ngModel)]="contactDetails.Password" placeholder="Password" name="Password" autocomplete="off">
                    <div class="alert alert-danger"
                        *ngIf="(contactInfoForm.submitted || !!contactInfoForm.controls.password.dirty) && !contactInfoForm.controls.password.valid">
                        <div *ngIf="!!contactInfoForm.controls['password'].hasError('required')">Your password is
                            required
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger" *ngIf="updateInfoError">
                {{updateInfoError}}
            </div>
            <button type="submit" class="btn btn-primary" [disabled]="contactInfoForm.invalid">Update</button>
            <button type="button" class="btn btn-secondary" (click)="getUserData()">Cancel</button>
        </form>
    </div>
    <div *ngIf="contactInfoChangeComplete">
        <p>Your account was successfully updated!</p>
        <button type="button" class="btn btn-primary" (click)="closeComplete()">OK</button>
    </div>
</div>