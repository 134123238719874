import { Crop } from './crop';
import { CropInventoryItem } from './crop-inventory-item';
import { Livestock } from './livestock';

export class Income {
    constructor(appData?: any) {
        if (appData) {
            this.cropList = Array.from(appData.CropList, (data) => new Crop(data));
            this.livestockList = Array.from(appData.LivestockList, (data) => new Livestock(data));
            this.cropInventory = appData.CropInventory
                ? Array.from(appData.CropInventory, (data) => new CropInventoryItem(data))
                : [];
            this.grossCropIncome = appData.GrossCropIncome;
            this.grossLivestockIncome = appData.GrossLivestockIncome;
            this.otherFarmIncome = appData.OtherFarmIncome;
        }
    }

    cropList: Crop[] = [];
    livestockList: Livestock[] = [];
    cropInventory: CropInventoryItem[] = [];

    grossCropIncome = 0;
    grossLivestockIncome = 0;
    otherFarmIncome = 0;

    get totalFarmIncome(): number {
        return this.otherFarmIncome + this.grossLivestockIncome + this.grossCropIncome;
    }

    calculateValues() {
        this.grossCropIncome = 0;
        this.grossLivestockIncome = 0;
        this.cropList.forEach((crop) => {
            this.grossCropIncome += crop.revenue;
        });
        this.livestockList.forEach((livestock) => {
            this.grossLivestockIncome += livestock.revenue;
        });
    }
}
