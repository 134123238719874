import * as _ from 'lodash';
import { AdditionalFinancialInfo } from './additional-financial-info';
import { Address } from './address';
import { Canada, Farmer } from './constants';
import { FullName } from './fullname';
import { Passport } from './passport';

export class Person {
    isBorrower = true;
    fullName: FullName = new FullName();
    ownershipPercentage = 0;
    ssn = '';
    dateOfBirth: Date;
    isCanadianCitizen = true;
    countryOfCitizenship = '';
    isBeneficialOwner?: boolean;
    isCanadaCountryOfPermanentResidence?: boolean;
    countryOfPermanentResidence?: string;
    primaryOccupationNotFarmer = false;
    primaryOccupation = Farmer;
    email = '';
    isPermanentResident = false;

    phoneNumber = '';
    alternatePhoneNumber = '';
    businessPhoneNumber = '';
    homePhoneNumber = '';
    mobilePhoneNumber = '';

    physicalAddress: Address = new Address(false);
    mailingAddress: Address = new Address(true);
    isMailingAddressSameAsPhysicalAddress: boolean;

    hasBankruptcy = false;
    bankruptcyFilingDate: Date;
    isPreApproved = false;
    isControllingParty = false;
    signerTitle: string;
    passport: Passport = new Passport();
    richardsonFinancialInformation: AdditionalFinancialInfo = new AdditionalFinancialInfo();

    constructor(appData?: any) {
        if (appData) {
            this.alternatePhoneNumber = _.isNil(appData.AlternatePhoneNumber) ? '' : appData.AlternatePhoneNumber;
            this.bankruptcyFilingDate = appData.BankruptcyFilingDate ? new Date(appData.BankruptcyFilingDate) : null;
            this.countryOfCitizenship = _.isNil(appData.CountryOfCitizenship) ? '' : appData.CountryOfCitizenship;
            this.isBeneficialOwner = appData.IsBeneficialOwner;
            this.isCanadaCountryOfPermanentResidence = _.isNil(appData.CountryOfPermanentResidence) ? null : appData.CountryOfPermanentResidence === Canada;
            this.countryOfPermanentResidence = _.isNil(appData.CountryOfPermanentResidence) ? null : appData.CountryOfPermanentResidence;
            this.primaryOccupation = appData.PrimaryOccupation ? appData.PrimaryOccupation : Farmer;
            this.primaryOccupationNotFarmer = this.primaryOccupation !== Farmer;
            this.dateOfBirth = appData.DateOfBirth ? new Date(appData.DateOfBirth) : null;
            this.email = _.isNil(appData.Email) ? '' : appData.Email;
            this.fullName = new FullName(appData.FullName);
            this.hasBankruptcy = appData.HasBankruptcy;
            this.isCanadianCitizen = _.isNil(appData.CountryOfCitizenship) ? true : appData.CountryOfCitizenship === Canada;
            this.mailingAddress = new Address(true, appData.MailingAddress);
            this.ownershipPercentage = appData.OwnershipPercentage;
            this.phoneNumber = _.isNil(appData.PhoneNumber) ? '' : appData.PhoneNumber;
            this.physicalAddress = new Address(false, appData.PhysicalAddress);
            this.isControllingParty = appData.IsControllingParty;
            this.signerTitle = appData.SignerTitle;
            this.passport = new Passport(appData.Passport);
            this.isPreApproved = appData.isPreApproved;
            this.richardsonFinancialInformation = new AdditionalFinancialInfo(appData.RichardsonFinancialInformation);
            this.businessPhoneNumber = _.isNil(appData.BusinessPhoneNumber) ? '' : appData.BusinessPhoneNumber;
            this.homePhoneNumber = _.isNil(appData.HomePhoneNumber) ? '' : appData.HomePhoneNumber;
            this.mobilePhoneNumber = _.isNil(appData.MobilePhoneNumber) ? '' : appData.MobilePhoneNumber;
            this.isBorrower = _.isNil(appData.IsBorrower) ? false : appData.IsBorrower;

            this.isMailingAddressSameAsPhysicalAddress = this.mailingAddress.isSameAs(this.physicalAddress);
        }
    }
}
