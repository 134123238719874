import { IItemFactory } from '../reusables/autocomplete/autocomplete.component';

export class LivestockItemFactory implements IItemFactory {
    public items: string[] = ['Beef Cattle Ranching', 'Hog Farming', 'Poultry Farming'];
    getItems(query: string): string[] {
        if (query == null || query === '') {
            return this.items;
        } else {
            return this.items.filter(function (el) {
                return el.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }.bind(this));
        }
    }
}
