import { Category } from './category';

export class Financial {
    type: string;
    categories: Category[];
    total: number[];
    constructor(type: string) {
        this.type = type;
        this.categories = [
            new Category('shortTerm', 'Short Term'),
            new Category('intermediate', 'Intermediate'),
            new Category('term', 'Long Term')
        ];
        this.total = [];
    }
    public addQuestion(questionIndex: number, length: string, description: string, values: number[]) {
        const categoryIndex = this.categories.findIndex(category => category.type === length);
        this.categories[categoryIndex].addQuestion(questionIndex, description, values);
        this.updateTotal(values);
    }
    private updateTotal(values: number[]) {
        if (this.total.length === 0) {
            this.total = values.slice();
        } else {
            this.total = this.total.map((current, index) => current + values[index]);
        }
    }
}
