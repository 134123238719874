import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/toPromise';

import { Commitment } from '../models/accounts/commitment';
import { Payment } from '../models/accounts/payment';
import { AuthenticatedHttp } from '../services/authenticated-http.service';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { Document } from '../models/document/document';
import { DocumentMetaData } from '../models/document/document-meta-data';
import { DocumentQuery } from '../models/document/document-query';
import { Loan } from '../models/accounts/loan';
import { Amendment } from '../models/accounts/amendment';
import { ContactRequest } from '../models/contact-request';
import { ContactDetail } from '../models/accounts/contact-detail';
import { SecurityQuestionReset } from '../models/security-question-reset';
import { Transaction } from '../models/accounts/transaction';
import { TransactionQuery } from '../models/accounts/transaction-query';
import { TransactionReport } from '../models/accounts/transaction-report';
import { CONFIG } from '../../environments/environment';
import { Credentials } from '../models/credentials';

@Injectable()
export class AccountHttpService implements IAccountHttpService {
    private headers = new HttpHeaders({ 'content-type': 'application/json' });
    private resourceUrl = CONFIG.apiBaseUri;
    private currentAccount: Commitment = null;
    private accountUpdatedSource = new Subject<boolean>();
    private currentAccountLoading: string;
    private currentAccountLoadingPromise: Promise<Commitment>;
    accountUpdated$ = this.accountUpdatedSource.asObservable();
    credentials: Credentials = new Credentials();

    constructor(private http: AuthenticatedHttp, private userService: UserService, private authService: AuthService) {
    }

    getCurrentAccount(route: ActivatedRouteSnapshot, forceRefresh: boolean = false): Promise<Commitment> {
        const loan_number = route.params['accountId'];
        if (this.currentAccount != null && this.currentAccount.LoanNumber === loan_number && forceRefresh === false) {
            return Promise.resolve(this.currentAccount);
        } else {
            const self = this;
            if (loan_number === self.currentAccountLoading) {
                return self.currentAccountLoadingPromise;
            }
            self.currentAccountLoading = loan_number;
            self.currentAccountLoadingPromise = this.getAccount(loan_number)
                .then(acct => {
                    self.currentAccount = acct;
                    self.currentAccountLoading = null;
                    return self.currentAccount;
                });
            return self.currentAccountLoadingPromise;
        }
    }

    updateAccountObservable() {
        this.accountUpdatedSource.next(true);
    }

    linkAccount(accountNumber: string, ssn: string, zip: string): Promise<boolean> {
        const apiUrl = `${this.resourceUrl}Grower/LinkAccount?accountNumber=${accountNumber}&ssn=${ssn}&zip=${zip}`;

        return this.http
            .post(apiUrl, { headers: this.headers })
            .toPromise()
            .then((x) => {
                return true;
            })
            .catch(this.handleError);
    }

    getAccounts(): Promise<Commitment[]> {
        const apiUrl = `${this.resourceUrl}Grower/Commitments`;

        return this.http.get(apiUrl)
            .toPromise()
            .then((res) => Array.from(res as any, (item) => new Commitment(item)))
            .catch(this.handleError);
    }

    getAllCommitments(): Promise<Commitment[]> {
        return this.http.get(this.resourceUrl + 'Grower/AllCommitments')
            .toPromise()
            .then((response) => {
                const allCommitments = Array.from(response as any, (item) => {
                    return new Commitment(item);
                });
                return allCommitments;
            })
            .catch(this.handleError);
    }

    getAccount(loanNumber: string): Promise<Commitment> {
        return this.getAccounts()
            .then(accounts => {
                const foundAccount = accounts.find(commitment => commitment.LoanNumber === loanNumber);
                if (!foundAccount) {
                    return null;
                }
                return foundAccount;
            });
    }

    getPaymentHistory(loanNumber: string): Promise<Payment[]> {
        return this.http.get(this.resourceUrl + 'Grower/GetPaymentHistory?loanNumber=' + loanNumber)
            .toPromise()
            .then(response => Array.from(response as any, (item) => {
                return new Payment(item);
            }))
            .catch(this.handleError);
    }

    cancelPendingPayments(pendingPayments: Payment[]): Promise<any> {
        const url = this.resourceUrl + 'Grower/CancelPendingPayments';
        const json = JSON.stringify(pendingPayments);
        return this.http
            .post(url, json, { headers: this.headers })
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    getPayoffDetails(loanNumber: string, payoffDays: number): Promise<Payment[]> {
        return this.http.get(this.resourceUrl + 'Grower/GetPayoffs?loanNumber=' + loanNumber + '&payoffDays=' + payoffDays)
            .toPromise()
            .then(response => Array.from(response as any, (item) => {
                return new Payment(item);
            }))
            .catch(this.handleError);
    }

    submitPayments(payments: Payment[]): Promise<any> {

        const url = this.resourceUrl + 'Grower/SubmitPayments';
        const json = JSON.stringify(payments);
        return this.http
            .post(url, json, { headers: this.headers })
            .toPromise()
            .then((res) => {
                return res;
            })
            .catch(this.handleError);
    }

    getDocuments(query: DocumentQuery): Promise<Document[]> {

        const url = this.resourceUrl + 'Grower/GetDocuments';
        const json = JSON.stringify(query);
        return this.http
            .post(url, json, { headers: this.headers })
            .toPromise()
            .then(response => Array.from(response as any, (item) => {
                return new Document(item);
            }))
            .catch(this.handleError);
    }

    getTransactionReport(query: TransactionQuery): Promise<TransactionReport> {
        const url = this.resourceUrl + 'Grower/GetTransactionReport';
        const json = JSON.stringify(query);
        return this.http
            .post(url, json, { headers: this.headers })
            .toPromise()
            .then(response => new TransactionReport(response))
            .catch(this.handleError);
    }

    downloadDocument(documentId: number) {
        const apiUrl = `${this.resourceUrl}Grower/DownloadDocument?documentId=${documentId}`;

        return this.http.get<Blob>(apiUrl, { responseType: 'blob', observe: 'response' });
    }

    getLoanDetails(loanAcctRefNo: number): Promise<Loan> {
        const url = this.resourceUrl + 'Grower/Loan/' + loanAcctRefNo;
        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then((res) => new Loan(res))
            .catch(this.handleError);
    }

    getACHProcessingCutoffHour(): Promise<any> {
        const url = this.resourceUrl + 'Grower/GetACHProcessingCutoffHour';
        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then((res) => res)
            .catch(this.handleError);
    }

    getCustomerContactDetails(cifno: number): Promise<ContactDetail> {
        const url = this.resourceUrl + 'Grower/GetCustomerContactDetails?cifno=' + cifno;
        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then((res) => new ContactDetail(res))
            .catch(this.handleError);
    }

    getCustomerContactPreferences(): Promise<ContactDetail> {
        const url = this.resourceUrl + 'Grower/GetCustomerContactPreferences';
        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then((res) => new ContactDetail(res))
            .catch(this.handleError);
    }

    updateCustomerContactPreferences(contactDetails: ContactDetail): Promise<ContactDetail> {
        const url = this.resourceUrl + 'Grower/updateCustomerContactPreferences';
        const json = JSON.stringify(contactDetails);
        return this.http
            .post(url, json, { headers: this.headers })
            .toPromise()
            .then((res) => new ContactDetail(res))
            .catch(this.handleError);
    }

    updateCustomerContactDetails(contactDetails: ContactDetail): Promise<ContactDetail> {
        const url = this.resourceUrl + 'Grower/UpdateCustomerContactDetails';
        const json = JSON.stringify(contactDetails);
        return this.http
            .post(url, json, { headers: this.headers })
            .toPromise()
            .then((res) => new ContactDetail(res))
            .catch(this.handleError);
    }

    updateSecurityQuestions(model: SecurityQuestionReset): Promise<boolean> {
        const url = this.resourceUrl + 'Account/UpdateSecurityQuestions';
        const json = JSON.stringify(model);
        return this.http
            .post(url, json, { headers: this.headers })
            .toPromise()
            .then((res) => true)
            .catch(this.handleError);
    }

    doesAccountHaveAmendment(loanNumber: string): Promise<any> {
        const url = this.resourceUrl + 'Grower/DoesAccountHaveAmendment?loanNumber=' + loanNumber;
        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then((res) => res)
            .catch(this.handleError);
    }

    submitAmendment(amendment: Amendment): Promise<any> {
        const url = this.resourceUrl + 'Grower/SubmitAmendment';
        const json = JSON.stringify(amendment);
        return this.http
            .post(url, json, { headers: this.headers })
            .toPromise()
            .then((res) => res)
            .catch(this.handleError);
    }

    getPhoneNumbers(): Promise<any> {
        const url = this.resourceUrl + 'Grower/GetGrowerPhoneNumbers';
        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then((res) => res)
            .catch(this.handleError);
    }

    submitContactRequest(contactRequest: ContactRequest): Promise<any> {
        const url = this.resourceUrl + 'Grower/SubmitContactRequest';
        const json = JSON.stringify(contactRequest);
        return this.http
            .post(url, json, { headers: this.headers })
            .toPromise()
            .then((res) => res)
            .catch(this.handleError);
    }

    checkForSubmittedContactRequest(): Promise<any> {
        const url = this.resourceUrl + 'Grower/CheckForSubmittedContactRequest';
        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then((res) => res)
            .catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}

export interface IAccountHttpService {
    linkAccount(accountNumber: string, ssn: string, zip: string);
    getAccounts();
    getAccount(commitmentNumber: string);
    getPayoffDetails(loanNumber: string, payoffDays: number);
    submitPayments(payments: Payment[]);
    getPaymentHistory(loanNumber: string);
    cancelPendingPayments(pendingPayments: Payment[]);
    getDocuments(query: DocumentQuery);
    getLoanDetails(loanAcctRefNo: number);
    getACHProcessingCutoffHour();
    getCustomerContactDetails(cifno: number);
}
