import { Component, OnInit } from '@angular/core';
import { NgModel, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as saver from 'file-saver';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { TopForm } from '../../../form/top-form';
import { AccountHttpService } from '../../../services/account-http.service';
import { Document } from '../../../models/document/document';
import { DocumentMetaData } from '../../../models/document/document-meta-data';
import { DocumentQuery } from '../../../models/document/document-query';

@Component({
    selector: 'rol-account-statements',
    templateUrl: 'account-statements.component.html',
    styleUrls: ['account-statements.component.css'],
    providers: [MessageService]
})
export class AccountStatementsComponent implements OnInit {

    loanNumber: string;
    loading = 0;
    query: DocumentQuery;
    statements: Document[] = [];
    statementYears: string[] = [];
    interestPaidStatements: Document[] = [];
    index = 0;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountHttpService,
        private messageService: MessageService) { }

    ngOnInit() {
        this.loanNumber = this.route.snapshot.parent.params['accountId'];
        this.getStatements();
        this.getInterestPaidStatements();
    }

    getStatements() {
        this.loading++;
        this.query = new DocumentQuery();
        this.query.RefNo = this.loanNumber;
        this.query.Description = 'Account Statement';

        const minimumDate = moment().subtract(18, 'months').toDate();
        this.query.MinimumDocumentDate = minimumDate;

        this.accountService.getDocuments(this.query)
            .then((results) => {
                this.statements = results;
                this.statements.forEach(statement => {
                    const year = statement.DocumentDate.substring(statement.DocumentDate.length - 4);
                    if (!this.statementYears.includes(year)) {
                        this.statementYears.push(year);
                    }
                });
                this.statementYears.sort((a, b) => parseFloat(a) - parseFloat(b)).reverse();
                this.statements.sort((a, b) => parseFloat(a.DocumentDate) - parseFloat(b.DocumentDate)).reverse();
                this.loading--;
            });
    }

    getInterestPaidStatements() {
        this.loading++;
        this.query = new DocumentQuery();
        this.query.RefNo = this.loanNumber;
        this.query.Description = 'Interest Paid Statement';

        this.accountService.getDocuments(this.query)
            .then((results) => {
                this.interestPaidStatements = results;
                this.interestPaidStatements.sort((a, b) => parseFloat(a.DocumentDate) - parseFloat(b.DocumentDate)).reverse();
                this.loading--;
            });
    }

    downloadDocument(document: Document) {
        this.accountService.downloadDocument(document.DocumentId)
            .subscribe(
                response => {
                    console.log(`Size of blob: ${response['body'].size}`);
                    saver.saveAs(response['body'], document.Name);
                },
                error => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Document unsuccessfully retrieved',
                        detail: 'An error occurred while retrieving the document '
                    });
                }
            );
    }

    clear() {
        this.messageService.clear();
    }
}
