import { Idle } from '@ng-idle/core';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../app/services/user.service';

// seconds after displaying message will it time user out
const TimeoutTime = 60;

@Component({
    selector: 'rol-idle-component',
    template: `
        <div class="idle-modal" *ngIf="showWarningMessage || showLogoutMessage || showErrorMessage">

            <!-- Modal content -->
            <div class="idle-modal-content" *ngIf="showWarningMessage">
                <!-- Session Timeout Widget header and close buttons here -->
		        <div class="rwd_session_alignLeft rwd_session_infoImageAlign" id="infoIcon">
			        <h3><i class="fa fa-exclamation-triangle fa-2x error" aria-hidden="true"></i> Your session is about to expire.</h3>
		        </div>

                <!-- Session Timeout Widget content comes here -->
                <div class="rwd_sessionTextDiv">
                    For your security, this online session is about to end due to inactivity in {{countdown}} seconds. If you want to extend your session, please select the 'Continue' button. If you select the 'Log Out' button or do not respond, your session will automatically close.
                </div>

                <!--Session timeout Widget Logout and Continue Buttons -->
                <div class="buttonContainer">
                    <button type="button" class="btn btn-secondary" (click)="logout()" title="Click to end this session">Log Out</button>

		            <button type="button" class="btn btn-primary" (click)="refresh()" title="Click to extend your session">Continue</button>
		        </div>
            </div>

            <div class="idle-modal-content" *ngIf="showLogoutMessage">
                <!-- Session Timeout Widget content comes here -->
                <div class="rwd_sessionTextDiv">
                    You have been logged out due to inactivity.
                </div>

                <!--Session timeout Widget Logout and Continue Buttons -->
                <div class="buttonContainer">
		            <button type="button" class="btn btn-primary" (click)="showLogoutMessage = false" title="Close">Close</button>
		        </div>
            </div>

            <div class="idle-modal-content" *ngIf="showErrorMessage">
                <!-- Session Timeout Widget content comes here -->
                <div class="rwd_sessionTextDiv">
                    We are having difficulty keeping you logged in. Please give us a moment and try again.
                </div>

                <!--Session timeout Widget Logout and Continue Buttons -->
                <div class="buttonContainer">
		            <button type="button" class="btn btn-primary" (click)="closeError()" title="Close">Close</button>
		        </div>
            </div>

        </div>
    `,
    styles: [`.idle-modal {
                position: fixed; /* Stay in place */
                z-index: 20001; /* Sit on top */
                left: 0;
                top: 0;
                width: 100%; /* Full width */
                height: 100%; /* Full height */
                overflow: auto; /* Enable scroll if needed */
                background-color: rgb(0,0,0); /* Fallback color */
                background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
            }

            /* Modal Content/Box */
            .idle-modal-content {
                background-color: #fefefe;
                margin: 15% auto; /* 15% from the top and centered */
                padding: 20px;
                border: 1px solid #888;
                width: 500px;
                border-radius: 5px;
                text-align: center;
            }
            @media screen and (min-width: 768px) {
                .idle-modal-content {
                    width: 80%;
                }
            }
            .rwd_sessionTextDiv {
                font-size: 24px;
                margin: 60px 0;
            }
            .buttonContainer {
                min-height:60px;
            }`]
})
export class IdleComponent implements OnInit {

    showWarningMessage = false;
    showLogoutMessage = false;
    showErrorMessage = false;
    countdown: number;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private idle: Idle, 
        private auth: AuthService, 
        private userSrvc: UserService) {
        idle.setIdle(30); // default will be overridden when user logs in to be based on token expiration
        idle.setTimeout(TimeoutTime);
        idle.setInterrupts([this.auth]);

        idle.onIdleStart.subscribe(onIdleStart);
        idle.onIdleEnd.subscribe(onIdleEnd);
        idle.onTimeoutWarning.subscribe(onTimeoutWarning);
        idle.onTimeout.subscribe(onTimeout);
        const self = this;

        function onTimeout() {
            self.userSrvc.Logout();
            self.router.navigateByUrl('/home');
            self.showLogoutMessage = true;
            self.showWarningMessage = false;
        }

        function onIdleStart() {
            self.showWarningMessage = true;
            self.countdown = TimeoutTime;
        }

        function onTimeoutWarning(countdown: number) {
            self.countdown = countdown;
        }

        function onIdleEnd() {
            self.showWarningMessage = false;
            self.showLogoutMessage = false;
        }
    }

    ngOnInit() { // TODO:MFA
        this.processMessageParams();
    }

    private processMessageParams() {
        this.route.queryParams.subscribe(params => {
            var messageParam = params.m;
            switch(messageParam) {
                case 'session-expired':
                    this.showLogoutMessage = true;
                    break;
            }
        })
    }

    // TODO:MFA - Same code but using new updated RefreshTokens method
    refresh() {
        this.auth.RefreshTokens()
            .catch((error) => {
                this.showErrorMessage = true;
                this.userSrvc.Logout();
            })
            .then(() => this.showWarningMessage = false);
    }

    closeError() {
        this.showErrorMessage = false;
        this.router.navigateByUrl('/home');
    }

    // TODO:MFA
    logout(postLogoutUrl : string = null) {
        this.auth.Logout(postLogoutUrl);
        this.showLogoutMessage = false;
        this.showWarningMessage = false;
    }
}
