import {
    Component, Input, OnChanges, QueryList,
    ViewChild, AfterViewInit
} from '@angular/core';
import { NgModel, FormGroup, FormControl, Validators } from '@angular/forms';
import { SimpleQuestion } from '../../../models/wizard/simple-question';
import { TopForm } from '../../top-form';
import { Person } from '../../../models/person';
import { Entity } from '../../../models/entity';
import { totalIncomeMaxNLS } from '../../financial-validation';

@Component({
    selector: 'rol-simple-question-component',
    template: `
        <h2 class="questionText">
            {{question.questionText}}
        </h2>
        <div class="example">
            {{question.example}}
        </div>
        <div *ngFor="let character of Characters; let i = index;" class="row question-input">
            <div class="col-md-offset-2 col-md-4">
                <label>{{character}}:</label>
            </div>
            <div class="col-md-6">
                <rol-number-input name="value{{i}}" [formControl]="valueCtrls[i]" [(ngModel)]="question.values[i]" #questionInput></rol-number-input>
                <div class="error" *ngIf="(form.submitted || valueCtrls[i].dirty) && valueCtrls[i].invalid">
                    <p *ngIf="valueCtrls[i].errors['required']">Required</p>
                    <p *ngIf="valueCtrls[i].errors['validateNumber'] && valueCtrls[i].errors['validateNumber'].max === true">Amount must be less than {{maxDollarValue | currency:'USD':true:'1.2-2'}}</p>
                </div>
            </div>
        </div>
        `,
    styleUrls: ['questions.css']
})
export class SimpleQuestionComponent implements OnChanges, AfterViewInit {
    @ViewChild('questionInput') inputElement;
    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() questionIndex: number;
    valueCtrls: FormControl[] = [];

    maxDollarValue: number = totalIncomeMaxNLS;

    @Input() question: SimpleQuestion;

    @Input() Characters: string[];

    ngOnChanges(changes) {
        this.valueCtrls = [];
        const validators = [Validators.required].concat(this.question.customValidators);

        this.Characters.forEach((c, i) => {
            this.valueCtrls.push(new FormControl('0', validators));
            if (!this.question.values[i]) {
                this.question.values[i] = 0;
            }
        });

        const useGroup: FormGroup = this.group || this.form;
        this.valueCtrls.forEach((ctrl, i) => {
            useGroup.addControl('single_' + this.questionIndex + '_' + i, ctrl);
        });
        this.initNumbers();
    }

    ngAfterViewInit() {
        this.initNumbers();
    }

    private initNumbers() {
        if (this.inputElement) {
            this.inputElement.focus();
        }
    }
}
