import { DealerHeader } from './dealer-header';
import { Loan } from './loan';

export class SubCommitment {
    SubCommitmentNumber: string;
    CropYear: number;
    MaturityDate: Date;
    Dealer: DealerHeader;
    CreditLimit = 0.00;
    PrincipalBalance: number;
    Index: number;
    Loans: Loan[];

    constructor(data?: any) {
        if (data) {
            this.Index = (data.Index === undefined ? null : data.Index);
            this.SubCommitmentNumber = data.SubCommitmentNumber;
            this.CropYear = data.CropYear;
            this.MaturityDate = data.MaturityDate ? new Date(data.MaturityDate) : null;
            this.Dealer = data.Dealer ? new DealerHeader(data.Dealer) : null;
            this.CreditLimit = data.CreditLimit;
            this.PrincipalBalance = data.PrincipalBalance;
            this.Loans = data.Loans ? Array.from(data.Loans, (d) => new Loan(d)) : null;
        }
    }

    get IsPastDue(): boolean {
        if (this.PrincipalBalance > 0 && this.MaturityDate != null) {
            return this.IsPastDrawPeriod;
        }
        return false;
    }

    get IsPastDrawPeriod(): boolean {
        if (this.MaturityDate != null) {
            const today = new Date();
            today.setHours(0, 0, 0);

            const maturityDate = new Date(this.MaturityDate);

            return maturityDate < today;
        }
        return true;
    }

    get UpcomingPaymentRequired(): boolean {
        return this.Loans.map(loan => loan.UpcomingPaymentRequired)
            .reduce((prev, curr) => prev || curr, false);
    }

    get LoanBalances(): number {
        return this.Loans.reduce<number>((pv, cl) => {
            return pv + (!cl ? 0 : cl.Balance);
        }, 0);
    }
}
