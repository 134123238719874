import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { TopForm } from '../../top-form';

@Component({
    selector: 'rol-month-year',
    templateUrl: 'month-year.component.html'
})
export class MonthYearComponent implements OnInit {
    @Input() title: string;
    @Input() form: TopForm;
    @Input() monthControl: FormControl;
    @Input() yearControl: FormControl;
    @Input() yearsBack: number;

    months: any[];
    years: number[];

    ngOnInit(): void {
        this.months = [
            { name: 'Jan', value: 1 },
            { name: 'Feb', value: 2 },
            { name: 'Mar', value: 3 },
            { name: 'Apr', value: 4 },
            { name: 'May', value: 5 },
            { name: 'Jun', value: 6 },
            { name: 'Jul', value: 7 },
            { name: 'Aug', value: 8 },
            { name: 'Sep', value: 9 },
            { name: 'Oct', value: 10 },
            { name: 'Nov', value: 11 },
            { name: 'Dec', value: 12 },
        ];

        if (this.yearsBack === undefined) {
            this.yearsBack = 10;
        }

        this.years = [];
        const currentYear = moment().toDate().getFullYear();
        for (let i = currentYear - this.yearsBack; i <= currentYear; ++i) {
            this.years.push(i);
        }
    }
}
