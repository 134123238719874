import * as _ from 'lodash';
import { Address } from './address';
import { FullName } from './fullname';

export class TradeReference {
    companyName = '';
    address: Address = new Address(true);
    contactName: '';
    phoneNumber = '';
    faxNumber = '';

    constructor(appData?: any) {
        if (appData) {
            this.companyName = _.isNil(appData.CompanyName) ? '' : appData.CompanyName;
            this.address = new Address(true, appData.Address);
            this.contactName = _.isNil(appData.ContactName) ? '' : appData.ContactName;
            this.phoneNumber = _.isNil(appData.PhoneNumber) ? '' : appData.PhoneNumber;
            this.faxNumber = _.isNil(appData.FaxNumber) ? '' : appData.FaxNumber;
        }
    }
}
