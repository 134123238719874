export class AdditionalEntityInfo {
    constructor(appData?: any) {
        if (appData) {
            this.hasFormedInLast12Months = appData.HasFormedInLast12Months;
            this.isActivityConsistentWithVision = appData.IsActivityConsistentWithVision;
            this.rationaleForActivitiesChanges = appData.RationaleForActivitiesChanges;
            this.hasRecentLeadershipChanges = appData.HasRecentLeadershipChanges;
            this.rationaleForLeadershipChanges = appData.RationaleForLeadershipChanges;
            this.isBorrowerNotForProfitOrganization = appData.IsBorrowerNotForProfitOrganization;
            this.isBorrowerRegisteredCharity = appData.IsBorrowerRegisteredCharity;
            this.doesBorrowerSolicitCharitableDonations = appData.DoesBorrowerSolicitCharitableDonations;
            this.charitableDonationsSource = appData.CharitableDonationsSource;
            this.charitableDonationsPurpose = appData.CharitableDonationsPurpose;
        }
    }
    hasFormedInLast12Months?: boolean = null;
    isActivityConsistentWithVision?: boolean = null;
    rationaleForActivitiesChanges?: string = '';
    hasRecentLeadershipChanges?: boolean = null;
    rationaleForLeadershipChanges?: string = '';
    isBorrowerNotForProfitOrganization?: boolean = null;
    isBorrowerRegisteredCharity?: boolean = null;
    doesBorrowerSolicitCharitableDonations?: boolean = null;
    charitableDonationsSource?: string = '';
    charitableDonationsPurpose?: string = '';
}