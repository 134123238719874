import { QuestionSummary } from './question-summary';

export class Category {
    type: string;
    title: string;
    questions: QuestionSummary[];
    total: number[];
    constructor(type: string, title: string) {
        this.type = type;
        this.title = title;
        this.questions = [];
        this.total = [];
    }
    public addQuestion(index: number, description: string, values: number[]): void {
        this.questions.push(new QuestionSummary(index, description, values));
        this.updateTotal(values);
    }
    private updateTotal(values: number[]) {
        if (this.total.length === 0) {
            this.total = values.slice();
        } else {
            this.total = this.total.map((current, index) => current + values[index]);
        }
    }
}
