export class AuthorizedPartyRequest {
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Suffix: string;
    DOB: Date;
    SSN: string;
    StreetAddress: string;
    City: string;
    StateAbbreviated: string;
    ZipCode: string;
    Email: string;
    AcctRefNo: string;
    USCitizen: boolean;
    PermanentResident: boolean;
    CountryOfBirth: string;
    CountryOfCitizenship: string;

    constructor(data?: any) {
        if (data) {
            this.FirstName = data.FirstName;
            this.MiddleName = data.MiddleName;
            this.LastName = data.LastName;
            this.Suffix = data.Suffix;
            this.DOB = data.DOB;
            this.SSN = data.SSN;
            this.StreetAddress = data.StreetAddress;
            this.City = data.City;
            this.StateAbbreviated = data.StateAbbreviated;
            this.ZipCode = data.ZipCode;
            this.Email = data.Email;
            this.AcctRefNo = data.AcctRefNo;
            this.USCitizen = data.USCitizen;
            this.PermanentResident = data.PermanentResident;
            this.CountryOfBirth = data.CountryOfBirth;
            this.CountryOfCitizenship = data.CountryOfCitizenship;
        }
    }
}
