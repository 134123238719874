import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppModuleV2 } from './appv2/app.module';
import { CONFIG } from './environments/environment';
import { AppVersionHelper } from './version-helper';

if (CONFIG.runAsProd) {
  enableProdMode();
}

if(AppVersionHelper.isVersion2()) {
  platformBrowserDynamic().bootstrapModule(AppModuleV2)
  .catch(err => console.log(err));
} else {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
}