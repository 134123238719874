export function hundredWithDecimalMask(inputValue) {
    const mask = [];
    let hasDecimal = false;
    let decimalCount = 0;
    let firstNumber = true;
    for (let i = 0; i < inputValue.length; ++i) {
        if (hasDecimal) {
            if (decimalCount < 2) {
                mask.push(/\d/);
                decimalCount++;
            }
            continue;
        } else {
            if (inputValue[i] == '.') {
                hasDecimal = true;
                mask.push('.');
                continue;
            }
            if (i < 3) {
                if (firstNumber) {
                    mask.push(/[1-9]/);
                    firstNumber = false;
                } else {
                    mask.push(/\d/);
                }
            }
        }
    }
    return mask;
}
