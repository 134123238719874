<div class="container stage main_container">
    <rol-side-nav selectedAlias="{{pageAlias}}"></rol-side-nav>
    <div class="row">
        <div *ngIf="preApproved" class="col-sm-12">
            <h1 class="row preapprovalBanner">
                Pre-Screened Offer Acceptance
            </h1>
        </div>
        <div class="col-sm-7">
            <h1>Program Information</h1>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-6">
            <p class="text-small">All fields are required unless otherwise noted.</p>
        </div>
    </div>
    <div class="spacer">
        <div fxFlex.gt-md="60">
            <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application">
                <div *ngIf="group !== undefined">
                    <p-header>
                        <h2>{{application.vendors[0].vendorName}}</h2>
                    </p-header>
                    <rol-vendor-programs [vendor]="application.vendors[0]" [application]="application" [form]="form"
                        [group]="group">
                    </rol-vendor-programs>
                </div>
                <rol-credit-requirements [creditLimit]="application.totalCreditLimit()" [entity]="application.entity">
                </rol-credit-requirements>
                <div class="totalCredit">
                    Total Credit Requested: {{application.totalCreditLimit() | currency:'USD'}}
                </div>
                <div class="buttonContainer" fxLayout="column" fxLayoutAlign="center center">
                    <div>
                    <button *ngIf="showBack" class="btn btn-secondary back-btn" type="button"
                        (click)="back()">Back</button>
                    <button type="submit" class="btn btn-primary">Continue</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="showModal" class="modal-wrapper">
        <div class="modal-content">
            <div>
                You have changed the values associated with your pre-screen offer. Please click “Restore Defaults” if
                you wish to continue with the pre-screen offer. If you would simply like to start a new application,
                which would not be processed under the pre-screen offer, please click “Proceed” and you can begin the
                new application process.
            </div>
            <div class="buttonContainer">
                <button type="button" class="btn btn-primary" (click)="ProceedWithoutPreApproval()">Proceed</button>
                <button type="button" class="btn btn-primary" (click)="restorePreApprovalDefaults()">Restore
                    Defaults</button>
            </div>
        </div>
    </div>

    <div style="clear:both"></div>
</div>