import { AuthorizedPartyRequest } from './authorized-party-request';
import { AuthorizedPartyStatus } from './authorized-party-status';

export class AuthorizedPartyWorkflow extends AuthorizedPartyRequest {
    public Id: number;
    public Status: AuthorizedPartyStatus;
    public CifNum: number;

    constructor(data?: any) {
        super(data);

        if (data) {
            this.Id = data.Id;
            this.Status = <AuthorizedPartyStatus>data.Status;
            this.CifNum = data.CifNum;
        }

    }
}
