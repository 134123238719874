import { Injectable } from '@angular/core';
import { Registree } from '../models/register';
import { CurrentApplicationService } from './current-application.service';
import { Router } from '@angular/router';
import { NavigationOptions } from '../models/navigation-options';

@Injectable()
export class CurrentRegistrationService {
    constructor(private currentAppService: CurrentApplicationService, public router: Router) {
    }
    private currentRegistree: Registree;

    setCurrentRegistree(registree: Registree) {
        this.currentRegistree = registree;
    }

    getCurrentRegistree(): Registree {
        if (!this.currentRegistree) {
            this.makeNewRegistree();
        }
        return this.currentRegistree;
    }

    makeNewRegistree(data?) {
        this.currentRegistree = new Registree();
    }

    back() {
        window.history.back();
    }

    cancel() {
        this.makeNewRegistree();
        let path = '/home';
        this.currentAppService.getCurrentApplication().then((app) => {
            if (app.currentPage) {
                path = NavigationOptions.GetStartPage(app);
            }
            this.router.navigate([path]);
        });
    }
}
