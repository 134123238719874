import { Component, OnInit, Input } from '@angular/core';
import { Commitment } from '../../../models/accounts/commitment';
import { SubCommitment } from '../../../models/accounts/sub-commitment';
import { Loan } from '../../../models/accounts/loan';
import { AccountHttpService } from '../../../services/account-http.service';

@Component({
    selector: 'rol-subcommitment-details',
    templateUrl: 'subcommitment-details.component.html',
    styleUrls: ['subcommitment-details.component.css']
})
export class SubCommitmentDetailsComponent implements OnInit {
    @Input() commitment: Commitment;
    @Input() subcommitment: SubCommitment;
    loanDetails: Loan[];

    constructor(private accountService: AccountHttpService) { }

    ngOnInit() {
        this.loanDetails = this.subcommitment.Loans;
        this.loanDetails.forEach(loan => {
            this.accountService.getLoanDetails(loan.AcctRefNo)
                .then(detail => {
                    loan.Transactions = detail.Transactions;
                    loan.NextRateDate = detail.NextRateDate;
                });
        });
    }

    getAvailableCredit(): number {
        if (this.commitment.StatusCode !== '' || this.subcommitment.IsPastDrawPeriod) {
            return 0;
        }
        return this.subcommitment.CreditLimit - this.subcommitment.PrincipalBalance;
    }

    getTotalCredit(): number {
        if (this.commitment.StatusCode !== '' || this.commitment.IsDelinquent || this.subcommitment.IsPastDrawPeriod) {
            return 0;
        }
        return this.subcommitment.CreditLimit;
    }
}
