<div ngFormGroupName="reference">
    <div class="form-group">
        <label for="companyName">Trade Reference Name</label>
        <input name="companyName" class="form-control" [(ngModel)]="reference.companyName" required
            placeholder="Trade Reference Name" #companyName="ngModel" />
        <div *ngIf="(form.submitted || companyName.dirty) && companyName.invalid" class="error">
            <p>Required</p>
        </div>
    </div>
    <div class="well">
        <fieldset>
            <legend><span class="spanTitle">Trade Reference Address</span></legend>
            <div class="form-group">
                <label for="address">Address<span class='optional'> (optional)</span></label>
                <input name="address" class="form-control" [(ngModel)]="reference.address.line1"
                    placeholder="Address" />
            </div>
            <div class="form-group">
                <label for="city">City</label>
                <input name="city" class="form-control" [(ngModel)]="reference.address.city"
                    placeholder="City" #city="ngModel" required>
                <div *ngIf="(form.submitted || city.dirty) && city.invalid" class="error">
                    <p>Required</p>
                </div>
            </div>
            <div class="form-group">
                <label for="province">Province<span class='optional'> (optional)</span></label>
                <input name="province" class="form-control" [(ngModel)]="reference.address.state"
                    placeholder="Province" />
            </div>
            <div class="form-group">
                <label for="postal">Postal code<span class='optional'> (optional)</span></label>
                <input name="postal" class="form-control" [(ngModel)]="reference.address.zipcode"
                    placeholder="Postal code" #postal="ngModel" maxlength="10" />
                <div *ngIf="(form.submitted || postal.dirty) && postal.invalid" class="error">
                    <p>Invalid postal code</p>
                </div>
            </div>
        </fieldset>
    </div>
    <div class="form-group">
        <label for="contact">Trade Reference Contact<span class='optional'> (optional)</span></label>
        <input name="contact" class="form-control" [(ngModel)]="reference.contactName"
            placeholder="Trade Reference Contact" />
    </div>
    <div class="form-group">
        <label for="phone">Trade Reference Phone Number<span class='optional'> (optional)</span></label>
        <input type="text" class="form-control" [(ngModel)]="reference.phoneNumber" name="phone"
            #phone="ngModel" [textMask]="{mask: phoneMask, guide:false}" maxlength="14"
            placeholder="Trade Reference Phone Number">
        <div class="error" *ngIf="(form.submitted || phone.dirty) && phone.invalid">
            <p>Invalid phone number</p>
        </div>
    </div>
    <div class="form-group">
        <label for="fax">Trade Reference Fax Number<span class='optional'> (optional)</span></label>
        <input type="text" class="form-control" [(ngModel)]="reference.faxNumber" name="fax"
            #fax="ngModel" [textMask]="{mask: phoneMask, guide:false}" maxlength="14"
            placeholder="Trade Reference Fax Number">
        <div class="error" *ngIf="(form.submitted || fax.dirty) && fax.invalid">
            <p>Invalid fax number</p>
        </div>
    </div>
</div>