import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { validatePasswordFactory, passwordCompareFactory } from '../validation/password-validator.directive';
import { TopForm } from '../top-form';

@Component({
    templateUrl: 'reset-password-landing.component.html',
    styleUrls: ['../styles_form.css', 'reset-landing.component.css']
})
export class ResetPasswordLandingComponent implements OnInit {
    email: string;
    token: string;
    securityQuestion: string;
    securityAnswer = '';
    newPassword: string;
    confirmNewPassword: string;
    showSecurityQuestion = true;
    serverError: string;
    password: FormControl = new FormControl('', [Validators.required, validatePasswordFactory()]);
    confirmPassword: FormControl = new FormControl('', Validators.required);
    passwordGroup: FormGroup;
    form: TopForm;
    showConfirmation: boolean;
    invalidFormMessage: string;
    isInvalidUserOrTokenInfo: boolean = false;

    constructor(private router: Router, private authService: AuthService) {
        this.form = new TopForm();
        this.showConfirmation = false;
        this.passwordGroup = new FormGroup({
            'password': this.password,
            'confirmPassword': this.confirmPassword
        }, passwordCompareFactory());
        this.form.addControl('passwordGroup', this.passwordGroup);
    }

    cancel() {
        this.router.navigate(['/home']);
    }

    ngOnInit() {
        this.email = this.router.parseUrl(this.router.url).queryParams['email'];
        this.token = this.router.parseUrl(this.router.url).queryParams['token'];
        this.authService.GetSecurityQuestion(this.email, null, this.token)
            .then((question) => this.securityQuestion = question)
            .catch((reason) => {
                this.invalidFormMessage = reason;
                this.isInvalidUserOrTokenInfo = true;
            });
    }

    passwordValidationMessage(errorObj) {
        let message = 'Password is invalid: ';
        // tslint:disable-next-line: forin
        for (const error in errorObj) {
            message += errorObj[error] + '; ';
        }

        return message;
    }

    submitWithQuestion() {
        this.form.submitted = true;
        if (!this.showConfirmation) {
            if (this.form.valid) {
                this.authService.ResetPassword(this.email, this.token, this.newPassword, this.securityQuestion, this.securityAnswer)
                    .then(() => {
                        this.showConfirmation = true;
                        
                    })
                    .catch((rejected) => {
                        this.showConfirmation = false;
                        this.serverError = rejected;
                        this.isInvalidUserOrTokenInfo = false;
                    });
            } else {
                const element: any = document.querySelector('.ng-invalid');
                if (element) {
                    element.scrollIntoView();
                    window.scrollBy(0, -100);
                }
            }
        }
    }

    successAndReturn() {
        this.router.navigate(['/home']);
    }
    answerUpdated(value: string) {
        this.securityAnswer = value;
    }
}
