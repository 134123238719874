import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { TopForm } from '../top-form';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Director } from '../../models/director';
import { FullName } from '../../models/fullname';

@Component({
    selector: 'rol-directors',
    templateUrl: 'directors.component.html',
    styleUrls: ['../styles_form.css', 'directors.component.css'],
})
export class DirectorsComponent implements OnInit, AfterViewInit {
    @Input() form: TopForm;
    @Input() directors: Director[];
    @Input() directorRequiredError: boolean;

    isOpen: boolean[];
    accordionMap: number[];
    formGroups: FormGroup[];

    constructor() {
        this.isOpen = [];
        this.formGroups = [];
        this.accordionMap = [];
    }

    ngOnInit() { 

        for (let i = 0; i < this.directors.length; ++i) {
            this.isOpen.push(false);
            this.accordionMap.push(i);
            const group = new FormGroup({});
            group.addControl("firstName", new FormControl(''));
            group.addControl("middleName", new FormControl(''));
            group.addControl("lastName", new FormControl(''));
            group.addControl("suffix", new FormControl(''));
            this.form.addControl("d" + this.accordionMap.length.toString(), group);
        }
    }

    ngAfterViewInit() {
    }

    tabOpen(e) {
        this.closeAllTabs();
        this.isOpen[e.index] = true;
    }

    tabClose(e) {
        this.isOpen[e.index] = false;
    }

    private closeAllTabs() {
        for (let i = 0; i < this.isOpen.length; ++i) {
            this.isOpen[i] = false;
        }
    }

    hasNonWhitespace(s) {
        return /[^\s]/g.test(s);
    }

    showDisplayName(director: Director): boolean {
        return this.hasNonWhitespace(director.toString());
    }

    add() {
        this.form.submitted = false;
        const d = new Director();
        d.fullName = new FullName();
        this.directors.push(d);
        this.closeAllTabs();
        this.accordionMap.push(this.isOpen.length);
        this.isOpen.push(true);
        const group = new FormGroup({});
        group.addControl("firstName", new FormControl(''));
        group.addControl("middleName", new FormControl(''));
        group.addControl("lastName", new FormControl(''));
        group.addControl("suffix", new FormControl(''));    
        this.form.addControl("d" + this.accordionMap.length.toString(), group);
        
    }

    

    delete(index: number, event) {
        this.form.removeControl("d" + index);       

        this.directors.splice(index, 1);
        this.isOpen.splice(index, 1);        

        event.preventDefault();
    }
}
