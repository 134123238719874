import { Component, Input } from '@angular/core';

@Component({
    selector: 'rol-credit-requirements',
    template: `
        <div *ngIf="creditLimit > 250000" class="creditLimitRequirements">
            <p>For requests over $250K, you will need to provide Security Agreement and your Financial Information.</p>
        </div>
        <div *ngIf="showInstructions && creditLimit > 200000" class="instructions">
            <p>Please send us your documents via:</p>
            <ul>
                <li><strong>Email:</strong> <a href="mailto:QuickLink@RaboAg.com">QuickLink@RaboAg.com</a></li>
                <li>or <strong>Fax:</strong> (866) 349-3139</li>
            </ul>
        </div>
    `,
    styles: [`
        .creditLimitRequirements {
            margin-top: 10px;
            padding: 10px;
            text-align: left;
            border: 1px solid #ffb584;
            border-radius: 5px;
            background: #ffe2c4;
        }
        .creditLimitRequirements p:last-child {
            margin-bottom: 0;
        }
        .instructions {
            margin-top: 15px;
            text-align: left;
            padding-left: 10px;
        }
        .instructions ul {
            list-style: none;
        }
    `]
})
export class CreditRequirements {
    @Input()
    creditLimit = 0;
    @Input()
    entity = false;
    @Input()
    showInstructions = false;
}
