import { IItemFactory } from './autocomplete.component';
export class CorporationItemFactory implements IItemFactory {
    public items: string[] = [
        'Corporation',
        'Co-operative',
        'Estate',
        'Community Association',
        'Unincorporated Association',
        'Non-Profit'
    ];
    getItems(query: string): string[] {
        if (query == null || query === '') {
            return this.items;
        } else {
            return this.items.filter(function (el) {
                return el.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }.bind(this));
        }
    }
}
