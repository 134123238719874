<div>
    <div class="form-group">
        <label>Business Phone Number</label>
        <input class="form-control" [formControl]="businessPhoneCtrl" [(ngModel)]="borrower.businessPhoneNumber"
            placeholder="Business Phone Number" [textMask]="{mask: mask, guide:false}" />
        <div class="error" *ngIf="(form.submitted || businessPhoneCtrl.dirty) && businessPhoneCtrl.invalid">
            <p *ngIf="businessPhoneCtrl.errors['minlength'] || businessPhoneCtrl.errors['maxlength']">Business Phone Number is invalid</p>
        </div>
        <div *ngIf="(form.submitted || group.dirty) && group.invalid" class="error">
            <p *ngIf="group.hasError('atLeastOneRequired')">At least one phone number is required</p>
        </div>
    </div>
    <div class="form-group">
        <label>Home Phone Number</label>
        <input class="form-control" [formControl]="homePhoneCtrl" [(ngModel)]="borrower.homePhoneNumber"
            placeholder="Home Phone Number" [textMask]="{mask: mask, guide:false}" />
        <div class="error" *ngIf="(form.submitted || homePhoneCtrl.dirty) && homePhoneCtrl.invalid">
            <p *ngIf="homePhoneCtrl.errors['minlength'] || homePhoneCtrl.errors['maxlength']">Home Phone Number is invalid</p>
        </div>
        <div *ngIf="(form.submitted || group.dirty) && group.invalid" class="error">
            <p *ngIf="group.hasError('atLeastOneRequired')">At least one phone number is required</p>
        </div>
    </div>
    <div class="form-group">
        <label>Mobile Phone Number</label>
        <input class="form-control" [formControl]="mobilePhoneCtrl" [(ngModel)]="borrower.mobilePhoneNumber"
            placeholder="Mobile Phone Number" [textMask]="{mask: mask, guide:false}" />
        <div class="error" *ngIf="(form.submitted || mobilePhoneCtrl.dirty) && mobilePhoneCtrl.invalid">
            <p *ngIf="mobilePhoneCtrl.errors['minlength'] || mobilePhoneCtrl.errors['maxlength']">Mobile Phone Number is invalid</p>
        </div>
        <div *ngIf="(form.submitted || group.dirty) && group.invalid" class="error">
            <p *ngIf="group.hasError('atLeastOneRequired')">At least one phone number is required</p>
        </div>
    </div>
</div>