export class UserSecurityProfile {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    permissions: Array<string>;
    roles: Array<string>;

    constructor(appData?: any) {
        if (appData) {
            this.id = appData.Id;
            this.email = appData.Email;
            this.firstName = appData.FirstName;
            this.lastName = appData.LastName;
            this.permissions = appData.Permissions == null ? null : appData.Permissions instanceof Array
                ? appData.Permissions
                : JSON.parse(appData.Permissions);
            this.roles = appData.Roles == null ? null : appData.Roles instanceof Array ? appData.Roles : JSON.parse(appData.Roles);
        }
    }
}