import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AuthorizationGuard implements CanActivate {

    constructor(protected router: Router, protected userService: UserService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (this.userService.GetUser() == null || this.userService.GetUser().isAnonymous) {
            this.router.navigate(['/home']);
            return false;
        }

        return true;
    }
}
