type Page = { alias: string, name: string, route: string };
type PageNavigation = { fromAlias: string, toAlias: string, isAllowed: boolean };

export const PageAliases = {
  profile: 'Profile',
  agreement: 'Agreement',
  summary: 'Summary'
}

export class NavigationOptionsRegistration {
  public static PageNavigationMatrix : PageNavigation[] = [
    { fromAlias: PageAliases.summary, toAlias: PageAliases.profile, isAllowed : true },
    { fromAlias: PageAliases.summary, toAlias: PageAliases.agreement, isAllowed : false },
    { fromAlias: PageAliases.summary, toAlias: PageAliases.summary, isAllowed : true }
  ];

  public static Pages: Page[] = [
    { alias: PageAliases.profile, name: 'Profile', route: "/registration/registration-profile" },
    { alias: PageAliases.agreement, name: 'Agreement', route: "/registration/registration-agreement" },
    { alias: PageAliases.summary, name: 'Summary', route: "/registration/registration-summary" }
  ];

  public static RegistrationPages(): Page[] {
    return this.Pages;
  }
}
