<div class="preference-group group raised-card">
    <h2>Reset Security Questions</h2>
    <rol-loading-feedback *ngIf="loading"></rol-loading-feedback>
    <div *ngIf="!loading && !changeComplete">
        <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
            <div [formGroup]="questionsGroup" class="well">
                <legend>
                    <span class="spanTitle">
                        Security Questions
                    </span>
                </legend>
                <div *ngFor="let securityItem of securityQuestionReset.securityQuestions; let i=index">
                    <div class="form-group">
                        <label>Security Question {{i+1}}</label>
                        <select name="securityItemQuestion" class="form-control"
                            (change)="changeSecurityQuestion(securityItem, $event.target.value)"
                            [formControlName]="'questionControl'+i" [(ngModel)]="securityItem.question">
                            <option value="" selected>Select Security Question</option>
                            <option *ngFor="let question of possibleSecurityQuestions" value="{{question}}">{{question}}
                            </option>
                        </select>
                        <div class="error"
                            *ngIf="(form.submitted || questionsGroup?.controls['questionControl'+i].dirty) && questionsGroup?.controls['questionControl'+i].invalid">
                            <p>Required</p>
                        </div>
                        <input name="answer" [formControlName]="'answerControl'+i" class="form-control"
                            [(ngModel)]="securityItem.answer" />
                        <div class="error"
                            *ngIf="(form.submitted || questionsGroup?.controls['answerControl'+i].dirty) && questionsGroup?.controls['answerControl'+i].invalid">
                            <p>Required</p>
                        </div>
                    </div>
                </div>
                <div class="error"
                    *ngIf="(form.submitted || questionsGroup.dirty) && questionsGroup.invalid && questionsGroup.errors">
                    <p>Each security question must be unique.</p>
                </div>
            </div>

            <div *ngIf="requirePassword" class="well">
                <legend>
                    <span class="spanTitle">
                        Password
                    </span>
                </legend>
                <div class="form-group">
                    <div>Your password is required to save changes.</div>
                    <input type="password" id="Password" class="form-control" formControlName="password"
                        [(ngModel)]="securityQuestionReset.password" placeholder="Password" name="Password"
                        autocomplete="off">
                    <div class="alert alert-danger"
                        *ngIf="(form?.submitted || form?.controls['password'].dirty) && !form?.controls.password.valid">
                        <p *ngIf="form?.controls['password'].errors['required']">Your password is required</p>
                    </div>
                </div>
            </div>

            <div class="form-group error" *ngIf="serverError">
                {{serverError}}
            </div>
            <button class="btn btn-primary" type="submit" [disabled]="form.invalid">Update</button>
        </form>
    </div>
    <div *ngIf="changeComplete">
        <p>Your account was successfully updated!</p>
        <button type="button" class="btn btn-primary" (click)="closeComplete()">OK</button>
    </div>
</div>