import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SecurityQuestion } from '../../../models/security-question';
import { SecurityQuestionReset } from '../../../models/security-question-reset';
import { SecurityQuestions } from '../../../models/security-questions';
import { TopForm } from '../../../form/top-form';
import { AccountHttpService } from '../../../services/account-http.service';
import { Router } from '@angular/router';

const SECURITY_QUESTIONS_COUNT = 5;

@Component({
    selector: 'rol-edit-security-questions',
    templateUrl: 'edit-security-questions.component.html',
    styleUrls: []
})
export class EditSecurityQuestionsComponent implements OnInit {
    form: TopForm;
    serverError = '';
    emailError = '';
    questionsGroup: FormGroup;
    possibleSecurityQuestions: string[] = SecurityQuestions;
    securityQuestionReset: SecurityQuestionReset;
    loading: boolean;
    changeComplete: boolean;
    @Input() requirePassword = true;

    constructor(private accountService: AccountHttpService, private router: Router) {
    }

    ngOnInit() {
        window.scrollTo(0, 0);
        this.securityQuestionReset = new SecurityQuestionReset();
        this.securityQuestionReset.email = this.accountService.credentials.email;
        const pw = this.requirePassword ? '' : this.accountService.credentials.password;
        this.securityQuestionReset.password = pw;
        this.form = new TopForm({
            password: new FormControl(pw, [Validators.required]),
        });

        this.questionsGroup = new FormGroup({}, this.noDuplicateQuestionsFactory());
        for (let x = 0; x < SECURITY_QUESTIONS_COUNT; ++x) {
            if (this.securityQuestionReset.securityQuestions.length <= x) {
                this.securityQuestionReset.securityQuestions.push(new SecurityQuestion());
            }
            const questionFC = new FormControl('', Validators.required);
            const answerFC = new FormControl('', Validators.required);
            this.questionsGroup.addControl('questionControl' + x, questionFC);
            this.questionsGroup.addControl('answerControl' + x, answerFC);
        }
        this.form.addControl('questionGroup', this.questionsGroup);
    }

    changeSecurityQuestion(securityQuestion: SecurityQuestion, questionValue: string) {
        securityQuestion.question = questionValue;
    }

    noDuplicateQuestionsFactory() {
        const self = this;
        return function (g: FormGroup) {
            const selectedQuestions = {};
            if (self.securityQuestionReset && self.securityQuestionReset.securityQuestions) {
                for (let i = 0; i < self.securityQuestionReset.securityQuestions.length; ++i) {
                    const value = self.securityQuestionReset.securityQuestions[i].question;
                    if (value) {
                        if (value in selectedQuestions) {
                            return { duplicateQuestions: true };
                        }
                        selectedQuestions[value] = true;
                    }
                }
            }
            return null;
        };
    }

    submit() {
        this.form.submitted = true;

        if (this.form.valid) {
            this.loading = true;
            this.accountService.updateSecurityQuestions(this.securityQuestionReset)
                .then((res) => {
                    if (res) {
                        this.changeComplete = res;
                    } else {
                        this.serverError = 'There was an issue submitting the request';
                    }
                })
                .catch((rejected) => {
                    if (rejected.status = 401) {
                        this.serverError = 'Incorrect Password';
                    } else {
                        this.serverError = rejected;
                    }
                })
                .then(() => {
                    this.loading = false;
                });
        }
    }

    closeComplete() {
        if (this.requirePassword) {
            this.changeComplete = false;
            this.ngOnInit();
        } else {
            this.router.navigate(['/home']);
        }

    }
}
