import { Injectable } from '@angular/core';

@Injectable()
export class TextRetrieverService {

    // eventually might refactor this for it to live somewhere else.
    private REPO = {
        'entityTypeTooltip': {
            'US': 'Select an option from the list or type in your entity type if it is not listed.',
            'UK': 'UK Help text for entity type...'
        }
    };

    public getText(alias: string, localization?: string): string {
        if (!localization) {
            localization = 'US';
        }
        if (this.REPO[alias] && this.REPO[alias][localization]) {
            return this.REPO[alias][localization];
        } else {
            return null;
        }
    }
}
