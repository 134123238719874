import { FormControl } from '@angular/forms';

export const totalIncomeMaxNLS = 999999999;

export class FinancialValidation {
    constructor() {

    }
    public hasNoValueError(c: FormControl): boolean {
        return c && c.hasError('required');
    }

    public hasInvalidNumberError(c: FormControl): boolean {
        return c && c.hasError('validateNumber') && c.errors['validateNumber'].NAN;
    }

    public hasOutOfRangePriceError(c: FormControl): boolean {
        return c && c.hasError('validateNumber') && c.errors['validateNumber'].max && c.errors['validateNumber'].max === true;
    }
}
