<div class="container stage main_container">
    <rol-side-nav selectedAlias="{{pageAlias}}"></rol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Review Application</h1>
        </div>
    </div>
    <hr />
    <div class="row">
    </div>
    <div class="row">
    </div>
    <div class="spacer">
        <div fxFlex.gt-md="60">
            <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application" class="summary-form">
                <div class="summary-section">
                    <h2>Program Information</h2>
                    <ul *ngFor="let vendor of application.vendors; let i=index;" class="border-layout vendorList">
                        <li><label>Ag Business Centre Name</label> 
                            <span class="summary-indent">{{vendor.businessCentreName}}</span>
                        </li>
                        <li><label>Ag Business Centre Number</label> 
                            <span class="summary-indent">{{vendor.businessCentreNumber}}</span>
                        </li>
                        <li><label>Customer Number</label> 
                            <span class="summary-indent">{{vendor.businessCentreCustomerNumber}}</span>
                        </li>
                        <li><label>Credit Limit</label> 
                            <span class="summary-indent">{{vendor.creditLimit | currency:'USD'}}</span>
                        </li>
                    </ul>
                    <div class="totalCredit">
                        Total Credit Requested: {{application.totalCreditLimit() | currency:'USD'}}
                    </div>
                </div>
                <div *ngIf="showEntity()" class="summary-section">
                    <h2>Entity Information</h2>
                    <ul class="border-layout">
                        <li><label>Legal Name</label> 
                            <span class="summary-indent">{{application.entity.legalName}}</span>
                        </li>
                        <li><label>Entity Type</label> 
                            <span class="summary-indent">{{application.entity.entityType}}</span></li>
                        <li *ngIf="application.entity.doingBusinessAs.length > 0">
                            <label>Doing Business As</label>
                            <span class="summary-indent">{{application.entity.doingBusinessAs.join(', ')}}</span>
                        </li>
                        <li>
                            <label>Physical Address</label> 
                            <span class="summary-indent">{{application.entity.physicalAddress.toString()}}</span>
                        </li>
                        <li>
                            <label>Mailing Address</label> 
                            <span class="summary-indent">{{application.entity.mailingAddress.toString()}}</span>
                        <li>
                        <li><label>Business Phone</label> 
                            <span class="summary-indent">{{application.entity.businessPhoneNumber}}</span></li>
                        <li><label title="Home Phone">Home Phone</label> 
                            <span class="summary-indent">{{application.entity.homePhoneNumber}}</span>
                        </li>
                        <li><label title="Mobile Phone">Mobile Phone</label> 
                            <span class="summary-indent">{{application.entity.mobilePhoneNumber}}</span>
                        <li><label>Province of Organization/ Incorporation</label> 
                            <span class="summary-indent">{{ application.entity.stateOfOrganization}}</span></li>
                        <li><label>Formed Less Than 12 Months Ago?</label> 
                            <span class="summary-indent">{{ wasFormedInLast12Months() ? "Yes" : "No" }}</span></li>
                        <li *ngIf="wasFormedInLast12Months()">
                            <label>Activities Consistent With Vision?</label> 
                            <span class="summary-indent">{{ application.entity.richardsonEntityInformation.isActivityConsistentWithVision ? "Yes" : "No"}}</span>
                        </li>
                        <li *ngIf="activitiesNotConsistentWithVision()">
                            <label>Rationale for Activities Changes</label>
                            <span class="summary-indent">{{ application.entity.richardsonEntityInformation.rationaleForActivitiesChanges}}</span>
                        </li>
                        <li *ngIf="wasFormedInLast12Months()">
                            <label>Has Recent Leadership Changes?</label> 
                            <span class="summary-indent">{{ application.entity.richardsonEntityInformation.hasRecentLeadershipChanges ? "Yes" : "No"}}</span>
                        </li>
                        <li *ngIf="hasRecentLeadershipChanges()">
                            <label>Rationale for Leadership Changes</label> 
                            <span class="summary-indent">{{ application.entity.richardsonEntityInformation.rationaleForLeadershipChanges}}</span>
                        </li>

                        <li><label>Is Not for Profit?</label>
                            <span class="summary-indent">{{ application.entity.richardsonEntityInformation.isBorrowerNotForProfitOrganization ? "Yes" : "No" }}</span></li>
                        <li *ngIf="isBorrowerNotForProfit()">
                            <label>Is Registered Charity?</label> 
                            <span class="summary-indent">{{ application.entity.richardsonEntityInformation.isBorrowerRegisteredCharity ? "Yes" : "No" }}</span>
                        </li>
                        <li *ngIf="isBorrowerNotForProfit()">
                            <label>Are Charitable Donations Solicited?</label> 
                            <span class="summary-indent">{{ application.entity.richardsonEntityInformation.doesBorrowerSolicitCharitableDonations ? "Yes" : "No" }}</span>
                        </li>
                        <li *ngIf="isBorrowerCharitable()">
                            <label>Source of Charitable Donations</label> 
                            <span class="summary-indent">{{ application.entity.richardsonEntityInformation.charitableDonationsSource}}</span>
                        </li>
                        <li *ngIf="isBorrowerCharitable()">
                            <label>Purpose of Charitable Donations</label> 
                            <span class="summary-indent">{{ application.entity.richardsonEntityInformation.charitableDonationsPurpose}}</span>
                        </li>
                    </ul>
                </div>
                <div *ngIf="showEntity() && application.entity.signer && application.entity.signerTitle" class="summary-section">
                    <h2>Signer</h2>
                    <p class="emphasize">
                        <span class="summary-indent">{{application.entity.signer.fullName}} ({{application.entity.signerTitle}})
                        will sign for {{application.entity.legalName}}</span></p>
                    <ul *ngIf="entityIsOther(application)" class="border-layout">
                        <li><label>Name</label>
                            <span class="summary-indent">{{application.entity.signer.fullName.toString()}}</span></li>
                        <li><label>Physical Address</label>
                            <span class="summary-indent">{{application.entity.signer.physicalAddress.toString()}}</span>
                        </li>
                        <li><label>Mailing Address</label>
                            <span class="summary-indent">{{application.entity.signer.mailingAddress.toString()}}</span>
                        </li>
                        <li><label>Email</label>
                            <span class="summary-indent">{{application.entity.signer.email}}</span></li>
                        <li><label>Date of Birth (mm/dd/yyyy)</label> 
                            <span class="summary-indent">{{displayDate(application.entity.signer.dateOfBirth)}}</span></li>
                        <li>
                            <label>Canadian Citizen</label>
                            <i *ngIf="application.entity.signer.isCanadianCitizen" title="You are a Canadian Citizen" class="fa fa-check-square green"></i>
                            <i *ngIf="!application.entity.signer.isCanadianCitizen" title="You are not a Canadian Citizen" class="fa fa-times-circle red"></i>
                        </li>
                        <li *ngIf="!application.entity.signer.isCanadianCitizen">
                            <label>Permanent Resident</label>
                            <i *ngIf="application.entity.signer.isPermanentResident" title="You are a Permanent Resident" class="fa fa-check-square green"></i>
                            <i *ngIf="!application.entity.signer.isPermanentResident" title="You are not a Permanent Resident" class="fa fa-times-circle red"></i>
                        </li>
                        <li *ngIf="!application.entity.signer.isCanadianCitizen"><label>Country of Citizenship</label>
                            <span class="summary-indent">{{application.entity.signer.countryOfCitizenship}}</span></li>
                    </ul>
                </div>
                <div class="summary-section">
                    <h2>
                        {{borrowerSectionTitle}} Information
                        <select name="borrowerName" class="form-control" [(ngModel)]="selectedBorrower" *ngIf="application.borrowers.length > 1">
                            <option *ngFor="let borrower of application.borrowers; let i=index;" value="{{i}}">
                                {{borrower.fullName.toString()}}</option>
                        </select>
                    </h2>
                    <ul class="border-layout">
                        <li *ngIf="showPrimaryApplicantConfirmation">
                            <label>Primary Applicant<br>Co-Borrower Confirmation</label>
                            <span class="summary-indent">
                                <i *ngIf="application.hasPrimaryApplicantConfirmation" title="Primary applicant has confirmed agreement" class="fa fa-check-square green"></i>
                                <i *ngIf="!application.hasPrimaryApplicantConfirmation" title="Primary applicant has not confirmed agreement" class="fa fa-times-circle red"></i>
                            </span>
                        </li>
                        <li><label>Name</label>
                            <span class="summary-indent">{{currentBorrower.fullName.toString()}}</span></li>
                        <li>
                            <label>Physical Address</label> 
                            <span class="summary-indent">{{currentBorrower.physicalAddress.toString()}}</span>
                        </li>
                        <li>
                            <label>Mailing Address</label> 
                            <span class="summary-indent">{{currentBorrower.mailingAddress.toString()}}</span>
                        <li>
                        <li><label>Email</label> 
                            <span class="summary-indent">{{currentBorrower.email}}</span></li>
                        <li><label>Date of Birth (mm/dd/yyyy)</label> 
                            <span class="summary-indent">{{displayDate(currentBorrower.dateOfBirth)}}</span></li>
                        <li><label>Business Phone</label> 
                            <span class="summary-indent">{{currentBorrower.businessPhoneNumber}}</span></li>
                        <li><label title="Home Phone">Home Phone</label> 
                            <span class="summary-indent">{{currentBorrower.homePhoneNumber}}</span>
                        </li>
                        <li><label title="Mobile Phone">Mobile Phone</label> 
                            <span class="summary-indent">{{currentBorrower.mobilePhoneNumber}}</span>
                        </li>
                        <li><label>Is Primary Occupation other than farming?</label>
                            <span class="summary-indent">{{currentBorrower.primaryOccupationNotFarmer ? "Yes" : "No" }}</span>
                        </li>
                        <li><label>Primary Occupation</label>
                            <span class="summary-indent">{{currentBorrower.primaryOccupation}}</span>
                        </li>
                        <li>
                            <label>Canadian Citizen</label>
                            <span class="summary-indent">
                            <i *ngIf="currentBorrower.isCanadianCitizen" title="You are a Canadian Citizen" class="fa fa-check-square green"></i>
                            <i *ngIf="!currentBorrower.isCanadianCitizen" title="You are not a Canadian Citizen" class="fa fa-times-circle red"></i>
                            </span>
                        </li>
                        <li *ngIf="!currentBorrower.isCanadianCitizen">
                            <label>Country of Citizenship</label> 
                            <span class="summary-indent">{{currentBorrower.countryOfCitizenship}}</span></li>
                        <li *ngIf="!currentBorrower.hasBankruptcy"><label>Bankruptcy</label>
                            <span class="summary-indent">No</span>
                        <li>
                        <li *ngIf="currentBorrower.hasBankruptcy"><label>Bankruptcy Filing Date (mm/dd/yyyy)</label>
                            <span class="summary-indent">{{displayDate(currentBorrower.bankruptcyFilingDate)}}</span></li>

                        <li *ngIf="!currentBorrower.richardsonFinancialInformation.hasMediation">
                            <label>Mediation?</label> 
                            <span class="summary-indent">{{ currentBorrower.richardsonFinancialInformation.hasMediation ? "Yes" : "No"}}</span>
                        </li>
                        <li *ngIf="currentBorrower.richardsonFinancialInformation.hasMediation">
                            <label>Mediation Month/Year</label> 
                            <span class="summary-indent">{{ formatMonth(currentBorrower.richardsonFinancialInformation.mediationMonth) }} / {{ currentBorrower.richardsonFinancialInformation.mediationYear}}</span>
                        </li>

                        <li *ngIf="!currentBorrower.richardsonFinancialInformation.hasCropInsurance">
                            <label>Crop Insurance?</label> 
                            <span class="summary-indent">{{ currentBorrower.richardsonFinancialInformation.hasCropInsurance ? "Yes" : "No"}}</span>
                        </li>
                        <li *ngIf="currentBorrower.richardsonFinancialInformation.hasCropInsurance">
                            <label>Insured Acres</label> 
                            <span *ngIf="currentBorrower.richardsonFinancialInformation.hasCropInsurance" class="summary-indent">{{ currentBorrower.richardsonFinancialInformation.cropInsuredAcres }}</span>
                        </li>

                        <li *ngIf="!currentBorrower.richardsonFinancialInformation.hasHailInsurance">
                            <label>Hail Insurance?</label> 
                            <span class="summary-indent">{{ currentBorrower.richardsonFinancialInformation.hasHailInsurance ? "Yes" : "No"}}</span>
                        </li>
                        <li *ngIf="currentBorrower.richardsonFinancialInformation.hasHailInsurance">
                            <label>Hail Insured Acres</label>
                            <span class="summary-indent">{{ currentBorrower.richardsonFinancialInformation.hailInsuredAcres }}</span>
                        </li>

                        <li *ngIf="!currentBorrower.richardsonFinancialInformation.hasOtherIncome">
                            <label>Off-Farm Income?</label> 
                            <span class="summary-indent">{{ currentBorrower.richardsonFinancialInformation.hasOtherIncome ? "Yes" : "No"}}</span>
                        </li>
                        <li *ngIf="currentBorrower.richardsonFinancialInformation.hasOtherIncome">
                            <label>Other Income Source</label> 
                            <span class="summary-indent">{{ currentBorrower.richardsonFinancialInformation.otherIncomeSource }}</span>
                        </li>
                        <li *ngIf="currentBorrower.richardsonFinancialInformation.hasOtherIncome">
                            <label>Other Income Amount</label> 
                            <span class="summary-indent">{{ currentBorrower.richardsonFinancialInformation.otherIncomeAmount | currency:'USD' }}</span>
                        </li>

                        <li><label>Outstanding Loans?</label> 
                            <span class="summary-indent">{{ currentBorrower.richardsonFinancialInformation.hasOutstandingLoans ? "Yes" : "No" }}</span>
                        </li>
                        <br />
                        <li *ngIf="currentBorrower.richardsonFinancialInformation.hasOutstandingLoans" style="margin-left:100px">
                            <div class="grid" style="font-weight:bold">
                                <div class="col">Type</div>
                                <div class="col">Creditor</div>
                                <div class="col-2">Issue<br>(Month/Year)</div>
                                <div class="col">Amount</div>
                            </div>
                            <hr />
                            <div *ngFor="let loan of currentBorrower.richardsonFinancialInformation.outstandingLoans; let i=index" class="grid">
                                <div class="col">{{loan.loanType}}</div>
                                <div class="col">{{loan.creditor}}</div>
                                <div class="col-2">{{formatMonth(loan.issueMonth)}} / {{loan.issueYear}}</div>
                                <div class="col">{{loan.amount | currency:'USD'}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="!isSingleOwnerApplication" class="summary-section">
                    <h2>Ownership Information</h2>
                    <ul class="border-layout">
                        <li class="group">
                            <label>Are there any intermediary Companies?</label>
                            <span class="summary-indent">{{application.hasIntermediaryOwner ? "Yes" : "No"}}</span>
                            <div *ngIf="application.hasIntermediaryOwner" class="ownership-forms">
                                <span class="summary-indent">
                                    Please ensure the
                                    <a href="assets/Additional_Information_on_Intermediary_Companies.pdf" target="_blank">Intermediary Company(ies) Information Form</a>
                                    is completed.
                                </span>
                            </div>
                        </li>
                        <li class="group">
                            <label>Is there a Trust?</label>
                            <span class="summary-indent">{{application.hasTrust ? "Yes" : "No"}}</span>
                            <div *ngIf="application.hasTrust" class="ownership-forms">
                                <span class="summary-indent">
                                    Please ensure the
                                    <a href="assets/Trust_Information_Form.pdf" target="_blank">Trust Information Form</a>
                                    is completed.
                                </span>
                            </div>
                        </li>
                        <li class="group">
                            <label>Is there an additional Signatory?</label>
                            <span class="summary-indent">{{application.hasAdditionalSignatoryVerification ? "Yes" : "No"}}</span>
                            <div *ngIf="application.hasAdditionalSignatoryVerification" class="ownership-forms">
                                <span class="summary-indent">
                                    Please ensure the
                                    <a href="assets/Additional_Signatory_Identity_Verification_Form.pdf" target="_blank">Signatory Identity Verification Form</a>
                                    is completed.
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="showBeneficialOwners()" class="summary-section">
                    <h2>Beneficial Owners</h2>
                    <ul *ngFor="let o of allBeneficialOwners" class="border-layout">
                        <li><label>Name</label>
                            <span class="summary-indent">{{o.fullName.toString()}}</span></li>
                        <li>
                            <label>Physical Address</label> 
                            <span class="summary-indent">{{o.physicalAddress.toString()}}</span>
                        </li>
                        <li><label>Date of Birth (mm/dd/yyyy)</label> 
                            <span class="summary-indent">{{displayDate(o.dateOfBirth)}}</span></li>
                        <li>
                            <label>Permanent Resident</label>
                            <span class="summary-indent">
                            <i *ngIf="o.isCanadaCountryOfPermanentResidence" title="You are a Permanent Resident" class="fa fa-check-square green"></i>
                            <i *ngIf="!o.isCanadaCountryOfPermanentResidence" title="You are not a Permanent Resident" class="fa fa-times-circle red"></i>
                            </span>
                        </li>
                        <li *ngIf="!o.isCanadaCountryOfPermanentResidence">
                            <label>Country of Permanent Residence</label>
                            <span class="summary-indent">{{o.countryOfPermanentResidence}}</span>
                        </li>
                    </ul>
                </div>
                <div *ngIf="application.directors.length > 0" class="summary-section">
                    <h2>Directors</h2>
                    <ul *ngFor="let d of application.directors" class="border-layout">
                        <li>
                            <label>Name</label>
                            <span class="summary-indent">{{d.fullName.toString()}}</span>
                        </li>
                    </ul>
                </div>
                <div class="summary-section">
                    <h2>Line of Business - {{application.lineOfBusiness}}</h2>
                    <ul class="border-layout">
                        <li class="group">
                            <label>Business Outside Canada or US?</label> 
                            <span class="summary-indent">{{hasIncreasedRiskCountries ? "Yes" : "No"}}</span>
                        </li>
                        <li *ngIf="hasIncreasedRiskCountries" class="group">
                            <label>Countries</label> 
                            <span class="summary-indent">{{getIncreasedRiskCountries()}}</span>
                        </li>
                    </ul>
                    <p></p>
                    <h3 class="trade-references-header">Trade References</h3>
                    <ul *ngFor="let tradeReference of application.tradeReferences" class="border-layout trade-reference-list">
                        <li class="group">
                            <label>Name</label>
                            <span class="summary-indent">{{tradeReference.companyName}}</span>
                        </li>
                        <li class="group">
                            <label>Address</label>
                            <span class="summary-indent">{{tradeReference.address}}</span>
                        </li>
                        <li class="group">
                            <label>Contact Name</label>
                            <span class="summary-indent">{{tradeReference.contactName}}</span>
                        </li>
                        <li class="group">
                            <label>Contact Phone Number</label>
                            <span class="summary-indent">{{tradeReference.phoneNumber}}</span>
                        </li>
                        <li class="group">
                            <label>Contact Fax Number</label>
                            <span class="summary-indent">{{tradeReference.faxNumber}}</span>
                        </li>
                    </ul>
                </div>
                <div class="summary-section">
                    <h2>Financial Information</h2>
                    <div class="indent">
                        <div *ngIf="application && application.income && application.income.cropInventory && application.income.cropInventory.length > 0">
                            <h3>Crop Inventory</h3>
                            <div class="crop-inventory-grid">
                                <div class="crop-inventory-header crop">Crop</div>
                                <div class="crop-inventory-header quantity">Quantity</div>
                                <div class="crop-inventory-header quantity-unit">&nbsp;</div>
                                <div class="crop-inventory-header value">Value</div>
                            </div>
                            <div *ngFor="let crop of application.income.cropInventory" class="crop-inventory-grid">
                                <div class="crop">{{crop.name}}</div>
                                <div class="crop-inventory-detail quantity">{{crop.quantity}}</div>
                                <div class="crop-inventory-detail quantity-unit">{{crop.quantityUnitOfMeasure}}</div>
                                <div class="crop-inventory-detail value">{{crop.value | currency:'USD':'symbol':'1.2-2'}}</div>
                            </div>
                            <p></p>
                        </div>

                        <h3>Production Information</h3>
                        <ul class="production-information">
                            <li class="group">
                                <label>Acres Owned</label>
                                <span class="summary-indent">{{application.productiveAcres.acresOwned}}</span>
                            </li>
                            <li class="group bottom-space">
                                <label>Acres Rented</label>
                                <span class="summary-indent">{{application.productiveAcres.acresRented}}</span>
                            </li>
                        </ul>    

                        <label>Date of Balance Sheet (mm/dd/yyyy):</label>
                        <span class="summary-indent">{{displayDate(application.balanceSheet.creationDate)}}</span>
                        <h3>Total Assets</h3>
                        <ul class="financial-items">
                            <li class="group">
                                <label>Short Term:</label>
                                <span>{{application.balanceSheet.shortTermAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Intermediate:</label>
                                <span>{{application.balanceSheet.intermediateAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Long Term:</label>
                                <span>{{application.balanceSheet.termAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                        </ul>
                        <div class="categoryTotal group">
                            <span class="green">{{application.balanceSheet.totalAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>
                        <h3>Total Liabilities</h3>
                        <ul class="financial-items">
                            <li class="group">
                                <label>Short Term:</label>
                                <span>{{application.balanceSheet.shortTermLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Intermediate:</label>
                                <span>{{application.balanceSheet.intermediateLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Long Term:</label>
                                <span>{{application.balanceSheet.termLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                        </ul>
                        <div class="categoryTotal group">
                            <span class="red">{{application.balanceSheet.totalLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>


                        <h3>Net Worth</h3>
                        <ul class="financial-items net-worth">
                            <li class="group">
                                <label>Total Assets:</label>
                                <span>{{application.balanceSheet.totalAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Total Liabilities:</label>
                                <span>{{application.balanceSheet.totalLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                        </ul>
                        <div class="categoryTotal group">
                            <span [class.green]="application.balanceSheet.totalEquity >= 0" [class.red]="application.balanceSheet.totalEquity < 0">{{application.balanceSheet.totalEquity | currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>
                        <h3>Total Annual Sales</h3>
                        <ul class="financial-items">
                            <li class="group">
                                <label>Gross Crop:</label>
                                <span>{{application.income.grossCropIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Gross Livestock:</label>
                                <span>{{application.income.grossLivestockIncome  | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Other Farm:</label>
                                <span>{{application.income.otherFarmIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                        </ul>
                        <div class="categoryTotal group">
                            <span class="green">{{application.income.totalFarmIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>

                    </div>
                </div>
                <div class="summary-section attestations">
                    <div *ngIf="showEntity()">
                        <div class="error" *ngIf="form.submitted && ownership.invalid">
                            <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                        </div>
                        <label><input type="checkbox" name="ownership" [formControl]="ownership" [ngClass]="{error: form.submitted && !ownership.valid}" />
                            I attest that the ownership information shown above is true and accurate and that all persons with at least 25%
                            ownership &/or control in the borrower have been entered.</label>
                    </div>

                    <div>
                        <div class="error" *ngIf="form.submitted && finances.invalid">
                            <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                        </div>
                        <label><input type="checkbox" name="finances" [formControl]="finances" [ngClass]="{error: form.submitted && !finances.valid}" />
                            I certify that all financial information displayed is true and accurate.</label>
                    </div>

                    <div *ngIf="isAnonymous">
                        <div class="error" *ngIf="form.submitted && terms.invalid">
                            <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                        </div>
                        <label><input type="checkbox" name="terms" [formControl]="terms" (change)="agreementChanged($event)" [ngClass]="{error: form.submitted && !terms.valid}" />
                            I agree to the <a class="clickable" tabindex="0" (click)="showTandC = true">Online Services
                                Agreement</a>.</label>
                    </div>
                </div>
                <div class="form-group summary-section captcha" *ngIf="isAnonymous">
                    <h2>Are you a robot?</h2>
                    <rol-re-captcha (captchaResponse)="recaptchaResolved($event)"></rol-re-captcha>
                    <div class="error" *ngIf="form.submitted && recaptchaToken == ''">
                        <p>Required</p>
                    </div>
                </div>
                <div class="row summary-review-text"><em>Note: Please review the application details for completeness and accuracy before clicking the 'Finalize & Print' button. 
                    Application cannot be updated once the button is clicked.</em></div>
                <div class="buttonContainer" fxLayout.xs="column">
                    <button *ngIf="showBack" class="btn btn-secondary back-btn" type="button" (click)="back()">Back</button>
                    <button [disabled]="disableSubmit" class="btn btn-primary btn-wrap" type="submit">Finalize & Print</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="loading-modal" *ngIf="waiting">
    <!-- Modal content -->
    <div class="loading-modal-content">
        Please wait while we generate your application
        <div class="loading">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
<p-dialog styleClass="terms-dialog" resizable="false" header="Online Services Agreement" [(visible)]="showTandC">
    <rol-terms-and-conditions></rol-terms-and-conditions>
</p-dialog>