<rol-fullname-component name="fullname" [fullName]="owner.fullName"
    Title="Legal Name" [form]="form" [group]="group"
    *ngIf="!isBorrower"></rol-fullname-component>

<div class="form-group" *ngIf="isBorrower">
    <label>Legal Name</label>
    <h4>{{owner.fullName.toString()}}</h4>
</div>

<div class="form-group" *ngIf="owner.isBorrower">
    <label>Is Beneficial Owner?</label>
    <select [ngModel]="owner.isBeneficialOwner" 
        (change)="onChangeIsBeneficialOwner($event.target.value)" 
        name="isBeneficialOwner"
        #isBeneficialOwner="ngModel" required
        class="form-control">
        <option value="true">Yes</option>
        <option value="false">No</option>
    </select>
    <div *ngIf="(form.submitted || isBeneficialOwner.dirty) && !isBeneficialOwner.valid" class="error">
        Required
    </div>
</div>

<div class="form-group" *ngIf="showIsCountryOfPermanentAddressCanada">
    <label>Country of Permanent Residence is Canada?</label>
    <select [ngModel]="owner.isCanadaCountryOfPermanentResidence" 
        (change)="onChangeCanadaPermanentResidence($event.target.value)" 
        name="isCanadaCountryOfPermanentResidence" 
        #isCanadaCountryOfPermanentResidence="ngModel" required
        class="form-control">
        <option value="true">Yes</option>
        <option value="false">No</option>
    </select>
    <div *ngIf="(form.submitted || isCanadaCountryOfPermanentResidence.dirty) && !isCanadaCountryOfPermanentResidence.valid" class="error">
        Required
    </div>
</div>

<div class="form-group" *ngIf="showCountryOfPermanentResidence">
    <label>Country of Permanent Residence</label>
    <select [(ngModel)]="owner.countryOfPermanentResidence" name="countryOfPermanentResidence" class="form-control"
        #countryOfPermanentResidence="ngModel" required>
        <option *ngFor="let x of citizenshipCountries" value="{{x}}">{{x}}</option>
    </select>
    <div *ngIf="(form.submitted || countryOfPermanentResidence.dirty) && !countryOfPermanentResidence.valid" class="error">
        Required
    </div>
</div>

<div class="form-group" *ngIf="!isBorrower">
    <rol-date-box [(ngModel)]="owner.dateOfBirth" label="Date of Birth (mm/dd/yyyy)" rolDateRangeValidator
        [min]="minDob" [max]="maxDob" required name="dob" #dob="ngModel"></rol-date-box>
    <div *ngIf="(form.submitted || dob.dirty) && dob.invalid" class="error">
        <p *ngIf="dob.errors['required']">A date is required</p>
        <p *ngIf="dob.errors['invalidDate']">That is not a valid date. Format is mm/dd/yyyy.</p>
        <p *ngIf="dob.errors['precedesMin']">Please select a date after {{dob.errors['precedesMin'].toDateString()}}</p>
        <p *ngIf="dob.errors['exceedsMax']">Please select a date before {{dob.errors['exceedsMax'].toDateString()}}</p>
    </div>
</div>

<rol-physical-address-component  *ngIf="!isBorrower"
    [address]="getPhysicalAddress()" 
    name="physicalAddress"
    Title="Physical Address" 
    [form]="form" 
    [group]="group">
</rol-physical-address-component>
