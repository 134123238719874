import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import { AuthenticatedHttp } from '../services/authenticated-http.service';
import { Document } from '../models/document/document';
import { CONFIG } from '../../environments/environment';

@Injectable()
export class DocumentService {
    private resourceUrl = CONFIG.apiBaseUri + 'Document';

    constructor(private http: AuthenticatedHttp) { }

    public uploadDocuments(documents: Document[]): Promise<Document[]> {
        const url = `${this.resourceUrl}/UploadDocuments`;

        const formData = new FormData();

        documents.forEach(document => {
            formData.append('file', document.File);
            formData.append('Refno', document.MetaData.Refno);
            formData.append('PrimaryCifNumber', document.MetaData.PrimaryCifNumber);
            formData.append('DocumentType', document.MetaData.DocumentType);
            formData.append('DocumentCategory', document.MetaData.DocumentCategory);
            formData.append('DocumentDescription', document.MetaData.DocumentDescription);
            if (document.DocumentId) { formData.append('DocumentId', document.DocumentId.toString()); }
            formData.append('Name', document.Name);
        });

        return this.http
            .post(url, formData)
            .toPromise()
            .then(function (response) {
                const tempResults = Array.from(response as any[], function (value) {
                    const document = new Document(value);
                    return document;
                });
                return tempResults;
            })
            .catch(this.handleError);
    }

    private handleError(error: any) {
        const json = error;
        if (json && json.Message) {
            const msg = json.Message;
            return Promise.reject(msg);
        } else {
            console.error('An error occurred', error.message || error);
            return Promise.reject(error.message || error);
        }
    }

}
