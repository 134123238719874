export class ContactAddress {
    line1 = '';
    line2 = '';
    city = '';
    state = '';
    zipcode = '';

    constructor(appData?: any) {
        if (appData) {
            this.city = appData.City;
            this.line1 = appData.Line1;
            this.line2 = appData.Line2;
            this.state = appData.State;
            this.zipcode = appData.ZipCode;
        }
    }
}
