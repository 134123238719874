<h3>Attachments for {{getTitle()}}</h3>
<p>Document must be one of the following types:<br /> .pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .bmp</p>
<div>
    <b>Upload Attachments:</b>
    <input type="file" accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .bmp" (change)="onChange($event)"
        multiple="multiple" />
    <div class="existingDocumentRow card-body" *ngFor="let d of uploadDocuments; let i = index">
        <i class="fa fa-times clickable" (click)="cancel(i)" title="Cancel"></i>
        <div class="row">
            <div class="col-10 docName">
                <b>{{d.Name}}</b>
            </div>
        </div>
    </div>
    <div class="success" *ngFor="let message of uploadMessages">
        {{message}}
    </div>
    <div class="alert" *ngFor="let message of errorMessages">
        {{message}}
    </div>
    <span class="required" *ngIf="uploadIncomplete">{{incompleteFields}}</span>
    <div *ngIf="uploading" class="loading-dots">
        <div></div>
        <div></div>
        <div></div>
    </div>
    <button *ngIf="uploadDocuments.length > 0" class="btn btn-primary" (click)="saveAll()">Save</button>
    <button *ngIf="uploadDocuments.length > 0" class="btn btn-secondary" (click)="cancelAll()">Cancel</button>
</div>