<div class="well">
    <fieldset>
        <legend>{{Title}}</legend>

        <div class="form-group">
            <label for="firstName">First Name</label>
            <input name="firstName" class="form-control" [(ngModel)]="fullName.firstName" required
                placeholder="First Name" #firstName="ngModel" [disabled]="disabled" />
            <div *ngIf="(form.submitted || firstName.dirty) && firstName.invalid" class="error">
                <p *ngIf="firstName.errors['required']">Required</p>
            </div>
        </div>

        <div class="form-group">
            <label for="middleName">Middle Name <span class='optional'>(optional)</span></label>
            <input name="middleName" class="form-control" [(ngModel)]="fullName.middleName" placeholder="Middle Name"
                #middleName="ngModel" [disabled]="disabled" />
        </div>

        <div class="form-group">
            <label for="lastName">Last Name</label>
            <input name="lastName" class="form-control" [(ngModel)]="fullName.lastName" placeholder="Last Name"
                #lastName="ngModel" required [disabled]="disabled" />
            <div *ngIf="(form.submitted || lastName.dirty) && lastName.invalid" class="error">
                <p *ngIf="lastName.errors['required']">Required</p>
            </div>
        </div>

        <div class="form-group">
            <label for="suffix">Suffix <span class='optional'>(optional)</span></label>
            <select name="suffix" class="form-control" [(ngModel)]="fullName.suffix" [disabled]="disabled">
                <option></option>
                <option>Jr</option>
                <option>Sr</option>
                <option>I</option>
                <option>II</option>
                <option>III</option>
                <option>IV</option>
                <option>V</option>
                <option>VI</option>
            </select>
        </div>

    </fieldset>
</div>