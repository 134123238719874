<div class="well">
    <fieldset>
        <legend>
            <span class="spanTitle">
                {{Title}}
            </span>
            <span class="spanSelectExisting dropdown" *ngIf="addressList.length > 0">
                <a tabindex="0" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                    aria-expanded="false">Use existing address <span class="caret"></span></a>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let a of addressList"><a (click)="addressListSelected(a)">{{a.toString()}}</a></li>
                </ul>
            </span>
        </legend>

        <div class="form-group">
            <label for="street">Address</label>
            <input name="street" class="form-control" [formControl]="streetCtrl" [(ngModel)]="address.line1"
                placeholder="Address" (ngModelChange)="updateFn()" />
            <div *ngIf="(form.submitted || streetCtrl.dirty) && streetCtrl.invalid" class="error">
                <p *ngIf="streetCtrl.errors['required']">Required</p>
            </div>
        </div>

        <div class="form-group">
            <label for="city">City</label>
            <input name="city" class="form-control" [formControl]="cityCtrl" [(ngModel)]="address.city"
                placeholder="City" (ngModelChange)="updateFn()" />
            <div *ngIf="(form.submitted || cityCtrl.dirty) && cityCtrl.invalid" class="error">
                <p *ngIf="cityCtrl.errors['required']">Required</p>
            </div>
        </div>

        <div class="mailing-address-state-autocomplete">  
            <div class="form-group p-fluid">
                <label>State/Province</label>
                <input name="state" class="form-control" [formControl]="stateCtrl" [(ngModel)]="address.state"
                       placeholder="State/Province" (ngModelChange)="updateFn()" />
                <div *ngIf="(form.submitted || stateCtrl.dirty) && stateCtrl.invalid" class="error">
                    <p *ngIf="stateCtrl.errors['required']">Required</p>
                </div>
            </div> 
        </div>

        <div class="form-group">
            <label for="zip">Postal Code</label>
            <input name="zip" class="form-control" [formControl]="zipCtrl" [(ngModel)]="address.zipcode"
                placeholder="Postal Code" (ngModelChange)="updateZipCode()" maxlength="10" />
            <div *ngIf="(form.submitted || zipCtrl.dirty) && zipCtrl.invalid" class="error">
                <p *ngIf="zipCtrl.errors['required']">Required</p>
            </div>
        </div>

        <div class="form-group">
            <label>Country</label>
            <select [(ngModel)]="address.country" name="country" class="form-control" [formControl]="countryCtrl"
                required>
                <option *ngFor="let x of countries" value="{{x.Name}}">{{x.DisplayName}}</option>
            </select>
            <div class="error" *ngIf="(form.submitted || countryCtrl.dirty) && !countryCtrl.valid">
                Required
            </div>
        </div>
    
    </fieldset>
</div>