import { Component, Input, AfterContentInit, AfterViewInit, QueryList, ViewChildren, EventEmitter, Output } from '@angular/core';
import { Address } from '../../../models/address';
import { NgModel, FormGroup, FormControl, Validators } from '@angular/forms';
import { TopForm } from '../../top-form';
import { CurrentApplicationService } from '../../../services/current-application.service';
import { validateIsNotPOBox, validateIsNotBox } from '../../validation/is-not-po-box';

@Component({
    selector: 'rol-physical-address-component',
    templateUrl: 'physical-address.component.html',
    styles: [`
        .spanSelectExisting {
            float:right;
            font-size:12px;
        }
    `]
})
export class PhysicalAddressComponent implements AfterContentInit {

    get controlsConstructed(): boolean {
        return (this.streetCtrl != null) && (this.cityCtrl != null);
    }

    constructor(currentAppService: CurrentApplicationService) {
        currentAppService.getCurrentApplication().then(app => {
            if (app.entity) {
                this.pushAddress(app.entity.mailingAddress);
                this.pushAddress(app.entity.physicalAddress);
                app.additionalOwners.forEach(owner => {
                    this.pushAddress(owner.physicalAddress);
                });
            }
            app.borrowers.forEach(borrower => {
                this.pushAddress(borrower.mailingAddress);
                this.pushAddress(borrower.physicalAddress);
            });

        });
    }

    @Input('disabled')
    set disabled(value: boolean) {
        this.disabledVal = value;
        this.handleDisableChange(value);
    }
    get disabled(): boolean {
        return this.disabledVal;
    }
    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;
    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() allowPOBox = false;
    @Input() allowBox = false;

    @Output() addressChanged: EventEmitter<Address> = new EventEmitter<Address>();

    streetCtrl: FormControl;
    zipCtrl: FormControl;
    stateCtrl: FormControl;
    cityCtrl: FormControl;
    addressList: Address[] = [];

    @Input()
    Title: string;

    @Input()
    address: Address;

    private disabledVal: boolean;

    private pushAddress(address: Address) {
        if (address.isComplete) {
            if (!this.addressList.find(a => {
                return a.toString() === address.toString();
            })) {
                this.addressList.push(address);
            }
        }
    }

    ngAfterContentInit() {
        const streetValidators = [Validators.required];
        if (!this.allowPOBox) {
            streetValidators.push(validateIsNotPOBox());
        }
        if (!this.allowBox) {
            streetValidators.push(validateIsNotBox());
        }

        this.streetCtrl = new FormControl({ value: this.address.line1, disabled: this.disabled }, streetValidators);
        this.cityCtrl = new FormControl({ value: this.address.city, disabled: this.disabled }, Validators.required);

        const useGroup: FormGroup = this.group || this.form;
        useGroup.addControl(this.Title + 'Street', this.streetCtrl);
        useGroup.addControl(this.Title + 'city', this.cityCtrl);
    }

    handleDisableChange(disabled: boolean) {
        if (this.controlsConstructed) {
            if (disabled) {
                this.streetCtrl.disable();
                this.cityCtrl.disable();
            } else {
                this.streetCtrl.enable();
                this.cityCtrl.enable();
            }
        }
    }

    addressListSelected(address: Address) {
        this.address.city = address.city;
        this.address.line1 = address.line1;
        this.address.state = address.state;
        this.address.zipcode = address.zipcode;
        this.onChangeAddress();
    }

    serializeAddress(address: Address) {
        return JSON.stringify(address);
    }

    onChangeAddress(): void {
        this.addressChanged.emit(this.address);
    }
}
