export class ProductiveAcres {
    constructor(appData?: any) {
        if (appData) {
            this.acresOwned = appData.AcresOwned;
            this.acresRented = appData.AcresRented;
        }
    }

    acresOwned: number = null;
    acresRented: number = null;
}