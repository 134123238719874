import { SecurityQuestion } from './security-question';

export class Registree {
    isAnonymous = true;
    isExistingCustomer = false;
    firstName = '';
    lastName = '';
    email = '';
    password = '';
    confirmPassword = '';
    cifNo = 0;
    securityQuestions: SecurityQuestion[] = [];
    userId = '';
    authToken = '';
    refreshToken = '';
    authExpires: Date;
    authCreated: Date;
    accountNumber: string;
    zip: string;
    ssn: string;

    constructor(userData?: any) {
        if (userData) {
            this.isAnonymous = userData.isAnonymous;
            this.firstName = userData.firstName;
            this.lastName = userData.lastName;
            this.email = userData.email;
            this.userId = userData.userId;
            this.authToken = userData.authToken;
            this.refreshToken = userData.refreshToken;
            this.authExpires = new Date(userData.authExpires);
            this.authCreated = new Date(userData.authCreated);
        }
    }

    get displayName() {
        return this.firstName + ' ' + this.lastName;
    }
}
