import * as _ from 'lodash';

export class OutstandingLoan {
    loanType = '';
    creditor = '';
    amount = 0;
    issueMonth: number;
    issueYear: number;

    constructor(appData?: any) {
        if (appData) {
            this.loanType = _.isNil(appData.LoanType) ? '' : appData.LoanType;
            this.creditor = _.isNil(appData.Creditor) ? '' : appData.Creditor;
            this.amount = appData.Amount;
            this.issueMonth = appData.IssueMonth;
            this.issueYear = appData.IssueYear;
        }
    }
}
