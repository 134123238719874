import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { User } from '../../../models/user';
import { TopForm } from '../../../form/top-form';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';
import { ContactDetail } from '../../../models/accounts/contact-detail';

@Component({
    selector: 'rol-preferences-name',
    templateUrl: 'preferences-name.component.html',
    styleUrls: ['../account-preferences.component.css']
})
export class PreferencesNameComponent implements OnInit {
    contactInfoForm: TopForm;
    formGroup: FormGroup;
    user: User;
    contactInfoChangeComplete = false;
    updateInfoError: string;
    contactDetails: ContactDetail;
    loading: boolean;
    error: boolean;


    constructor(private userService: UserService, private authService: AuthService) {
    }

    ngOnInit() {
        this.contactInfoForm = new TopForm({
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
        });

        this.formGroup = new FormGroup({});

        this.user = new User(this.userService.GetUser());

    }

    contactInfoSubmit() {
        this.authService.UpdateUser(this.user)
            .then(res => {
                this.contactInfoChangeComplete = true;
                this.updateInfoError = null;
            })
            .catch(rejected => {
                this.updateInfoError = rejected;
            });
    }
}
