import { Component, OnInit } from '@angular/core';
import { CONFIG } from '../../../../environments/environment';

@Component({
    selector: 'rol-preferences-contact',
    templateUrl: 'preferences-contact.component.html',
    styleUrls: ['../../../../app/dashboard/preferences/account-preferences.component.css', 'preferences-contact.component.css']
})
export class PreferencesContactComponent implements OnInit {
    b2cFrameSource: string =  CONFIG.b2cEmailChangeFrame.src;
    b2cFrameHeight: string = CONFIG.b2cEmailChangeFrame.height;

    constructor() { }

    ngOnInit() {
        window.scroll(0, 0);
    }
}