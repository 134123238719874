<div class="container stage main_container">
    <h1>Password Reset</h1>
    <hr>
    <form ngFormModel="form" class="group" (ngSubmit)="submitWithQuestion()" novalidate>
        <div [formGroup]="passwordGroup">
            <div class="form-group">
                <label for="password">New Password</label>
                <input id="password" name="password" type="password" [formControl]="password" class="form-control"
                    [(ngModel)]="newPassword" placeholder="New Password" autocomplete="off" [readonly]="isInvalidUserOrTokenInfo"/>
                <div class="error" *ngIf="(form.submitted || password.dirty) && password.invalid">
                    <p *ngIf="passwordGroup.controls['password'].hasError('required')">Required</p>
                    <p *ngIf="passwordGroup.controls['password'].hasError('passwordValidation')">
                        {{passwordValidationMessage(password.errors['passwordValidation'])}}
                    </p>
                </div>
            </div>

            <div class="form-group">
                <label for="confirmPassword">Confirm Password</label>
                <input id="confirmPassword" name="confirmPassword" type="password" [formControl]="confirmPassword"
                    class="form-control" [(ngModel)]="confirmNewPassword" placeholder="Confirm Password"
                    autocomplete="off" [readonly]="isInvalidUserOrTokenInfo"/>
                <div class="error"
                    *ngIf="(form.submitted || confirmPassword.dirty) && (!confirmPassword.valid || !passwordGroup.valid)">
                    <p *ngIf="passwordGroup.controls['confirmPassword'].hasError('required')">Required</p>
                    <p *ngIf="passwordGroup.hasError('noMatch')">Passwords do not match</p>
                </div>
            </div>
        </div>
        <p *ngIf="invalidFormMessage" class="error">{{invalidFormMessage}}</p>
        <rol-security-question *ngIf="securityQuestion" [serverError]="serverError"
            [securityQuestion]="securityQuestion" [securityAnswer]="securityAnswer"
            (answerUpdated)="answerUpdated($event)"></rol-security-question>
        <div class="buttonContainer">
            <button class="btn btn-primary" type="submit">Change</button>
            <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
        </div>
    </form>
    <div style="clear:both"></div>
</div>

<rol-modal-dialog [show]="showConfirmation" (close)="showConfirmation = false">
    <div class="modal-body">
        <h1>Your password has been changed.</h1>
        <p>Click the Ok button to return to the login page.</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit" (click)="successAndReturn();">Ok</button>
    </div>
</rol-modal-dialog>