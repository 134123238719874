import { Component, Input, forwardRef, QueryList, AfterViewInit, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel, NgForm, FormGroup } from '@angular/forms';
import { TopForm } from '../../top-form';

export const ZIP_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ZipComponent),
    multi: true
};

@Component({
    selector: 'rol-zip-component',
    template: `<div class="form-group">
                <label for="zip">{{Title}}</label>
                <input name="zip" class="form-control" [(ngModel)]="zipCode" (ngModelChange)="update()" placeholder="{{Title}}" [disabled]="disabled" #zip="ngModel" [textMask]="{mask: mask, guide:false}" required pattern="^[0-9]{5}(?:-[0-9]{4})?$" maxlength="10" />
                <div class="error" *ngIf="(form.submitted || zip.dirty) && zip.invalid">
                    <p *ngIf="zip.errors['required']">{{requiredErrorMessage}}</p>
                    <p *ngIf="zip.errors['pattern']">{{Title}} is invalid</p>
                </div>
            </div>`,
    providers: [ZIP_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class ZipComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;

    @Input() form: TopForm;
    @Input() group: FormGroup;
    mask = zipMask;

    @Input()
    Title = 'ZIP Code';
    @Input()
    requiredErrorMessage = 'Required';
    @Input()
    disabled = false;

    zipCode: String;

    private onTouchedCallback: () => {};
    private onChangeCallback: (_: any) => {};

    ngAfterViewInit() {
        const useGroup: FormGroup = this.group || this.form;
        this.inputChildren.forEach((item) => {
            useGroup.addControl(this.Title, item.control);
        });
    }

    writeValue(value: any) {
        if (value !== this.zipCode) {
            this.zipCode = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    update() {
        this.onChangeCallback(this.zipCode);
    }
}

export function zipMask(inputValue) {
    let array: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/];
    if (inputValue.length > 5) {
        array = array.concat(['-', /\d/, /\d/, /\d/, /\d/]);
    }
    return array;
}
