import { ContactAddress } from './contact-address';
import { ContactPhone } from './contact-phone';
import { UserPreferences } from '../user-preferences';

export class ContactDetail {
    CifNo = '';
    MailingAddress: ContactAddress;
    PhysicalAddress: ContactAddress;
    PhoneNumbers: ContactPhone[];
    PrimaryPhoneNumber: string;
    UserPreferences: UserPreferences;
    Password: string;
    AccountsDisabled: boolean;

    constructor(data?: any) {
        if (data) {
            this.CifNo = data.CifNo;
            this.MailingAddress = data.MailingAddress ? new ContactAddress(data.MailingAddress) : null;
            this.PhysicalAddress = data.PhysicalAddress ? new ContactAddress(data.PhysicalAddress) : null;
            this.PhoneNumbers = data.PhoneNumbers ? Array.from(data.PhoneNumbers, (d) => new ContactPhone(d)) : null;
            this.PrimaryPhoneNumber = data.PrimaryPhoneNumber;
            this.UserPreferences = data.UserPreferences ? new UserPreferences(data.UserPreferences) : null;
            this.AccountsDisabled = data.AccountsDisabled;
        }
    }
}

