import { Component, OnInit, ViewEncapsulation } from '@angular/core';

// This component provides the header, footer, and other static elements to the webpage.  
// B2C content frames do not require this component.
@Component({
    selector: 'rol-base',
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class SiteComponent implements OnInit {
    constructor() { }

    ngOnInit() {
        window.scrollTo(0, 0);
    }
}
