<ul class="rc-items" display='flex' fxLayout="row wrap">
    <li *ngFor="let option of options" (click)="updateValue(option)" [class.selected]="shouldCheck('radio', option)" fxFlex.lt-md="45">
        <input type="radio" [checked]="shouldCheck('radio', option)">
        <label>{{option}}</label>
    </li>
    <li class="other" (click)="updateValue(other.value)" [class.selected]="shouldCheck('custom')">
        <input type="radio" [checked]="shouldCheck('custom')">
        <input type="text" class="form-control" placeholder="Other" [value]="initialValue"
            (keyup)="updateValue(other.value)" #other>
    </li>
</ul>