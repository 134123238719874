import { SecurityQuestion } from './security-question';

export class User {

    constructor(userData?: any) {
        if (userData) {
            this.isAnonymous = userData.isAnonymous;
            this.firstName = userData.firstName;
            this.lastName = userData.lastName;
            this.email = userData.email;
            this.userId = userData.userId;
            this.authToken = userData.authToken;
            this.refreshToken = userData.refreshToken;
            this.authExpires = new Date(userData.authExpires);
            this.authCreated = new Date(userData.authCreated);
            this.cifno = userData.cifno;
            this.registeredSystem = userData.registeredSystem;
        }
    }

    isAnonymous = true;
    firstName = '';
    lastName = '';
    email = '';
    password = '';
    confirmPassword = '';
    userId = '';
    authToken = '';
    refreshToken = '';
    authExpires: Date;
    authCreated: Date;
    securityQuestions: SecurityQuestion[];
    cifno: number;
    registeredSystem = '';

    hasGoodCreditAccounts = false;
    hasBadCreditAccounts = false;
    canApply = true;

    get displayName() {
        return this.firstName + ' ' + this.lastName;
    }
}
