<div class="container stage main_container">
    <rol-side-nav selectedAlias="income"></rol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Financial Information - Balance Sheet</h1>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-6">
            <p class="text-small">All fields are required unless otherwise noted.</p>
        </div>
    </div>

    <div>
        <div fxFlex.gt-md="60">
            <div *ngIf="application" class="category">
                <div class="summary-section">
                    <div class="inventory-panel">
                        <h2>Crop Inventory</h2>
                        <form (ngSubmit)="addCropInventoryItem()" [formGroup]="cropInventory.form" class="data-entry group" novalidate>
                            <ul *ngIf="cropInventory.current">
                                <li class="group">
                                    <label>Crop <rol-help-tip>Select an option from the list or type in a crop if it is not
                                            listed.</rol-help-tip></label>
                                    <div class="autoCompleteWrapper">
                                        <rol-autocomplete DisplayTitle="Crop" [itemFactory]="cropFactory" [maxSize]="4"
                                            [form]="cropInventory.form" [group]="cropInventory.form" [value]="cropInventory.cropName"
                                            [update]="cropInventory.setCropName()" [showErrors]="false"></rol-autocomplete>
                                        <span *ngIf="cropInventory.form.submitted && !cropInventory.cropName" class="error">A crop is
                                            required</span>
                                    </div>
                                </li>
                                <li class="data-entry-yield group">
                                    <label>Quantity</label>
                                    <input type="text" formControlName="quantity" class="form-control"
                                        [ngModel]="cropInventory.current.quantity"
                                        (ngModelChange)="cropInventory.current.quantity = toNumber($event)" validate-number
                                        [textMask]="{mask: numbersOnlyMask, guide:false}">
                                    <span *ngIf="cropInventory.form.submitted && !cropInventory.current.quantity" class="error">
                                        Quantity is required
                                    </span>
                                    <div class="autoCompleteWrapper units">
                                        <rol-autocomplete DisplayTitle="Units" [itemFactory]="yieldUnitsFactory"
                                            [maxSize]="4" [form]="cropInventory.form" [group]="cropInventory.form"
                                            [value]="cropInventory.cropQuantityUnitOfMeasure" [update]="cropInventory.setQuantityUnitOfMeasure()"
                                            [showErrors]="false"></rol-autocomplete>
                                    </div>
                                    <span *ngIf="cropInventory.form.submitted && !cropInventory.cropQuantityUnitOfMeasure" class="error">Quantity units
                                        are required</span>
                                </li>
                                <li class="group">
                                    <label>Value</label>
                                    <div class="autoCompleteWrapper">
                                        <rol-number-input formControlName="value" [(ngModel)]="cropInventory.current.value"
                                            #value>
                                        </rol-number-input>
                                        <span *ngIf="cropInventory.form.submitted && !cropInventory.current.value" class="error">Value is
                                            required</span>
                                    </div>
                                </li>
                            </ul>
                            <button class="btn btn-primary">{{cropInventory.editingCropInventory ? 'Update' : 'Add'}}</button>
                        </form>
                    </div>
                    <ul *ngIf="application && application.income" class="inventory-table">
                        <li class="header group">
                            <label>Crop</label>
                            <label>Value</label>
                            <label>&nbsp;</label>
                            <label>Quantity</label>
                        </li>
                        <li *ngIf="application.income.cropInventory && application.income.cropInventory.length === 0"
                            class="empty">No inventory added</li>
                        <li *ngFor="let crop of application.income.cropInventory" (click)="cropInventoryUpdate(crop)"
                            class="group" title="Click to edit">
                            <label><i class="fa fa-trash" (click)="cropInventoryRemove(crop)" title="Click to delete"></i><i
                                    class="fa fa-pencil"></i>{{crop.name}}</label>
                            <label>{{crop.value | currency:'USD':'symbol':'1.2-2'}}</label>
                            <label>&nbsp;{{crop.quantityUnitOfMeasure}}</label>
                            <label>{{crop.quantity}} </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="spacer">
        <div fxFlex.gt-md="60">
            <form *ngIf="application" ngFormModel="form" (ngSubmit)="submit()" class="summary-section" novalidate>
                <div class="date-input-wrapper">
                    <mat-form-field>
                        <input matInput [formControl]="creationDate" (dateChange)="checkDateAge()"
                            [(ngModel)]="application.balanceSheet.creationDate" [matDatepicker]="picker"
                            placeholder="Date of Balance Sheet (mm/dd/yyyy)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker name="creationDate" #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="form.submitted && !application.balanceSheet.creationDate && application.balanceSheet.totalEquity > 0"
                    class="error ng-invalid">Your balance sheet requires a date.</div>
                <div *ngIf="form.submitted && minDateError" class="error ng-invalid">The date must be more recent than
                    {{minDate | date}}.</div>
                <div *ngIf="form.submitted && maxDateError" class="error ng-invalid">You may not select a date in the
                    future.</div>
                <div class="category">
                    <h2>Total Assets</h2>
                    <ul class="financial-items">
                        <li class="group"
                            [ngClass]="{error: !currentAssets.valid && (currentAssets.dirty || form.submitted)}">
                            <label>Short Term:</label>
                            <div>
                                <rol-number-input name="currentAssets"
                                    [(ngModel)]="application.balanceSheet.shortTermAssets"
                                    [formControl]="currentAssets">
                                </rol-number-input>
                                <span *ngIf="form.submitted && financialValidation.hasNoValueError(currentAssets)"
                                    class="error ng-invalid">An amount is required</span>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(currentAssets)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group"
                            [ngClass]="{error: !intermediateAssets.valid && (intermediateAssets.dirty || form.submitted)}">
                            <label>Intermediate:</label>
                            <div>
                                <rol-number-input name="intermediateAssets"
                                    [(ngModel)]="application.balanceSheet.intermediateAssets"
                                    [formControl]="intermediateAssets">
                                </rol-number-input>
                                <span *ngIf="form.submitted && financialValidation.hasNoValueError(intermediateAssets)"
                                    class="error ng-invalid">An amount is required</span>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(intermediateAssets)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group"
                            [ngClass]="{error: !termAssets.valid && (termAssets.dirty || form.submitted)}">
                            <label>Long Term:</label>
                            <div>
                                <rol-number-input name="termAssets" [(ngModel)]="application.balanceSheet.termAssets"
                                    [formControl]="termAssets"></rol-number-input>
                                <span *ngIf="form.submitted && financialValidation.hasNoValueError(termAssets)"
                                    class="error ng-invalid">An amount is required</span>
                                <span *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(termAssets)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="total group">
                        <span class="green"
                            [class.error]="totalAssetsInvalid">{{application.balanceSheet.totalAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                    </div>
                    <span *ngIf="totalAssetsInvalid" class="error">Amount must be less than
                        {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                </div>

                <div class="category">
                    <h2>Total Liabilities</h2>
                    <ul class="financial-items">
                        <li class="group"
                            [ngClass]="{error: !currentLiabilities.valid && (currentLiabilities.dirty || form.submitted)}">
                            <label>Short Term:</label>
                            <div>
                                <rol-number-input name="currentLiabilities"
                                    [(ngModel)]="application.balanceSheet.shortTermLiabilities"
                                    [formControl]="currentLiabilities">
                                </rol-number-input>
                                <span *ngIf="form.submitted && financialValidation.hasNoValueError(currentLiabilities)"
                                    class="error ng-invalid">An amount is required</span>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(currentLiabilities)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group"
                            [ngClass]="{error: !intermediateLiabilities.valid && (intermediateLiabilities.dirty || form.submitted)}">
                            <label>Intermediate:</label>
                            <div>
                                <rol-number-input name="intermediateLiabilities"
                                    [(ngModel)]="application.balanceSheet.intermediateLiabilities"
                                    [formControl]="intermediateLiabilities">
                                </rol-number-input>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasNoValueError(intermediateLiabilities)"
                                    class="error ng-invalid">An amount is required</span>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(intermediateLiabilities)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group"
                            [ngClass]="{error: !termLiabilities.valid && (termLiabilities.dirty || form.submitted)}">
                            <label>Long Term:</label>
                            <div>
                                <rol-number-input name="termLiabilities"
                                    [(ngModel)]="application.balanceSheet.termLiabilities"
                                    [formControl]="termLiabilities">
                                </rol-number-input>
                                <span *ngIf="form.submitted && financialValidation.hasNoValueError(termLiabilities)"
                                    class="error ng-invalid">An amount is required</span>
                                <span
                                    *ngIf="form.submitted && financialValidation.hasOutOfRangePriceError(termLiabilities)"
                                    class="error ng-invalid">Amount must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="total group">
                        <span
                            class="red">{{application.balanceSheet.totalLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                    </div>
                    <span *ngIf="totalLiabilitiesInvalid" class="error ng-invalid">Amount must be less than
                        {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                </div>
                <div class="category read-only">
                    <h2>Net Worth</h2>
                    <ul class="financial-items net-worth">
                        <li class="group">
                            <label>Total Assets:</label>
                            <div><span>{{application.balanceSheet.totalAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group">
                            <label>Total Liabilities:</label>
                            <div>
                                <span>{{application.balanceSheet.totalLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="total group">
                        <span [class.green]="application.balanceSheet.totalEquity >= 0"
                            [class.red]="application.balanceSheet.totalEquity < 0">{{application.balanceSheet.totalEquity | currency:'USD':'symbol':'1.2-2'}}</span>
                    </div>
                </div>
                <div class="category read-only">
                    <h2>Total Income</h2>
                    <ul class="financial-items">
                        <li class="group">
                            <label>Gross Crop:</label>
                            <div><span>{{application.income.grossCropIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group">
                            <label>Gross Livestock:</label>
                            <div>
                                <span>{{application.income.grossLivestockIncome  | currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </li>
                        <li class="group">
                            <label>Other Farm:</label>
                            <div><span>{{application.income.otherFarmIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="total group">
                        <span
                            class="green">{{application.income.totalFarmIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                        <span *ngIf="totalFarmIncomeInvalid" class="error ng-invalid">Amount must be less than
                            {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                    </div>
                </div>

                <div class="row warning-text">
                    <strong><em>Please complete the above Financial Information - Balance sheet if applying for over $250,000</em></strong>
                </div>

                <div class="buttonContainer group" fxLayoutAlign="space-evenly">
                    <button *ngIf="showBack" class="btn btn-secondary back-btn" type="button"
                        (click)="back()"fxFlex.lt-md="40">Back</button>
                    <button type="submit" class="btn btn-primary" fxFlex.lt-md="40">Continue</button>
                </div>
            </form>
        </div>
    </div>
    <div style="clear:both"></div>
</div>