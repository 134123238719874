<div *ngIf="!success && !submitted" class="raised-card">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <p>Please enter the information of the person you would like to add as an authorized party. Upon submission you
            will be redirected to DocuSign to endorse the document. The authorized party will also receive a message
            from DocuSign so that they can endorse the document.</p>
        <div class="form-group">
            <label for="firstname">First Name</label>
            <input type="text" class="form-control" formControlName="firstname"
                [(ngModel)]="authorizedPartyReq.FirstName" />
            <div class="error" *ngIf="isFormControlInvalid('firstname')">
                Required
            </div>
        </div>
        <div class="form-group">
            <label for="middlename">Middle Name</label>
            <input type="text" class="form-control" formControlName="middlename"
                [(ngModel)]="authorizedPartyReq.MiddleName" />
        </div>
        <div class="form-group">
            <label for="lastname">Last Name</label>
            <input type="text" class="form-control" formControlName="lastname"
                [(ngModel)]="authorizedPartyReq.LastName" />
            <div class="error" *ngIf="isFormControlInvalid('lastname')">
                Required
            </div>
        </div>
        <div class="form-group">
            <label for="suffix">Suffix <span class='optional'>(optional)</span></label>
            <select name="suffix" class="form-control" [(ngModel)]="authorizedPartyReq.Suffix" formControlName="suffix">
                <option></option>
                <option>Jr</option>
                <option>Sr</option>
                <option>I</option>
                <option>II</option>
                <option>III</option>
                <option>IV</option>
                <option>V</option>
                <option>VI</option>
            </select>
        </div>
        <div class="form-group">
            <rol-date-box [(ngModel)]="authorizedPartyReq.DOB" label="Date of Birth" formControlName="dob"
                [disabled]="form.disabled"></rol-date-box>
            <div class="error" *ngIf="isFormControlInvalid('dob')">
                Invalid date
            </div>
        </div>
        <div class="form-group">
            <label for="ssn">Social Security Number</label>
            <input type="text" class="form-control" formControlName="ssn" [(ngModel)]="authorizedPartyReq.SSN"
                [textMask]="{mask: ssnMask, guide:false}" />
            <div class="error" *ngIf="isFormControlInvalid('ssn')">
                Invalid SSN
            </div>
        </div>
        <div class="form-group">
            <label for="streetaddress">Physical Address</label>
            <input type="text" class="form-control" formControlName="streetaddress"
                [(ngModel)]="authorizedPartyReq.StreetAddress" />
            <div class="error" *ngIf="physicalAddressRequiredInvalid">
                Required
            </div>
            <div class="error" *ngIf="physicalAddressIsPOBoxInvalid">
                Cannot be a PO box
            </div>
        </div>
        <div class="form-group">
            <label for="city">City</label>
            <input type="text" class="form-control" formControlName="city" [(ngModel)]="authorizedPartyReq.City" />
            <div class="error" *ngIf="isFormControlInvalid('city')">
                Required
            </div>
        </div>
        <div class="form-group">
            <rol-state-select [Title]="'State'" [(ngModel)]="authorizedPartyReq.StateAbbreviated" [form]="form"
                [ngModelOptions]="{standalone: true}"></rol-state-select>
        </div>
        <div class="form-group">
            <rol-zip-component [form]="form" [(ngModel)]="authorizedPartyReq.ZipCode"
                [ngModelOptions]="{standalone: true}">
            </rol-zip-component>
        </div>
        <div class="form-group">
            <label for="email">Email</label>
            <input type="text" [(ngModel)]="authorizedPartyReq.Email" class="form-control" formControlName="email" />
            <div class="error" *ngIf="isFormControlInvalid('email')">
                Required
            </div>
        </div>
        <div class="form-group">
            <label for="uscitizen">US Citizen</label>
            <select [(ngModel)]="authorizedPartyReq.USCitizen" class="form-control" formControlName="uscitizen">
                <option value="true">Yes</option>
                <option value="false">No</option>
            </select>
        </div>
        <div class="form-group" *ngIf="nonUSCitizenFieldsRequired">
            <label for="permanentresident">Permanent Resident</label>
            <select [(ngModel)]="authorizedPartyReq.PermanentResident" class="form-control"
                formControlName="permanentresident">
                <option value="true">Yes</option>
                <option value="false">No</option>
            </select>
            <div class="error" *ngIf="isFormControlInvalid('permanentresident')">
                Required
            </div>
        </div>
        <div class="form-group" *ngIf="nonUSCitizenFieldsRequired">
            <label for="countryofcitizenship">Country of Citizenship</label>
            <select [(ngModel)]="authorizedPartyReq.CountryOfCitizenship" formControlName="countryofcitizenship"
                class="form-control" required>
                <option *ngFor="let x of citizenshipCountries" value="{{x}}">{{x}}</option>
            </select>
            <div class="error" *ngIf="isFormControlInvalid('countryofcitizenship')">
                Required
            </div>
        </div>
        <button type="submit" class="btn btn-primary bottom raised" [disabled]="form.disabled">Submit</button>
        <div class="clear"></div>
    </form>
</div>
<div class="loading-modal" *ngIf="waitingOnDocuSign">
    <!-- Modal content -->
    <div class="loading-modal-content">
        Please wait while we contact DocuSign
        <div class="loading">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>