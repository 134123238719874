import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import * as Bowser from 'bowser';

import { TextRetrieverService } from './services/text-retriever.service';
import { UtilitiesService } from './services/utilities.service';
import { UserService } from './services/user.service';
import { CurrentApplicationService } from './services/current-application.service';
import { ReCaptchaService } from './services/recaptcha.service';
import { HistoryService } from './services/history.service';

@Component({
    selector: 'rol-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [TextRetrieverService, UtilitiesService, ReCaptchaService, HistoryService],
})
export class AppComponent implements OnInit {
    showBowserAlert: boolean;

    constructor(userService: UserService, currentApp: CurrentApplicationService) {
        userService.CurrentAppService = currentApp;
    }

    ngOnInit() {
        this.browserCheck();
    }

    browserCheck() {
        const browser = Bowser.getParser(window.navigator.userAgent);

        this.showBowserAlert = !browser.satisfies({
            chrome: '>=87',
            firefox: '>=80',
            edge: '>=88',
            ios_saf: '>=12',
            and_chr: '>=87',
            and_ff: '>=80'
        });
    }
}
