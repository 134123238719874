import * as _ from 'lodash';
import { OutstandingLoan } from './outstanding-loan';

export class AdditionalFinancialInfo {
    hasOutstandingLoans = false;
    outstandingLoans: OutstandingLoan[] = [];
    hasMediation = false;
    mediationMonth: number;
    mediationYear: number;
    hasCropInsurance = false;
    cropInsuredAcres: number;
    hasHailInsurance = false;
    hailInsuredAcres: number;
    hasOtherIncome = false;
    otherIncomeAmount: number;
    otherIncomeSource: string;

    constructor(appData?: any) {
        if (appData) {
            this.hasOutstandingLoans = appData.HasOutstandingLoans;
            this.outstandingLoans = appData.OutstandingLoans
                ? Array.from(appData.OutstandingLoans, (data) => new OutstandingLoan(data)) : [];
            this.hasMediation = appData.HasMediation;
            this.mediationMonth = appData.MediationMonth;
            this.mediationYear = appData.MediationYear;
            this.hasCropInsurance = appData.HasCropInsurance;
            this.cropInsuredAcres = appData.CropInsuredAcres;
            this.hasHailInsurance = appData.HasHailInsurance;
            this.hailInsuredAcres = appData.HailInsuredAcres;
            this.hasOtherIncome = appData.HasOtherIncome;
            this.otherIncomeAmount = appData.OtherIncomeAmount;
            this.otherIncomeSource = _.isNil(appData.OtherIncomeSource) ? '' : appData.OtherIncomeSource;
        }
    }
}
