import { Person } from './person';
import { Entity } from './entity';
import { BalanceSheet } from './balance-sheet';
import { Income } from './income';
import { IApplicationHttpService } from '../services/application-http.service';
import { Vendor } from './vendor';
import { IUserService } from '../services/user.service';
import { ApplicationType } from './application-type';
import { IAuthService } from '../services/auth.service';
import { ProductiveAcres } from './productive-acres';
import { TradeReference } from './trade-reference';
import { RichardsonOnline } from './constants';
import { Director } from './director';

export class Application {
    id: any;
    appType: ApplicationType = ApplicationType.Single;
    additionalOwners: Person[] = [];
    borrowers: Person[] = [];
    balanceSheet: BalanceSheet = new BalanceSheet;
    vendors: Vendor[] = [new Vendor()];
    income: Income = new Income;
    entity: Entity = null;
    status = 'Draft';
    currentPage = '';
    lineOfBusiness = '';
    createdDate: Date;
    onlineAgreementAcceptanceDate: Date;
    preApprovalStagingId: number;
    preApprovalBorrower: Person;
    preApprovalVendor: Vendor;
    hasEntityOwnership = false;
    increasedRiskCountries: string[];
    productiveAcres: ProductiveAcres = new ProductiveAcres;
    tradeReferences: TradeReference[] = [new TradeReference()];
    originatingSite = RichardsonOnline;
    directors: Director[] = [];
    hasIntermediaryOwner = false;
    hasTrust = false;
    hasAdditionalSignatoryVerification = false;
    hasPrimaryApplicantConfirmation = false;

    constructor(
        private appHttpService: IApplicationHttpService,
        private userService: IUserService,
        private authService: IAuthService,
        appData?: any) {
        if (appData) {
            // populate with existing data model from api call.
            this.id = appData.ID;
            this.status = appData.Status;
            this.appType = appData.AppType;
            this.borrowers = Array.from(appData.Borrowers, (data) => new Person(data));
            this.additionalOwners = Array.from(appData.AdditionalOwners, (data) => new Person(data));
            this.vendors = Array.from(appData.Vendors, (data) => new Vendor(data));
            this.entity = appData.Entity ? new Entity(appData.Entity) : null;
            this.balanceSheet = new BalanceSheet(appData.BalanceSheet);
            this.income = new Income(appData.Income);
            this.lineOfBusiness = appData.LineOfBusiness;
            this.createdDate = new Date(appData.CreatedDate);
            this.currentPage = appData.CurrentPage;
            this.onlineAgreementAcceptanceDate = appData.OnlineAgreementAcceptanceDate;
            this.preApprovalStagingId = appData.preApprovalCode;
            this.hasEntityOwnership = appData.HasEntityOwnership;
            this.increasedRiskCountries = appData.IncreasedRiskCountries;
            this.productiveAcres = new ProductiveAcres(appData.ProductiveAcres);
            this.tradeReferences = Array.from(appData.TradeReferences, (data) => new TradeReference(data));
            this.originatingSite = appData.OriginatingSite;
            this.directors = appData.Directors
                ? Array.from(appData.Directors, (data) => new Director(data))
                : [];
            this.hasIntermediaryOwner = appData.HasIntermediaryOwner;
            this.hasTrust = appData.HasTrust;
            this.hasAdditionalSignatoryVerification = appData.HasAdditionalSignatoryVerification;
            this.hasPrimaryApplicantConfirmation = appData.HasPrimaryApplicantConfirmation
                ? appData.HasPrimaryApplicantConfirmation : false;

            if (this.entity) {
                if (this.entity.signer) {
                    this.entity.signer.isBorrower = this.beneficialOwnerIsBorrower(this.entity.signer);
                }
            }

            this.borrowers.forEach(o => o.isBorrower = true);

            this.additionalOwners.forEach(o => {
                o.isBorrower = false;
                o.isBeneficialOwner = true;
            });
        }
    }

    private beneficialOwnerIsBorrower(person: Person): boolean {
        return !!this.borrowers.find((borrower) => {
            return borrower.email === person.email && borrower.fullName.toString() === person.fullName.toString();
        });
    }

    save() {
        if (this.appType !== ApplicationType.Single) {
            this.entity.ultimateBeneficialOwners = this.ownersList;
        }

        if (!this.userService.GetUser()) {
            return new Promise<number>((resolve, reject) => {
                this.authService.CreateAnonymousUser()
                    .then(() => this.callHttpSave())
                    .then(resolve)
                    .catch((reason) => {
                        reject(new Error('Could not create anonymous user' + reason));
                    });
            });
        } else {
            return this.callHttpSave();
        }
    }


    private callHttpSave() {
        return new Promise<number>((resolve, reject) => {
            this.appHttpService.save(this)
                .then((data: any) => {
                    this.id = data.Id;
                    this.createdDate = new Date(data.CreatedDate);
                    resolve(this.id);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    }

    submit(recaptchaToken: string) {
        return new Promise<string>((resolve, reject) => {
            this.save()
                .then(() => this.appHttpService.submit(this, recaptchaToken))
                .then((url) => {
                    resolve(url);
                })
                .catch((reason) => reject(reason));
        });
    }

    get ownersList(): Person[] {
        return this.borrowers.concat(this.additionalOwners);
    }

    totalCreditLimit() {
        const total = this.vendors.reduce((a, b) => a + b.creditLimit, 0);
        return total;
    }

    print() {
        return new Promise<Blob>((resolve, reject) => {
            this.appHttpService.print(this)
                .then((blob) => {
                    let navigator = window.navigator as any;
                    if (navigator && navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(blob, 'Rabo AgriFinance New Application.pdf');
                    } else {
                        const fileURL = URL.createObjectURL(blob);
                        window.open(fileURL, '_blank');
                    }
                    resolve(blob);
                })
                .catch((reason) => reject(reason));
        });
    }

    setStatus(applicationStatus: string) {
        this.status = applicationStatus;
        return new Promise<any>((resolve, reject) => {
            this.appHttpService.setStatus(this.id, applicationStatus)
            .then((_) => {
                resolve(this.id);
            })
            .catch((reason) => reject(reason));
        });
    }
}
