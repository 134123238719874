import { Customer } from './customer';
import { SubCommitment } from './sub-commitment';

export class Commitment {
    AcctRefNo: string;
    LoanNumber: string;
    AccountHolder: Customer;
    Relationships: Customer[];
    OwnershipRelationships: Customer[];
    Dealers: string[];
    CreditLimit: number;
    Balance: number;
    MaturityDate: Date;
    SubCommitments: SubCommitment[];
    PaymentHistory: string;
    StatusCode: string;
    HasPendingPayment: boolean;

    constructor(data?: any) {
        if (data) {
            this.AcctRefNo = data.AcctRefNo;
            this.LoanNumber = data.LoanNumber;
            this.AccountHolder = data.AccountHolder ? new Customer(data.AccountHolder) : null;
            this.Relationships = data.Relationships ? Array.from(data.Relationships, (d) => new Customer(d)) : null;
            this.OwnershipRelationships = data.OwnershipRelationships ?
                Array.from(data.OwnershipRelationships, (d) => new Customer(d)) : null;
            this.Dealers = data.Dealers ? data.Dealers : null;
            this.CreditLimit = data.CreditLimit ? data.CreditLimit : 0.00;
            this.Balance = data.Balance ? data.Balance : 0.00;
            this.MaturityDate = data.MaturityDate ? data.MaturityDate : null;
            this.SubCommitments = data.SubCommitments ? Array.from(data.SubCommitments, (d) => new SubCommitment(d)) : null;
            this.PaymentHistory = data.PaymentHistory;
            this.StatusCode = data.StatusCode;
            this.HasPendingPayment = data.HasPendingPayment;
        }
    }

    get IsPastDue(): boolean {
        return !!this.SubCommitments.find(sc => sc.IsPastDue);
    }

    get IsDelinquent(): boolean {
        return this.IsPastDue && !this.HasLegalAction;
    }

    get HasLegalAction(): boolean {
        return this.StatusCode.includes('Legal Action');
    }

    get HasCreditHold(): boolean {
        return this.StatusCode.includes('Credit Hold');
    }

    get HasExtension(): boolean {
        return this.StatusCode.includes('Extens');
    }

    get HasBankruptcy(): boolean {
        return this.StatusCode.includes('Bankruptcy');
    }

    get HasCollections(): boolean {
        return this.StatusCode.includes('Collections');
    }

    getTotalLoanAmount(): number {
        let totalLoanAmount = 0;
        this.SubCommitments.forEach(element => {
            totalLoanAmount += element.CreditLimit;
        });
        return totalLoanAmount;
    }

    get IsHelenaAccount(): boolean {
        return this.LoanNumber.includes('HF');
    }

    get HasBalance(): boolean {
        let balance = false;

        this.SubCommitments.forEach(sub => {
            sub.Loans.forEach(loan => {
                if (loan.Balance > 0) {
                    balance = true;
                }
            });
        });

        return balance;
    }

    get DealerNames(): string[] {
        if (this.Dealers && this.Dealers.length > 0) {
            return this.Dealers;
        }

        return this.SubCommitments.map(sc => {
            return sc.Dealer.Name;
        });
    }

    get UpcomingPaymentRequired(): boolean {
        return this.SubCommitments.map(sc => {
            return sc.UpcomingPaymentRequired;
        })
            .reduce((prev, curr) => prev || curr, false);
    }

    get DisplayCreditLimit(): number {
        if (this.StatusCode != '' || this.IsDelinquent) {
            return 0;
        }

        return this.CreditLimit;
    }
}
