import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { ApplicationType } from '../../models/application-type';
import { Person } from '../../models/person';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';
import { validateEmailFactory } from '../validation/email-validator.directive';
import { OrganizationType } from '../../models/organization-type';

@Component({
    selector: 'rol-ownership',
    templateUrl: 'ownership.component.html',
    styleUrls: ['../styles_form.css', 'ownership.component.css'],
})
export class OwnershipComponent extends AbstractFormPage implements OnInit {
    signerSelect = null;
    signerGroup: FormGroup = new FormGroup({});

    isOpen: boolean[];
    accordionMap: number[];
    formGroups: FormGroup[];
    form: TopForm;
    intermediaryOwnerControl: FormControl;
    trustControl: FormControl;
    additionalSignatoryControl: FormControl;
    directorRequiredError: boolean;

    // Split the borrowers into two lists in order to show "Primary Applicant Co-Borrower" seprately from "Joint Applicant Co-Borrower"
    get primaryApplicants(): Person[] {
        return this.application.borrowers.slice(0, 1);
    }

    // Split the borrowers into two lists in order to show "Primary Applicant Co-Borrower" seprately from "Joint Applicant Co-Borrower"
    get jointApplicants(): Person[] {
        return this.application.borrowers.length > 1 ? this.application.borrowers.slice(1) : [];
    }

    get beneficialOwners(): Person[] {
        return this.application.additionalOwners;
    }

    get isPartnershipApplication(): boolean {
        return this.application.appType === ApplicationType.CoBorrower;
    }

    checkDirectorRequiredError() {
        this.directorRequiredError = this.application.entity.entityType === OrganizationType.Corporation
            && this.application.directors.length === 0;        
    }

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService) {
        super(currentAppSvc, router, utilities, history);
        this.pageAlias = 'ownership';
        this.isOpen = [];
        this.formGroups = [];
        this.accordionMap = [];
        this.initPage();
    }

    ngOnInit() { }

    afterInitPage() {
        this.form = new TopForm();
        for (let i = 0; i < this.application.ownersList.length; ++i) {
            this.isOpen.push(false);
            this.accordionMap.push(i);
            const group = new FormGroup({});
            this.formGroups.push(group);
            this.form.addControl(this.accordionMap.length.toString(), group);
        }

        // Check for signer
        if (this.application.entity.signer) {
            // Signer could be borrower/owner
            const match = this.application.ownersList.findIndex((o) => {
                return (o.fullName.toString() === this.application.entity.signer.fullName.toString() &&
                    o.email === this.application.entity.signer.email &&
                    o.ssn === this.application.entity.signer.ssn);
            });
            if (match !== -1) {
                this.signerSelect = match;
            } else {
                this.signerSelect = null;
            }

            // Set up validation
            this.refreshSignerGroupControls();
        }

        this.intermediaryOwnerControl = new FormControl(this.application.hasIntermediaryOwner);
        this.trustControl = new FormControl(this.application.hasTrust);
        this.additionalSignatoryControl = new FormControl(this.application.hasAdditionalSignatoryVerification);

        this.intermediaryOwnerControl.valueChanges.subscribe({
            next: (value: boolean) => this.application.hasIntermediaryOwner = value
        });
        this.trustControl.valueChanges.subscribe({
            next: (value: boolean) => this.application.hasTrust = value
        });
        this.additionalSignatoryControl.valueChanges.subscribe({
            next: (value: boolean) => this.application.hasAdditionalSignatoryVerification = value
        });

        this.form.addControl('intermediaryOwnerControl', this.intermediaryOwnerControl);
        this.form.addControl('trustControl', this.trustControl);
        this.form.addControl('additionalSignatoryControl', this.additionalSignatoryControl);
        this.checkDirectorRequiredError();
        
    }

    updateSelect(index) {
        this.signerSelect = +index;

        // Reset for validation
        this.form.submitted = false;

        this.saveSignerOnApplication();
        this.refreshSignerGroupControls();
    }

    private saveSignerOnApplication(): void {
        if (this.signerSelect !== null && this.signerSelect !== undefined) {
            this.application.entity.signer = this.application.entity.controllingParties[this.signerSelect];
            this.application.entity.signerTitle = this.application.entity.signer.signerTitle;
        }
    }

    refreshSignerGroupControls() {
        if (this.form.controls['signerGroup']) {
            this.form.removeControl('signerGroup');
        }

        this.signerGroup = new FormGroup({});
        if (this.application.entity.signer) {
            if (!this.application.entity.signer.isBorrower) {
                if (!this.signerGroup.controls['signerEmail']) {
                    this.signerGroup.addControl('signerEmail', new FormControl('', [Validators.required, validateEmailFactory()]));
                }
            }
        }
        this.form.addControl('signerGroup', this.signerGroup);
    }

    add() {
        this.form.submitted = false;
        const p = new Person();
        p.isCanadianCitizen = false;
        p.isBorrower = false;
        p.isBeneficialOwner = true;
        this.application.additionalOwners.push(p);
        this.closeAllTabs();
        this.accordionMap.push(this.isOpen.length);
        this.isOpen.push(true);
        const group = new FormGroup({});
        this.formGroups.push(group);
        this.form.addControl(this.accordionMap.length.toString(), group);
        window.scrollTo(0, 0);
    }

    delete(index: number, event) {
        const additionalOwnersIndex = index - this.application.borrowers.length;
        this.application.additionalOwners.splice(additionalOwnersIndex, 1);
        this.isOpen.splice(index, 1);
        this.formGroups.splice(index, 1);
        let found = null;
        this.accordionMap = this.accordionMap.map((mapping, idx) => {
            if (mapping === index) {

                // If signer is deleted, clear signer details
                if (index === this.signerSelect) {
                    this.signerSelect = null;
                    this.application.entity.signer = null;
                    this.application.entity.signerTitle = '';
                }

                found = true;
                this.form.removeControl((idx + 1).toString());
                return -1;
            } else {
                return found ? mapping - 1 : mapping;
            }
        });
        event.preventDefault();
    }

    tabOpen(e, offset) {
        for (let i = 0; i < this.isOpen.length; ++i) {
            this.isOpen[i] = i === e.index + offset;
        }
    }

    tabClose(e) {
        this.isOpen[this.accordionMap[e.index]] = false;
    }

    private closeAllTabs() {
        for (let i = 0; i < this.isOpen.length; ++i) {
            this.isOpen[i] = false;
        }
    }

    hasNonWhitespace(s) {
        return /[^\s]/g.test(s);
    }

    showDisplayName(owner: Person): boolean {
        return this.hasNonWhitespace(owner.fullName);
    }

    submit() {
        this.form.updateValueAndValidity();
        this.form.submitted = true;
        this.saveSignerOnApplication();

        this.checkDirectorRequiredError();

        if (this.form.valid && !this.directorRequiredError) {
            this.next();
        } else {
            this.scrollToError();
        }
    }

    calcJointApplicantIndex(index) {
        return index + 1;
    }

    calcBeneficialIndex(index) {
        return index + this.application.borrowers.length;
    }
}
