import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IfAuthService } from 'if-angular-security';
import { IRedirectRequestParams } from 'if-angular-security/lib/models/redirect-request-params.interface';
import { StringDict } from 'if-angular-security/lib/models/string-dict.type';
import { CONFIG } from '../../../../environments/environment';
import { RichardsonOnlineWebsiteName } from "../../../../app/models/constants";

@Component({
    selector: 'rol-forgot-password-login',
    templateUrl: './forgot-password-login.component.html',
    styleUrls: ['./forgot-password-login.component.css']
})
export class ForgotPasswordLoginComponent implements OnInit {
    constructor(private sanitizer: DomSanitizer,
        private ifAuthSecurityService: IfAuthService) { }

    ngOnInit() {
        const queryParams: StringDict = {};
        queryParams['app'] = RichardsonOnlineWebsiteName;

        const redirectRequest: IRedirectRequestParams = {
            policy : CONFIG.b2cPasswordResetFrame.policy,
            redirectUri: CONFIG.b2cPasswordResetFrame.redirectUri,
            extraQueryParameters: queryParams
        }
      
        this.ifAuthSecurityService.loginRedirect(redirectRequest);
    }
}