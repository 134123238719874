import { Component, OnInit, AfterViewInit, QueryList, ViewChildren, Input, ChangeDetectorRef } from '@angular/core';
import { Application } from '../../models/application';
import { TopForm } from '../top-form';
import { NgModel, FormControl, FormGroup, Validators } from '@angular/forms';
import { validateNumberFactory } from '../validation/number-validator.directive';
import { UtilitiesService } from '../../services/utilities.service';
import { VendorService } from '../../services/vendor.service';
import { Vendor } from '../../models/vendor';
import { totalIncomeMaxNLS } from '../financial-validation';
import numberMask from 'text-mask-addons/dist/createNumberMask';

export const businessCentreMask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const customerNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

@Component({
    selector: 'rol-vendor-programs',
    templateUrl: 'vendor-programs.component.html',
    styleUrls: ['../styles_form.css', 'vendor-programs.component.css']
})
export class VendorProgramsComponent implements OnInit, AfterViewInit {
    @ViewChildren(NgModel) inputChildren;

    @Input() application: Application;
    @Input() vendor: Vendor;
    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() vendorNumber: number;

    _utilities: UtilitiesService;
    creditLimit: FormControl;
    businessCentreName: FormControl;
    businessCentreNumber: FormControl;
    customerNumber: FormControl;

    creditMask = numberMask({
        prefix: '$',
        includeThousandsSeperator: true,
        allowDecimal: true,
    });
    businessCentreMask = businessCentreMask;
    customerNumberMask = customerNumberMask;

    constructor(utilities: UtilitiesService, private vendorService: VendorService, private cdr: ChangeDetectorRef) {
        this._utilities = utilities;
    }

    ngOnInit() {
        this.addAdditionalControls();
    }

    ngAfterViewInit() {
        this.inputChildren.forEach((item) => {
            this.group.addControl(item.name, item.control);
        });

        this.cdr.detectChanges();
    }

    addAdditionalControls() {
        const numberValidation = validateNumberFactory(false, 0, 1, totalIncomeMaxNLS, this._utilities);
        this.creditLimit = new FormControl('', [Validators.required, numberValidation]);
        this.creditLimit.updateValueAndValidity();
        this.group.addControl('creditLimit', this.creditLimit);

        this.businessCentreName = new FormControl('', [Validators.required, Validators.maxLength(50)]);
        this.businessCentreName.updateValueAndValidity();
        this.group.addControl('businessCentreName', this.businessCentreName);

        this.businessCentreNumber = new FormControl('', [
            Validators.required,
            Validators.minLength(7),
            Validators.maxLength(7),
            Validators.pattern('^\\d{2}-\\d{4}$')
        ]);
        this.businessCentreNumber.updateValueAndValidity();
        this.group.addControl('businessCentreNumber', this.businessCentreNumber);

        this.customerNumber = new FormControl('', [
            Validators.required,
            Validators.maxLength(6),
            Validators.pattern('^\\d{1,6}$')
        ]);
        this.customerNumber.updateValueAndValidity();
        this.group.addControl('customerNumber', this.customerNumber);
    }
}
