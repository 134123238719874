import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CONFIG } from '../../../environments/environment';

@Component({
    templateUrl: 'reset-password-landing.component.html',
    styleUrls: ['../../../app/form/styles_form.css', 'reset-landing.component.css'] // TODO:MFA
})
export class ResetPasswordLandingComponent implements OnInit {
    private frameSrc: SafeResourceUrl;
    shouldHideFrame = true;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        // We need to store the value into a variable otherwise the iframe will reset every change detection cycle
        // This will avoid the page refreshing multiple times despite the URL remaining constant
        this.frameSrc = this.getSafeFrameSource();

        const wait = new Promise(resolve => setTimeout(resolve, CONFIG.b2cFrameDelay));
        wait.then(_ => {
            this.shouldHideFrame = false;
        });
    }

    get b2cFrameSrc() : SafeResourceUrl {
        return this.frameSrc;
    }
    
    get b2cFrameHeight() : string {
        return CONFIG.b2cUpdatePasswordFrame.height;
    }

    private getSafeFrameSource() : SafeResourceUrl {
        var url = new URL(window.location.href); // Using URL as route.queryParams observable can't seem t owork with SafeResourceUrl 
        const email = url.searchParams.get('email');
        const token = url.searchParams.get('token');
        const encodedEmail = encodeURIComponent(email);
        const encodedToken = encodeURIComponent(token);

        var frameBaseUrl = CONFIG.b2cUpdatePasswordFrame.src;
        return this.sanitizer.bypassSecurityTrustResourceUrl(`${frameBaseUrl}?email=${encodedEmail}&token=${encodedToken}`);
    }
}
