import { ActivatedRoute } from '@angular/router';
import { AccountHttpService } from '../../../services/account-http.service';
import { Commitment } from '../../../models/accounts/commitment';

export const authorizedPartyIdStorageKey = 'additionalAuthorityIdStorageKey';

export class AuthorizedPartyBase {

    protected accountNumber: string;

    public get CurrentCommitment(): Promise<Commitment> {
        return this.accountService.getCurrentAccount(this.route.snapshot.parent.parent);
    }

    constructor(protected route: ActivatedRoute, protected accountService: AccountHttpService) {
        const r = this.route.snapshot.parent.parent;
        this.accountNumber = r.params['accountId'];
    }
}
