import { IItemFactory } from './autocomplete/autocomplete.component';

export class CropItemFactory implements IItemFactory {
    public getItems(query: string): string[] {
        if (query == null || query === '') {
            return Object.keys(this.getCropItems());
        } else {
            return Object.keys(this.getCropItems()).filter(function (el) {
                return el.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }.bind(this));
        }
    }
    public getCropItems() {
        // tslint:disable-next-line: max-line-length
        return { 'Alfalfa': 'T', 'Barley': 'Bu', 'Canola': 'Bu', 'Corn': 'Bu', 'Cotton': 'lb.', 'Hay': 'T', 'Milo/Sorghum': 'Bu', 'Oats': 'Bu', 'Peanuts': 'lb.', 'Potatoes': 'Cwt', 'Rice': 'Bu', 'Soybeans': 'Bu', 'Sugar Beets': 'T', 'Sunflowers': 'lb.', 'Sweet Potatoes': 'Cwt', 'Wheat': 'Bu' };
    }
}
