import { Component, Output, EventEmitter } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { TopForm } from '../../top-form';
import { AuthService } from '../../../services/auth.service';


@Component({
    selector: 'rol-reset-password',
    templateUrl: 'reset-password.component.html',
    styles: [`.buttonContainer { height:50px }`]
})
export class ResetPasswordComponent {

    form: TopForm;
    email: FormControl;
    serverError = '';
    showConfirmation: boolean;
    @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private authService: AuthService) {
        this.form = new TopForm();
        this.email = new FormControl('', Validators.required);
        this.form.addControl('email', this.email);
        this.showConfirmation = false;
    }

    submit() {
        if (!this.showConfirmation) {
            this.form.submitted = true;
            this.serverError = '';
            if (this.form.valid) {
                this.authService.InitiatePasswordReset(this.email.value)
                    .then(() => {                        
                        this.showConfirmation = true;
                    })
                    .catch((rejected) => {
                        this.serverError = rejected;
                        this.showConfirmation = false;
                    });
            }
        }
    }

    cancel() {
        this.closeEvent.emit(false);
    }

    successAndReturn() {
        this.closeEvent.emit(true);
    }


}
