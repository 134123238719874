import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../../models/address';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TopForm } from '../../top-form';
import { CurrentApplicationService } from '../../../services/current-application.service';
import { validateIsNotPOBox } from '../../validation/is-not-po-box';
import { LookupService } from '../../../services/lookup.service';

@Component({
    selector: 'rol-mailing-address-component',
    templateUrl: 'mailing-address.component.html',
    styleUrls: ['./mailing-address.component.css']
})
export class MailingAddressComponent implements OnInit {
    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() allowPOBox = false;

    @Input() Title: string;

    @Input() address: Address;

    @Input() updateFn: Function = () => { };

    countries: any[] = [];

    streetCtrl: FormControl;
    cityCtrl: FormControl;
    stateCtrl: FormControl;
    zipCtrl: FormControl;
    countryCtrl: FormControl;

    addressList: Address[] = [];

    constructor(currentAppService: CurrentApplicationService, private lookupService: LookupService) {
        currentAppService.getCurrentApplication().then(app => {
            if (app.entity) {
                this.pushAddress(app.entity.mailingAddress);
                this.pushAddress(app.entity.physicalAddress);
                app.additionalOwners.forEach(owner => {
                    this.pushAddress(owner.physicalAddress);
                });
            }
            app.borrowers.forEach(borrower => {
                this.pushAddress(borrower.mailingAddress);
                this.pushAddress(borrower.physicalAddress);
            });
        });
    }

    ngOnInit() {
        this.getCountries();

        let streetValidators = [Validators.required, validateIsNotPOBox()];
        if (this.allowPOBox) {
            streetValidators = [Validators.required];
        }

        this.streetCtrl = new FormControl({ value: this.address.line1 }, Validators.required);
        this.cityCtrl = new FormControl({ value: this.address.city }, Validators.required);
        this.stateCtrl = new FormControl({ value: this.address.state }, Validators.required);
        this.zipCtrl = new FormControl({ value: this.address.zipcode }, Validators.required);
        this.countryCtrl = new FormControl({ value: this.address.country }, Validators.required);

        const useGroup: FormGroup = this.group || this.form;
        useGroup.addControl('mailing-addr-street', this.streetCtrl);
        useGroup.addControl('mailing-addr-city', this.cityCtrl);
        useGroup.addControl('mailing-addr-state', this.stateCtrl);
        useGroup.addControl('mailing-addr-zipcode', this.zipCtrl);
        useGroup.addControl('mailing-addr-country', this.countryCtrl);
    }

    addressListSelected(address: Address) {
        this.address.city = address.city;
        this.address.line1 = address.line1;
        this.address.state = address.state;
        this.address.zipcode = address.zipcode;
        this.address.country = address.country;
    }

    serializeAddress(address: Address) {
        return JSON.stringify(address);
    }

    public updateZipCode() {
        this.address.zipcode = this.address.zipcode.toUpperCase();
    }

    private pushAddress(address: Address) {
        if (address.isComplete) {
            if (!this.addressList.find(a => {
                return a.toString() === address.toString();
            })) {
                this.addressList.push(address);
            }
        }
    }

    private getCountries(): void {
        this.lookupService.getAddressCountries()
            .then(resolve => {
                this.countries = [{ Name: '', DisplayName: ''}, ...resolve];
            });
    }
}
