import { Financial } from './financial';

export class Finances {
    assets: Financial;
    liabilities: Financial;

    constructor() {
        this.assets = new Financial('Assets');
        this.liabilities = new Financial('Liabilities');
    }
}
