import { Component, Input, ElementRef, OnInit, AfterViewInit, ViewChildren, QueryList, HostListener, ViewChild } from '@angular/core';
import { NgModel, FormGroup, FormControl, Validators } from '@angular/forms';
import { TopForm } from '../../top-form';
import { noop } from 'rxjs/util/noop';
import { AutoComplete } from 'primeng/autocomplete';
const $ = window['$'];

@Component({
    selector: 'rol-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.css']
})

export class AutocompleteComponent implements OnInit, AfterViewInit {

    constructor(myElement: ElementRef) {
        this.elementRef = myElement;
    }
    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;
    @ViewChild('autocomplete') autocomplete: AutoComplete;
    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input()
    DisplayTitle = 'Title';
    @Input()
    itemFactory: IItemFactory;
    @Input()
    maxSize = 5;
    @Input()
    showErrors = true;
    @Input()
    forceSelection = false;
    @Input()
    required = true;

    @Input()
    update: Function = noop;
    @Input()
    value = '';

    public filteredList = [];
    public elementRef;

    ngAfterViewInit() {
        const useGroup: FormGroup = this.group || this.form;
        this.inputChildren.forEach((item) => {
            if (this.required) {
                useGroup.addControl(this.DisplayTitle, item.control);
            } else {
                const notRequired = new FormControl('');
                useGroup.addControl(this.DisplayTitle, notRequired);
            }
        });
    }

    ngOnInit() {
    }

    filter(event) {
        if (!event || event.keyCode !== 13) {
            this.filteredList = this.itemFactory.getItems(this.value);
        }

        if (this.forceSelection && this.filteredList.length === 0) {
            this.value = '';
        }
    }
}

export interface IItemFactory {
    getItems(query: string): string[];
}
