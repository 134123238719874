import { ErrorHandler, Injectable, Injector, Type } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as StackTrace from 'stacktrace-js';

import { CONFIG } from '../../environments/environment';
import { UserService } from './user.service';
import { LoggingService } from './logging.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  numberOfStackTraceItemsToReport = 10;

  constructor(private injector: Injector) { }

  handleError(error: any) {
    const loggingService = this.injector.get(LoggingService);
    const url = this.getUrl();
    const message = error.message ? error.message : error.toString();
    const userId = this.getUserId();

    StackTrace
      .fromError(error)
      .then(stackframes => {
        const stackTrace = stackframes
          .splice(0, this.numberOfStackTraceItemsToReport)
          .map(function (sf) {
            return sf.toString();
          }).join('\n');

        if (!CONFIG.runAsProd) {
          console.warn('path: ' + url);
          console.warn('stackTrace: ' + stackTrace);
          console.warn('error: ' + message);
        }

        loggingService
          .recordError(url, message, stackTrace, userId)
          .catch(_ => null);
      });


    throw error;
  }

  private getUserId(): string {
    const userService = this.injector.get(UserService);
    const user = userService.GetUser();
    return user && user.userId ? user.userId : 'unknown';
  }

  private getUrl(): string {
    const location = this.injector.get<LocationStrategy>(LocationStrategy as unknown as Type<LocationStrategy>);
    return location instanceof PathLocationStrategy ? location.path() : '';
  }
}
