import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IfB2CConfigService } from 'if-angular-security';
import { CONFIG } from '../../../../environments/environment';


@Component({
    selector: 'rol-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {
    @Input() hideCancel = false;
    serverError = '';
    @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    b2cFrameSource: string =  CONFIG.b2cSigninFrame.src;
    b2cFrameHeight: string = CONFIG.b2cSigninFrame.height;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.processMessageParams();

        // Clear any unique policy usage and go back to the default signin policy set via config
        IfB2CConfigService.clearPolicy();
    }

    private processMessageParams() {
        this.route.queryParams.subscribe(params => {
            var messageParam = params.m;
            switch(messageParam) {
                case 'account-access':
                    this.setFailedLogin('There is an issue with your account.  Please contact customer service for more details.');
                    break;
            }
        })
    }

    private setFailedLogin(message: string) {
        this.serverError = message;
    }
}
