import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class FinancialSummaryService {

    public ContainsErrorsMethod: Subject<() => boolean> = new Subject<() => boolean>();

    public setContainsErrorsMethod(f: () => boolean): void {
        this.ContainsErrorsMethod.next(f);
    }
}
