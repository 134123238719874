import { SubCommitment } from './sub-commitment';
import { Commitment } from './commitment';

export class Amendment {
    MasterAcctRefNo: string;
    MasterLoanNumber: string;
    SubCommitments: SubCommitment[];

    constructor(commitment: Commitment) {
        if (commitment) {
            this.MasterAcctRefNo = commitment.AcctRefNo;
            this.MasterLoanNumber = commitment.LoanNumber;
            this.SubCommitments = commitment.SubCommitments
                ? Array.from(commitment.SubCommitments, (data) => new SubCommitment(data))
                : null;
        }
    }

    getTotalLoanAmount(): number {
        let totalLoanAmount = 0;
        this.SubCommitments.forEach(element => {
            totalLoanAmount += element.CreditLimit;
        });
        return totalLoanAmount;
    }
}
