export class QuestionSummary {
    index: number;
    description: string;
    values: number[];
    constructor(index: number, description: string, values: number[]) {
        this.index = index;
        this.description = description;
        this.values = values;
    }
}
