export class Livestock {
    constructor(appData?: any) {
        if (appData) {
            this.name = appData.Name;
            this.count = appData.Count;
            this.price = appData.Price;
        }
    }
    name = '';
    count = 0;
    price = 0;
    get revenue(): number {
        return this.count * this.price;
    }
}
