<div class="link-account raised-card">
    <h2>Account Information</h2>
    <form name="form" [formGroup]="form" (ngSubmit)="continue()" novalidate>
        <div class="row link-account-form-group">
            <div class="col-md-5">
                <div class="form-group">
                    <label>Account Number (view example below)</label>
                    <input name="accountNumber" [formControl]="accountNumber" class="form-control"
                        [(ngModel)]="AccountNumber" placeholder="Account Number" />
                    <div class="error" *ngIf="(form.submitted || accountNumber.dirty) && accountNumber.invalid">
                        <p *ngIf="accountNumber.errors['required']">An account number is required.</p>
                        <p *ngIf="accountNumber.errors['minlength']">The account number is too short.</p>
                        <p *ngIf="accountNumber.errors['maxlength']">The account number is too long.</p>
                    </div>
                </div>
                <div class="form-group">
                    <label>Last 4 digits of SSN</label>
                    <input name="last4ssn" [formControl]="last4ssn" class="form-control" [(ngModel)]="Last4ssn"
                        placeholder="Last 4 digits of Social Security Number" />
                    <div class="error" *ngIf="(form.submitted || last4ssn.dirty) && last4ssn.invalid">
                        <p *ngIf="last4ssn.errors['required']">The last 4 digits of SSN is required.</p>
                        <p *ngIf="last4ssn.errors['minlength']">The SSN is too short.</p>
                        <p *ngIf="last4ssn.errors['maxlength']">The SSN is too long.</p>
                    </div>
                </div>
                <div class="form-group">
                    <label>Zip Code</label>
                    <input name="zip" [formControl]="zip" class="form-control" [(ngModel)]="ZipCode" placeholder="Zip"
                        [textMask]="{mask: mask, guide:false}" required pattern="^[0-9]{5}(?:-[0-9]{4})?$"
                        maxlength="10" />
                    <div class="error" *ngIf="(form.submitted || zip.dirty) && zip.invalid">
                        <p *ngIf="zip.errors['required']">The zip code is required.</p>
                        <p *ngIf="zip.errors['minlength'] || zip.errors['maxlength']">The zip code is invalid.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-7" [class.lightbox]="openLightbox" (click)="openLightbox = !openLightbox">
                <img class="statementImage img-responsive" src="assets/images/statement-cropped.png"
                    [title]="openLightbox ? 'Click to shrink image' : 'Click to enlarge image'">
                <div *ngIf="openLightbox" class="lightbox-controls">
                    <button type="button" class="btn btn-primary">Close</button>
                    <div style="clear:both"></div>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="attestationVisible || eStatementVisible || achUSBankStatementVisible">
            <h2>Agreements</h2>
            <div class="row link-account-form-group">
                <div *ngIf="attestationVisible" class="col-md-12 agreement-wrapper">
                    <label>
                        <input type="checkbox" name="attestation" (change)="agreementChanged($event)"
                            [formControl]="attestation" [ngClass]="{error: form.submitted && attestation.invalid}">
                        <span>I have read and agree to the <a class="clickable" tabindex="0"
                                (click)="$event.preventDefault(); showTandC = true">Online Services
                                Agreement</a>.</span>
                    </label>
                    <div class="error" *ngIf="form.submitted && attestation.invalid">
                        <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                    </div>
                </div>
                <div *ngIf="eStatementVisible" class="col-md-12 agreement-wrapper">
                    <label>
                        <input type="checkbox" name="eStatement" (change)="eStatementChanged($event)"
                            [formControl]="eStatement" [ngClass]="{error: form.submitted && eStatement.invalid}">
                        <span>I have read and agree to the <a class="clickable" tabindex="0"
                                (click)="$event.preventDefault(); showEStatement = true">Electronic Delivery Disclosure
                                And Consent Agreement</a>.</span>
                    </label>
                    <div class="error" *ngIf="form.submitted && eStatement.invalid">
                        <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                    </div>
                </div>
                <div *ngIf="achUSBankStatementVisible" class="col-md-12 agreement-wrapper">
                    <label>
                        <input type="checkbox" name="achUSBankStatement" (change)="achUSBankStatementChanged($event)"
                            [formControl]="achUSBankStatement"
                            [ngClass]="{error: form.submitted && achUSBankStatement.invalid}">
                        <span>I have read and agree to the <a class="clickable" tabindex="0"
                                (click)="$event.preventDefault(); showAchUSBankStatement = true">ACH service terms and
                                conditions</a>.</span>
                    </label>
                    <div class="error" *ngIf="form.submitted && achUSBankStatement.invalid">
                        <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group error" *ngIf="serverError">
            {{serverError}}
        </div>
        <div class="buttonContainer">
            <button class="btn btn-primary raised" type="submit"
                [disabled]="loading || form.invalid">{{SubmitText()}}</button>
            <button *ngIf="newUser" type="button" class="btn btn-secondary" (click)="Skip()"
                [disabled]="loading">Skip</button>
            <button type="button" class="btn btn-secondary" (click)="Cancel()" [disabled]="loading">Cancel</button>
            <button *ngIf="newUser" type="button" class="btn btn-secondary back-btn" (click)="Back()"
                [disabled]="loading">Back</button>
            <div class="clear"></div>
        </div>
    </form>
    <p-dialog [responsive]="true" header="Online Services Agreement" [(visible)]="showTandC">
        <rol-terms-and-conditions></rol-terms-and-conditions>
    </p-dialog>
    <p-dialog [responsive]="true" header="Electronic Delivery Disclosure and Consent Agreement"
        [(visible)]="showEStatement">
        <rol-electronic-statement></rol-electronic-statement>
    </p-dialog>
    <p-dialog [responsive]="true" header="ACH Agreement" [(visible)]="showAchUSBankStatement">
        <rol-ach-us-bank-statement></rol-ach-us-bank-statement>
    </p-dialog>
</div>