export class Crop {
    constructor(appData?: any) {
        if (appData) {
            this.name = appData.Name;
            this.acres = appData.Acres;
            this.averageYield = appData.AverageYield;
            this.yieldUnits = appData.YieldUnits,
                this.price = appData.Price;
        }
    }

    name = '';
    acres = 0;
    averageYield = 0;
    yieldUnits = '';
    price = 0;

    get revenue(): number {
        return this.acres * this.averageYield * this.price;
    }
}
