import { Component, Input, AfterViewInit, ViewChildren, OnInit } from '@angular/core';
import { Person } from '../../models/person';
import { NgForm, NgModel, FormGroup, FormControl, Validators } from '@angular/forms';
import { hundredWithDecimalMask } from '../validation/custom-masks';
import * as moment from 'moment';
import { UtilitiesService } from '../../services/utilities.service';
import { LookupService } from '../../services/lookup.service';
import { Canada, Farmer } from '../../models/constants';
import { OutstandingLoan } from '../../models/outstanding-loan';
import numberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
    selector: 'rol-borrower-detail',
    templateUrl: 'borrower-detail.component.html',
    styleUrls: ['../styles_form.css'],
    styles: [`
    .postfix {
        float: right;
        margin-top:-30px;
        padding-right:5px;
    }`]

})
export class BorrowerDetailComponent implements OnInit, AfterViewInit {
    _utilities: UtilitiesService;

    citizenshipCountries: string[] = null;

    @ViewChildren(NgModel) inputChildren;

    @Input() form: NgForm;
    @Input() group: FormGroup;
    @Input() borrower: Person;

    maxDob: Date = moment().subtract(18, 'years').toDate();
    minDob: Date = new Date(1880, 0, 1);
    today: Date = moment().toDate();

    isOpen: boolean[];
    accordionMap: number[];
    formGroups: FormGroup[];
    mediationMonth: FormControl;
    mediationYear: FormControl;

    priceMask = numberMask({
        prefix: '$',
        includeThousandsSeperator: true,
        allowDecimal: true,
    });
    ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    hundredMask = hundredWithDecimalMask;
    numbersOnlyMask = function (valueInput: string) {
        const mask = [];
        for (let i = 0; i < valueInput.length; ++i) {
            mask.push(/\d/);
        }
        return mask;
    };

    constructor(utilities: UtilitiesService, private lookupService: LookupService) {
        this._utilities = utilities;
    }

    ngOnInit() {
        if (this.borrower.phoneNumber) {
            this.borrower.phoneNumber = this._utilities.formatPhoneNumber(this.borrower.phoneNumber);
        }

        this.lookupService.getCitizenshipCountriesExceptCanada()
            .then(resolve => {
                this.citizenshipCountries = [''].concat(resolve);
            });

        if (!this.borrower.countryOfCitizenship) {
            this.setBorrowerCitizenship(Canada);
        }

        this.isOpen = [];
        this.formGroups = [];
        this.accordionMap = [];

        this.mediationMonth = new FormControl(this.borrower.richardsonFinancialInformation.mediationMonth, [ Validators.required ]);
        this.mediationMonth.updateValueAndValidity();

        this.mediationYear = new FormControl(this.borrower.richardsonFinancialInformation.mediationYear, [ Validators.required ]);
        this.mediationYear.updateValueAndValidity();

        this.mediationMonth.valueChanges.subscribe({
            next: (month) => { this.borrower.richardsonFinancialInformation.mediationMonth = month; }
        });
        this.mediationYear.valueChanges.subscribe({
            next: (year) => { this.borrower.richardsonFinancialInformation.mediationYear = year; }
        });

        if (this.borrower.richardsonFinancialInformation.hasOutstandingLoans) {
            this.addOutstandingLoans();
        }
    }

    ngAfterViewInit() {
        this.inputChildren.forEach((item) => {
            this.group.addControl(item.name, item.control);
        });

        if (this.borrower.richardsonFinancialInformation.hasMediation) {
            this.group.addControl('mediationMonth', this.mediationMonth);
            this.group.addControl('mediationYear', this.mediationYear);
        }
    }

    private addOutstandingLoans(): void {
        if (this.borrower.richardsonFinancialInformation.outstandingLoans.length === 0) {
            this.add();
        } else {
            let opened = false;
            for (let i = 0; i < this.borrower.richardsonFinancialInformation.outstandingLoans.length; ++i) {
                if (!opened && !this.borrower.richardsonFinancialInformation.outstandingLoans[i].loanType) {
                    opened = true;
                    this.isOpen.push(true);
                } else {
                    this.isOpen.push(false);
                }
                this.accordionMap.push(i);
                const formGroup = new FormGroup({});
                this.formGroups.push(formGroup);
                this.group.addControl(this.accordionMap.length.toString(), formGroup);
            }
        }
    }

    add(): void {
        this.borrower.richardsonFinancialInformation.outstandingLoans.push(new OutstandingLoan());
        this.closeAllTabs();
        this.accordionMap.push(this.isOpen.length);
        this.isOpen.push(true);
        const formGroup = new FormGroup({});
        this.formGroups.push(formGroup);
        this.group.addControl(this.accordionMap.length.toString(), formGroup);
    }

    delete(i: number, e: MouseEvent): void {
        this.borrower.richardsonFinancialInformation.outstandingLoans.splice(i, 1);
        this.isOpen.splice(i, 1);
        this.formGroups.splice(i, 1);
        let found = null;
        this.accordionMap = this.accordionMap.map((mapping, idx) => {
            if (mapping === i) {
                found = true;
                this.group.removeControl((idx + 1).toString());
                return -1;
            } else {
                return found ? mapping - 1 : mapping;
            }
        });
        e.preventDefault();

        if (this.borrower.richardsonFinancialInformation.outstandingLoans.length === 0) {
            this.add();
        }
    }

    tabOpen(e) {
        this.isOpen[e.index] = true;
    }

    tabClose(e) {
        this.isOpen[e.index] = false;
    }

    hasNonWhitespace(s: string): boolean {
        return /[^\s]/g.test(s);
    }

    showDisplayName(loan: OutstandingLoan): boolean {
        return this.hasNonWhitespace(loan.loanType);
    }

    private closeAllTabs() {
        for (let i = 0; i < this.isOpen.length; ++i) {
            this.isOpen[i] = false;
        }
    }

    changeCitizen(value) {
        this.borrower.isCanadianCitizen = (value === 'true');
        if (this.borrower.isCanadianCitizen) {
            this.group.removeControl('citizenshipCountry');
            this.setBorrowerCitizenship(Canada);
        } else {
            this.reAddConditionalControls();
            this.setBorrowerCitizenship(null);
        }
    }

    changeControllingParty(value) {
        if (this.borrower.isControllingParty) {
            this.group.removeControl('citizenshipCountry');
        } else {
            this.reAddConditionalControls();
        }
    }

    setBorrowerCitizenship(value: string) {
        this.borrower.countryOfCitizenship = value;
    }

    changeBankruptcy(value) {
        this.borrower.hasBankruptcy = (value === 'true');
        if (this.borrower.hasBankruptcy) {
            this.reAddConditionalControls();
        } else {
            this.group.removeControl('filingDate');
            this.group.removeControl('dischargeDate');
        }
    }

    changeOccupationNotFarmer(value) {
        const notFarmer = value === 'true';
        this.borrower.primaryOccupationNotFarmer = notFarmer;
        this.borrower.primaryOccupation = notFarmer ? '' : Farmer;
        if (notFarmer) {
            this.reAddConditionalControls();
        } else {
            this.group.removeControl('primaryOccupation');
        }
    }

    reAddConditionalControls() {
        setTimeout(() => {
            this.inputChildren.forEach((item) => {
                if (!this.group.contains(item.name)) {
                    this.group.addControl(item.name, item.control);
                }
            });
        });
    }

    toNumber(value: string): number {
        return this._utilities.toNumber(value);
    }

    changeOutstandingLoans(value): void {
        this.borrower.richardsonFinancialInformation.hasOutstandingLoans = value.checked;
        if (this.borrower.richardsonFinancialInformation.hasOutstandingLoans) {
            this.reAddConditionalControls();
            this.addOutstandingLoans();
        } else {
            for (let i = 1; i <= this.accordionMap.length; ++i) {
                this.group.removeControl(i.toString());
            }
        }
    }

    changeMediation(value): void {
        this.borrower.richardsonFinancialInformation.hasMediation = value.checked;
        if (this.borrower.richardsonFinancialInformation.hasMediation) {
            this.group.addControl('mediationMonth', this.mediationMonth);
            this.group.addControl('mediationYear', this.mediationYear);
        } else {
            this.group.removeControl('mediationMonth');
            this.group.removeControl('mediationYear');
        }
    }

    changeCropInsurance(value): void {
        this.borrower.richardsonFinancialInformation.hasCropInsurance = value.checked;
        if (this.borrower.richardsonFinancialInformation.hasCropInsurance) {
            this.reAddConditionalControls();
        } else {
            this.group.removeControl('cropAcres');
        }
    }

    changeHailInsurance(value): void {
        this.borrower.richardsonFinancialInformation.hasHailInsurance = value.checked;
        if (this.borrower.richardsonFinancialInformation.hasHailInsurance) {
            this.reAddConditionalControls();
        } else {
            this.group.removeControl('hailAcres');
        }
    }

    changeOtherIncome(value): void {
        this.borrower.richardsonFinancialInformation.hasOtherIncome = value.checked;
        if (this.borrower.richardsonFinancialInformation.hasOtherIncome) {
            this.reAddConditionalControls();
        } else {
            this.group.removeControl('otherIncomeAmount');
            this.group.removeControl('otherIncomeSource');
        }
    }
}
