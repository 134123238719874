export class Payment {
    PaymentDate: Date;
    AccountPayment: boolean;
    AcctRefNo: number;
    DisplayName: string;
    MasterLoanNumber: string;
    LoanNumber: string;
    ProgramId: number;
    ProgramName: string;
    DealerName: string;
    MaturityDate: Date;
    PayoffAmount: number;
    PaymentAmount: number;
    AccountPaymentAmount: number;
    BankAccountNumber: string;
    BankAccountHolder: string;
    BankRoutingNumber: string;
    Status: string;
    ConfirmationNumber: string;
    PaymentGrowerDetailID: number;
    PaymentType: string;
    ACHAccountType: string;

    constructor(data?: any) {
        if (data) {
            this.PaymentDate = data.PaymentDate ? new Date(data.PaymentDate) : null;
            this.AccountPayment = data.AccountPayment;
            this.AcctRefNo = data.AcctRefNo;
            this.DisplayName = data.DisplayName;
            this.MasterLoanNumber = data.MasterLoanNumber;
            this.LoanNumber = data.LoanNumber;
            this.ProgramId = data.ProgramId;
            this.ProgramName = data.ProgramName;
            this.DealerName = data.DealerName;
            this.MaturityDate = data.MaturityDate ? new Date(data.MaturityDate) : null;
            this.PayoffAmount = data.PayoffAmount;
            this.PaymentAmount = data.PaymentAmount;
            this.AccountPaymentAmount = data.AccountPaymentAmount;
            this.BankAccountNumber = data.BankAccountNumber;
            this.BankAccountHolder = data.BankAccountHolder;
            this.BankRoutingNumber = data.BankRoutingNumber;
            this.Status = data.Status;
            this.ConfirmationNumber = data.ConfirmationNumber;
            this.PaymentGrowerDetailID = data.PaymentGrowerDetailID;
            this.PaymentType = data.PaymentType;
            this.ACHAccountType = data.ACHAccountType;
        }
    }
}
