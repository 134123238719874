<div ngFormGroupName="loan">
    <div class="form-group">
        <label for="loanType">Type of Loan (e.g. Crop Input Loan)</label>
        <input name="loanType" class="form-control" [(ngModel)]="loan.loanType" required
            placeholder="Loan Type" [formControl]="loanType" />
        <div *ngIf="form.submitted && loanType.invalid" class="error">
            <p>Required</p>
        </div>
    </div>
    <div class="form-group">
        <label for="creditor">Creditors (e.g. Input Capital, CCGA etc.)</label>
        <input name="creditor" class="form-control" [(ngModel)]="loan.creditor" required
            placeholder="Creditor" [formControl]="creditor" />
        <div *ngIf="form.submitted && creditor.invalid" class="error">
            <p>Required</p>
        </div>
    </div>
    <div class="form-group">
        <label for="amount">Amount Owing</label>
        <rol-number-input [formControl]="amount" [(ngModel)]="loan.amount" required></rol-number-input>
    </div>
    <div class="form-group">
        <rol-month-year [form]="form" name="issueDate" [yearsBack]="7" 
            title="Issue" required [monthControl]="issueMonth"
            [yearControl]="issueYear"></rol-month-year>
    </div>
</div>