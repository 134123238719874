import { TopForm } from '../top-form';
import { FormControl, Validators } from '@angular/forms';

import { UtilitiesService } from '../../services/utilities.service';
import { CropInventoryItem } from '../../models/crop-inventory-item';
import { validateNumberFactory } from '../validation/number-validator.directive';
import { CropItemFactory } from '../reusables/crop-item-factory';

export class CropInventory {
    form: TopForm;
    editingCropInventory: CropInventoryItem;
    cropName: string;
    cropQuantityUnitOfMeasure: string;
    current: CropInventoryItem;

    constructor(private utilities: UtilitiesService) {
        this.current = new CropInventoryItem();
        this.form = new TopForm();
        this.form.addControl('quantity', new FormControl('', Validators.required));
        this.form.addControl('value', new FormControl('',
            [Validators.required, validateNumberFactory(false, 2, 0, 0, this.utilities)]));
    }

    update(cropInventoryItem?: CropInventoryItem) {
        this.cropName = cropInventoryItem ? cropInventoryItem.name : "";
        this.current.quantity = cropInventoryItem ? cropInventoryItem.quantity : 0;
        this.cropQuantityUnitOfMeasure = cropInventoryItem ? cropInventoryItem.quantityUnitOfMeasure : '';
        this.current.value = cropInventoryItem ? cropInventoryItem.value : 0;
    }

    clear() {
        this.update();
    }

    setCropName() {
        const cropFactory = new CropItemFactory();

        const cropInventory = this;
        return function (cropName) {
            cropInventory.cropName = cropName;
            const cropItems = cropFactory.getCropItems();

            if (cropItems[cropName]) {
                cropInventory.cropQuantityUnitOfMeasure = cropItems[cropName];
            }
        };
    }

    setQuantityUnitOfMeasure() {
        const cropInventory = this;
        return function (quantityUnitOfMeasure) {
            cropInventory.cropQuantityUnitOfMeasure = quantityUnitOfMeasure;
        };
    }

    get value(): CropInventoryItem {
        return new CropInventoryItem({
            Name: this.cropName,
            Quantity: this.current.quantity,
            QuantityUnitOfMeasure: this.cropQuantityUnitOfMeasure,
            Value: this.current.value
        });
    }
}