import { Injectable } from '@angular/core';
import { Application } from '../models/application';
import { ApplicationHttpService } from './application-http.service';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { Person } from '../models/person';

@Injectable()
export class CurrentApplicationService {
    constructor(
        private appHttpService: ApplicationHttpService,
        private userService: UserService,
        public authService: AuthService,
        private storageService: StorageService) {
    }
    private currentApp: Application;
    private appStorageKey = 'currentApplication';

    setCurrentApplication(app: Application) {

        this.currentApp = app;

        if (!app) {
            this.storageService.deleteFromLocal(this.appStorageKey);
        } else {
            // Fix the appType to be a number instead of a string, which is how it is being set.
            app.appType = +(app.appType);

            this.storageService.storeInLocal(this.appStorageKey, app.id);
        }
    }

    hasCurrentApplication() {
        return !!(this.userService.GetUser() && (this.currentApp || this.storageService.getFromLocal(this.appStorageKey)));
    }

    getCurrentApplication(): Promise<Application> {
        if (!this.currentApp) {
            const storageAppId = this.storageService.getFromLocal(this.appStorageKey);
            if (storageAppId && this.userService.GetUser()) {
                return new Promise((resolve, reject) => {
                    this.appHttpService.getApplication(+storageAppId)
                        .then(app => {
                            this.setCurrentApplication(app);
                            resolve(this.currentApp);
                        })
                        .catch(reason => {
                            this.makeNewApp();
                            resolve(this.currentApp);
                        });
                });
            } else {
                this.makeNewApp();
            }
        }
        return Promise.resolve(this.currentApp);
    }

    makeNewApp(data?) {
        this.currentApp = new Application(this.appHttpService, this.userService, this.authService, data);
    }

    getPreApprovalStagingForApplication(stagingId: number): Promise<Person> {
        return new Promise((resolve, reject) => {
            this.appHttpService.getPreApprovalStagingForApplication(stagingId)
                .then(res => {
                    return resolve(res);
                });
        });
    }
}
