import { Component } from '@angular/core';

@Component({
    selector: 'rol-dashboard-home',
    templateUrl: 'dashboard-home.component.html',
    styles: [`
        .dashboard {
            padding-bottom: 15px;
        }
    `]
})
export class DashboardHomeComponent {

    loadingApplications = true;
    hasOpenApplication: boolean;

    returnHasOpenApplication(event: boolean) {
        this.hasOpenApplication = event;
    }

    returnLoadingApplications(event: boolean) {
        this.loadingApplications = event;
    }
}
