import { Component } from '@angular/core';

@Component({
    selector: 'rol-ach-us-bank-statement',
    templateUrl: 'ach-us-bank-statement.component.html',
    styles: [`
        .displayBox {
            background:#e7e7e7;
            margin: 15px;
            padding:15px;
            overflow: auto;
            height:400px;
        }
    `]
})
export class AchUSBankStatementComponent {

}
