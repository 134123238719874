import { AdditionalEntityInfo } from './additional-entity-info';
import { Address } from './address';
import { Person } from './person';

export class Entity {
    constructor(appData?: any) {
        if (appData) {
            this.dateOfOrganization = appData.DateOfOrganization ? new Date(appData.DateOfOrganization) : null;
            this.entityType = appData.EntityType;
            this.legalName = appData.LegalName;
            this.mailingAddress = new Address(true, appData.MailingAddress);
            this.businessPhoneNumber = appData.BusinessPhoneNumber;
            this.homePhoneNumber = appData.HomePhoneNumber;
            this.mobilePhoneNumber = appData.MobilePhoneNumber;
            this.physicalAddress = new Address(false, appData.PhysicalAddress);
            this.stateOfOrganization = appData.StateOfOrganization;
            this.taxId = appData.TaxId;
            this.ultimateBeneficialOwners = appData.UltimateBeneficialOwners ? Array.from(appData.UltimateBeneficialOwners, (data) => { let p = new Person(data); p.isBorrower = false; return p; }) : [];
            this.controllingParties = Array.from(appData.ControllingParties, (data) => new Person(data));
            this.signer = appData.Signer ? new Person(appData.Signer) : null;
            this.signerTitle = appData.SignerTitle;
            this.doingBusinessAs = appData.DoingBusinessAs;
            
            this.richardsonEntityInformation = new AdditionalEntityInfo(appData.RichardsonEntityInformation);
        }
    }
    legalName = '';
    taxId = '';
    entityType = '';
    businessPhoneNumber = '';
    homePhoneNumber = '';
    mobilePhoneNumber = '';
    stateOfOrganization = '';
    dateOfOrganization: Date;
    physicalAddress: Address = new Address(false);
    mailingAddress: Address = new Address(true);
    ultimateBeneficialOwners: Person[];
    controllingParties: Person[] = [];
    signer: Person;
    signerTitle = '';
    doingBusinessAs: string[] = [];
    richardsonEntityInformation: AdditionalEntityInfo = new AdditionalEntityInfo();
    phoneNumber = '';
}
