import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, FormGroup } from '@angular/forms';

const minPasswordLength = 8;
const maxPasswordLength = 999;
const mustContainNumber = true;
const mustContainLowercaseLetter = true;
const mustContainCapitalLetter = true;
const mustContainNonAlphaNumeric = false;

export function validatePasswordFactory() {
    return function (c: FormControl) {
        if (!c.value || c.value.length === 0) {
            return null;
        }
        const result = { passwordValidation: {} };
        if (c.value.length < minPasswordLength) {
            result['passwordValidation']['minlength'] = 'Must be ' + minPasswordLength + ' characters';
        }
        if (mustContainNumber && !(new RegExp('[0-9]').test(c.value))) {
            result['passwordValidation']['nonumber'] = 'Must have a number';
        }
        if (mustContainCapitalLetter && !(new RegExp('[A-Z]').test(c.value))) {
            result['passwordValidation']['nocapital'] = 'Must have a capital letter';
        }
        if (mustContainLowercaseLetter && !(new RegExp('[a-z]').test(c.value))) {
            result['passwordValidation']['nolowercase'] = 'Must have a lowercase letter';
        }
        if (mustContainNonAlphaNumeric && !(new RegExp('[^a-zA-Z0-9]').test(c.value))) {
            result['passwordValidation']['nospecial'] = 'Must have a non alphanumeric character';
        }
        if (c.value.length > maxPasswordLength) {
            result['passwordValidation']['maxlength'] = 'Cannot be over ' + maxPasswordLength + ' characters';
        }
        if (Object.keys(result.passwordValidation).length === 0) {
            return null;
        }
        return result;
    };
}

export function passwordCompareFactory() {
    return function (group: FormGroup) {
        if (!group.controls['password'] || !group.controls['confirmPassword']) {
            return null; // if controls are not present do not validate
        }
        return (group.controls['password'].value === group.controls['confirmPassword'].value) ? null : { noMatch: true };
    };
}

@Directive({
    selector: '[rolValidatePassword][ngModel],[validate-password][formControl]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PasswordValidatorDirective), multi: true }
    ]
})
export class PasswordValidatorDirective {

    validator: Function;

    constructor() {
        this.validator = validatePasswordFactory();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }
}
