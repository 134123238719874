<div class="row">
    <div class="col-sm-2"></div>
    <div class="raised registrationStage white col-sm-8">

        <h2>Agreements</h2>
        <form [formGroup]="form" (ngSubmit)="continue()" novalidate>
            <div class="agreement-wrapper">
                <label>
                    <input type="checkbox" name="attestation" (change)="agreementChanged($event)"
                        [formControl]="attestation" [ngClass]="{error: form.submitted && attestation.invalid}">
                    <span>I have read and agree to the <a class="clickable" tabindex="0"
                            (click)="showTandC = true">Online Services Agreement</a>.</span>
                </label>
                <div class="error" *ngIf="form.submitted && attestation.invalid">
                    <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                </div>
            </div>
            <div class="agreement-wrapper">
                <label>
                    <input type="checkbox" name="eStatement" (change)="eStatementChanged($event)"
                        [formControl]="eStatement" [ngClass]="{error: form.submitted && eStatement.invalid}">
                    <span>I have read and agree to the <a class="clickable" tabindex="0"
                            (click)="showEStatement = true">Electronic Delivery Disclosure And Consent
                            Agreement</a>.</span>
                </label>
                <div class="error" *ngIf="form.submitted && eStatement.invalid">
                    <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                </div>
            </div>
            <div class="form-group">
                <label for="recaptcha">Are you a robot?</label>
                <rol-re-captcha (captchaResponse)="captchaResolved($event)"></rol-re-captcha>
                <div class="error" *ngIf="form.submitted && recaptchaToken == ''">
                    <p>Required</p>
                </div>
            </div>
            <div class="form-group error" *ngIf="serverError">
                {{serverError}}
            </div>
            <div class="buttonContainer">
                <button type="submit" class="btn btn-primary" [disabled]="loading">Register</button>
                <button type="button" class="btn btn-secondary" (click)="registrationService.cancel()"
                    [disabled]="loading">Cancel</button>
                <button type="button" class="btn btn-secondary back-btn" (click)="registrationService.back()"
                    [disabled]="loading">Back</button>
                <div class="clear"></div>
            </div>
        </form>
        <p-dialog [responsive]="true" header="Online Services Agreement" [(visible)]="showTandC">
            <rol-terms-and-conditions></rol-terms-and-conditions>
        </p-dialog>
        <p-dialog [responsive]="true" header="Electronic Delivery Disclosure and Consent Agreement"
            [(visible)]="showEStatement">
            <rol-electronic-statement></rol-electronic-statement>
        </p-dialog>
    </div>
</div>