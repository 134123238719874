<form [formGroup]="changePasswordForm" class="preference-group group raised-card" (ngSubmit)="changePasswordSubmit()"
    novalidate autocomplete="off">
    <h2>Change Profile Password</h2>
    <div *ngIf="!passwordChangeComplete">
        <div class="form-group">
            <label for="oldPassword">Old Password</label>
            <input type="password" id="oldPassword" class="form-control" formControlName="oldPassword"
                [(ngModel)]="password.oldPassword" placeholder="Old Password" name="oldPassword" autocomplete="off">
            <div *ngIf="(changePasswordForm.submitted || oldPassword.dirty) && !oldPassword.valid"
                class="alert alert-danger">Please type your old password</div>
        </div>
        <div [formGroup]="newPasswordGroup">
            <div class="form-group">
                <label for="newPassword">New Password</label>
                <input type="password" id="newPassword" class="form-control" formControlName="newPassword"
                    [(ngModel)]="password.newPassword" placeholder="New Password" name="newPassword" autocomplete="off">
                <div *ngIf="(changePasswordForm.submitted || newPassword.dirty) && !newPassword.valid"
                    class="alert alert-danger">
                    <p *ngIf="newPasswordGroup.controls['newPassword'].hasError('required')">A new password is
                        required</p>
                    <p *ngIf="newPasswordGroup.controls['newPassword'].hasError('passwordValidation')">
                        {{passwordValidationMessage(newPassword.errors['passwordValidation'])}}
                    </p>
                </div>
            </div>
            <div class="form-group">
                <label for="confirmPassword">Confirm Password</label>
                <input type="password" id="confirmPassword" class="form-control" formControlName="confirmPassword"
                    [(ngModel)]="password.confirmPassword" placeholder="Confirm Password" name="confirmPassword"
                    autocomplete="off">
                <div class="alert alert-danger"
                    *ngIf="(changePasswordForm.submitted || confirmPassword.dirty) && (!confirmPassword.valid || newPasswordGroup.errors)">
                    <p *ngIf="newPasswordGroup.controls['confirmPassword'].hasError('required') ">Required</p>
                    <p *ngIf="newPasswordGroup.hasError('noMatch')">Passwords do not match</p>
                </div>
            </div>
        </div>
        <div class="error" *ngIf="changePasswordError">
            {{changePasswordError}}
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="changePasswordForm.invalid">Update</button>
    </div>
    <div *ngIf="passwordChangeComplete">
        <p>Your password was successfully changed!</p>
        <button type="button" class="btn btn-primary" (click)="resetChangePasswordForm()">OK</button>
    </div>
</form>