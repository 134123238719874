import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import { Application } from '../models/application';
import { ApplicationHeader } from '../models/application-header';
import { AuthenticatedHttp } from '../services/authenticated-http.service';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { CONFIG } from '../../environments/environment';
import { Person } from '../models/person';
import { Address } from '../models/address';
import { RichardsonOnline } from '../models/constants';

export interface IApplicationHttpService {
    getApplications();
    getApplication(id: number);
    save(app: Application): Promise<number>;
    submit(app: Application, recaptchaToken: string): Promise<string>;
    delete(app: Application);
    print(app: Application);
    getPreApprovalStagingForApplication(stagingId: number): Promise<Person>;
    setStatus(id: number, applicationStatus: string): Promise<any>;
}

@Injectable()
export class ApplicationHttpService implements IApplicationHttpService {
    private resourceUrl = CONFIG.apiBaseUri + 'Application';
    private headers = new HttpHeaders({ 'content-type': 'application/json' });

    constructor(private http: AuthenticatedHttp, private userService: UserService, private authService: AuthService) { }

    getApplicationHeaders() {
        const srvc = this;
        return this.http.get(this.resourceUrl + `/GetApplicationHeaders/${RichardsonOnline}`)
            .toPromise()
            .then(response => Array.from(response as any[], (item) => {
                return new ApplicationHeader(item);
            }))
            .catch(this.handleError);
    }

    getApplications() {
        const srvc = this;
        return this.http.get(this.resourceUrl)
            .toPromise()
            .then(response => Array.from(response as any[], (item) => {
                return new Application(srvc, srvc.userService, srvc.authService, item);
            }))
            .catch(this.handleError);
    }

    getApplication(id: number) {
        const srvc = this;
        const url = `${this.resourceUrl}/${id}`;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return new Application(srvc, srvc.userService, srvc.authService, response);
            })
            .catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    private getAppDTO(app: Application) {
        return {
            id: app.id,
            appType: app.appType,
            additionalOwners: app.additionalOwners,
            borrowers: app.borrowers,
            balanceSheet: app.balanceSheet,
            lineOfBusiness: app.lineOfBusiness,
            income: app.income,
            entity: app.entity,
            vendors: app.vendors,
            currentPage: app.currentPage,
            createdDate: app.createdDate,
            onlineAgreementAcceptanceDate: app.onlineAgreementAcceptanceDate,
            hasEntityOwnership: app.hasEntityOwnership,
            increasedRiskCountries: app.increasedRiskCountries,
            tradeReferences: app.tradeReferences,
            productiveAcres: app.productiveAcres,
            originatingSite: app.originatingSite,
            directors: app.directors,
            hasIntermediaryOwner: app.hasIntermediaryOwner,
            hasTrust: app.hasTrust,
            hasAdditionalSignatoryVerification: app.hasAdditionalSignatoryVerification,
            hasPrimaryApplicantConfirmation: app.hasPrimaryApplicantConfirmation
        };
    }

    private post(app: Application): Promise<number> {
        return this.http
            .post(this.resourceUrl, JSON.stringify(this.getAppDTO(app)), { headers: this.headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(() => Promise.reject(new Error('Could not save application')));
    }

    delete(app: Application) {
        const url = `${this.resourceUrl}/${app.id}`;

        return this.http
            .delete(url, { headers: this.headers })
            .toPromise()
            .catch(this.handleError);
    }

    cancel(app: Application) {
        const url = `${this.resourceUrl}/Cancel/${app.id}`;

        return this.http
            .post(url, { headers: this.headers })
            .toPromise()
            .catch(this.handleError);
    }

    save(app: Application): Promise<number> {
        return this.post(app);
    }

    submit(app: Application, recaptchaToken: string): Promise<string> {
        const url = `${this.resourceUrl}/submit/${app.id}`;

        return this.http
            .post(url, recaptchaToken ? JSON.stringify(recaptchaToken) : '', { headers: this.headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    getDocuSignUrl(app: Application): Promise<string> {
        const url = `${this.resourceUrl}/GetDocuSignUrl/${app.id}`;

        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    editSigningApplication(app: Application) {
        const srvc = this;
        const url = `${this.resourceUrl}/EditSigningApplication/${app.id}`;

        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then(response => {
                return new Application(srvc, srvc.userService, srvc.authService, response);
            })
            .catch(this.handleError);
    }



    print(app: Application): Promise<Blob> {
        const url = `${this.resourceUrl}/printable/${app.id}`;

        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then(res => {
                const byteChars = atob(res);
                const byteNumbers = new Array(byteChars.length);
                for (let i = 0; i < byteChars.length; i++) {
                    byteNumbers[i] = byteChars.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                const blob = new Blob([byteArray], { type: 'application/pdf' });
                return blob;
            })
            .catch(this.handleError);
    }


    getPreApprovalStagingForApplication(stagingId: number): Promise<Person> {
        const url = `${this.resourceUrl}/GetPreApprovalStaging/${stagingId}`;
        return this.http.post(url, { headers: this.headers })
            .toPromise()
            .then(res => {
                return this.convertStagingToPerson(res);
            })
            .catch(this.handleError);
    }

    setStatus(id: number, applicationStatus: string): Promise<any> {
        const url = `${this.resourceUrl}/status/${id}`;

        return this.http
            .post(url, JSON.stringify(applicationStatus), { headers: this.headers })
            .toPromise()
            .catch(this.handleError);
    }

    private convertStagingToPerson(data: any): Person {
        const person = new Person();
        const address = new Address(true);
        address.city = data.GrowerCity;
        address.line1 = data.GrowerAddress;
        address.line2 = data.GrowerAddress2;
        address.state = data.GrowerState;
        address.zipcode = data.GrowerZip;

        person.mailingAddress = address;
        person.physicalAddress = address;
        person.fullName.firstName = data.GrowerFirstName;
        person.fullName.lastName = data.GrowerLastName;
        person.fullName.middleName = data.GrowerMiddle;
        person.fullName.suffix = data.GrowerSuffix;
        person.dateOfBirth = null;
        return person;
    }
}
