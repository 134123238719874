import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { Address } from '../../../models/address';
import { NgModel, FormGroup } from '@angular/forms';
import { TopForm } from '../../top-form';

@Component({
    selector: 'rol-combined-address-component',
    templateUrl: 'combined-address.component.html',
})
export class CombinedAddressComponent implements OnInit {
    @Input() form: TopForm;
    @Input() group: FormGroup;

    @Input() name: string;
    @Input() physicalAddress: Address;
    @Input() mailingAddress: Address;

    @ViewChildren(NgModel) inputChildren;

    isMailingAddressSameAsPhysicalAddress: boolean;

    ngOnInit() {
        this.isMailingAddressSameAsPhysicalAddress = this.mailingAddress.isSameAs(this.physicalAddress);
    }

    onChangeAddress(address: Address) {
        if (this.isMailingAddressSameAsPhysicalAddress) {
            this.copyPhysicalToMailingAddress();
        }
    }

    onChangeIsMailingAddressSame(value) {
        this.isMailingAddressSameAsPhysicalAddress = (value === 'true');
        const controlNames = [
            'mailing-addr-street',
            'mailing-addr-city',
            'mailing-addr-state',
            'mailing-addr-zipcode',
            'mailing-addr-country'
        ];
        if (this.isMailingAddressSameAsPhysicalAddress) {
            this.removeConditionalControls(controlNames);
            this.copyPhysicalToMailingAddress();
        } else {
            this.reAddConditionalControls(controlNames);
            this.clearMailingAddress();
        }
    }

    removeConditionalControls(controlNames: string[]) {
        controlNames.forEach((ctrlName) => {
            this.group.removeControl(ctrlName);
        });
    }

    reAddConditionalControls(controlNames: string[]) {
        setTimeout(() => {
            controlNames.forEach((ctrlName) => {
                this.addChildControl(ctrlName);
            });
        });
    }

    addChildControl(controlName: string) {
        Object.keys(this.group.controls).forEach(key => {
            if (key === controlName) {
                if (!this.group.contains(key)) {
                    this.group.addControl(key, this.group[key].control);
                }
            }
        });
    }

    copyPhysicalToMailingAddress() {
        this.mailingAddress.line1 = this.physicalAddress.line1;
        this.mailingAddress.city = this.physicalAddress.city;
        this.mailingAddress.state = this.physicalAddress.state;
        this.mailingAddress.zipcode = this.physicalAddress.zipcode;
        this.mailingAddress.country = 'CA';
    }

    clearMailingAddress() {
        this.mailingAddress.line1 = '';
        this.mailingAddress.city = '';
        this.mailingAddress.state = '';
        this.mailingAddress.zipcode = '';
        this.mailingAddress.country = '';
    }
}
