import {
    Component, Input, OnChanges,
    QueryList, AfterViewInit, ViewChild
} from '@angular/core';
import {
    NgModel, FormGroup, Validators,
    FormControl
} from '@angular/forms';
import { ComplexQuestion } from '../../../models/wizard/complex-question';
import { TopForm } from '../../top-form';
import { Person } from '../../../models/person';
import { Entity } from '../../../models/entity';
import { UtilitiesService } from '../../../services/utilities.service';
import { totalIncomeMaxNLS } from '../../financial-validation';

@Component({
    selector: 'rol-complex-question-component',
    templateUrl: './complex-question.component.html',
    styleUrls: ['questions.css']
})
export class ComplexQuestionComponent implements OnChanges, AfterViewInit {

    constructor(private utilities: UtilitiesService) {

    }
    @ViewChild('asset') assetInput;
    @Input() form: TopForm;
    @Input() group: FormGroup;

    @Input() questionIndex: number;

    assetCtrls: FormControl[] = [];
    totalLibCtrls: FormControl[] = [];
    shortTermLibCtrls: FormControl[] = [];

    maxDollarValue: number = totalIncomeMaxNLS;

    @Input()
    question: ComplexQuestion;

    @Input() Characters: string[];

    ngOnChanges(changes) {
        this.assetCtrls = [];
        this.totalLibCtrls = [];
        this.shortTermLibCtrls = [];
        this.Characters.forEach((c, i) => {
            this.assetCtrls.push(new FormControl('0', [Validators.required].concat(this.question.assetCustomValidators)));
            this.totalLibCtrls.push(new FormControl('0', [Validators.required].concat(this.question.totalLiabilityCustomValidators)));
            this.shortTermLibCtrls.push(
                new FormControl('0', [Validators.required, this.termLessThanTotal(i)]
                    .concat(this.question.shortTermLiabilityCustomValidators)));
            if (!this.question.assets[i]) {
                this.question.assets[i] = 0;
            }
            if (!this.question.shortTermLiability[i]) {
                this.question.shortTermLiability[i] = 0;
            }
            if (!this.question.totalLiability[i]) {
                this.question.totalLiability[i] = 0;
            }
        });

        const useGroup: FormGroup = this.group || this.form;
        this.assetCtrls.forEach((ctrl, i) => {
            useGroup.addControl('asset_' + this.questionIndex + '_' + i, ctrl);
        });
        this.totalLibCtrls.forEach((ctrl, i) => {
            useGroup.addControl('totalLib_' + this.questionIndex + '_' + i, ctrl);
        });
        this.shortTermLibCtrls.forEach((ctrl, i) => {
            useGroup.addControl('shortTermLib_' + this.questionIndex + '_' + i, ctrl);
        });
        this.InitNumbers();
    }

    ngAfterViewInit() {
        this.InitNumbers();
    }

    private InitNumbers() {
        if (this.assetInput) {
            this.assetInput.focus();
        }
    }

    // validators
    termLessThanTotal(i: number) {
        const parent = this;
        return function (c: FormControl) {
            const ctrlNumber = typeof c.value === 'string' ? parent.utilities.toNumber(c.value) : c.value;
            if (ctrlNumber <= parent.question.totalLiability[i]) {
                return null;
            } else {
                return { termGreaterThanTotal: true };
            }
        };
    }
}
