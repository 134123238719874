import { Injectable } from '@angular/core';

import { VendorHeader } from '../models/vendor-header';
import { AuthenticatedHttp } from './authenticated-http.service';
import { CONFIG } from '../../environments/environment';

@Injectable()
export class VendorService {
    public vendors: VendorHeader[] = [];
    public filteredVendors: VendorHeader[] = [];
    public vendorRetailers: VendorHeader[] = [];
    public sponsoringVendor: VendorHeader;
    private apiUrl = CONFIG.apiBaseUri + 'program/';

    constructor(private http: AuthenticatedHttp) { }

    public filterSelectedVendors(selectedVendorIds: number[]) {
        if (this.vendors) {
            this.filteredVendors = this.vendors.filter(v => !selectedVendorIds.includes(v.rowId));
        }
    }

    public GetVendors(): Promise<boolean> {
        if (this.vendors.length > 0) {
            return Promise.resolve(true);
        }
        return this.http.get(this.apiUrl + 'USVendors')
            .toPromise()
            .then(response => {
                this.vendors = Array.from(response as any[], (value) => {
                    return new VendorHeader(value);
                });
                this.filteredVendors = Array.from(response as any[], (value) => {
                    return new VendorHeader(value);
                });
                return true;
            })
            .catch(this.handleError);
    }

    public GetRetailersForVendor(vendorId: number, nearZipCode: string): Promise<boolean> {
        return this.http.get(this.apiUrl + 'VendorRetailers?vendorId=' + vendorId + '&nearZipCode=' + nearZipCode)
            .toPromise()
            .then(response => {
                this.vendorRetailers = Array.from(response as any[], (value) => {
                    return new VendorHeader(value);
                });
                return true;
            })
            .catch(this.handleError);
    }

    public GetSponsoringDealerForPreApproval(stagingId: number): Promise<VendorHeader> {
        return this.http.get(this.apiUrl + 'GetSponsoringDealer?preApprovalStagingId=' + stagingId)
            .toPromise()
            .then(response => {
                this.sponsoringVendor = new VendorHeader();
                if (response) {
                    this.sponsoringVendor = new VendorHeader(response);
                }
                return this.sponsoringVendor;
            })
            .catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}

