import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'rol-preferences-email-confirmation',
    templateUrl: './preferences-email-confirmation.component.html',
    styleUrls: ['../../../../app/dashboard/preferences/account-preferences.component.css', './preferences-email-confirmation.component.css']

})
export class PreferencesEmailConfirmationComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit() {
        if (window.top.location !== window.location) {
            window.top.location = window.location;
        }
    }

    closeComplete() {
        this.router.navigate(['/dashboard/preferences/contact']);
        window.scrollTo(0, 0);
    }
}
