import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import { AuthenticatedHttp } from './authenticated-http.service';
import { AuthorizedPartyRequest } from '../models/authorized-party-request';
import { AuthorizedPartyWorkflow } from '../models/authorized-party-workflow';
import { CONFIG } from '../../environments/environment';

@Injectable()
export class AuthorizedPartyService {
    private resourceUrl = `${CONFIG.apiBaseUri}AuthorizedParty`;
    private headers = new HttpHeaders({ 'content-type': 'application/json' });

    constructor(private http: AuthenticatedHttp) { }

    public addAuthorizedParty(authorizedParty: AuthorizedPartyRequest):
        Promise<{ DocuSignUrl: string, AuthorizedPartyRequestId: number, Error: string }> {
        const json = JSON.stringify(authorizedParty);
        const url = `${this.resourceUrl}/Add`;
        return this.http.post(url, json, { headers: this.headers }).toPromise()
            .then((response) => {
                if (response && response) {
                    return response;
                }
            })
            .catch((error) => this.handleError(error));
    }

    public getAllAuthorizedPartyWorkflows(acctRefNo: string): Promise<AuthorizedPartyWorkflow[] | void> {
        const url = `${this.resourceUrl}/Pending/${acctRefNo}`;
        return this.http.get(url, { headers: this.headers }).toPromise()
            .then((response) => {
                if (response && response) {
                    return response.map(x => new AuthorizedPartyWorkflow(x));
                }
            })
            .catch((error) => this.handleError(error));
    }

    public getDocuSignURL(requestId: number): Promise<string | void> {
        const url = `${this.resourceUrl}/Sign/${requestId}`;
        return this.http.get(url, { headers: this.headers }).toPromise()
            .then((response) => {
                if (response && response) {
                    return response;
                }
            })
            .catch((error) => this.handleError(error));
    }

    public cancelAuthorizedPartyRequest(requestId: number): Promise<void> {
        const url = `${this.resourceUrl}/Cancel/${requestId}`;
        return this.http.post(url, null, { headers: this.headers }).toPromise()
            .then((response) => {
                if (response) {
                    return null;
                }
            })
            .catch((error) => this.handleError(error));
    }

    public getPrintableRequest(id: number, accountRefNo: any): Promise<Blob> {
        const headers = new Headers();
        headers.append('Content-type', 'application/json');
        const url = `${this.resourceUrl}/printable/${id}/${accountRefNo}`;

        return this.http
            .get(url, { headers: headers })
            .toPromise()
            .then(res => {
                const byteChars = atob(res);
                const byteNumbers = new Array(byteChars.length);
                for (let i = 0; i < byteChars.length; i++) {
                    byteNumbers[i] = byteChars.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                const blob = new Blob([byteArray], { type: 'application/pdf' });
                return blob;
            })
            .catch(this.handleError);
    }

    private handleError(error: any) {
        const json = error;
        if (json && json.Message) {
            const msg = json.Message;
            return Promise.reject(msg);
        } else {
            console.error('An error occurred', error.message || error);
            return Promise.reject(error.message || error);
        }
    }
}
