import { Component, OnInit, AfterViewInit, ViewChildren, Input } from '@angular/core';

import { UtilitiesService } from '../../services/utilities.service';

import { TopForm } from '../top-form';
import { NgModel, FormGroup } from '@angular/forms';
import { TradeReference } from '../../models/trade-reference';
import { phoneMask } from '../reusables/phone/phone.component';

@Component({
    selector: 'rol-trade-reference',
    templateUrl: 'trade-reference.component.html',
    styleUrls: ['../styles_form.css']
})
export class TradeReferenceComponent implements OnInit, AfterViewInit {
    @ViewChildren(NgModel) inputChildren;

    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() reference: TradeReference;

    phoneMask = phoneMask;
    private _utilities: UtilitiesService;

    constructor(utilities: UtilitiesService) {
        this._utilities = utilities;
    }

    ngOnInit() {
        if (this.reference.phoneNumber) {
            this.reference.phoneNumber = this._utilities.formatPhoneNumber(this.reference.phoneNumber);
        }
        if (this.reference.faxNumber) {
            this.reference.faxNumber = this._utilities.formatPhoneNumber(this.reference.faxNumber);
        }
    }

    ngAfterViewInit() {
        this.inputChildren.forEach((item) => {
            this.group.addControl(item.name, item.control);
        });
    }
}
