import { VERSIONCONFIG as  V2CONFIG } from './appv2/version-config'
import { CONFIG } from './environments/environment'

export class AppVersionHelper {

    public static isVersion1() {
        return !this.isVersion2();
    }

    public static isVersion2() {
        if(!CONFIG.enableAppV2) {
            return false;
        }

        return window.location.pathname.includes(V2CONFIG.bootstrapPath) || CONFIG.migrateAppV2;
    }

    public static get versionRoutePrefix() : string {
        if(this.isVersion2()) {
            return V2CONFIG.bootstrapPath;
        } else {
            return '';
        }
    }

    public static getVersionRoute(path: string): string {
        const pathFromRoot = path.startsWith('/') ? path : `/${path}`;
        const versionAwarePath = `${AppVersionHelper.versionRoutePrefix}${pathFromRoot}`;
        return versionAwarePath;
    }

    public static get versionPath() : string {
        if(this.isVersion2()) {
            return V2CONFIG.path;
        } else {
            return '';
        }
    }
}