import { Component, OnInit, AfterViewInit, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { IncreasedRiskCountriesComponent } from '../increased-risk-countries/increased-risk-countries.component';

import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';
import { CurrentApplicationService } from '../../services/current-application.service';

import { LineOfBusinesses } from '../../models/line-of-businesses';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { FormGroup } from '@angular/forms';
import { TradeReference } from '../../models/trade-reference';

@Component({
    selector: 'rol-line-of-business-information',
    templateUrl: 'line-of-business-information.component.html',
    styleUrls: ['../styles_form.css', 'line-of-business-information.component.css']
})
export class LineOfBusinessInformationComponent extends AbstractFormPage implements AfterViewInit, OnChanges {
    businesses = LineOfBusinesses;
    isOpen: boolean[];
    accordionMap: number[];
    formGroups: FormGroup[];

    @ViewChild(IncreasedRiskCountriesComponent) increasedRiskCountriesComponent: IncreasedRiskCountriesComponent;

    form: TopForm;

    ngOnChanges(LOB) {
        this.application.lineOfBusiness = LOB;
    }

    ngAfterViewInit() {
    }

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService) {
        super(currentAppSvc, router, utilities, history);
        this.pageAlias = 'line-of-business-information';
        this.isOpen = [];
        this.formGroups = [];
        this.accordionMap = [];
        this.initPage();
    }

    afterInitPage(): void {
        this.form = new TopForm();
        if (this.application.tradeReferences.length === 0) {
            this.add();
        } else {
            let opened = false;
            for (let i = 0; i < this.application.tradeReferences.length; ++i) {
                if (!opened && !this.application.tradeReferences[i].companyName) {
                    opened = true;
                    this.isOpen.push(true);
                } else {
                    this.isOpen.push(false);
                }
                this.accordionMap.push(i);
                const group = new FormGroup({});
                this.formGroups.push(group);
                this.form.addControl(this.accordionMap.length.toString(), group);
            }
        }
    }

    submit() {
        this.form.updateValueAndValidity();
        this.form.submitted = true;

        if (this.form.valid && this.application.lineOfBusiness && this.increasedRiskCountriesComponent.isValid) {
            this.next();
        } else {
            this.scrollToError();
        }
    }

    add() {
        this.form.submitted = false;
        this.application.tradeReferences.push(new TradeReference());
        this.closeAllTabs();
        this.accordionMap.push(this.isOpen.length);
        this.isOpen.push(true);
        const group = new FormGroup({});
        this.formGroups.push(group);
        this.form.addControl(this.accordionMap.length.toString(), group);
    }

    delete(i: number, e: MouseEvent) {
        this.application.tradeReferences.splice(i, 1);
        this.isOpen.splice(i, 1);
        this.formGroups.splice(i, 1);
        let found = null;
        this.accordionMap = this.accordionMap.map((mapping, idx) => {
            if (mapping === i) {
                found = true;
                this.form.removeControl((idx + 1).toString());
                return -1;
            } else {
                return found ? mapping - 1 : mapping;
            }
        });
        e.preventDefault();

        if (this.application.tradeReferences.length === 0) {
            this.add();
        }
    }

    tabOpen(e) {
        this.isOpen[this.accordionMap[e.index]] = true;
    }

    tabClose(e) {
        this.isOpen[this.accordionMap[e.index]] = false;
    }

    hasNonWhitespace(s: string) {
        return /[^\s]/g.test(s);
    }

    showDisplayName(tradeReference: TradeReference): boolean {
        return this.hasNonWhitespace(tradeReference.companyName);
    }

    private closeAllTabs() {
        for (let i = 0; i < this.isOpen.length; ++i) {
            this.isOpen[i] = false;
        }
    }
}
