export class ContactPhone {
    PhoneNumber = '';
    IsMobile: boolean;
    IsPrimary: boolean;
    IsSMSNumber: boolean;

    constructor(data?: any) {
        if (data) {
            this.PhoneNumber = data.PhoneNumber;
            this.IsMobile = data.IsMobile;
            this.IsPrimary = data.IsPrimary;
            this.IsSMSNumber = data.IsSMSNumber;
        }
    }
}

