export const TopVendors = [
    { name: 'Ag Venture Pinnacle', id: 193, number: 'D100008' },
    { name: 'Agrigold', id: 39242, number: 'D100102' },
    { name: 'Bayer', id: 78265, number: 'D101326' },
    { name: 'Beck\'s Hybrids', id: 279, number: 'D10505' },
    { name: 'Dairyland Seed', id: 40270, number: 'D100107' },
    { name: 'Dow AgroSciences', id: 74903, number: 'D101216' },
    { name: 'Golden Acres Genetics', id: 77563, number: 'D101268' },
    { name: 'Great Lakes Hybrids', id: 642, number: 'D11213' },
    { name: 'LG Seeds', id: 641, number: 'D10148' },
    { name: 'Mycogen', id: 514, number: 'D9921' },
    { name: 'Phytogen', id: 201, number: 'D100016' },
    { name: 'Producers Hybrids', id: 62388, number: 'D100898' },
    { name: 'Seed Consultants', id: 211, number: 'D10130' },
    { name: 'Syngenta', id: 483, number: 'D1626' },
    { name: 'Wensman', id: 58166, number: 'D100169' }
];

export class Vendor {
    vendorId: number;
    vendorName = '';
    retailerId: number;
    retailer = '';
    retailerAddress = '';
    retailerCity = '';
    retailerState = '';
    retailerZip = '';
    retailerPhone = '';
    creditLimit = 0;
    forceVendor = false;
    businessCentreName = '';
    businessCentreNumber = '';
    businessCentreCustomerNumber = '';

    constructor(appData?: any) {
        if (appData) {
            this.vendorId = appData.VendorId;
            this.vendorName = appData.VendorName;
            this.retailerId = appData.RetailerId;
            this.retailer = appData.Retailer;
            this.retailerCity = appData.RetailerCity;
            this.retailerState = appData.RetailerState;
            this.retailerAddress = appData.RetailerAddress;
            this.retailerZip = appData.RetailerZip;
            this.retailerPhone = appData.RetailerPhone;
            this.creditLimit = appData.CreditLimit;
            this.businessCentreName = appData.BusinessCentreName;
            this.businessCentreNumber = appData.BusinessCentreNumber;
            this.businessCentreCustomerNumber = appData.BusinessCentreCustomerNumber;
        }
    }

    get isTopVendor() {
        return TopVendors.find((value, idx) => {
            return value.id === this.vendorId;
        });
    }
}
