<div class="displayBox">
        <p class=MsoNormal><span lang=EN-GB>This site allows you to view your commitments and balances and to make
                        payments via an Automated Clearing House Network (“ACH”). By utilizing the ACH service, you are
                        authorizing Rabo AgriFinance LLC (“RAF”) to request that your Financial Institution debit your
                        payment amount directly from your account. Please note: RAF utilizes the services of US Bank to
                        process payments and thus your account statement will reflect transactions being processed by US
                        Bank, not RAF Please be aware of this when checking your statements.<br /><br /> The following
                        terms and conditions apply, and use of the ACH service constitutes acceptance of the terms and
                        conditions.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Definitions</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>“You” and “your” refers to the person or persons in whose name the Account
                        has been established. The words “we” and “our” and “us” refer to RAF, its subsidiaries,
                        officers, directors, and employees. “Account” is the bank account, debit card account, or credit
                        card account you have designated as the payment account. “Financial institution” is the bank,
                        credit union, or card issuer who holds or issues your account. “Agreement” refers to the terms
                        and conditions stated below or as amended from time to time.</span> </p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Disclaimers</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>You acknowledge and agree that RAF disclaims to the fullest extent permitted
                        by applicable law all warranties and representations of any kind, whether express, implied or by
                        law, statutory or otherwise, including without limitation any warranties of: (i)
                        merchantability, (ii) fitness for a particular purpose, (iii) non-infringement, (iv) workmanlike
                        effort, (v) quality, (vi) accuracy, (vii) timeliness, (viii) completeness, or (ix) system
                        integration. In no event will RAF be liable for (a) errors caused by you, including but not
                        limited to incorrect or incomplete Account information, insufficient funds, closed accounts, or
                        similar fund access issues, or (b) damages, including indirect, special, incidental or
                        consequential damages, including but not limited to, damages for lost profits, disclosure of
                        confidential information, or loss of privacy, arising out of your use of or inability to use
                        this site. In addition, RAF will not be liable for any act or omission by any third party,
                        including but not limited to your Financial Institution, any payment system, or any provider of
                        Internet access, computer equipment or software, or for any circumstances beyond our control
                        including but not limited to fire, flood, natural disaster, war, riot, strikes, equipment
                        failure, computer virus, or interruption of electrical or other utility services.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Limitations</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>You agree not to make a payment that exceeds the funds or available credit
                        line in your Account. If you do, RAF has no obligation to complete the transaction and may debit
                        your Account for any fees associated with insufficient fund transfer.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Liability</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>You remain liable for all transactions initiated via ACH. Your obligation to
                        RAF remains in effect in the event of a dishonoured payment and you agree to pay all costs and
                        expenses RAF may incur in order to collect any amount owed.</span></p>
</div>