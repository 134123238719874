import { Address } from './address';

export class VendorHeader {
    rowId: number;
    dealerNumber: string;
    name: string;
    address: Address = new Address(true);
    phoneNumber: string;

    constructor(appData?: any) {
        if (appData) {
            this.rowId = appData.RowId;
            this.dealerNumber = appData.DealerNumber;
            this.name = appData.Name;
            this.address = new Address(true, appData.Address);
            this.phoneNumber = appData.PhoneNumber;
        }
    }

    getItemName(): string {
        return this.name + ' - ' + this.address.line1 + ', ' + this.address.city + ' ' + this.address.state + ' ' + this.address.zipcode;
    }

    getShortName(): string {
        return this.name + ' - ' + this.address.city + ' ' + this.address.state;
    }
}
