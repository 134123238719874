<div class="container stage main_container">
    <rol-side-nav selectedAlias="{{pageAlias}}"></rol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Ownership Information</h1>

        </div>
    </div>
    <hr />
    <div class="row">
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div>
                <p class="text-small">All fields are required unless otherwise noted.</p>
            </div>
        </div>
    </div>
    <div class="spacer">
        <div fxFlex.gt-md="60">
            <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application">
                <div class="well">
                    <label>Are there any intermediary Companies in the Borrower's organizational structure?</label>
                    <br />
                    <label>No&nbsp;</label>
                    <p-radioButton name="hasIntermediaryOwner" [value]="false" [formControl]="intermediaryOwnerControl"></p-radioButton>
                    <label>&nbsp;Yes&nbsp;</label>
                    <p-radioButton name="hasIntermediaryOwner" [value]="true" [formControl]="intermediaryOwnerControl"></p-radioButton>
                    <p *ngIf="application.hasIntermediaryOwner">
                        Please complete the <a href="assets/Additional_Information_on_Intermediary_Companies.pdf" target="_blank">Intermediary Company(ies) Information Form</a>
                        for each Intermediary Company. Print additional pages if required. This form is required for the application to be processed.
                    </p>
                    <br *ngIf="!application.hasIntermediaryOwner" />
                    <label>Is there a Trust in the Loan Applicant's ownership structure?</label>
                    <br />
                    <label>No&nbsp;</label>
                    <p-radioButton name="hasTrust" [value]="false" [formControl]="trustControl"></p-radioButton>
                    <label>&nbsp;Yes&nbsp;</label>
                    <p-radioButton name="hasTrust" [value]="true" [formControl]="trustControl"></p-radioButton>
                    <p *ngIf="application.hasTrust">
                        Please complete the <a href="assets/Trust_Information_Form.pdf" target="_blank">Trust Information Form</a>.
                        Print additional pages if required. This form is required for the application to be processed.
                    </p>
                    <br *ngIf="!application.hasTrust" />
                    <label>Is there a Signatory who is neither a Primary Applicant Co-Borrower nor a Joint Applicant Co-Borrower?</label>
                    <br />
                    <label>No&nbsp;</label>
                    <p-radioButton name="hasAdditionalSignatoryVerification" [value]="false" [formControl]="additionalSignatoryControl"></p-radioButton>
                    <label>&nbsp;Yes&nbsp;</label>
                    <p-radioButton name="hasAdditionalSignatoryVerification" [value]="true" [formControl]="additionalSignatoryControl"></p-radioButton>
                    <p class="no-margin" *ngIf="application.hasAdditionalSignatoryVerification">
                        Both the Signatory and the Richardson Representative must complete the
                        <a href="assets/Additional_Signatory_Identity_Verification_Form.pdf" target="_blank">Signatory Identity Verification Form</a>.
                        Print additional pages if required. This form is required for the application to be processed.
                    </p>
                </div>
                <div class="patriot-act disclaimer" *ngIf="application">
                    <div *ngIf="isPartnershipApplication">
                        <h4>Please provide the names of all individuals that:</h4>
                        <ul>
                            <li>Ultimately, directly or indirectly 
                                <strong>have at least 25% of the partnership interest</strong>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="!isPartnershipApplication">
                        <h4>Please provide the names of all individuals that:</h4>
                        <ul>
                            <li>Ultimately, directly or indirectly 
                                <strong>
                                    own at least 25% of the outstanding common shares issued by the Borrower (in the case of a corporation)
                                </strong>
                            </li>
                            <li>Ultimately, directly or indirectly
                                <strong>have at least 25% of the voting control in the Borrower</strong>
                            </li>
                        </ul>
                    </div>
                </div>
                <h4>Co-Borrowers/Owners:</h4>
                <div> Please add Beneficial Owner information for the primary applicant and all listed joint applicant co-borrowers.</div>

                <p-accordion (onClose)="tabClose($event)" (onOpen)="tabOpen($event, 0)">
                    <div>
                        <p></p>
                        <div class="row applicant-ribbon">Primary Applicant Co-Borrower</div>
                    </div>
                    <div *ngFor="let o of primaryApplicants; let i=index;">
                        <p-accordionTab [selected]="isOpen[i]">
                            <p-header [ngClass]="{'error': form.submitted && !formGroups[i].valid, 'ng-invalid': form.submitted && !formGroups[i].valid && !isOpen[i]}">
                                <span>{{o.fullName.firstName}} {{o.fullName.lastName}}</span>
                            </p-header>
                            <rol-owner-detail [owner]="o" [form]="form" [group]="formGroups[i]" [isBorrower]="o.isBorrower"
                                mode="borrower"></rol-owner-detail>
                        </p-accordionTab>
                    </div>
                </p-accordion>

                <p-accordion (onClose)="tabClose($event)" (onOpen)="tabOpen($event, 1)">
                    <div>
                        <p></p>
                        <div class="row applicant-ribbon">Joint Applicant Co-Borrowers</div>
                        <div *ngIf="jointApplicants.length === 0">No Joint Applicant Co-Borrowers</div>
                    </div>
                    <div *ngFor="let o of jointApplicants; let i=index;">
                        <p-accordionTab [selected]="isOpen[calcJointApplicantIndex(i)]">
                            <p-header [ngClass]="{'error': form.submitted && !formGroups[i+1].valid, 'ng-invalid': form.submitted && !formGroups[i+1].valid && !isOpen[i+1]}">
                                <span>{{o.fullName.firstName}} {{o.fullName.lastName}}</span>
                            </p-header>
                            <rol-owner-detail [owner]="o" [form]="form" [group]="formGroups[calcJointApplicantIndex(i)]" [isBorrower]="o.isBorrower"
                                mode="borrower"></rol-owner-detail>
                        </p-accordionTab>
                    </div>
                </p-accordion>

                <div class="beneficial-owners-section">
                    <h4>Beneficial Owners:&nbsp;
                        <rol-help-tip>
                            A beneficial owner is a person who –
                            <ul>
                                <li *ngIf="isPartnershipApplication">Ultimately, directly or indirectly has at least 25% of the partnership interest</li>
                                <li *ngIf="!isPartnershipApplication">Ultimately, directly or indirectly owns at least 25% of the outstanding common shares issued by the Borrower (in the case of a corporation)</li>
                                <li *ngIf="!isPartnershipApplication">Ultimately, directly or indirectly has at least 25% of the voting control in the Borrower</li>
                            </ul>
                        </rol-help-tip>
                    </h4>
                    <div>If all Beneficial Owners have not been identified, please add below.</div>
                    <p></p>
    
                    <p-accordion (onClose)="tabClose($event)" (onOpen)="tabOpen($event, calcBeneficialIndex(0))">
                        <div *ngFor="let o of beneficialOwners; let i=index;">
                            <p-accordionTab [selected]="isOpen[calcBeneficialIndex(i)]">
                                <p-header
                                    [ngClass]="{'error': form.submitted && !formGroups[calcBeneficialIndex(i)].valid, 'ng-invalid': form.submitted && !formGroups[calcBeneficialIndex(i)].valid && !isOpen[calcBeneficialIndex(i)]}">
                                    <span *ngIf="!showDisplayName(o)" class="new">(new)</span>
                                    <span *ngIf="showDisplayName(o)">{{o.fullName.firstName}} {{o.fullName.lastName}}</span>
                                    <span class="actionicon" (click)="delete(calcBeneficialIndex(i), $event)">
                                        Delete
                                        <i class="glyphicon fa fa-trash-o"></i>
                                    </span>
                                </p-header>
                                <rol-owner-detail [owner]="o" [form]="form" [group]="formGroups[calcBeneficialIndex(i)]" [isBorrower]="o.isBorrower"
                                    mode="borrower"></rol-owner-detail>
                            </p-accordionTab>
                        </div>
                    </p-accordion>
    
                    <div class="group">
                        <button type="button" class="btn btn-primary" (click)="add()">Add Beneficial Owner</button>
                    </div>
                </div>

                <div *ngIf="!isPartnershipApplication" class="spacer">
                    <rol-directors [form]="form"
                                   [directors]="application.directors"
                                   [(directorRequiredError)]="directorRequiredError">
                    </rol-directors>
                </div>

                <div class="group">
                    <button *ngIf="showBack" class="btn btn-secondary back-btn" type="button"
                        (click)="back()">Back</button>
                    <button class="btn btn-primary" type="submit">Continue</button>
                </div>
            </form>
        </div>
    </div>
    <div style="clear:both"></div>
</div>