<div class="well">
    <fieldset>
        <legend>
            <span class="spanTitle">
                {{Title}}
            </span>
            <span class="spanSelectExisting dropdown" *ngIf="!disabled && addressList.length > 0">
                <a tabindex="0" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                    aria-expanded="false">Use existing address <span class="caret"></span></a>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let a of addressList"><a (click)="addressListSelected(a)">{{a.toString()}}</a></li>
                </ul>
            </span>
        </legend>

        <div class="form-group">
            <label for="street">Civic or Rural route address or legal land description (do not use a PO Box/Box address)</label>
            <input name="street" class="form-control" [formControl]="streetCtrl" [(ngModel)]="address.line1"
                placeholder="Address" (ngModelChange)="onChangeAddress()"  />
            <div *ngIf="(form.submitted || streetCtrl.dirty) && streetCtrl.invalid" class="error">
                <p *ngIf="streetCtrl.errors['required']">Required</p>
                <p *ngIf="streetCtrl.errors['isPOBox']">P.O. Box is not valid for physical addresses.</p>
                <p *ngIf="streetCtrl.errors['isBox']">Please ensure the above address is a physical address and not solely a box number.</p>
            </div>
        </div>

        <div class="form-group">
            <label for="city">City</label>
            <input name="city" class="form-control" [formControl]="cityCtrl" [(ngModel)]="address.city"
                placeholder="City" (ngModelChange)="onChangeAddress()"  />
            <div *ngIf="(form.submitted || cityCtrl.dirty) && cityCtrl.invalid" class="error">
                <p *ngIf="cityCtrl.errors['required']">Required</p>
            </div>
        </div>

        <rol-physical-address-state-select name="addressState" [(ngModel)]="address.state" Title="Province" [form]="form" [group]="group"
            (ngModelChange)="onChangeAddress()" ></rol-physical-address-state-select>

        <rol-physicial-address-zip-component name="zip" [(ngModel)]="address.zipcode" Title="Postal Code" [form]="form" [group]="group"
            (ngModelChange)="onChangeAddress()" ></rol-physicial-address-zip-component>

    </fieldset>
</div>