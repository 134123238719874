import { Component, OnInit } from '@angular/core';
import { IfAuthService } from 'if-angular-security';
import { IRedirectRequestParams } from 'if-angular-security/lib/models/redirect-request-params.interface';
import { CONFIG } from '../../../../environments/environment';
import { StringDict } from 'if-angular-security/lib/models/string-dict.type';
import { RichardsonOnlineWebsiteName } from '../../../../app/models/constants';

@Component({
    selector: 'rol-preferences-contact',
    templateUrl: './preferences-contact-frame.component.html',
    styleUrls: ['./preferences-contact-frame.component.css']
})
export class PreferencesContactFrameComponent implements OnInit {
    constructor(private ifAuthSecurityService: IfAuthService) { }

    ngOnInit() {
        const queryParams: StringDict = {};
        queryParams['app'] = RichardsonOnlineWebsiteName;

        const redirectRequest: IRedirectRequestParams = {
            policy : CONFIG.b2cEmailChangeFrame.policy,
            redirectUri: CONFIG.b2cEmailChangeFrame.redirectUri,
            extraQueryParameters: queryParams
        };

        this.ifAuthSecurityService.loginRedirect(redirectRequest);
    }
}
