import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { validatePasswordFactory, passwordCompareFactory } from '../../validation/password-validator.directive';
import { TopForm } from '../../top-form';

@Component({
    selector: 'rol-password-expired',
    templateUrl: 'password-expired.component.html',
    styleUrls: []
})
export class PasswordExpiredComponent {
    @Input() email: string;
    @Input() oldPassword: string;
    @Output() passwordUpdated: EventEmitter<string> = new EventEmitter<string>();
    newPassword: string;
    confirmNewPassword: string;
    serverError: string;
    password: FormControl = new FormControl('', [Validators.required, validatePasswordFactory()]);
    confirmPassword: FormControl = new FormControl('', Validators.required);
    passwordGroup: FormGroup;
    form: TopForm;
    showConfirmation: boolean;

    constructor(private router: Router, private authService: AuthService) {
        this.form = new TopForm();
        this.showConfirmation = false;
        this.passwordGroup = new FormGroup({
            'password': this.password,
            'confirmPassword': this.confirmPassword
        }, passwordCompareFactory());
        this.form.addControl('passwordGroup', this.passwordGroup);
    }

    passwordValidationMessage(errorObj) {
        let message = 'Password is invalid: ';
        // tslint:disable-next-line: forin
        for (const error in errorObj) {
            message += errorObj[error] + '; ';
        }
        return message;
    }

    submit() {
        this.form.submitted = true;
        this.serverError = '';
        if (!this.showConfirmation) { 
            if (this.form.valid) {
                this.authService.ChangeExpiredPassword(this.email, this.oldPassword, this.newPassword)
                    .then(() => {
                        this.showConfirmation = true;
                    })
                    .catch((rejected) => {
                        this.showConfirmation = false;
                        this.serverError = rejected;
                    });
            } else {
                const element: any = document.querySelector('.ng-invalid');
                if (element) {
                    element.scrollIntoView();
                    window.scrollBy(0, -100);
                }
            }
        }
    }

    successAndReturn() {
        this.passwordUpdated.emit(this.newPassword);
    }
}
