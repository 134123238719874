export class Credentials {
    email: string;
    password: string;
    question: string;
    answer: string;

    constructor(props?: any) {
        if (props) {
            this.email = props.email;
            this.password = props.password;
            this.question = props.question;
            this.answer = props.answer;
        }
    }
}