import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { TopForm } from '../../top-form';
import { validateCheckboxFactory } from '../../validation/checkbox-required.directive';
import { validateEmailFactory } from '../../validation/email-validator.directive';
import { User } from '../../../models/user';
import { AuthService, IAuthService } from '../../../services/auth.service';


@Component({
    selector: 'rol-security-question',
    templateUrl: 'security-question.component.html',
    styles: [`.buttonContainer { height:50px }`]
})
export class SecurityQuestionComponent {

    form: TopForm;
    @Input() securityQuestion = '';
    @Input() header: string;
    @Input() email: string;
    @Input() password: string;
    @Input() securityAnswer: string;
    answerForm: FormControl;
    @Input() serverError = '';
    @Input() loginMessage= '';
    @Output() answerUpdated: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
        this.form = new TopForm();
        this.answerForm = new FormControl('', Validators.required);
        this.form.addControl('securityAnswer', this.answerForm);
    }

    updateAnswer(value: string) {
        this.answerUpdated.emit(value);
    }
}
