import { Component } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Registree } from '../../models/register';
import { TopForm } from '../../form/top-form';
import { SecurityQuestion } from '../../models/security-question';
import { CurrentRegistrationService } from '../../services/current-registration.service';
import { SecurityQuestions } from '../../models/security-questions';

const SECURITY_QUESTIONS_COUNT = 5;

@Component({
    selector: 'rol-registration-security',
    templateUrl: 'registration-security.component.html',
    styleUrls: ['../css/styles_form.css'],
})

export class RegistrationSecurityComponent {
    form: TopForm = new TopForm();
    serverError = '';
    emailError = '';
    registree: Registree;
    questionsGroup: FormGroup;
    possibleSecurityQuestions: string[] = SecurityQuestions;

    constructor(public router: Router, public registrationService: CurrentRegistrationService) {
        this.initPage();
        this.questionsGroup = new FormGroup({}, this.noDuplicateQuestionsFactory());
        for (let x = 0; x < SECURITY_QUESTIONS_COUNT; ++x) {
            if (this.registree.securityQuestions.length <= x) {
                this.registree.securityQuestions.push(new SecurityQuestion());
            }
            const questionFC = new FormControl('', Validators.required);
            const answerFC = new FormControl('', Validators.required);
            this.questionsGroup.addControl('questionControl' + x, questionFC);
            this.questionsGroup.addControl('answerControl' + x, answerFC);
        }
        this.form.addControl('questionGroup', this.questionsGroup);
    }

    initPage() {
        this.registree = this.registrationService.getCurrentRegistree();
        if ((this.registree.isExistingCustomer && this.registree.cifNo === 0) || !this.registree.email.length) {
            this.registrationService.back();
        }
        window.scrollTo(0, 0);
    }

    changeSecurityQuestion(securityQuestion: SecurityQuestion, questionValue: string) {
        securityQuestion.question = questionValue;
    }

    noDuplicateQuestionsFactory() {
        const self = this;
        return function (g: FormGroup) {
            const selectedQuestions = {};
            if (self.registree && self.registree.securityQuestions) {
                for (let i = 0; i < self.registree.securityQuestions.length; ++i) {
                    const value = self.registree.securityQuestions[i].question;
                    if (value) {
                        if (value in selectedQuestions) {
                            return { duplicateQuestions: true };
                        }
                        selectedQuestions[value] = true;
                    }
                }
            }
            return null;
        };
    }

    continue() {
        this.form.submitted = true;
        this.serverError = '';
        if (this.form.valid) {
            this.registrationService.setCurrentRegistree(this.registree);
            this.router.navigate(['/registration/registration-agreement']);
        }
    }
}
