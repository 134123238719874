<div class="container preapprovalLanding">
    <h1 class="row preapprovalBanner">
        Pre-Screened Offer Acceptance
    </h1>
    <div class="col-lg-12">&nbsp;</div>
    <div class="col-lg-12">
        <form ngFormModel="form" (ngSubmit)="submit()">
            <div class="form-group">
                <label for="code">Enter Pre-Screened Offer Acceptance Code</label>
                <input name="code" [formControl]="code" class="form-control" [autofocus] />
                <div class="error" *ngIf="(form.submitted || code.dirty) && code.invalid">
                    <p *ngIf="code.errors['required']">Required</p>
                </div>
            </div>

            <div class="form-group">
                <label for="name">Last Name</label>
                <input name="name" type="name" [formControl]="name" class="form-control" autocomplete="off" />
                <div class="error" *ngIf="(form.submitted || name.dirty) && name.invalid">
                    <p *ngIf="name.errors['required']">Required</p>
                </div>
            </div>
            <div class="btn-container">
                <div>
                    <h4>Are you a robot?</h4>
                    <rol-re-captcha (captchaResponse)="recaptchaResolved($event)"></rol-re-captcha>
                    <div class="error" *ngIf="form.submitted && recaptchaToken == ''">
                        <p>Required</p>
                    </div>
                </div>
                <div class="RightAlign">
                    <button class="btn btn-primary" type="submit" [disabled]="submitting">Continue</button>
                    <button type="button" class="btn btn-secondary" (click)="cancel()"
                        [disabled]="submitting">Cancel</button>
                </div>

            </div>
            <div class="error" *ngIf="serverError">
                <p [innerHtml]="serverError | safeHtml"></p>
            </div>
        </form>
    </div>
</div>