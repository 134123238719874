import { Transaction } from './transaction';

export class Loan {
    AcctRefNo: number;
    LoanNumber: string;
    MaturityDate: Date;
    Balance: number;
    PrincipalBalance: number;
    ProgramName: string;
    InterestRate: number;
    Transactions: Transaction[];
    NextRateDate: Date;

    constructor(data?: any) {
        if (data) {
            this.LoanNumber = data.LoanNumber;
            this.AcctRefNo = data.AcctRefNo;
            this.MaturityDate = data.MaturityDate ? new Date(data.MaturityDate) : null;
            this.PrincipalBalance = data.PrincipalBalance;
            this.Balance = data.Balance;
            this.ProgramName = data.ProgramName;
            this.InterestRate = data.InterestRate;
            this.Transactions = data.Transactions ? Array.from(data.Transactions, (d) => new Transaction(d)) : null;
            this.NextRateDate = data.NextRateDate ? new Date(data.NextRateDate) : null;
        }
    }

    get UpcomingPaymentRequired(): boolean {
        const maxDays = 30;
        const withinTimeframe = new Date();
        withinTimeframe.setDate(withinTimeframe.getDate() + maxDays);

        return (this.MaturityDate < withinTimeframe && this.Balance > 0);
    }
}
