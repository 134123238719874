<div class="well">
    <fieldset>
        <legend>
            <span class="spanTitle">
                <span *ngIf="showHomeAddressPrefix">Home </span>{{Title}}
            </span>
            <span class="spanSelectExisting dropdown" *ngIf="!disabled && addressList.length > 0">
                <a tabindex="0" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                    aria-expanded="false">Use existing address <span class="caret"></span></a>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let a of addressList"><a (click)="addressListSelected(a)">{{a.toString()}}</a></li>
                </ul>
                <!--select class="form-control" (change)="addressListSelected($event.target.value)">
                    <option value=''></option>
                    <option *ngFor="let a of addressList" value="{{serializeAddress(a)}}">{{a.toString()}}</option>
                </select-->
            </span>
        </legend>

        <div class="form-group">
            <label for="street"><span *ngIf="showHomeAddressPrefix">Home </span>Address</label>
            <input name="street" class="form-control" [formControl]="streetCtrl" [(ngModel)]="address.line1"
                placeholder="Address" (ngModelChange)="updateFn()" />
            <div *ngIf="(form.submitted || streetCtrl.dirty) && streetCtrl.invalid" class="error">
                <p *ngIf="streetCtrl.errors['required']">Required</p>
                <p *ngIf="streetCtrl.errors['isPOBox']">P.O. Box is not valid for physical addresses.</p>
            </div>
        </div>

        <div class="form-group">
            <label for="city">City</label>
            <input name="city" class="form-control" [formControl]="cityCtrl" [(ngModel)]="address.city"
                placeholder="City" (ngModelChange)="updateFn()" />
            <div *ngIf="(form.submitted || cityCtrl.dirty) && cityCtrl.invalid" class="error">
                <p *ngIf="cityCtrl.errors['required']">Required</p>
            </div>
        </div>

        <rol-state-select name="addressState" [(ngModel)]="address.state" Title="State" [form]="form" [group]="group"
            (ngModelChange)="updateFn()" [disabled]="disabled"></rol-state-select>

        <rol-zip-component name="zip" [(ngModel)]="address.zipcode" Title="ZIP Code" [form]="form" [group]="group"
            (ngModelChange)="updateFn()" [disabled]="disabled"></rol-zip-component>

    </fieldset>
</div>