<div class="form-group dbas" *ngIf="dbaFormGroup" [formGroup]="dbaFormGroup">
    <label for="doingBusinessAs">Doing Business As</label>
    <rol-help-tip>If your entity uses any trade names or does business under any other names, please list them here.
    </rol-help-tip>
    <div class="dba-row">
        <div class="row mb-3" *ngFor="let dbaItem of dbas; let i = index">
            <div class="col-10">
                <input class="form-control" [(ngModel)]="application.entity.doingBusinessAs[i]"
                    [formControlName]="dbaItem.name">
                <div class="error" *ngIf="(dbaItem.control.dirty || form.submitted) && dbaItem.control.invalid">Required
                </div>
            </div>
            <div class="col-2 dba-options">
                <a tabindex="0" (click)="removeDBA(dbaItem)"><i class="glyphicon fa fa-trash-o"></i> Remove</a>
            </div>
        </div>
        <button type="button" class="btn btn-secondary btn-add" (click)="addDBA()">Add</button>
    </div>
</div>