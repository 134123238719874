<div class="preference-group group raised-card">
    <h2>Update Name</h2>
    <div *ngIf="!loading && !contactInfoChangeComplete">
        <form [formGroup]="contactInfoForm" class="preference-group group" (ngSubmit)="contactInfoSubmit()" novalidate>
            <div class="form-group">
                <label for="fName">First Name</label>
                <input type="text" id="fName" class="form-control" formControlName="firstName"
                    [(ngModel)]="user.firstName">
                <div *ngIf="(contactInfoForm.submitted || contactInfoForm.controls['firstName'].dirty) && !contactInfoForm.controls.firstName.valid"
                    class="alert alert-danger">Your first name is required</div>
            </div>
            <div class="form-group">
                <label for="lName">Last Name</label>
                <input type="text" id="lName" class="form-control" formControlName="lastName"
                    [(ngModel)]="user.lastName">
                <div *ngIf="(contactInfoForm.submitted || contactInfoForm.controls['lastName'].dirty) && !contactInfoForm.controls.lastName.valid"
                    class="alert alert-danger">Your last name is required</div>
            </div>
            <div class="error" *ngIf="updateInfoError">
                {{updateInfoError}}
            </div>
            <button type="submit" class="btn btn-primary" [disabled]="contactInfoForm.invalid">Update</button>
        </form>
    </div>
    <div *ngIf="contactInfoChangeComplete">
        <p>Your account was successfully updated!</p>
        <button type="button" class="btn btn-primary" (click)="contactInfoChangeComplete = false">Back</button>
    </div>
</div>