import { ValidationErrors, ValidatorFn, FormGroup } from '@angular/forms';

export const AtLeastOneValidator: ValidatorFn = (group: FormGroup): ValidationErrors | null => {

    for (const c in group.controls) { 
        const control = group.get(c);
        if (control.value !== null && control.value !== '') {
            return null;
        }
    }

    return { atLeastOneRequired: true };
};
