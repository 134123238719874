import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { Vendor } from '../../models/vendor';
import { HistoryService } from '../../services/history.service';


@Component({
    selector: 'rol-vendor',
    templateUrl: 'vendor.component.html',
    styleUrls: ['../styles_form.css', 'vendor-programs.component.css']
})
export class VendorComponent extends AbstractFormPage {
    group: FormGroup;
    form: TopForm;
    preApproved = false;
    showModal = false;

    private readonly vendorName = 'Ag Partner Financing';

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService) {
        super(currentAppSvc, router, utilities, history);
        this.pageAlias = 'program';
        this.initPage();
    }

    afterInitPage() {
        this.form = new TopForm();
        this.group = new FormGroup({});
        this.form.addControl('group', this.group);
        if (this.application.vendors.length === 0) {
            this.add();
        } else {
            this.application.vendors[0].vendorName = this.vendorName;
        }

        if (this.application.borrowers[0]) {
            this.preApproved = this.application.borrowers[0].isPreApproved;
        }
    }

    add() {
        const vendor = new Vendor();
        vendor.vendorName = this.vendorName;
        this.application.vendors.push(vendor);
        window.scrollTo(0, 0);
    }

    submit() {
        this.form.updateValueAndValidity();

        if (this.form.valid && this.application.preApprovalStagingId && !this.comparePreApprovalData()) {
            this.showModal = true;
            return;
        }

        this.form.submitted = true;
        if (this.form.valid) {
            this.next();
        } else {
            this.scrollToError();
        }
    }

    comparePreApprovalData() {
        const defaults = this.application.preApprovalVendor;
        if (this.application.vendors[0].vendorId !== defaults.vendorId) {
            return false;
        }

        if (this.application.vendors[0].creditLimit > 125000) {
            return false;
        }

        return true;
    }

    restorePreApprovalDefaults() {
        const defaults = this.application.preApprovalVendor;

        if (this.application.vendors[0]) {
            this.application.vendors.splice(0, 1);
            this.application.vendors.push(defaults);
        }

        this.application.vendors[0].creditLimit = 0;
        this.showModal = false;
    }

    ProceedWithoutPreApproval() {
        this.application.preApprovalStagingId = null;
        this.application.borrowers[0].isPreApproved = false;
        this.submit();
    }
}
