import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentRegistrationService as CurrentRegistrationServiceV1 } from '../../../app/services/current-registration.service';
import { CurrentRegistrationService as CurrentRegistrationService } from '../../services/current-registration.service';
import { AuthService as AuthServiceV1 } from '../../../app/services/auth.service';
import { RegistrationProfileComponent as RegistrationProfileComponentV1 } from '../../../app/registration/profile/registration-profile.component';

@Component({
    selector: 'rol-registration-profile',
    templateUrl: '../../../app/registration/profile/registration-profile.component.html',
    styleUrls: ['../../../app/registration/css/styles_form.css'],
})

export class RegistrationProfileComponent extends RegistrationProfileComponentV1{
    constructor(public routerV1: Router, 
        private authServiceV1: AuthServiceV1,
        private registrationServiceV1: CurrentRegistrationServiceV1,
        private registrationServiceV2: CurrentRegistrationService,
        ) {
        super(routerV1, authServiceV1, registrationServiceV1)
    }

    continue() {
        this.form.submitted = true;
        if (this.form.valid) {
            this.loading = true;
            this.authServiceV1.CheckAvailability(this.email.value, this.email.value)
                .then((result) => {
                    this.loading = false;
                    if (result === 'Available') {
                        this.registrationServiceV2.setCurrentRegistree(this.registree);
                        //TODO:MFA
                        this.registrationServiceV2.gotoNextPage();
                    } else {
                        this.emailError = '\'' + this.email.value + '\' is already registered';
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.serverError = error;
                })
                .then(() => this.loading = false);
        }
    }
}