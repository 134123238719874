import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';


@Directive({
    selector: '[rolNoDuplicates][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NoDuplicatesValidatorDirective, multi: true }]
})
export class NoDuplicatesValidatorDirective implements Validator {
    @Input() noDuplicates: string[];

    validate(control: AbstractControl): { [key: string]: any } | null {
        if (this.noDuplicates == null || this.noDuplicates === undefined) {
            return null;
        } else {
            return this.noDuplicates.includes(control.value) ? { hasDuplicate: true } : null;
        }
    }
}
