import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class UtilitiesService {

    public clearmask(str: string): string {
        if (!str || typeof str !== 'string') {
            return str;
        }
        const newstr = str.replace(/\//g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/,/g, '').replace('$', '');
        return newstr;
    }

    public toNumber(value: string): number {
        if (_.isNil(value)) { return null; }
        const convert = +(this.clearmask(value));
        if (Number.isNaN(convert)) {
            return null;
        }
        return convert;
    }

    public formatPhoneNumber(phone: string): string {
        if (!phone) {
            return phone;
        }

        const s2 = ('' + phone).replace(/\D/g, '');

        if (s2.length !== 10) {
            return phone;
        }

        const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!m) ? null : `(${m[1]}) ${m[2]}-${m[3]}`;
    }
}
