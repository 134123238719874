import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    storeInLocal(key: string, payload: string): void {
        window.localStorage.setItem(key, payload);
    }

    storeObjectInLocal(key: string, payload: any): void {
        const value = JSON.stringify(payload);
        this.storeInLocal(key, value);
    }

    getFromLocal(key: string): string {
        return window.localStorage.getItem(key);
    }

    getObjectFromLocal(key: string): string {
        return JSON.parse(window.localStorage.getItem(key));
    }

    deleteFromLocal(key: string): void {
        window.localStorage.removeItem(key);
    }

    storeInSession(key: string, payload: string): void {
        window.sessionStorage.setItem(key, payload);
    }

    storeObjectInSession(key: string, payload: any): void {
        const value = JSON.stringify(payload);
        this.storeInSession(key, value);
    }

    getFromSession(key: string): string {
        return window.sessionStorage.getItem(key);
    }

    getObjectFromSession(key: string): any { //TODO:MFA
        return JSON.parse(window.sessionStorage.getItem(key));
    }
}
