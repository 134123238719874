import { Component, OnInit } from '@angular/core';
import { CONFIG } from '../../../../environments/environment';

@Component({
    selector: 'rol-preferences-password',
    templateUrl: 'preferences-password.component.html',
    styleUrls: ['../../../../app/dashboard/preferences/account-preferences.component.css', 'preferences-password.component.css']
})
export class PreferencesPasswordComponent implements OnInit {
    b2cFrameSource: string = CONFIG.b2cPasswordChangeFrame.src;
    b2cFrameHeight: string = CONFIG.b2cPasswordChangeFrame.height;

    constructor() { }

    ngOnInit() {
        window.scroll(0, 0);        
    }
}
