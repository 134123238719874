import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { Commitment } from '../../../models/accounts/commitment';
import { AccountHttpService } from '../../../services/account-http.service';

@Component({
    selector: 'rol-account-details',
    templateUrl: 'account-details.component.html',
    styleUrls: ['account-details.component.css']
})
export class AccountDetailsComponent implements OnInit {

    loading = false;
    error = false;
    errorMessage = '';
    account: Commitment = null;

    constructor(private route: ActivatedRoute, private router: Router, private accountService: AccountHttpService) { }

    ngOnInit(): void {
        this.getAccount();
        window.scrollTo(0, 0);
    }

    getAccount() {
        this.loading = true;
        this.accountService.getCurrentAccount(this.route.snapshot.parent)
            .then(act => this.account = act)
            .catch(err => {
                this.errorMessage = 'Error pulling down account details: ' + err;
                this.error = true;
            })
            .then(() => this.loading = false);
    }
}
