import * as moment from 'moment';
import { UserSecurityProfile } from './user-security-profile';

export class UserExtendedSecurityProfile extends UserSecurityProfile {
    registeredSystem: string;
    cifNo?: number;
    isInternal: boolean;
    lastLoginDate?: Date;
    lastFailedLoginDate?: Date;
    isAnonymous: boolean;

    constructor(appData?: any) {
        super(appData);
        if (appData) {
            this.registeredSystem = appData.RegisteredSystem;
            this.cifNo = appData.CifNo;
            this.isInternal = appData.IsInternal;
            this.lastLoginDate = appData.LastLoginDate ? moment(appData.LastLoginDate).toDate() : null;
            this.lastFailedLoginDate = appData.LastFailedLoginDate ? moment(appData.LastFailedLoginDate).toDate() : null;
            this.isAnonymous = appData.IsAnonymous;
        }
    }
}