<div>
    <div>
        <rol-physical-address-component name="physicalAddress" [(address)]='physicalAddress' (addressChanged)="onChangeAddress($event)" Title="Physical Address"
            [form]="form" [group]="group"></rol-physical-address-component>
    </div>
    <div class="form-group">
        <label for="isMailingAddressSameAsPhysicalAddress">
            Is Mailing Address same as Physical Address?
        </label>
        <select [(ngModel)]="isMailingAddressSameAsPhysicalAddress" (change)="onChangeIsMailingAddressSame($event.target.value)" name="isMailingAddressSameAsPhysicalAddress" required 
            class="form-control">
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    </div>
    <div *ngIf="!isMailingAddressSameAsPhysicalAddress">
        <rol-mailing-address-component name="mailingAddress" [(address)]='mailingAddress' Title="Mailing Address"
            [form]="form" [group]="group"></rol-mailing-address-component>
    </div>
</div>