import { Component, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { Registree } from '../../models/register';
import { TopForm } from '../../form/top-form';
import { CurrentRegistrationService } from '../../services/current-registration.service';
import { AuthService, IAuthService } from '../../services/auth.service';

@Component({
    selector: 'rol-registration-linkaccount',
    templateUrl: 'registration-linkaccount.component.html',
    styleUrls: ['../css/styles_form.css']
})
export class RegistrationLinkAccountComponent {
    registree: Registree;
    loading: boolean;
    serverError = '';

    constructor(public router: Router, private authService: AuthService, public registrationService: CurrentRegistrationService) {
        this.initPage();
    }

    initPage() {
        this.registree = this.registrationService.getCurrentRegistree();
        if (!this.registree.email.length) {
            this.registrationService.back();
        }
        window.scrollTo(0, 0);
    }

    back(triggered: boolean) {
        if (triggered) {
            this.registrationService.back();
        }
    }

    cancel(triggered: boolean) {
        if (triggered) {
            this.registrationService.cancel();
        }
    }

    skip(triggered: boolean) {
        if (triggered) {
            this.registree.isExistingCustomer = false;
            this.registrationService.setCurrentRegistree(this.registree);
            this.router.navigate(['/registration/registration-security']);
        }
    }

    continue(linkedData: any) {
        const cifno = linkedData['cifno'];
        if (cifno > 0) {
            this.registree.cifNo = cifno;
            this.registree.accountNumber = linkedData['accountNumber'];
            this.registree.zip = linkedData['zip'];
            this.registree.ssn = linkedData['ssn'];
            this.registrationService.setCurrentRegistree(this.registree);
            this.router.navigate(['/registration/registration-security']);
        }
    }
}
