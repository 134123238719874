import { Component, Input, forwardRef, QueryList, AfterViewInit, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel, NgForm, FormGroup } from '@angular/forms';
import { TopForm } from '../../top-form';

export const PHYSICAL_ADDRESS_ZIPCODE_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhysicalAddressZipComponent),
    multi: true
};

@Component({
    selector: 'rol-physicial-address-zip-component',
    template: `<div class="form-group">
                <label for="zip">{{Title}}</label>
                <input name="zip" class="form-control" [(ngModel)]="zipCode" (ngModelChange)="update()" placeholder="{{Title}}" 
                    [disabled]="disabled" #zip="ngModel" [textMask]="{mask: mask, guide:false}" required
                    pattern="^[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]$" 
                    maxlength="10" />
                <div class="error" *ngIf="(form.submitted || zip.dirty) && zip.invalid">
                    <p *ngIf="zip.errors['required']">{{requiredErrorMessage}}</p>
                    <p *ngIf="zip.errors['pattern']">{{Title}} is invalid</p>
                </div>
            </div>`,
    providers: [PHYSICAL_ADDRESS_ZIPCODE_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class PhysicalAddressZipComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;

    @Input() form: TopForm;
    @Input() group: FormGroup;

    // This is only used for Canadian addresses. In Canada they refer to it as the postal code, but we refer to the field as zipcode throughout the system.
    mask = postalMask;

    @Input()
    Title = 'POSTAL Code';
    @Input()
    requiredErrorMessage = 'Required';
    @Input()
    disabled = false;

    zipCode: String;

    private onTouchedCallback: () => {};
    private onChangeCallback: (_: any) => {};

    ngAfterViewInit() {
        const useGroup: FormGroup = this.group || this.form;
        this.inputChildren.forEach((item) => {
            useGroup.addControl(this.Title, item.control);
        });
    }

    writeValue(value: any) {
        if (value !== this.zipCode) {
            this.zipCode = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    update() {
        this.zipCode = this.zipCode.toUpperCase();
        this.onChangeCallback(this.zipCode);
    }
}

export function postalMask() {
    let array: any[] = [/[a-zA-Z]/, /\d/, /[a-zA-Z]/, ' ', /\d/, /[a-zA-Z]/, /\d/];
    return array;
}
