<form ngFormModel="form" (ngSubmit)="submit()" novalidate>
    <h2>Password Expired</h2>
    <div [formGroup]="passwordGroup">
        <div class="form-group">
            <label for="password">New Password</label>
            <input id="password" name="password" type="password" [formControl]="password" class="form-control"
                [(ngModel)]="newPassword" placeholder="New Password" autocomplete="off" />
            <div class="error" *ngIf="(form.submitted || password.dirty) && password.invalid">
                <p *ngIf="password.errors['required']">Required</p>
                <p *ngIf="password.errors['passwordValidation']">
                    {{passwordValidationMessage(password.errors['passwordValidation'])}}
                </p>
            </div>
        </div>

        <div class="form-group">
            <label for="confirmPassword">Confirm Password</label>
            <input id="confirmPassword" name="confirmPassword" type="password" [formControl]="confirmPassword"
                class="form-control" [(ngModel)]="confirmNewPassword" placeholder="Confirm Password"
                autocomplete="off" />
            <div class="error"
                *ngIf="(form.submitted || confirmPassword.dirty) && (!confirmPassword.valid || !passwordGroup.valid)">
                <p *ngIf="confirmPassword.errors['required']">Required</p>
                <p *ngIf="passwordGroup.errors['noMatch']">Passwords do not match</p>
            </div>
        </div>
    </div>
    <div class="error" *ngIf="serverError">
        <p>{{serverError}}</p>
    </div>
    <div class="buttonContainer">
        <button class="btn btn-primary" type="submit">Update Password</button>
    </div>
</form>

<rol-modal-dialog [show]="showConfirmation" (close)="showConfirmation = false">
    <div class="modal-body">
        <h1>Your password has been changed.</h1>
        <p>Click the Ok button to return to the login page.</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit" (click)="successAndReturn();">Ok</button>
    </div>
</rol-modal-dialog>