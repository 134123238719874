export class CropInventoryItem {
    constructor(appData?: any) {
        if (appData) {
            this.name = appData.Name;
            this.quantity = appData.Quantity;
            this.quantityUnitOfMeasure = appData.QuantityUnitOfMeasure,
            this.value = appData.Value;
        }
    }

    name: string = '';
    quantity?: number = null;
    quantityUnitOfMeasure: string = '';
    value?: number = null;
}
