export class OrganizationType {
    public static LLC = 'LLC';
    public static LLP = 'LLP';
    public static GeneralPartnership = 'General Partnership';
    public static Corporation = 'Corporation';

    static getValidTypes(): OrganizationType[] {
        return ['', OrganizationType.LLC, OrganizationType.LLP, OrganizationType.GeneralPartnership, OrganizationType.Corporation];
    }
}
