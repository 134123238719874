import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Application } from '../../models/application';
import { ApplicationType } from '../../models/application-type';
import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { Person } from '../../models/person';
import { TopForm } from '../top-form';
import { validateCheckboxFactory } from '../validation/checkbox-required.directive';
import { UtilitiesService } from '../../services/utilities.service';
import { UserService } from '../../services/user.service';
import { HistoryService } from '../../services/history.service';
import { NavigationOptions } from '../../models/navigation-options';
import { LookupService } from '../../../app/services/lookup.service';
import * as moment from 'moment';

class Country {
    Name: string;
    DisplayName: string;

    constructor(name: string, displayName: string) {
        this.Name = name;
        this.DisplayName = displayName;
    }
}

@Component({
    selector: 'rol-summary-and-sign',
    templateUrl: 'summary-and-sign.component.html',
    styleUrls: ['../styles_form.css', 'waiting.css', 'summary-and-sign.component.css', '../balance-sheet/financial-table.css']
})
export class SummaryAndSignComponent extends AbstractFormPage {
    recaptchaToken = '';
    showTandC = false;
    form: TopForm = new TopForm();
    ownership: FormControl;
    finances: FormControl;
    terms: FormControl;
    waiting = false;

    selectedOwner = 0;
    selectedBorrower = 0;

    isAnonymous = false;

    countries: Country[];

    get currentOwner(): Person {
        return this.application.entity.ultimateBeneficialOwners[this.selectedOwner];
    }

    get currentBorrower(): Person {
        return this.application.borrowers[this.selectedBorrower];
    }

    get isSingleOwnerApplication(): boolean {
        return this.application.appType === ApplicationType.Single;
    }

    get borrowerSectionTitle(): string {
        return this.isSingleOwnerApplication ? "Borrower" : "Co-Borrower";
    }

    get showPrimaryApplicantConfirmation(): boolean {
        return !this.isSingleOwnerApplication && this.selectedBorrower.toString() === '0';
    }

    get hasIncreasedRiskCountries(): boolean {
        return this.application.increasedRiskCountries.length > 0;
    }

    get disableSubmit() {
        return !this.finances.value;
    }

    get allBeneficialOwners() {
        return this.application.borrowers
            .filter(b => b.isBeneficialOwner)
            .concat(this.application.additionalOwners);
    }

    constructor(currentAppSvc: CurrentApplicationService,
        router: Router,
        utilities: UtilitiesService,
        history: HistoryService,
        private userService: UserService,
        private lookupService: LookupService
        ) {
        super(currentAppSvc, router, utilities, history);
        this.pageAlias = 'sign';
        this.initPage();
    }

    afterInitPage() {
        this.isAnonymous = this.userService.GetUser().isAnonymous;

        this.finances = new FormControl(false, validateCheckboxFactory());
        this.form.addControl('finances', this.finances);
        if (this.application.entity) {
            this.ownership = new FormControl(false, validateCheckboxFactory());
            this.form.addControl('ownership', this.ownership);
        }
        if (this.isAnonymous) {
            this.terms = new FormControl(false, validateCheckboxFactory());
            this.form.addControl('terms', this.terms);
        }
        if (this.hasIncreasedRiskCountries) {
            this.loadCountries();
        }
    }

    submit() {
        this.form.submitted = true;
        if (this.form.valid && (!this.isAnonymous || this.recaptchaToken !== '')) {
            if (!this.isAnonymous) {
                const currentDateTime = new Date();
                this.application.onlineAgreementAcceptanceDate = currentDateTime;
            }
            this.waiting = true;
            const newPage = NavigationOptions.AppPages(this.application, true)[this.pageNumber + 1];
            this.application.save()
                .then((_) => {
                    this.application.print();
                })
                .then((_) => {
                    this.application.setStatus('Printed')
                        .then((__) => {
                            // Bypass the confirmation page and take users to the dashboard for phase 1
                            this.router.navigate(['/dashboard']);
                        })
                        .catch((reason) => {
                            console.log(reason);
                            this.waiting = false;
                        });
                })
                .catch((_) => {
                    this.waiting = false;
                });
        } else {
            this.scrollToError();
        }
    }

    agreementChanged(event) {
        const checked = event.target.checked;
        const acceptedDateTime = checked ? new Date() : null;
        this.application.onlineAgreementAcceptanceDate = acceptedDateTime;
    }

    displayDate(date: Date): string { //  mm/dd/yyyy
        if (!date) { return ''; }
        const mm = (date.getMonth() + 1).toString();
        const dd = date.getDate().toString();
        return [!mm[1] ? '0' : '', mm, '/', !dd[1] ? '0' : '', dd, '/', date.getFullYear()].join('');
    }

    showEntity(): boolean {
        return this.application.entity !== null;
    }

    wasFormedInLast12Months(): boolean {
        return this.application.entity !== null
            && this.application.entity.richardsonEntityInformation.hasFormedInLast12Months;
    }

    activitiesNotConsistentWithVision(): boolean {
        return this.wasFormedInLast12Months()
            && this.application.entity.richardsonEntityInformation.isActivityConsistentWithVision === false;
    }

    hasRecentLeadershipChanges(): boolean {
        return this.wasFormedInLast12Months()
            && this.application.entity.richardsonEntityInformation.hasRecentLeadershipChanges;
    }

    isBorrowerNotForProfit(): boolean {
        return this.application.entity !== null
            && this.application.entity.richardsonEntityInformation.isBorrowerNotForProfitOrganization;
    }
    
    isBorrowerCharitable(): boolean {
        return this.isBorrowerNotForProfit()
            && (this.application.entity.richardsonEntityInformation.isBorrowerRegisteredCharity
                || this.application.entity.richardsonEntityInformation.doesBorrowerSolicitCharitableDonations);
    }

    showBeneficialOwners(): boolean {
        return this.application.entity 
            && (this.application.appType === ApplicationType.Entity
                || this.application.appType === ApplicationType.CoBorrower)
            && ((this.allBeneficialOwners && this.allBeneficialOwners.length > 0)
                || this.application.directors.length > 0);
    }

    getPhysicalAddress(person: Person) {
        return person.physicalAddress;
    }

    getMailingAddress(person: Person) {
        return person.mailingAddress;
    }

    recaptchaResolved(token: string) {
        this.recaptchaToken = token;
    }

    entityIsOther(app: Application) {
        return app.ownersList && app.entity.signer && !(app.ownersList.findIndex((o) => o === app.entity.signer) >= 0);
    }

    loadCountries() {
        this.lookupService.getAddressCountries()
            .then(resolve => {
                this.countries = resolve.map(x => new Country(x.Name, x.DisplayName));
                this.countries = this.countries.filter(c => c.Name !== 'CA' && c.Name !== 'US');
            });

    }

    getIncreasedRiskCountries() {
        if (this.countries && this.application.increasedRiskCountries) {
            const selectedCountries =
                this.countries
                    .filter(
                        c => this.application.increasedRiskCountries.some(irc => irc === c.Name))
                    .map(c => c.DisplayName);

            return selectedCountries.join(',');
        }

        return '';
    }

    formatMonth(month: number) {
        const months = [
            { name: 'Jan', value: 1 },
            { name: 'Feb', value: 2 },
            { name: 'Mar', value: 3 },
            { name: 'Apr', value: 4 },
            { name: 'May', value: 5 },
            { name: 'Jun', value: 6 },
            { name: 'Jul', value: 7 },
            { name: 'Aug', value: 8 },
            { name: 'Sep', value: 9 },
            { name: 'Oct', value: 10 },
            { name: 'Nov', value: 11 },
            { name: 'Dec', value: 12 },
        ];
        return months.find(m => m.value === month).name;
    }
}
